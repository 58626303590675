import { ActionType, TableDrawerState } from './types';
import { actions } from './actions';
import { setTableDrawer } from 'src/storage/TableDrawerConfig';
import { defaultState } from './constants';

const handlePersistTableDrawer = (state: TableDrawerState) => {
  setTableDrawer({
    dashboardSites: state.dashboardSites,
    managementTenants: state.managementTenants,
    managementCompanies: state.managementCompanies,
    managementUsers: state.managementUsers,
    managementVendors: state.managementVendors,
    managementTickets: state.managementTickets,
    dashboardAnnouncements: state.dashboardAnnouncements,
    managementNotifications: state.managementNotifications,
  });
};

// Initial table configs will be modelled after all "TABLE_HEAD" constants. Then saved into app storage.
// On first run, reducer needs to save default configs to storage.
// Subsequent runs will fetch from app storage.
// Each table config will be customizable.
export const reducer = (state: TableDrawerState, action: ActionType) => {
  let result = state;

  switch (action.type) {
    case actions.SET_DASHBOARD_SITES:
      result = {
        ...state,
        dashboardSites: action.payload,
      };

      handlePersistTableDrawer(result);
      return result;

    case actions.SET_MANAGEMENT_TENANTS:
      result = {
        ...state,
        managementTenants: action.payload,
      };

      handlePersistTableDrawer(result);
      return result;

    case actions.SET_MANAGEMENT_COMPANIES:
      result = {
        ...state,
        managementCompanies: action.payload,
      };

      handlePersistTableDrawer(result);
      return result;

    case actions.SET_MANAGEMENT_USERS:
      result = {
        ...state,
        managementUsers: action.payload,
      };

      handlePersistTableDrawer(result);
      return result;

    case actions.SET_MANAGEMENT_VENDORS:
      result = {
        ...state,
        managementVendors: action.payload,
      };

      handlePersistTableDrawer(result);
      return result;

    case actions.SET_MANAGEMENT_TICKETS:
      result = {
        ...state,
        managementTickets: action.payload,
      };

      handlePersistTableDrawer(result);
      return result;  

    case actions.SET_DASHBOARD_ANNOUNCEMENTS:
      result = {
        ...state,
        dashboardAnnouncements: action.payload,
      };

      handlePersistTableDrawer(result);
      return result;

    case actions.SET_MANAGEMENT_NOTIFICATIONS:
      result = {
        ...state,
        managementNotifications: action.payload,
      };

      handlePersistTableDrawer(result);
      return result;

    case actions.SET_OPEN:
      result = {
        ...state,
        open: !state.open,
        currentOperation: action.payload,
      };

      return result;

    case actions.RESET_SITES:
      result = {
        ...defaultState,
        open: true,
      };

      return result;
    default:
      return { ...state };
  }
};
