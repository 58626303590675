import { ApplicationState } from '../types';

const _getData = (state: ApplicationState) => state.CustomerSuccessAccess;

const getCustomerSuccessAccess = (state: ApplicationState) =>
  _getData(state).customer_success_access;

const getLimit = (state: ApplicationState) => _getData(state).limit;

const getCursor = (state: ApplicationState) => _getData(state).cursor;

const getOrderBy = (state: ApplicationState) => _getData(state).order_by;

const getOrderByDirection = (state: ApplicationState) => _getData(state).order_by_direction;

const getSearch = (state: ApplicationState) => _getData(state).search;

const getSearchField = (state: ApplicationState) => _getData(state).search_field;

const getCompanyId = (state: ApplicationState) => _getData(state).company_id;

const getUserId = (state: ApplicationState) => _getData(state).user_id;

const getPostCustomerSuccessAccessLoading = (state: ApplicationState) =>
  _getData(state).is_post_customer_success_access_loading;

const getPostCustomerSuccessAccessSuccess = (state: ApplicationState) =>
  _getData(state).is_post_customer_success_access_success;

const getDeleteCustomerSuccessAccessLoading = (state: ApplicationState) =>
  _getData(state).is_delete_customer_success_access_loading;

const getDeleteCustomerSuccessAccessSuccess = (state: ApplicationState) =>
  _getData(state).is_delete_customer_success_access_success;

const getCustomerSuccessSwitchError = (state: ApplicationState) =>
  _getData(state).customer_success_switch_error;

const getCustomerSuccessSwitchLoading = (state: ApplicationState) =>
  _getData(state).is_customer_success_switch_loading;

const getCustomerSuccessCompanies = (state: ApplicationState) =>
  _getData(state).customer_success_companies;

const getCustomerSuccessCompaniesLoading = (state: ApplicationState) =>
  _getData(state).is_customer_success_companies_loading;


const getCustomerSuccessAccessCompaniesSearch = (state: ApplicationState) =>
  _getData(state).customer_success_companies_search;


const getError = (state: ApplicationState) => _getData(state).error;

const isLoading = (state: ApplicationState) => _getData(state).loading;

const getCustomerSuccessAccessCompanyOptions = (state: ApplicationState) =>
  _getData(state).customer_success_access_company_options;

const getCustomerSuccessAccessUserOptions = (state: ApplicationState) =>
  _getData(state).customer_success_access_user_options;

const getCustomerSuccessAccessCompanyOptionsSearch = (state: ApplicationState) =>
  _getData(state).customer_success_access_company_options_search;

const getCustomerSuccessAccessUserOptionsSearch = (state: ApplicationState) =>
  _getData(state).customer_success_access_user_options_search;

const getCustomerSuccessAccessUserOptionsLoading = (state: ApplicationState) =>
  _getData(state).is_customer_success_access_user_options_loading;

const getCustomerSuccessAccessCompanyOptionsLoading = (state: ApplicationState) =>
  _getData(state).is_customer_success_access_company_options_loading;

const selectors = {
  getCustomerSuccessAccess,
  getCompanyId,
  getUserId,
  getCustomerSuccessCompanies,
  getCustomerSuccessCompaniesLoading,
  getCustomerSuccessAccessCompaniesSearch,
  getPostCustomerSuccessAccessLoading,
  getPostCustomerSuccessAccessSuccess,
  getDeleteCustomerSuccessAccessLoading,
  getDeleteCustomerSuccessAccessSuccess,
  getCustomerSuccessSwitchError,
  getCustomerSuccessSwitchLoading,
  getCursor,
  getLimit,
  getOrderBy,
  getOrderByDirection,
  getSearch,
  getSearchField,
  getError,
  isLoading,
  getCustomerSuccessAccessCompanyOptions,
  getCustomerSuccessAccessUserOptions,
  getCustomerSuccessAccessUserOptionsLoading,
  getCustomerSuccessAccessCompanyOptionsLoading,
  getCustomerSuccessAccessCompanyOptionsSearch,
  getCustomerSuccessAccessUserOptionsSearch,
};

export default selectors;
