import request from 'src/services/request';
import endpoints from './endpoints';

const api = {
  authenticate: async (email: string, password: string) =>
    request(endpoints.login, {
      method: 'POST',
      body: JSON.stringify({
        user_credentials: {
          email: email,
          password: password,
        }
      }),
      headers: {
        'Content-Type': 'application/json',
      },
      // credentials: 'include',
    }),
  requestResetPassword: async (email: string) =>
    request(`${endpoints.reset_password}send/`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email
      }),
    }),
  resetPassword: async (password: string, token: string) =>
    request(endpoints.reset_password, {
      method: 'PUT',
      body: JSON.stringify({
        password: password,
        token: token,
      }),
      headers: { 'Content-Type': 'application/json' },
    }),
  postOnboardUser: async (email: string) =>
    request(endpoints.onboard, {
      method: 'POST',
      body: JSON.stringify({
        email: email,
      }),
      headers: { 'Content-Type': 'application/json' },
    }),
};

export default api;
