import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// actions
import QcActions from 'src/store/Qc/actions';

// selectors
import QcSelectors from 'src/store/Qc/selectors';

const useQc = () => {
  const loading = useSelector(QcSelectors.isLoading);
  const error = useSelector(QcSelectors.isError);
  const revisions = useSelector(QcSelectors.revisions);
  const success = useSelector(QcSelectors.isSuccess);
  const fileExtensionSelectOptions = useSelector(QcSelectors.fileExtensionSelectOptions);
  const filePurposeSelectOptions = useSelector(QcSelectors.filePurposeSelectOptions);

  // qc record
  const qcRecords = useSelector(QcSelectors.qcRecords);
  const lastQcRecord = useSelector(QcSelectors.lastQcRecord);
  const isFetchQcRecordLoading = useSelector(QcSelectors.isFetchQcRecordsLoading);
  const isCreateQcRecordLoading = useSelector(QcSelectors.isCreateQcRecordLoading);
  const isCreateQcRecordSuccess = useSelector(QcSelectors.isCreateQcRecordSuccess);
  const isUpdateQcRecordLoading = useSelector(QcSelectors.isUpdateQcRecordLoading);
  const fallOutReasons = useSelector(QcSelectors.fallOutReasons);
  const statusColors = useSelector(QcSelectors.statusColors);
  const isFetchFallOutReasonsLoading = useSelector(QcSelectors.isFetchFallOutReasonsLoading);
  const isFetchStatusColorsLoading = useSelector(QcSelectors.isFetchStatusColorsLoading);

  const dispatch = useDispatch();

  const setError = useCallback(
    (error: Error | undefined) => {
      dispatch(QcActions.setError(error));
    },
    [dispatch]
  );

  const fetchOtiqFiles = useCallback(
    (sasId: any) => {
      dispatch(QcActions.getOtiqRevisions(sasId));
    },
    [dispatch]
  );

  const insertOtiqFiles = useCallback(
    (payload: any) => {
      dispatch(QcActions.insertOtiqFiles(payload));
    },
    [dispatch]
  );

  const insertOtiqRevision = useCallback(
    (payload: any) => {
      dispatch(QcActions.insertOtiqRevisions(payload));
    },
    [dispatch]
  );

  const deleteOtiqFile = useCallback(
    (fileId: any, sasId: string) => {
      dispatch(QcActions.deleteOtiqFile(fileId, sasId));
    },
    [dispatch]
  );

  // Select options
  const fetchFilePurposeOptions = useCallback(() => {
    dispatch(QcActions.fetchFilePurposeOptions());
  }, [dispatch]);

  const fetchFileExtenionOptions = useCallback(() => {
    dispatch(QcActions.fetchFileExtensionOptions());
  }, [dispatch]);

  // qc record
  const fetchLastQcRecord = useCallback(
    (currentSiteAssessmentId: string) => {
      dispatch(QcActions.fetchLastQcRecord(currentSiteAssessmentId));
    },
    [dispatch]
  );

  const fetchQcRecords = useCallback(
    (currentSiteAssessmentId: string) => {
      dispatch(QcActions.fetchQcRecords(currentSiteAssessmentId));
    },
    [dispatch]
  );

  const updateQcRecord = useCallback(
    ( qc_form_id, qc: any) => {
      dispatch(QcActions.updateQcRecord(qc_form_id, qc));
    },
    [dispatch]
  );
  const createQcRecord = useCallback(
    (qc: any) => {
      dispatch(QcActions.createQcRecord(qc));
    },
    [dispatch]
  );
  const fetchFallOutReasons = useCallback(() => {
    dispatch(QcActions.fetchFallOutReasons());
  }, [dispatch]);

  const fetchStatusColors = useCallback(() => {
    dispatch(QcActions.fetchStatusColors());
  }, [dispatch]);

  return {
    revisions,
    loading,
    error,
    success,
    setError,
    fetchOtiqFiles,
    insertOtiqFiles,
    deleteOtiqFile,
    insertOtiqRevision,
    fileExtensionSelectOptions,
    filePurposeSelectOptions,
    fetchFilePurposeOptions,
    fetchFileExtenionOptions,

    // qc record
    qcRecords,
    lastQcRecord,
    isCreateQcRecordLoading,
    isCreateQcRecordSuccess,
    isUpdateQcRecordLoading,
    isFetchQcRecordLoading,
    fetchQcRecords,
    fetchLastQcRecord,
    updateQcRecord,
    createQcRecord,
    fetchFallOutReasons,
    fetchStatusColors,
    fallOutReasons,
    statusColors,
    isFetchFallOutReasonsLoading,
    isFetchStatusColorsLoading,
  };
};

export default useQc;
