import { Link } from 'react-router-dom';

// ca
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  TablePagination,
  PageTableHead,
} from 'ca-react-component-lib';

// components
import QcRecordStatusPopover from 'src/components/QcRecordStatusPopover';

// hooks
import useSiteSearchQuery from 'src/hooks/useSiteSearchQuery';

// constants
import { TABLE_HEAD } from './constants';

// types
import { SORT } from 'src/@types/root';
import { SitesTableProps } from './types';
import { SiteWithAssessmentAndUserRoleSchema } from 'src/@types/site';

const SitesTable = ({ onClose = () => null }: SitesTableProps) => {
  const { quickSearchQueryResult, setQuickSearchQuery, quickSearchQuery } = useSiteSearchQuery();

  if (
    !quickSearchQueryResult ||
    (quickSearchQueryResult && quickSearchQueryResult.sites?.length === 0)
  ) {
    return null;
  }
  const handleChangeRowsPerPage = (event) => {
    setQuickSearchQuery({
      ...quickSearchQuery,
      limit: parseInt(event.target.value, 10),
      cursor: ''
    });
  };

  const handlePageChange = (newPage) => {
  
    const adjustedNewPage = newPage;
  
    const current_page = quickSearchQueryResult?.current_page ? quickSearchQueryResult.current_page - 1 : 0;
    const isNextPage = adjustedNewPage > current_page;
    let cursor = '';
  
    if (isNextPage) {
      cursor = quickSearchQueryResult?.next_page_cursor ?? '';
    } else {
      cursor = quickSearchQueryResult?.previous_page_cursor ?? '';
    }

    setQuickSearchQuery({
      ...quickSearchQuery,
      cursor: cursor,
    });

  };


  return (
    <>
      <TableContainer>
        <Table>
          <PageTableHead
            order={quickSearchQuery.order_by_direction === SORT.ASC ? 'asc' : 'desc'}
            orderBy={quickSearchQuery.order_by}
            headLabel={TABLE_HEAD}
            onRequestSort={(column) => {
              setQuickSearchQuery({
                ...quickSearchQuery,
                order_by: column,
                order_by_direction:
                  quickSearchQuery.order_by === column && quickSearchQuery.order_by_direction === SORT.ASC
                    ? SORT.DESC
                    : SORT.ASC,
              });
            }}
          />
          <TableBody>
            {quickSearchQueryResult.sites?.map(
              (siteRoot: SiteWithAssessmentAndUserRoleSchema, index: number) => {
                const { name, owner_name, owner_site_id, assessments } = siteRoot;

                return (
                  <TableRow hover key={index} tabIndex={-1}>
                    <TableCell>
                      <Link
                        to={`/app/site/${name}`}
                        style={{
                          textDecoration: 'none',
                        }}
                        onClick={onClose}
                      >
                        {name ? (
                          <Typography sx={{ fontSize: '0.875rem' }}>
                            {`#${name}`}
                          </Typography>
                        ) : (
                          '-'
                        )}
                      </Link>
                    </TableCell>
                    <TableCell>
                      {owner_name ?? '-'}
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {owner_site_id ?? '-'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Stack direction="row" spacing={1}>
                        {assessments &&
                          assessments.map((assessment: any, index: number) => (
                            <QcRecordStatusPopover
                              key={index}
                              companySiteId={assessment.client_site_identifier ?? ''}
                              siteAssessment={assessment}
                            />
                          ))}
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[15, 30, 50]}
        component="div"
        count={quickSearchQueryResult.total_count}
        rowsPerPage={quickSearchQuery.limit}
        page={quickSearchQuery.current_page - 1}
        onPageChange={(e, page) => {
          handlePageChange(page);
        }}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default SitesTable;
