import { SuccessError } from 'src/@types/root';
import api from './api';

import { LoginRequestResponse } from './types';

export const login = async (email: string, password: string): Promise<LoginRequestResponse> => {
  try {
    const response = await api.authenticate(email, password);
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const requestResetPassword = async (email: string): Promise<any> => {
  try {
    const response: SuccessError = await api.requestResetPassword(email);
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const resetPassword = async (
  password: string,
  token: string
): Promise<any> => {
  try {
    const response: SuccessError = await api.resetPassword(password, token);
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const postOnboardUser = async (email: string): Promise<any> => {
  try {
    const response: SuccessError = await api.postOnboardUser(email);
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
