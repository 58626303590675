import request from 'src/services/request';
import endpoints from './endpoints';

import { QCFormBodyRequestSchema } from './types';

const api = {
  fetchAssessmentQCForms: async (assessment_id: string) =>
    request(`${endpoints.assessment}${assessment_id}/qc-form/`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }),

  fetchAssessmentLastQCForm: async (assessment_id: any) =>
    request(`${endpoints.assessment}${assessment_id}/last-qc-form/`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }),
    
  postQCForm: async (payload: QCFormBodyRequestSchema) =>
    request(`${endpoints.qc_form}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    }),
    
  patchQCForm: async (qc_form_id, payload: QCFormBodyRequestSchema) =>
    request(`${endpoints.qc_form}${qc_form_id}/`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    }),

  fetchQCFalloutReasonOptions: async () =>
    request(`${endpoints.fallout_reason}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }),

  fetchQCStatusColorOptions: async () =>
    request(`${endpoints.status_color}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }),
};

export default api;
