export type PageSearchQueryResultResponse = {
  From: number;
  Page: number;
  PerPage: number;
  To: number;
  Total: number;
};

export type PageSearchQueryResult = {
  from: number;
  currentPageNumber: number;
  pageSize: number;
  to: number;
  totalRecords: number;
};

export type LabelValue = {
  label?: string;
  value?: string;
};

export type SortBy = 'ASC' | 'DESC';

export enum SORT {
  ASC = 'asc',
  DESC = 'desc',
}

export type SuccessError = {
  errors: string | null;
  success: boolean | null;
  message: string | null;
};

export type SuccessErrorResponse = {
  errors: string | null;
  success: boolean | null;
  message: string | null;
};

export type CategoryLabelValue = {
  category: string | null;
  label: string;
  value: string;
};

export enum KEYS {
  // Auth sessions.
  SESSION = 'consilienceanalytics.session',
  REFRESH_SESSION = 'consilienceanalytics.refresh_session',

  // Table drawer configuration.
  TABLE_DRAWER_CONFIG = 'consilienceanalytics.tableDrawerConfig',

  // Site Filters SearchQuery
  SEARCH_QUERY = 'consilienceanalytics.searchQuery',

  // Todo Filters Tenant Id
  TODO_TENANT = 'consilienceanalytics.todoTenantId',
}
