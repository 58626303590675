import { useCallback } from 'react';

// @mui
import {
  Typography,
  Stack,
  useTheme,
  Button,
  ICON,
  alpha,
  FontAwesome,
  useResponsive
} from 'ca-react-component-lib';

// utils
import { getOperationalSystem } from 'ca-react-util-lib';

// types
import { OPERATIONAL_SYSTEM } from 'src/@types/operationalSystem';

const HotKeysLabel = ({ onClick }: { onClick: Function }) => {
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'lg');

  const label = useCallback(() => {
    const os = getOperationalSystem();

    if (os === OPERATIONAL_SYSTEM.MAC) {
      return '⌘ + K';
    }
    if (os === OPERATIONAL_SYSTEM.WINDOWS || os === OPERATIONAL_SYSTEM.LINUX) {
      return 'Ctrl + K';
    }
    return '';
  }, []);

  return (
    <Button
      variant={isDesktop ? "contained" : "text"}
      sx={isDesktop ? {
        borderRadius: 0.75,
        background: alpha(theme.palette.common.black, 0.06),
        color: alpha(theme.palette.common.black, 0.6),
        padding: {
          xs: '5px 7px',
          md: '5px 15px',
        },
        width: 'auto',
        minWidth: {
          xs: 0,
          md: 64,
        },
        "&:hover": {
          color: theme.palette.common.white
        }
      } : { minWidth: 24 }}
      onClick={() => {
        onClick();
      }}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <FontAwesome icon="magnifying-glass" size={ICON.SMALL} />
        {isDesktop && <Typography
          sx={{
            fontWeight: 'fontWeightBold',
            display: {
              xs: 'none',
              sm: 'block',
            },
          }}
          variant="body2"
        >
          {label()}
        </Typography>}
      </Stack>
    </Button>
  );
};

export default HotKeysLabel;
