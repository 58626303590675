// util
import { getOperationalSystem } from 'ca-react-util-lib';

// enum
import { OPERATIONAL_SYSTEM } from 'src/@types/operationalSystem';

export enum SHORTCUT_KEY {
  META = 'META',
  CTRL = 'CTRL',
}

// Windows: "ctrl + k"
// Mac: "command + k"
export const operationalSystemKey =
  getOperationalSystem() === OPERATIONAL_SYSTEM.MAC ? SHORTCUT_KEY.META : SHORTCUT_KEY.CTRL;
