import { SiteRoleResponse, UserBodyRequestSchema, UserOptionsContent, UserPagination, UserWithCompanyResponse } from 'src/@types/user';

export enum UsersActionType {
  FETCH_ALL_USERS = 'Users@FETCH_ALL_USERS',
  SET_USERS = 'Users@SET_USERS',
  FETCH_USER_CONTENT = 'Users@FETCH_USER_CONTENT',
  SET_USER_CONTENT = 'Users@SET_USER_CONTENT',
  SET_LIMIT = 'Users@SET_LIMIT',
  SET_CURSOR = 'Users@SET_CURSOR',
  SET_ORDERBY = 'Users@SET_ORDERBY',
  SET_ORDERBY_DIRECTION = 'Users@SET_ORDERBY_DIRECTION',
  SET_SEARCH = 'Users@SET_SEARCH',
  SET_SEARCH_FIELD = 'Users@SET_SEARCH_FIELD',
  SET_COMPANY_ID = 'Users@SET_COMPANY_ID',
  SET_EXCLUDE_INVITED = 'Users@SET_EXCLUDE_INVITED',
  SET_LOADING = 'Users@SET_LOADING',
  SET_ERROR = 'Users@SET_ERROR',

  SET_INVITED = 'Users@SET_INVITED',
  SET_INVITE_USER_BY_ID = 'Users@SET_INVITE_USER_BY_ID',
  INVITE_USER_BY_ID = 'Users@INVITE_USER_BY_ID',

  // users options.
  FETCH_USER_OPTIONS = 'Users@FETCH_USER_OPTIONS',
  SET_USER_OPTIONS = 'Users@SET_USER_OPTIONS',
  SET_USER_OPTIONS_LOADING = 'Users@SET_USER_OPTIONS_LOADING',
  SET_USER_OPTIONS_SELECTION_ID = 'Users@SET_USER_OPTIONS_SELECTION_ID',
  SET_MIN_ROLE_LEVEL = 'Users@SET_MIN_ROLE_LEVEL',
  SET_MAX_ROLE_LEVEL = 'Users@SET_MAX_ROLE_LEVEL',
  

  // update user.
  UPDATE_USER = 'Users@UPDATE_USER',
  SET_USER_UPDATE_LOADING = 'Users@SET_USER_UPDATE_LOADING',
  SET_USER_UPDATE_SUCCESS = 'Users@SET_USER_UPDATE_SUCCESS',
  SET_USER_CONTENT_LOADING = 'Users@SET_USER_CONTENT_LOADING',
 
  // add user.
  CREATE_USER = 'Users@CREATE_USER',
  SET_USER_CREATE_LOADING = 'Users@SET_USER_CREATE_LOADING',
  SET_USER_CREATE_SUCCESS = 'Users@SET_USER_CREATE_SUCCESS',

  // role types.
  FETCH_ROLES_TYPES = 'Users@FETCH_ROLES_TYPES',
  SET_ROLE_TYPES = 'Users@SET_ROLE_TYPES',
  SET_ROLE_TYPES_LOADING = 'Users@SET_ROLE_TYPES_LOADING',

  // bulk insert.
  BULK_INSERT = 'Users@BULK_INSERT',
  SET_BULK_INSERT_LOADING = 'Users@SET_BULK_INSERT_LOADING',
  SET_BULK_INSERT_RESULT = 'Users@SET_BULK_INSERT_RESULT',

  //role options
  FETCH_ROLE_OPTIONS = 'Users@FETCH_ROLE_OPTIONS',
  SET_ROLE_OPTIONS = 'Users@SET_ROLE_OPTIONS',
  SET_ROLE_OPTIONS_LOADING = 'Users@SET_ROLE_OPTIONS_LOADING',

  CLEAR = 'Users@CLEAR',
}

export type UsersState = {
  user_pagination?: UserPagination;
  user_content?: UserWithCompanyResponse;
  limit?: number;
  cursor?: string;
  search?: string;
  search_field?: 'company_name' | 'full_name' | 'email';
  order_by_direction?: 'asc' | 'desc';
  order_by?: 'company_name' | 'full_name' | 'email' | 'phone_number' | 'invited' | 'date_created';
  company_id?: string;
  is_loading: boolean;
  error?: Error;
  exclude_invited?: boolean;

  // user simple.
  user_options?: UserOptionsContent;
  is_user_options_loading?: boolean;
  user_options_selection_id?: string;
  min_role_level?: number;
  max_role_level?: number;

  // edit user.
  is_user_update_loading?: boolean;
  is_user_content_loading?: boolean;
  is_user_update_success?: boolean;

  // add user.
  is_user_create_loading: boolean;
  is_user_create_success?: boolean;

  // role types.
  role_types?: SiteRoleResponse;
  is_role_types_loading: boolean;

  // bulk insert.
  bulk_insert_result: any;
  is_bulk_insert_loading: boolean;

  //role options
  role_options?: any;
  is_role_options_loading: boolean;
};

export type UsersPayload = {
  user_pagination?: UserPagination;
  user_content?: UserWithCompanyResponse;
  limit?: number;
  cursor?: string;
  search?: string;
  search_field?: 'company_name' | 'full_name' | 'email';
  order_by_direction?: 'asc' | 'desc';
  order_by?: 'company_name' | 'full_name' | 'email' | 'phone_number' | 'invited' | 'date_created';
  company_id?: string;
  is_loading?: boolean;
  error?: Error;
  exclude_invited?: boolean;

  // user simple.
  user_options?: UserOptionsContent;
  is_user_options_loading?: boolean;
  user_options_selection_id?: string;
  min_role_level?: number;
  max_role_level?: number;

  // edit user.
  is_user_update_loading?: boolean;
  is_user_content_loading?: boolean;
  user_id?: string;
  user_ids?: string[];
  is_user_update_success?: boolean;

  // add user.
  is_user_create_loading?: boolean;
  is_user_create_success?: boolean;
  user?: UserBodyRequestSchema;

  // role types.
  role_types?: SiteRoleResponse;
  is_role_types_loading?: boolean;

  // bulk insert.
  bulk_insert_result?: any;
  is_bulk_insert_loading?: boolean;
  file?: any;

  //role options
  role_options?: any;
  is_role_options_loading?: boolean;
};

export type UsersAction = {
  payload?: UsersPayload;
  type: UsersActionType;
};

export type UsersReducer = (state: UsersState, payload?: UsersPayload) => UsersState;
