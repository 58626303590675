export type Tenant = {
    id?: string;
    name?: string;
    subdomain?: string;
    logo_url?: string;
};


export type TenantContent = {
  tenant?: Tenant;
};


export type TenantCompany = {
  id?: string;
  name?: string;
  short_name?: string;
  abbreviation?: string;
  website?: string;
  accounts_payable_email?: string;
  bill_to_address?: string;
  accounts_receivable_email?: string;
  bill_from_address?: string;
  users_count?: number;
  is_vendor?: boolean;
  is_tower_owner?: boolean;
};


export type TenantWithCompany = {
  id?: string;
  name?: string;
  subdomain?: string;
  logo_url?: string;
  company?: TenantCompany;
};


export type TenantWithCompanyContent = {
  tenant?: TenantWithCompany;
};


export type TenantPagination = {
  previous_page_cursor?: string;
  next_page_cursor?: string;
  page_size?: number;
  total_count?: number;
  current_page?: number;
  last_page?: number;
  tenants?: Tenant[];
};


export type TenantCompanyCreateBody = {
  short_name?: string;
  abbreviation?: string;
  website?: string;
  accounts_payable_email?: string;
  bill_to_address?: string;
  accounts_receivable_email?: string;
  bill_from_address?: string;
  is_vendor?: boolean;
  is_tower_owner?: boolean;
};


export type TenantCreateBody = {
  name?: string;
  subdomain?: string;
  logo_url?: string;
  company?: TenantCompanyCreateBody;
};


export type TenantCreateRequestObj = {
  tenant?: TenantCreateBody;
};


export type TenantCompanyUpdateBody = {
  short_name?: string;
  abbreviation?: string;
  website?: string;
  accounts_payable_email?: string;
  bill_to_address?: string;
  accounts_receivable_email?: string;
  bill_from_address?: string;
  is_vendor?: boolean;
  is_tower_owner?: boolean;
};

export type TenantUpdateBody = {
  name?: string;
  subdomain?: string;
  logo_url?: string;
  company?: TenantCompanyUpdateBody;
};

export type TenantUpdateRequestObj = {
  tenant?: TenantUpdateBody;
};


export type TenantOptions = {
  id?: string;
  name?: string;
};


export type TenantOptionsContent = {
  tenants?: TenantOptions[];
};


export type TenantOptionsRequestObj = {
  search?: string;
};


export enum TENANT_FORM_VARIANT {
  EDIT = 'Edit',
  CREATE = 'Create',
};

export enum TENANT_COLUMNS {
  NAME = 'name',
  COMPANY_NAME = 'company_name',
  SUBDOMAIN = 'subdomain',
  ACTIONS = 'Actions',
};