import api from './api';

export const fetchAllCustomerSuccessAccess = async (
  limit: number,
  cursor?: string,
  search?: string,
  search_field?: string,
  order_by_direction?: string,
  order_by?: string,
  company_id?: string,
  user_id?: string
) => {
  try {
    const response: any = await api.fetchAllCustomerSuccessAccess(
      limit,
      cursor,
      search,
      search_field,
      order_by_direction,
      order_by,
      company_id,
      user_id
    );
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const postCustomerSuccessAccess = async (customer_success_access: any) => {
  try {
    const response = await api.postCustomerSuccessAccess(customer_success_access);
    return response;
  } catch (e) {
    throw e;
  }
};

export const deleteCompanySuccessAccess = async (customer_success_access: any) => {
  try {
    const response = await api.deleteCompanySuccessAccess(customer_success_access);
    return response;
  } catch (e) {
    throw e;
  }
};

export const fetchCustomerSuccessCompanies = async (
  search?: string,
) => {
  try {
    const response = await api.fetchCustomerSuccessCompanies(search);
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const setCustomerSuccessCompany = async (companyId: string) => {
  try {
    const response = await api.setCustomerSuccessCompany(companyId);
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const fetchCustomerSuccessAccessUserOptions = async (
  search?: string,
  company_id?: string
) => {
  try {
    const response = await api.fetchCustomerSuccessAccessUserOptions(search, company_id);
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const fetchCustomerSuccessAccessCompanyOptions = async (
  search?: string,
  user_id?: string
) => {
  try {
    const response = await api.fetchCustomerSuccessAccessCompanyOptions(search, user_id);
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
