import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnnouncementEditorObj } from 'src/@types/announcement';
import announcementActions from 'src/store/Announcement/actions';
import announcementSelectors from 'src/store/Announcement/selector';

const useAnnouncement = () => {
  const announcement = useSelector(announcementSelectors.getAnnouncement);
  const announcementContent = useSelector(announcementSelectors.getAnnouncementContent);
  const isLoading = useSelector(announcementSelectors.getLoading);
  const limit = useSelector(announcementSelectors.getLimit);
  const cursor = useSelector(announcementSelectors.getCursor);
  const orderBy = useSelector(announcementSelectors.getOrderBy);
  const orderByDirection = useSelector(announcementSelectors.getOrderByDirection);
  const search = useSelector(announcementSelectors.getSearch);
  const searchField = useSelector(announcementSelectors.getSearchField);
  const announcementEditorObj = useSelector(announcementSelectors.getAnnouncementEditorObj);
  const isEditorUploading = useSelector(announcementSelectors.getIsEditorUploading);
  const finishEditorUploading = useSelector(announcementSelectors.getFinishEditorUploading);

  const dispatch = useDispatch();

  const fetchAllAnnouncements = useCallback(() => {
    dispatch(announcementActions.fetchAllAnnouncements());
  }, [dispatch]);

  const fetchAnnouncementContent = useCallback(
    (announcementId: string) => {
      dispatch(announcementActions.fetchAnnouncementContent(announcementId));
    },
    [dispatch]
  );

  const setLimit = useCallback(
    (limit: number) => {
      dispatch(announcementActions.setLimit(limit));
    },
    [dispatch]
  );

  const setCursor = useCallback(
    (cursor: string) => {
      dispatch(announcementActions.setCursor(cursor));
    },
    [dispatch]
  );

  const setOrderBy = useCallback(
    (orderBy: 'date_created' | 'title') => {
      dispatch(announcementActions.setOrderBy(orderBy));
    },
    [dispatch]
  );

  const setOrderByDirection = useCallback(
    (orderByDirection: 'asc' | 'desc') => {
      dispatch(announcementActions.setOrderByDirection(orderByDirection));
    },
    [dispatch]
  );

  const setSearch = useCallback(
    (search: string) => {
      dispatch(announcementActions.setSearch(search));
    },
    [dispatch]
  );

  const setSearchField = useCallback(
    (searchField: 'title' | 'content') => {
      dispatch(announcementActions.setSearchField(searchField));
    },
    [dispatch]
  );

  const setAnnouncementEditorObj = useCallback(
    (announcementEditorObj: AnnouncementEditorObj) => {
      dispatch(announcementActions.setAnnouncementEditorObj(announcementEditorObj));
    },
    [dispatch]
  );

  const postAnnouncement = useCallback(
    (editorContent: string) => {
      dispatch(announcementActions.postAnnouncement(editorContent));
    },
    [dispatch]
  );

  const patchAnnouncement = useCallback(
    (announcementId: string, editorContent: string) => {
      dispatch(announcementActions.patchAnnouncement(announcementId, editorContent));
    },
    [dispatch]
  );

  const setIsEditorUploading = useCallback(
    (isEditorUploading) => {
      dispatch(announcementActions.setIsEditorUploading(isEditorUploading));
    }, [dispatch]
  );

  const setFinishEditorUploading = useCallback(
    (finishEditorUploading) => {
      dispatch(announcementActions.setFinishEditorUploading(finishEditorUploading));
    }, [dispatch]
  );

  return {
    announcement,
    announcementContent,
    fetchAllAnnouncements,
    fetchAnnouncementContent,
    setOrderBy,
    setLimit,
    setCursor,
    setOrderByDirection,
    setSearch,
    setSearchField,
    setAnnouncementEditorObj,
    postAnnouncement,
    setIsEditorUploading,
    setFinishEditorUploading,
    patchAnnouncement,
    limit,
    cursor,
    orderByDirection,
    orderBy,
    search,
    searchField,
    isLoading,
    announcementEditorObj,
    isEditorUploading,
    finishEditorUploading
  };
};

export default useAnnouncement;
