import { ReactNode } from 'react';

import { SITE_COLUMNS } from 'src/@types/site';

export type ActionType = {
  type: string;
  payload: any;
};

export type TableHeads = Array<{
  id: string | number | SITE_COLUMNS;
  label: string;
  alignRight: boolean;
  canSort: boolean;
  enabled: boolean;
}>;

export type TableDrawerState = {
  dashboardSites: TableHeads;
  managementTenants: TableHeads;
  managementCompanies: TableHeads;
  managementUsers: TableHeads;
  managementVendors: TableHeads;
  managementTickets: TableHeads;
  dashboardAnnouncements: TableHeads;
  managementNotifications: TableHeads;
  open: boolean;
  currentOperation: Operation;
};

export enum Operation {
  setDashboardSites = 1,
  setManagementTenants,
  setManagementCompanies,
  setManagementUsers,
  setManagementVendors,
  resetSites,
  setManagementTickets,
  setDashboardAnnouncements,
  setManagementNotifications,
}

export type ContextProps = {
  dashboardSites: TableHeads;
  managementTenants: TableHeads;
  managementCompanies: TableHeads;
  managementUsers: TableHeads;
  managementVendors: TableHeads;
  managementTickets: TableHeads;
  dashboardAnnouncements: TableHeads;
  managementNotifications: TableHeads;
  open: boolean;
  currentOperation: Operation;
  changeTableColumns: (tableOperation: number, arr: TableHeads) => void;
  toggleDrawer: (operation: number) => void;
};

export type ProviderProps = {
  children: ReactNode;
};
