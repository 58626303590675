import { TOAST_SEVERITY } from "src/@types/toast";

export enum ToastActionType {
  SET_TOAST = 'Toast@SET_TOAST',
  CLEAR = 'ToastActionType@CLEAR',
}

export type ToastState = {
  message?: string;
  severity: TOAST_SEVERITY;
  options?: any;
};

export type ToastPayload = {
  message?: string;
  severity: TOAST_SEVERITY;
  options?: any;
};

export type ToastAction = {
  payload?: ToastPayload;
  type: ToastActionType;
};

export type ToastReducer = (
  state: ToastState,
  payload?: ToastPayload
) => ToastState;
