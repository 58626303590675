import { put, takeLatest, call, select } from 'redux-saga/effects';

// services
import * as tenantService from 'src/servicesV2/tenant';

import { TOAST_SEVERITY } from 'src/@types/toast';

// actions
import actions from './actions';

// selectors
import selector from './selectors';

import ToastAction from 'src/store/Toast/actions';
import { TenantPagination, TenantOptionsContent, TenantWithCompanyContent } from 'src/@types/tenant';
import { TenantAction, TenantActionType } from './types';

export function* fetchAllTenants() {
  const limit: number = yield select(selector.getLimit);
  const cursor: string = yield select(selector.getCursor);
  const order_by_direction: string = yield select(selector.getOrderByDirection);
  const order_by: string = yield select(selector.getOrderBy);
  const search: string = yield select(selector.getSearch);
  const searchField: string = yield select(selector.getSearchField);
  try {
    yield put(actions.setLoading(true));
    const response: TenantPagination = yield call(
      tenantService.fetchAllTenants,
      limit,
      cursor,
      order_by_direction,
      order_by,
      search,
      searchField,
    );
    yield put(actions.setTenants(response));
  } catch (e) {
    yield put(actions.setError(e as Error));
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* fetchTenantContent({ payload }: TenantAction) {
  try {
    if (!payload || !payload.tenant_id) {
      return;
    }

    const { tenant_id } = payload;

    yield put(actions.setTenantContentLoading(true));
    const response: TenantWithCompanyContent = yield call(
      tenantService.fetchTenantContent, 
      tenant_id
    );
    yield put(actions.setTenantContent(response));
    yield put(actions.setTenantRequestObj(response));
  } catch (e) {
    yield put(actions.setError(e as Error));
  } finally {
    yield put(actions.setTenantContentLoading(false));
  }
}

export function* postTenant({ payload }: TenantAction) {
  if (!payload?.tenant_request_obj) return;

  yield put(actions.setTenantCreateLoading(true));

  try {
    const { tenant_request_obj } = payload;
    const response: any = yield call(tenantService.postTenant, tenant_request_obj);

    if (response) {
      yield put(actions.setTenantCreateSuccess(true));
      yield put(ToastAction.setToast('Create success!' ?? ''));
    } else {
      if (response) yield put(actions.setError(new Error(response)));
      yield put(ToastAction.setToast('Create failed!', TOAST_SEVERITY.ERROR ?? ''));
      return;
    }

    yield call(fetchAllTenants);
  } catch (e: any) {
    yield put(actions.setError(e));
    yield put(ToastAction.setToast('Create failed!', TOAST_SEVERITY.ERROR ?? ''));
  } finally {
    yield put(actions.setTenantCreateLoading(false));
    yield put(ToastAction.clearState());
    yield put(actions.setTenantCreateSuccess(false));
  }
}

export function* patchTenant({ payload }: TenantAction) {
  if (!payload || !payload.tenant_id || !payload.tenant_request_obj) {
    return;
  }
  
  yield put(actions.setTenantUpdateLoading(true));
  
  try {
    const { tenant_request_obj, tenant_id } = payload;

    const response: TenantWithCompanyContent  = yield call(tenantService.patchTenant, tenant_id, tenant_request_obj);
    if (response.tenant?.id) {
      yield put(ToastAction.setToast('Edit success!' ?? ''));
      yield put(actions.setTenantRequestObj({}));
      yield put(actions.setTenantUpdateSuccess(true));
    } else {
      yield put(ToastAction.setToast('Edit failed!', TOAST_SEVERITY.ERROR ?? ''));
      return;
    }
  } catch (e: any) {
    yield put(actions.setError(e));
  } finally {
    yield put(actions.setTenantUpdateLoading(false));
    yield put(ToastAction.clearState());
    yield put(actions.setTenantUpdateSuccess(false));
  }
}

export function* fetchTenantOptions() {
  const search: string = yield select(selector.getSearch);

  yield put(actions.setTenantOptionsLoading(true));
  try {
    const response: TenantOptionsContent = yield call(
      tenantService.fetchTenantOptions,
      search,
    );
    yield put(actions.setTenantOptions(response));
  } catch (e) {
    console.error(e);
  } finally {
    yield put(actions.setTenantOptionsLoading(false));
  }
}

const sagas = [
  takeLatest(TenantActionType.FETCH_ALL_TENANTS, fetchAllTenants),
  takeLatest(TenantActionType.FETCH_TENANT_CONTENT, fetchTenantContent),
  takeLatest(TenantActionType.CREATE_TENANT, postTenant),
  takeLatest(TenantActionType.UPDATE_TENANT, patchTenant),
  takeLatest(TenantActionType.FETCH_TENANT_OPTIONS, fetchTenantOptions),
];

export default sagas;
