import { AnnouncementPagination, AnnouncementContent, AnnouncementEditorObj } from 'src/@types/announcement';
import api from './api';
import { AnnouncementResponseSchema, AnnouncementCursorPaginatorResponseSchema } from './type';

const editorjsHTML = require('editorjs-html');

const parseAnnouncementContent = (response: AnnouncementResponseSchema): AnnouncementContent => {

  const customParsers = {
    header: function (data: any) {
      return `<h${data.level}>${data.text}</h${data.level}>`;
    },
    paragraph: function (data: any) {
      return `<p>${data.text}</p>`;
    },
    image: function (data: any) {
      return `<img src="${data.url}" alt="${data.caption}" />`;
    },
  };

  const parseHtml = (content: string) => {
    const edjsParser = editorjsHTML({ custom: customParsers });
    const htmlArray: string[] = edjsParser.parse(JSON.parse(content));
    return htmlArray.join('');
  };

  return {
    announcement: {
      id: response.announcement?.id || '',
      title: response.announcement?.title || '',
      excerpt: response.announcement?.excerpt || '',
      content: response.announcement?.content || '',
      content_string: response.announcement?.content ? parseHtml(response.announcement?.content) : '',
      date_created: response.announcement?.date_created,
    }
  };
};

export const fetchAllAnnouncements = async (
  limit: number,
  cursor?: string,
  search?: string,
  search_field?: string,
  order_by_direction?: string,
  order_by?: string,
): Promise<AnnouncementPagination> => {
  try {
    const response: AnnouncementCursorPaginatorResponseSchema = await api.fetchAllAnnouncements(
      limit,
      cursor,
      search,
      search_field,
      order_by_direction,
      order_by);
    return response;
  } catch (e) {
    throw e;
  }
};

export const fetchAnnouncementContent = async (
  announcement_id: string
): Promise<AnnouncementContent> => {
  try {
    const response: AnnouncementResponseSchema = await api.fetchAnnouncementContent(
      announcement_id
    );
    return parseAnnouncementContent(response);
  } catch (e) {
    throw e;
  }
};

export const postAnnouncement = async (
  announcement: AnnouncementEditorObj
): Promise<AnnouncementContent> => {
  try {
    const res: any = await api.postAnnouncement(announcement);
    return res;
  } catch (e) {
    throw e;
  }
};

export const patchAnnouncement = async (
  announcement_id: string,
  announcement: AnnouncementEditorObj
): Promise<AnnouncementContent> => {
  try {
    const res: any = await api.patchAnnouncement(announcement_id, announcement);
    return res;
  } catch (e) {
    throw e;
  }
};
