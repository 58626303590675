import { ApplicationState } from '../types';

const _getData = (state: ApplicationState) => state.Notification;

const getError = (state: ApplicationState) => _getData(state).error;

const getNotifications = (state: ApplicationState) => _getData(state).notification_pagination;

const getLimit = (state: ApplicationState) => _getData(state).limit;

const getCursor = (state: ApplicationState) => _getData(state).cursor;

const getOrderBy = (state: ApplicationState) => _getData(state).order_by;

const getOrderByDirection = (state: ApplicationState) => _getData(state).order_by_direction;

const getSearch = (state: ApplicationState) => _getData(state).search;

const getSearchField = (state: ApplicationState) => _getData(state).search_field;

const getIsNotificationPageLoading = (state:ApplicationState) => _getData(state).is_notification_page_loading;

const getNotificationTypes = (state: ApplicationState) => _getData(state).notification_types;

const getNotificationEditorObj = (state: ApplicationState) => _getData(state).notification_editor_obj;

const getIsUpdateNotification = (state: ApplicationState) => _getData(state).is_update_notification;

const getIsCreateNotification = (state: ApplicationState) => _getData(state).is_create_notification;

const getIsSubmitingLoading = (state: ApplicationState) => _getData(state).is_submiting_loading;

const getNotificationCreateSuccess = (state: ApplicationState) => _getData(state).is_notification_create_success;

const getNotificationUpdateSuccess = (state: ApplicationState) => _getData(state).is_notification_update_success;

const selectors = {
  getError,
  getIsNotificationPageLoading,
  getNotificationTypes,
  getIsCreateNotification,
  getIsSubmitingLoading,
  getIsUpdateNotification,
  getNotificationEditorObj,
  getNotifications,
  getLimit,
  getCursor,
  getOrderBy,
  getOrderByDirection,
  getSearch,
  getSearchField,
  getNotificationCreateSuccess,
  getNotificationUpdateSuccess,

};

export default selectors;
