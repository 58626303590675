const apiUrl = process.env.REACT_APP_STAFF_V2_API_ENDPOINT;

const endpoints = {
  company: `${apiUrl}/api/company`,
  company_options: `${apiUrl}/api/company/options/`,
  company_ownership: `${apiUrl}/api/company/ownership-type/`,
  company_access: `${apiUrl}/api/company-access/`,
};

export default endpoints;

