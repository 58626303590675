import { ConnectionResponse, JobResponse, QueueResponse } from 'src/@types/processing';
import { ApplicationState } from '../types';
import { ProcessingType } from './types';

const _getData = (state: ApplicationState) => state.Processing;

const getSearchQuery = (state: ApplicationState) => _getData(state).searchQuery;

const getJobs = (state: ApplicationState) => _getData(state).jobs;

const getJobsFiltered = (state: ApplicationState) =>
  getJobs(state).filter((job) => {
    if (getSearchQuery(state).text === '') {
      return true;
    }
    return Object.keys(job).some(
      (key) =>
        job[key] &&
        job[key].includes &&
        job[key].toUpperCase().includes(getSearchQuery(state).text.toUpperCase())
    );
  });

const getJobsRunning = (state: ApplicationState) =>
  getJobs(state).filter(
    (j) => j.status === ProcessingType.RUNNING || j.status === ProcessingType.WAITING
  );

const getJobsFinished = (state: ApplicationState) =>
  getJobs(state).filter(
    (j) => j.step === ProcessingType.FINISH && j.status === ProcessingType.FINISHED
  );

const getJobsFailed = (state: ApplicationState) =>
  getJobs(state).filter(
    (j) => j.step === ProcessingType.FINISH && j.status === ProcessingType.FAILED
  );

const getQueued = (state: ApplicationState) => _getData(state).queued;

const getQueuedFiltered = (state: ApplicationState): JobResponse[] => { 
  const response = getQueued(state);
  const queued = Array.isArray(response) ? response : (getQueued(state) as ConnectionResponse).default_queue.jobs;

  return queued.filter((job: JobResponse) => {
    if (getSearchQuery(state).text === '') {
      return true;
    }
    return Object.keys(job).some(
      (key) =>
        job[key] &&
        job[key].includes &&
        job[key].toUpperCase().includes(getSearchQuery(state).text.toUpperCase())
    );
  });
};

const getAltQueued = (state: ApplicationState): QueueResponse[] => { 
  const response = getQueued(state);
  if(Array.isArray(response)) return [];
  return response.alternate_queues;
};

const getIsOpen = (state: ApplicationState) => _getData(state).isOpen;

const getIsLoading = (state: ApplicationState) => _getData(state).isLoading;

const getSession = (state: ApplicationState) => _getData(state).session;

const getTargetQueueId = (state: ApplicationState) => _getData(state).targetQueueId;

const getModelProcessingQueues = (state: ApplicationState) => _getData(state).modelProcessingQueues;

const selectors = {
  getJobs,
  getJobsFiltered,
  getJobsRunning,
  getJobsFinished,
  getJobsFailed,
  getQueued,
  getQueuedFiltered,
  getIsOpen,
  getIsLoading,
  getSearchQuery,
  getAltQueued,
  getSession,
  getTargetQueueId,
  getModelProcessingQueues,
};

export default selectors;
