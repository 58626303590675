import { SiteAction, SiteActionType, SiteReducer, SitePayload, SiteState } from './types';

export const initialState: SiteState = {
  site: undefined,
  loading: false,
  isPhotosLoading: false,
  error: undefined,
  currentSiteAssessmentId: undefined,
  assetProfilePhoto: undefined,
  currentSiteAssessmentPhotos: [],
  dailyForecasts: [],
  isAddSiteSuccess: false,
  isSubmitQueueForProcessingLoading: false,
  isSubmitQueueForProcessingSuccess: false,
  isFetchSiteSuccess: false,

  //asset class select options
  assetClassSelectOptions: undefined,

  //structure select options
  structureSelectOptions: undefined,

  jobProgress: undefined,

  photoTab: 0,
  photoLimit: 50,
  photoOffset: 0,
};

const setLoading: SiteReducer = (state: SiteState, payload?: SitePayload) => ({
  ...state,
  loading: !!payload?.loading,
});

const setPhotosLoading: SiteReducer = (state: SiteState, payload?: SitePayload) => ({
  ...state,
  isPhotosLoading: !!payload?.isPhotosLoading,
});

const setFetchSiteSuccess: SiteReducer = (state: SiteState, payload?: SitePayload) => ({
  ...state,
  isFetchSiteSuccess: (payload && payload.isFetchSiteSuccess) ?? state.isFetchSiteSuccess,
});

const setSite: SiteReducer = (state: SiteState, payload?: SitePayload) => ({
  ...state,
  site: (payload && payload.site) ?? state.site,
});

const setCurrentSiteAssessmentId: SiteReducer = (state: SiteState, payload?: SitePayload) => ({
  ...state,
  currentSiteAssessmentId:
    (payload && payload.currentSiteAssessmentId) ?? state.currentSiteAssessmentId,
});

const setCurrentSiteAssessmentPhotos: SiteReducer = (state: SiteState, payload?: SitePayload) => ({
  ...state,
  currentSiteAssessmentPhotos:
    (payload && payload.currentSiteAssessmentPhotos) ?? state.currentSiteAssessmentPhotos,
});

const setAssetProfilePhoto: SiteReducer = (state: SiteState, payload?: SitePayload) => ({
  ...state,
  assetProfilePhoto:
    (payload && payload.assetProfilePhoto) ?? state.assetProfilePhoto,
});

const editCurrentSiteAssessmentPhotos: SiteReducer = (state: SiteState, payload?: SitePayload) => ({
  ...state,
  currentSiteAssessmentPhotos:
    (payload &&
      state.currentSiteAssessmentPhotos?.map((image) =>
        image.id === payload.editingCurrentSiteAssessmentPhotos?.id
          ? payload.editingCurrentSiteAssessmentPhotos
          : image
      )) ??
    state.currentSiteAssessmentPhotos,
});

const setDailyForecasts: SiteReducer = (state: SiteState, payload?: SitePayload) => ({
  ...state,
  dailyForecasts: (payload && payload.dailyForecasts) ?? state.dailyForecasts,
});

const setClientSiteIdentifier: SiteReducer = (state: SiteState, payload?: SitePayload) => ({
  ...state,
  clientSiteIdentifier: (payload && payload.clientSiteIdentifier) ?? state.clientSiteIdentifier,
});

const setIsAddSiteSuccess: SiteReducer = (
  state: SiteState,
  payload?: SitePayload
) => ({
  ...state,
  isAddSiteSuccess:
    (payload && payload.isAddSiteSuccess) ?? state.isAddSiteSuccess,
});
const setSubmitQueueForProcessingLoading: SiteReducer = (state: SiteState, payload?: SitePayload) => ({
  ...state,
  isSubmitQueueForProcessingLoading:
    (payload && payload.isSubmitQueueForProcessingLoading) ?? state.isSubmitQueueForProcessingLoading,
});

const setSubmitQueueForProcessingSuccess: SiteReducer = (state: SiteState, payload?: SitePayload) => ({
  ...state,
  isSubmitQueueForProcessingSuccess: 
    (payload && payload.isSubmitQueueForProcessingSuccess) ?? state.isSubmitQueueForProcessingSuccess,
});

const setJobProgress: SiteReducer = (state: SiteState, payload?: SitePayload) => ({
  ...state,
  jobProgress:
    (payload && payload.jobProgress) ?? state.jobProgress,
});

const setPhotoTab: SiteReducer = (state: SiteState, payload?: SitePayload) => ({
  ...state,
  photoTab:
    (payload && payload.photoTab) ?? state.photoTab,
});

const setPhotoLimit: SiteReducer = (state: SiteState, payload?: SitePayload) => ({
  ...state,
  photoLimit:
    (payload && payload.photoLimit) ?? state.photoLimit,
});

const setPhotoOffset: SiteReducer = (state: SiteState, payload?: SitePayload) => ({
  ...state,
  photoOffset:
    (payload && payload.photoOffset) ?? state.photoOffset,
});

//asset class select options
const setAssetClassSelectOptions: SiteReducer = (state: SiteState, payload?: SitePayload) => ({
  ...state,
  assetClassSelectOptions:
    (payload && payload.assetClassSelectOptions) ?? state.assetClassSelectOptions,
});
const setisAssetClassSelectOptionsLoading: SiteReducer = (
  state: SiteState,
  payload?: SitePayload
) => ({
  ...state,
  isAssetClassSelectOptionsLoading:
    (payload && payload.isAssetClassSelectOptionsLoading) ?? state.isAssetClassSelectOptionsLoading,
});

const setStructureSelectOptions: SiteReducer = (state: SiteState, payload?: SitePayload) => ({
  ...state,
  structureSelectOptions:
    (payload && payload.structureSelectOptions) ?? state.structureSelectOptions,
});
const setIsStructureSelectOptionsLoading: SiteReducer = (
  state: SiteState,
  payload?: SitePayload
) => ({
  ...state,
  isStructureSelectOptionsLoading:
    (payload && payload.isStructureSelectOptionsLoading) ?? state.isStructureSelectOptionsLoading,
});

const setError: SiteReducer = (state: SiteState, payload?: SitePayload) => ({
  ...state,
  error: payload ? payload.error : undefined,
});

const clearState: SiteReducer = () => ({
  ...initialState,
});

//assessment sales order
const setIsAssessmentSalesOrder: SiteReducer = (
  state: SiteState,
  payload?: SitePayload
) => ({
  ...state,
  isAssessmentSalesOrder:
    (payload && payload.isAssessmentSalesOrder) ?? state.isAssessmentSalesOrder,
});
const setIsAssessmentSalesOrderLoading: SiteReducer = (
  state: SiteState,
  payload?: SitePayload
) => ({
  ...state,
  isAssessmentSalesOrderLoading:
    (payload && payload.isAssessmentSalesOrderLoading) ?? state.isAssessmentSalesOrderLoading,
});



const reducerMap = new Map<SiteActionType, SiteReducer>([
  [SiteActionType.SET_SITE, setSite],
  [SiteActionType.SET_FETCH_SITE_SUCCESS, setFetchSiteSuccess],
  [SiteActionType.SET_CURRENT_ASSESSMENT_ID, setCurrentSiteAssessmentId],
  [SiteActionType.SET_CURRENT_SITE_ASSESSMENT_PHOTOS, setCurrentSiteAssessmentPhotos],
  [SiteActionType.SET_ASSET_PROFILE_PHOTO, setAssetProfilePhoto],
  [SiteActionType.SET_LOADING, setLoading],
  [SiteActionType.SET_PHOTOS_LOADING, setPhotosLoading],
  [SiteActionType.SET_DAILY_FORECASTS, setDailyForecasts],
  [SiteActionType.SET_CLIENT_SITE_IDENTIFIER, setClientSiteIdentifier],
  [SiteActionType.EDIT_CURRENT_SITE_ASSESSMENT_PHOTOS, editCurrentSiteAssessmentPhotos],
  [SiteActionType.SET_IS_ADD_SITE_SUCCESS, setIsAddSiteSuccess],
  [SiteActionType.SET_SUBMIT_QUEUE_FOR_PROCESSING_LOADING, setSubmitQueueForProcessingLoading],
  [SiteActionType.SET_SUBMIT_QUEUE_FOR_PROCESSING_SUCCESS, setSubmitQueueForProcessingSuccess],
  [SiteActionType.SET_JOB_PROGRESS, setJobProgress],
  [SiteActionType.SET_PHOTO_TAB, setPhotoTab],
  [SiteActionType.SET_PHOTO_LIMIT, setPhotoLimit],
  [SiteActionType.SET_PHOTO_OFFSET, setPhotoOffset],
  [SiteActionType.SET_ERROR, setError],
  [SiteActionType.CLEAR, clearState],

  //asset class select options
  [SiteActionType.SET_ASSET_CLASS_SELECT_OPTIONS, setAssetClassSelectOptions],
  [SiteActionType.SET_IS_ASSET_CLASS_SELECT_OPTIONS_LOADING, setisAssetClassSelectOptionsLoading],

  //structure select options
  [SiteActionType.SET_STRUCTURE_SELECT_OPTIONS, setStructureSelectOptions],
  [SiteActionType.SET_IS_STRUCTURE_SELECT_OPTIONS_LOADING, setIsStructureSelectOptionsLoading],

  //assessment sales order
  [SiteActionType.SET_IS_ASSESSMENT_SALES_ORDER, setIsAssessmentSalesOrder],
  [SiteActionType.SET_IS_ASSESSMENT_SALES_ORDER_LOADING, setIsAssessmentSalesOrderLoading],

  [SiteActionType.SET_ERROR, setError],
  [SiteActionType.CLEAR, clearState],
]);

const reducer = (state: SiteState = initialState, action: SiteAction): SiteState => {
  const reducer = reducerMap.get(action.type);
  if (reducer) return reducer(state, action.payload);
  return state;
};

export default reducer;