
import { Stack, Typography, Box, Button } from 'ca-react-component-lib';
import { Onboarding } from 'src/@types/auth';
import { useAuth } from 'src/hooks';

const ErrorPanel = ({ message }) => {

  const { setOnboarding } = useAuth();

  return (
  
    <Stack spacing={3}>
      <Stack direction="row" alignItems="center">
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h6" gutterBottom>
            Oops... something went wrong.
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            {message}
          </Typography>
        </Box>
      </Stack>
      <Button
        sx={{ textTransform: 'none' }}
        fullWidth
        size="large"
        variant="outlined"
        onClick={() => {
          setOnboarding(Onboarding.LOGIN);
        }}
      >
        <Typography>Return to Login</Typography>
      </Button>
    </Stack>
  );
};

export default ErrorPanel;