import request from 'src/services/request';
import endpoints from './endpoints';
import { CustomerSuccessAccessRequestSchema } from './types';

const api = {
  fetchAllCustomerSuccessAccess: async (
    limit: number,
    cursor?: string,
    search?: string,
    search_field?: string,
    order_by_direction?: string,
    order_by?: string,
    company_id?: string,
    user_id?: string
  ) => {
    let queryString = `?limit=${limit}`;

    if (cursor) queryString += `&cursor=${cursor}`;
    if (search) queryString += `&search=${search}`;
    if (search_field) queryString += `&search_field=${search_field}`;
    if (order_by_direction) queryString += `&order_by_direction=${order_by_direction}`;
    if (order_by) queryString += `&order_by=${order_by}`;
    if (company_id) queryString += `&company_id=${company_id}`;
    if (user_id) queryString += `&user_id=${user_id}`;

    return request(`${endpoints.customer_success_access}${queryString}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
  },
  postCustomerSuccessAccess: async (payload: CustomerSuccessAccessRequestSchema) =>
    request(`${endpoints.customer_success_access}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    }),
  deleteCompanySuccessAccess: async (payload: CustomerSuccessAccessRequestSchema) =>
    request(`${endpoints.customer_success_access}`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    }),
  fetchCustomerSuccessCompanies: async (search?: string) => {
    let queryString = '';

    if (search) queryString += `?search=${search}`;

    return request(`${endpoints.customer_success_companies}${queryString}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
  },
  setCustomerSuccessCompany: async (company_id: string) =>
    request(`${endpoints.customer_success_switch}${company_id}/`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
    }),

  fetchCustomerSuccessAccessUserOptions: async (search?: string, company_id?: string) => {
    const queryParams: string[] = [];
    if (search) queryParams.push(`search=${search}`);
    if (company_id) queryParams.push(`company_id=${company_id}`);

    const queryString = queryParams.length ? `?${queryParams.join('&')}` : '';

    return request(`${endpoints.customer_success_access_user_options}${queryString}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
  },

  fetchCustomerSuccessAccessCompanyOptions: async (search?: string, user_id?: string) => {
    const queryParams: string[] = [];
    if (search) queryParams.push(`search=${search}`);
    if (user_id) queryParams.push(`user_id=${user_id}`);

    const queryString = queryParams.length ? `?${queryParams.join('&')}` : '';
    return request(`${endpoints.customer_success_access_company_options}${queryString}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
  },
};

export default api;
