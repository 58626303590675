import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CompanyContent, CompanyOptionsContent } from 'src/@types/company';
import companiesActions from 'src/store/Companies/actions';
import companiesSelectors from 'src/store/Companies/selectors';

const useCompanies = () => {
  const companies = useSelector(companiesSelectors.getCompanies);
  const companyContent = useSelector(companiesSelectors.getCompanyContent);
  const companyRequestObj = useSelector(companiesSelectors.getCompanyRequestObj);
  const isLoading = useSelector(companiesSelectors.isLoading);
  const limit = useSelector(companiesSelectors.getLimit);
  const cursor = useSelector(companiesSelectors.getCursor);
  const orderBy = useSelector(companiesSelectors.getOrderBy);
  const orderByDirection  = useSelector(companiesSelectors.getOrderByDirection);
  const search = useSelector(companiesSelectors.getSearch);
  const searchField = useSelector(companiesSelectors.getSearchField);
  const tenantId = useSelector(companiesSelectors.getTenantId);
  const isTowerOwner = useSelector(companiesSelectors.getIsTowerOwner);
  const isVendor = useSelector(companiesSelectors.getIsVendor);
  const companyContentLoading = useSelector(companiesSelectors.isCompanyContentLoading);
  const companyCreateLoading = useSelector(companiesSelectors.isCompanyCreateLoading);
  const companyUpdateLoading = useSelector(companiesSelectors.isCompanyUpdateLoading);
  const isCompanyCreateSuccess = useSelector(companiesSelectors.getCompanyCreateSuccess);
  const isCompanyUpdateSuccess = useSelector(companiesSelectors.getCompanyUpdateSuccess);
  const error = useSelector(companiesSelectors.getError);

  // companies simple.
  const companyOptions = useSelector(companiesSelectors.getCompanyOptions);
  const isCompanyOptionsLoading = useSelector(companiesSelectors.getCompanyOptionsLoading);
  const companySelectionId = useSelector(companiesSelectors.getCompanySelectionId);

  // Ownership types.
  const ownershipTypes = useSelector(companiesSelectors.getOwnershipTypes);
  const isOwnershipTypesLoading = useSelector(companiesSelectors.getOwnershipTypesLoading);

  const dispatch = useDispatch();

  const fetchAllCompanies = useCallback(() => {
    dispatch(companiesActions.fetchAllCompanies());
  },
  [dispatch]
  );

  const fetchCompanyContent = useCallback(
    (company_id: string) => {
      dispatch(companiesActions.fetchCompanyContent(company_id));
    },
    [dispatch]
  );  
 
  const fetchCompanyOptions = useCallback(
    (search?: string, is_vendor?: boolean, is_tower_owner?: boolean) => {
      dispatch(companiesActions.fetchCompanyOptions(search, is_vendor, is_tower_owner));
    },
    [dispatch]
  );

  const setCompanyOptionsLoading = useCallback(
    (is_company_options_loading: boolean) => {
      dispatch(companiesActions.setCompanyOptionsLoading(is_company_options_loading));
    },
    [dispatch]
  );

  const setCompanySelectionId = useCallback(
    (company_selection_id: string) => {
      dispatch(companiesActions.setCompanySelectionId(company_selection_id));
    },
    [dispatch]
  );

  const setCompanyOptions = useCallback(
    (company_options: CompanyOptionsContent) => {
      dispatch(companiesActions.setCompanyOptions(company_options));
    },
    [dispatch]
  );

  // Ownership types.
  const fetchOwnershipTypes = useCallback(() => {
    dispatch(companiesActions.fetchOwnershipTypes());
  }, [dispatch]);


  const createCompany = useCallback(
    (company: CompanyContent) => {
      dispatch(companiesActions.postCompany(company));
    },
    [dispatch]
  );

  const updateCompany = useCallback(
    (company: CompanyContent, company_id: string) => {
      dispatch(companiesActions.patchCompany(company_id, company));
    },
    [dispatch]
  );

  const setLimit = useCallback(
    (limit: number) => {
      dispatch(companiesActions.setLimit(limit));
    },
    [dispatch]
  );

  const setCursor = useCallback(
    (cursor: string) => {
      dispatch(companiesActions.setCursor(cursor));
    },
    [dispatch]
  );

  const setOrderBy = useCallback(
    (order_by: 'name' | 'short_name' | 'abbreviation' | 'users_count') => {
      dispatch(companiesActions.setOrderBy(order_by));
    },
    [dispatch]
  );

  const setOrderByDirection = useCallback(
    (order_by_direction: 'asc' | 'desc') => {
      dispatch(companiesActions.setOrderByDirection(order_by_direction));
    },
    [dispatch]
  );

  const setSearchField = useCallback(
    (search_field: 'name' | 'short_name' | 'abbreviation' | 'users_count') => {
      dispatch(companiesActions.setSearchField(search_field));
    },
    [dispatch]
  );

  const setSearch = useCallback(
    (search: string) => {
      dispatch(companiesActions.setSearch(search));
    },
    [dispatch]
  );

  const setTenantId = useCallback(
    (tenant_id: string) => {
      dispatch(companiesActions.setTenantId(tenant_id));
    },
    [dispatch]
  );

  const setIsVendor = useCallback(
    (is_vendor: boolean) => {
      dispatch(companiesActions.setIsVendor(is_vendor));
    },
    [dispatch]
  );

  const setIsTowerOwner = useCallback(
    (is_tower_owner: boolean) => {
      dispatch(companiesActions.setIsTowerOwner(is_tower_owner));
    },
    [dispatch]
  );


  return {
    companies,
    limit,
    cursor,
    orderBy,
    orderByDirection,
    searchField,
    search,
    companyContent,
    companyRequestObj,
    tenantId,
    isTowerOwner,
    isVendor,
    isLoading,
    companyContentLoading,
    companyCreateLoading,
    companyUpdateLoading,
    fetchAllCompanies,
    setLimit,
    setCursor,
    setOrderBy,
    setOrderByDirection,
    setSearchField,
    setSearch,
    setTenantId, 
    setIsVendor,
    setIsTowerOwner,
    createCompany,
    updateCompany,
    fetchCompanyContent,
    isCompanyCreateSuccess,
    isCompanyUpdateSuccess,
    error,

    // companies simple.
    fetchCompanyOptions,
    setCompanyOptions,
    setCompanyOptionsLoading,
    setCompanySelectionId,
    companyOptions,
    isCompanyOptionsLoading,
    companySelectionId,

    // Ownership types.
    ownershipTypes,
    isOwnershipTypesLoading,
    fetchOwnershipTypes,
  };
};

export default useCompanies;
