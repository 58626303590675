export enum CustomerSuccessAccessActionType {
  FETCH_ALL_CUSTOMER_SUCCESS_ACCESS = 'CustomerSuccessAccess@FETCH_ALL_CUSTOMER_SUCCESS_ACCESS',
  SET_ALL_CUSTOMER_SUCCESS_ACCESS = 'CustomerSuccessAccess@SET_ALL_CUSTOMER_SUCCESS_ACCESS',
  SET_LIMIT = 'CustomerSuccessAccess@SET_LIMIT',
  SET_CURSOR = 'CustomerSuccessAccess@SET_CURSOR',
  SET_ORDERBY = 'CustomerSuccessAccess@SET_ORDERBY',
  SET_ORDERBY_DIRECTION = 'CustomerSuccessAccess@SET_ORDERBY_DIRECTION',
  SET_SEARCH = 'CustomerSuccessAccess@SET_SEARCH',
  SET_SEARCH_FIELD = 'CustomerSuccessAccess@SET_SEARCH_FIELD',
  SET_COMPANY_ID = 'CustomerSuccessAccess@SET_COMPANY_ID',
  SET_USER_ID = 'CustomerSuccessAccess@SET_USER_ID',
  SET_LOADING = 'CustomerSuccessAccess@SET_LOADING',
  SET_ERROR = 'CustomerSuccessAccess@SET_ERROR',

  POST_CUSTOMER_SUCCESS_ACCESS = 'CustomerSuccessAccess@POST_CUSTOMER_SUCCESS_ACCESS',
  SET_POST_CUSTOMER_SUCCESS_ACCESS_LOADING = 'CustomerSuccessAccess@SET_pOST_CUSTOMER_SUCCESS_ACCESS_LOADING',
  SET_POST_CUSTOMER_SUCCESS_ACCESS_SUCCESS = 'CustomerSuccessAccess@SET_POST_CUSTOMER_SUCCESS_ACCESS_SUCCESS',

  DELETE_CUSTOMER_SUCCESS_ACCESS = 'CustomerSuccessAccess@DELETE_CUSTOMER_SUCCESS_ACCESS',
  SET_DELETE_CUSTOMER_SUCCESS_ACCESS_LOADING = 'CustomerSuccessAccess@SET_DELETE_CUSTOMER_SUCCESS_ACCESS_LOADING',
  SET_DELETE_CUSTOMER_SUCCESS_ACCESS_SUCCESS = 'CustomerSuccessAccess@SET_DELETE_CUSTOMER_SUCCESS_ACCESS_SUCCESS',

  FETCH_CUSTOMER_SUCCESS_COMPANIES = 'CustomerSuccessAccess@FETCH_CUSTOMER_SUCCESS_COMPANIES',
  SET_CUSTOMER_SUCCESS_COMPANIES_LOADING = 'CustomerSuccessAccess@SET_CUSTOMER_SUCCESS_COMPANY_LOADING',
  SET_CUSTOMER_SUCCESS_COMPANIES_SEARCH = 'CustomerSuccessAccess@SET_CUSTOMER_SUCCESS_COMPANIES_SEARCH',
  SET_CUSTOMER_SUCCESS_COMPANIES = 'CustomerSuccessAccess@SET_CUSTOMER_SUCCESS_COMPANIES',
  SET_CUSTOMER_SUCCESS_SWITCH_ERROR = 'CustomerSuccessAccess@SET_CUSTOMER_SUCCESS_SWITCH_ERROR',
  SET_CUSTOMER_SUCCESS_SWITCH_LOADING = 'CustomerSuccessAccess@SET_CUSTOMER_SUCCESS_SWITCH_LOADING',
  SET_CUSTOMER_SUCCESS_COMPANY = 'CustomerSuccessAccess@SET_CUSTOMER_SUCCESS_COMPANY',

  CLEAR_ALL_CUSTOMER_SUCCESS_ACCESS = 'CustomerSuccessAccess@CLEAR_ALL_CUSTOMER_SUCCESS_ACCESS',
  CLEAR_MANAGE_ACCESS_TABLE_COMPANY_ID = 'CustomerSuccessAccess@CLEAR_MANAGE_ACCESS_TABLE_COMPANY_ID',
  CLEAR_MANAGE_ACCESS_TABLE_USER_ID = 'CustomerSuccessAccess@CLEAR_MANAGE_ACCESS_TABLE_USER_ID',

  FETCH_CUSTOMER_SUCCESS_ACCESS_USER_OPTIONS = 'CustomerSuccessAccess@FETCH_CUSTOMER_SUCCESS_ACCESS_USER_OPTIONS',
  SET_CUSTOMER_SUCCESS_ACCESS_USER_OPTIONS = 'CustomerSuccessAccess@SET_CUSTOMER_SUCCESS_ACCESS_USER_OPTIONS',
  SET_CUSTOMER_SUCCESS_ACCESS_USER_OPTIONS_LOADING = 'CustomerSuccessAccess@SET_CUSTOMER_SUCCESS_ACCESS_USER_OPTIONS_LOADING',
  SET_CUSTOMER_SUCCESS_ACCESS_USER_OPTIONS_SEARCH = 'CustomerSuccessAccess@SET_CUSTOMER_SUCCESS_ACCESS_USER_OPTIONS_SEARCH',

  FETCH_CUSTOMER_SUCCESS_ACCESS_COMPANY_OPTIONS = 'CustomerSuccessAccess@FETCH_CUSTOMER_SUCCESS_ACCESS_COMPANY_OPTIONS',
  SET_CUSTOMER_SUCCESS_ACCESS_COMPANY_OPTIONS = 'CustomerSuccessAccess@SET_CUSTOMER_SUCCESS_ACCESS_COMPANY_OPTIONS',
  SET_CUSTOMER_SUCCESS_ACCESS_COMPANY_OPTIONS_LOADING = 'CustomerSuccessAccess@SET_CUSTOMER_SUCCESS_ACCESS_COMPANY_OPTIONS_LOADING',
  SET_CUSTOMER_SUCCESS_ACCESS_COMPANY_OPTIONS_SEARCH = 'CustomerSuccessAccess@SET_CUSTOMER_SUCCESS_ACCESS_COMPANY_OPTIONS_SEARCH',
}

export type CustomerSuccessAccessState = {
  error?: Error;
  customer_success_access?: any;
  limit?: number;
  cursor?: string;
  order_by?: 'user_full_name' | 'company_name';
  order_by_direction?: 'asc' | 'desc';
  search?: string;
  search_field?: 'user_full_name' | 'user_email' | 'company_name';
  company_id?: string;
  user_id?: string;

  is_post_customer_success_access_loading?: boolean;
  is_post_customer_success_access_success?: boolean;
  is_delete_customer_success_access_loading?: boolean;
  is_delete_customer_success_access_success?: boolean;

  customer_success_companies?: any;
  is_customer_success_companies_loading?: boolean;
  customer_success_companies_search?: string;
  customer_success_switch_error?: Error;
  is_customer_success_switch_loading?: boolean;
  customer_success_company?: any;

  customer_success_access_user_options?: any;
  is_customer_success_access_user_options_loading?: boolean;
  customer_success_access_user_options_search?: string;

  customer_success_access_company_options?: any;
  is_customer_success_access_company_options_loading?: boolean;
  customer_success_access_company_options_search?: string;

  loading?: boolean;
};

export type CustomerSuccessAccessPayload = {
  error?: Error;
  customer_success_access?: any;
  limit?: number;
  cursor?: string;
  order_by?: 'user_full_name' | 'company_name';
  order_by_direction?: 'asc' | 'desc';
  search?: string;
  search_field?: 'user_full_name' | 'user_email' | 'company_name';
  company_id?: string;
  user_id?: string;

  is_post_customer_success_access_loading?: boolean;
  is_post_customer_success_access_success?: boolean;
  is_delete_customer_success_access_loading?: boolean;
  is_delete_customer_success_access_success?: boolean;

  customer_success_companies?: any;
  is_customer_success_companies_loading?: boolean;
  customer_success_companies_search?: string;
  customer_success_switch_error?: Error;
  is_customer_success_switch_loading?: boolean;
  customer_success_company?: any;

  customer_success_access_user_options?: any;
  is_customer_success_access_user_options_loading?: boolean;
  customer_success_access_user_options_search?: string;

  customer_success_access_company_options?: any;
  is_customer_success_access_company_options_loading?: boolean;
  customer_success_access_company_options_search?: string;

  loading?: boolean;
};

export type CustomerSuccessAccessAction = {
  payload?: CustomerSuccessAccessPayload;
  type: CustomerSuccessAccessActionType;
};

export type CustomerSuccessAccessReducer = (
  state: CustomerSuccessAccessState,
  payload?: CustomerSuccessAccessPayload
) => CustomerSuccessAccessState;
