import { QcAction, QcActionType, QcReducer, QcState } from './types';
export const initialState: QcState = {
  loading: false,
  error: undefined,
  success: false,
  otiQRevisions: [],
  qcRecords: undefined,
  lastQcRecord: undefined,
  fallOutReasons: undefined,
  statusColors: undefined,
  isFetchQcRecordsLoading: false,
  isCreateQcRecordLoading: false,
  isUpdateQcRecordLoading: false,
  isFetchFallOutReasonsLoading: false,
  isFetchStatusColorsLoading: false,

  // select options
  fileExtensionSelectOptions: [],
  filePurposeSelectOptions: [],
};

const setOtiqRevisions: QcReducer = (state: QcState, payload?: any) => ({
  ...state,
  otiQRevisions: payload,
});

const setLoading: QcReducer = (state: QcState, payload?: any) => ({
  ...state,
  loading: !!payload?.loading,
});
const setError: QcReducer = (state: QcState, payload?: any) => ({
  ...state,
  error: payload ? payload.error : undefined,
});
const setSuccess: QcReducer = (state: QcState, payload?: any) => ({
  ...state,
  success: payload ? payload.success : undefined,
});

const setFilePurposeOptions: QcReducer = (state: QcState, payload?: any) => ({
  ...state,
  filePurposeSelectOptions:
    (payload && payload.filePurposeSelectOptions) ?? state.filePurposeSelectOptions,
});

const setFileExtensionOptions: QcReducer = (state: QcState, payload?: any) => ({
  ...state,
  fileExtensionSelectOptions:
    (payload && payload.fileExtensionSelectOptions) ?? state.fileExtensionSelectOptions,
});

const setQcRecords: QcReducer = (state: QcState, payload?: any) => ({
  ...state,
  qcRecords: payload && payload.qcRecords !== undefined ? payload.qcRecords : state.qcRecords,
});

const setLastQcRecord: QcReducer = (state: QcState, payload?: any) => ({
  ...state,
  lastQcRecord: payload && payload.qc !== undefined ? payload.qc : state.lastQcRecord,
});

const setCreateQcRecordLoading: QcReducer = (state: QcState, payload?: any) => ({
  ...state,
  isCreateQcRecordLoading:
    payload && payload.isCreateQcRecordLoading !== undefined
      ? payload.isCreateQcRecordLoading
      : state.isCreateQcRecordLoading,
});

const setUpdateQcRecordLoading: QcReducer = (state: QcState, payload?: any) => ({
  ...state,
  isUpdateQcRecordLoading:
    payload && payload.isUpdateQcRecordLoading !== undefined
      ? payload.isUpdateQcRecordLoading
      : state.isUpdateQcRecordLoading,
});

const setFetchQcRecordsLoading: QcReducer = (state: QcState, payload?: any) => ({
  ...state,
  isFetchQcRecordsLoading:
    payload && payload.isFetchQcRecordsLoading !== undefined
      ? payload.isFetchQcRecordsLoading
      : state.isFetchQcRecordsLoading,
});

const setFallOutReasons: QcReducer = (state: QcState, payload?: any) => ({
  ...state,
  fallOutReasons:
    payload && payload.fallOutReasons !== undefined ? payload.fallOutReasons : state.fallOutReasons,
});

const setStatusColors: QcReducer = (state: QcState, payload?: any) => ({
  ...state,
  statusColors:
    payload && payload.statusColors !== undefined ? payload.statusColors : state.statusColors,
});

const setFetchFallOutReasonsLoading: QcReducer = (state: QcState, payload?: any) => ({
  ...state,
  isFetchFallOutReasonsLoading:
    payload && payload.isFetchFallOutReasonsLoading !== undefined
      ? payload.isFetchFallOutReasonsLoading
      : state.isFetchFallOutReasonsLoading,
});

const setFetchStatusColorsLoading: QcReducer = (state: QcState, payload?: any) => ({
  ...state,
  isFetchStatusColorsLoading:
    payload && payload.isFetchStatusColorsLoading !== undefined
      ? payload.isFetchStatusColorsLoading
      : state.isFetchStatusColorsLoading,
});

const reducerMap = new Map<QcActionType, QcReducer>([
  [QcActionType.SET_OTIQ_REVISIONS, setOtiqRevisions],
  [QcActionType.SET_LOADING, setLoading],
  [QcActionType.SET_ERROR, setError],
  [QcActionType.SET_SUCCESS, setSuccess],
  [QcActionType.SET_FILE_EXTENSION_OPTIONS, setFileExtensionOptions],
  [QcActionType.SET_FILE_PURPOSE_OPTIONS, setFilePurposeOptions],
  [QcActionType.SET_QC_RECORDS, setQcRecords],
  [QcActionType.SET_LAST_QC_RECORD, setLastQcRecord],
  [QcActionType.SET_FETCH_QC_RECORDS_LOADING, setFetchQcRecordsLoading],
  [QcActionType.SET_CREATE_QC_RECORD_LOADING, setCreateQcRecordLoading],
  [QcActionType.SET_UPDATE_QC_RECORD_LOADING, setUpdateQcRecordLoading],
  [QcActionType.SET_FALLOUT_REASONS, setFallOutReasons],
  [QcActionType.SET_STATUS_COLORS, setStatusColors],
  [QcActionType.SET_FETCH_FALLOUT_REASONS_LOADING, setFetchFallOutReasonsLoading],
  [QcActionType.SET_FETCH_STATUS_COLORS_LOADING, setFetchStatusColorsLoading],
]);

const reducer = (state: QcState = initialState, action: QcAction): QcState => {
  const reducer = reducerMap.get(action.type);
  if (reducer) return reducer(state, action.payload);
  return state;
};

export default reducer;
