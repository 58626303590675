
import {
  UsersAction,
  UsersActionType,
  UsersReducer,
  UsersPayload,
  UsersState,
} from './types';

export const initialState: UsersState = {
  limit: 15,
  cursor: undefined,
  order_by: 'full_name',
  order_by_direction: 'asc',
  search_field: undefined,
  search: undefined,
  user_pagination: undefined,
  user_content: undefined,
  error: undefined,
  is_loading: false,
  exclude_invited: undefined,

  // edit user.
  is_user_update_loading: false,
  is_user_content_loading: false,
  is_user_update_success: undefined,

  // add user.
  is_user_create_loading: false,
  is_user_create_success: undefined,

  
  // User options.
  user_options: undefined,
  is_user_options_loading: false,
  min_role_level: undefined,
  max_role_level: undefined,

  // role types.
  role_types: undefined,
  is_role_types_loading: false,

  // bulk insert.
  bulk_insert_result: undefined,
  is_bulk_insert_loading: false,

  // role options.
  role_options: undefined,
  is_role_options_loading: false,
};

const setError: UsersReducer = (state: UsersState, payload?: UsersPayload) => ({
  ...state,
  error: payload ? payload.error : undefined,
});

const setLoading: UsersReducer = (state: UsersState, payload?: UsersPayload) => ({
  ...state,
  is_loading: !!payload?.is_loading,
});

const setLimit: UsersReducer = (
  state: UsersState,
  payload?: UsersPayload
) => ({
  ...state,
  limit: (payload && payload.limit) ?? state.limit,
});

const setCursor: UsersReducer = (
  state: UsersState,
  payload?: UsersPayload
) => ({
  ...state,
  cursor: (payload && payload.cursor) ?? state.cursor,
});

const setOrderBy: UsersReducer = (
  state: UsersState,
  payload?: UsersPayload
) => ({
  ...state,
  order_by: (payload && payload.order_by) ?? state.order_by,
});

const setOrderByDirection: UsersReducer = (
  state: UsersState,
  payload?: UsersPayload
) => ({
  ...state,
  order_by_direction: (payload && payload.order_by_direction) ?? state.order_by_direction,
});

const setSearch: UsersReducer = (
  state: UsersState,
  payload?: UsersPayload
) => ({
  ...state,
  search: (payload && payload.search) ?? state.search,
});

const setSearchField: UsersReducer = (
  state: UsersState,
  payload?: UsersPayload
) => ({
  ...state,
  search_field: (payload && payload.search_field) ?? state.search_field,
});

const setCompanyId: UsersReducer = (state: UsersState, payload?: UsersPayload) => ({
  ...state,
  company_id: payload ? payload.company_id : undefined
});

const setUsers: UsersReducer = (state: UsersState, payload?: UsersPayload) => ({
  ...state,
  user_pagination: (payload && payload.user_pagination) ?? state.user_pagination,
});

const setExcludeInvited: UsersReducer = (state: UsersState, payload?: UsersPayload) => ({
  ...state,
  exclude_invited: payload ? payload.exclude_invited : undefined
});

// edit user.
const setUserUpdateLoading: UsersReducer = (state: UsersState, payload?: UsersPayload) => ({
  ...state,
  is_user_update_loading: (payload && payload.is_user_update_loading) ?? state.is_user_update_loading,
});
const setUserContentLoading: UsersReducer = (state: UsersState, payload?: UsersPayload) => ({
  ...state,
  is_user_content_loading: (payload && payload.is_user_content_loading) ?? state.is_user_content_loading,
});
const setUserContent: UsersReducer = (state: UsersState, payload?: UsersPayload) => ({
  ...state,
  user_content: (payload && payload.user_content) ?? undefined,
});
const setUserUpdateSuccess: UsersReducer = (state: UsersState, payload?: UsersPayload) => ({
  ...state,
  is_user_update_success: (payload && payload.is_user_update_success) ?? undefined,
});

// add user.
const setUserCreateLoading: UsersReducer = (state: UsersState, payload?: UsersPayload) => ({
  ...state,
  is_user_create_loading: (payload && payload.is_user_create_loading) ?? state.is_user_create_loading,
});
const setUserCreateSuccess: UsersReducer = (state: UsersState, payload?: UsersPayload) => ({
  ...state,
  is_user_create_success: (payload && payload.is_user_create_success) ?? undefined,
});

// users options.
const setUserOptions: UsersReducer = (
  state: UsersState,
  payload?: UsersPayload
) => ({
  ...state,
  user_options: (payload && payload.user_options) ?? state.user_options,
});

const setMinRoleLevel: UsersReducer = (state: UsersState, payload?: UsersPayload) => ({
  ...state,
  min_role_level: payload ? payload.min_role_level : undefined
});

const setMaxRoleLevel: UsersReducer = (state: UsersState, payload?: UsersPayload) => ({
  ...state,
  max_role_level: payload ? payload.max_role_level : undefined
});

const setUserOptionsLoading: UsersReducer = (
  state: UsersState,
  payload?: UsersPayload
) => ({
  ...state,
  is_user_options_loading:
    (payload && payload.is_user_options_loading) ?? state.is_user_options_loading,
});

const setUserOptionsSelectionId: UsersReducer = (state: UsersState, payload?: UsersPayload) => ({
  ...state,
  user_options_selection_id: payload ? payload.user_options_selection_id : undefined
});

// role types.
const setRoleTypes: UsersReducer = (state: UsersState, payload?: UsersPayload) => ({
  ...state,
  role_types: (payload && payload.role_types) ?? state.role_types,
});

const setRoleTypesLoading: UsersReducer = (state: UsersState, payload?: UsersPayload) => ({
  ...state,
  is_role_types_loading: (payload && payload.is_role_types_loading) ?? state.is_role_types_loading,
});

// bulk insert.
const setBulkInsertLoading: UsersReducer = (state: UsersState, payload?: UsersPayload) => ({
  ...state,
  is_bulk_insert_loading: (payload && payload.is_bulk_insert_loading) ?? state.is_bulk_insert_loading
});

const setBulkInsertResult: UsersReducer = (state: UsersState, payload?: UsersPayload) => ({
  ...state,
  bulk_insert_result: (payload && payload.bulk_insert_result) ?? state.bulk_insert_result
});

// role options.
const setRoleOptions: UsersReducer = (state: UsersState, payload?: UsersPayload) => ({
  ...state,
  role_options: (payload && payload.role_options) ?? state.role_options,
});

const setRoleOptionsLoading: UsersReducer = (state: UsersState, payload?: UsersPayload) => ({
  ...state,
  is_role_options_loading: (payload && payload.is_role_options_loading) ?? state.is_role_options_loading,
});

const clearState: UsersReducer = () => ({
  ...initialState,
});

const reducerMap = new Map<UsersActionType, UsersReducer>([
  [UsersActionType.SET_USERS, setUsers],
  [UsersActionType.SET_SEARCH, setSearch],
  [UsersActionType.SET_SEARCH_FIELD, setSearchField],
  [UsersActionType.SET_LIMIT, setLimit],
  [UsersActionType.SET_CURSOR, setCursor],
  [UsersActionType.SET_ORDERBY, setOrderBy],
  [UsersActionType.SET_ORDERBY_DIRECTION, setOrderByDirection],
  [UsersActionType.SET_LOADING, setLoading],
  [UsersActionType.SET_ERROR, setError],
  [UsersActionType.CLEAR, clearState],
  [UsersActionType.SET_COMPANY_ID, setCompanyId],
  [UsersActionType.SET_EXCLUDE_INVITED, setExcludeInvited],

  // edit use.
  [UsersActionType.SET_USER_UPDATE_LOADING, setUserUpdateLoading],
  [UsersActionType.SET_USER_CONTENT_LOADING, setUserContentLoading],
  [UsersActionType.SET_USER_CONTENT, setUserContent],
  [UsersActionType.SET_USER_UPDATE_SUCCESS, setUserUpdateSuccess],

  // add user.
  [UsersActionType.SET_USER_CREATE_LOADING, setUserCreateLoading],
  [UsersActionType.SET_USER_CREATE_SUCCESS, setUserCreateSuccess],

  // user simple.
  [UsersActionType.SET_USER_OPTIONS, setUserOptions],
  [UsersActionType.SET_USER_OPTIONS_LOADING, setUserOptionsLoading],
  [UsersActionType.SET_USER_OPTIONS_SELECTION_ID, setUserOptionsSelectionId],
  [UsersActionType.SET_MIN_ROLE_LEVEL, setMinRoleLevel],
  [UsersActionType.SET_MAX_ROLE_LEVEL, setMaxRoleLevel],
  


  // role types.
  [UsersActionType.SET_ROLE_TYPES, setRoleTypes],
  [UsersActionType.SET_ROLE_TYPES_LOADING, setRoleTypesLoading],

  // bulk insert.
  [UsersActionType.SET_BULK_INSERT_LOADING, setBulkInsertLoading],
  [UsersActionType.SET_BULK_INSERT_RESULT, setBulkInsertResult],

  // role options.
  [UsersActionType.SET_ROLE_OPTIONS, setRoleOptions],
  [UsersActionType.SET_ROLE_OPTIONS_LOADING, setRoleOptionsLoading],
]);

const reducer = (state: UsersState = initialState, action: UsersAction): UsersState => {
  const reducer = reducerMap.get(action.type);
  if (reducer) return reducer(state, action.payload);
  return state;
};

export default reducer;
