// Import the request module for making HTTP requests and the endpoints module
import request from 'src/services/request';
import endpoints from './endpoints';

// Define the api object containing methods to fetch data related to process pipelines
const api = {
  fetchTodoWaitingForImages: async (tenant_id?: string) => {
    const tenantIdQuery = tenant_id ? `&tenant_id=${tenant_id}` : '';
    return request(`${endpoints.waitingForImages}${tenantIdQuery}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
  },

  fetchTodoQueueInfo: async (tenant_id?: string) => {
    const tenantIdQuery = tenant_id ? `&tenant_id=${tenant_id}` : '';
    return request(`${endpoints.queueInfo}${tenantIdQuery}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
  },

  fetchTodoProcessingInfo: async (tenant_id?: string) => {
    const tenantIdQuery = tenant_id ? `&tenant_id=${tenant_id}` : '';
    return request(`${endpoints.processingInfo}${tenantIdQuery}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
  },

  fetchTodoPerformQC: async (tenant_id?: string) => {
    const tenantIdQuery = tenant_id ? `&tenant_id=${tenant_id}` : '';
    return request(`${endpoints.performQcInfo}${tenantIdQuery}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
  },

  fetchTodoCompletedInfo: async (tenant_id?: string) => {
    const tenantIdQuery = tenant_id ? `&tenant_id=${tenant_id}` : '';
    return request(`${endpoints.completedInfo}${tenantIdQuery}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
  },

  fetchTodoReflyInfo: async (tenant_id?: string) => {
    const tenantIdQuery = tenant_id ? `&tenant_id=${tenant_id}` : '';
    return request(`${endpoints.reflyInfo}${tenantIdQuery}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
  },

  fetchTodoMeasurementsInfo: async (tenant_id?: string) => {
    const tenantIdQuery = tenant_id ? `&tenant_id=${tenant_id}` : '';
    return request(`${endpoints.measurementsInfo}${tenantIdQuery}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
  },

  fetchTodoReprocessingInfo: async (tenant_id?: string) => {
    const tenantIdQuery = tenant_id ? `&tenant_id=${tenant_id}` : '';
    return request(`${endpoints.reprocessingInfo}${tenantIdQuery}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
  },

  editSiteAssessmentStaging: async (assessment_id: string, payload: any) =>
    request(`${endpoints.getSiteAssessmentStaging}${assessment_id}/`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    }),
};

// Export the api object for use in other modules
export default api;
