import { FalloutReasonOptionsContent, QCFormContent, QCFormListContent, StatusColorOptionsCotent } from 'src/@types/qcForm';

export enum QcActionType {
  GET_OTIQ_REVISIONS = 'Qc@GET_OTIQ_REVISIONS',
  SET_OTIQ_REVISIONS = 'Qc@SET_OTIQ_REVISIONS',
  INSERT_OTIQ_FILE = 'Qc@INSERT_OTIQ_FILE',
  INSERT_OTIQ_FILES = 'Qc@INSERT_OTIQ_FILES',
  INSERT_OTIQ_REVISIONS = 'Qc@INSERT_OTIQ_REVISIONS',
  DELETE_OTIQ_REVISION = 'Qc@DELETE_OTIQ_REVISION',
  DELETE_OTIQ_FILE = 'Qc@DELETE_OTIQ_FILE',
  SET_LOADING = 'Qc@SET_LOADING',
  SET_ERROR = 'Qc@SET_ERROR',
  SET_SUCCESS = 'Qc@SET_SUCCESS',
  FETCH_FILE_PURPOSE_OPTIONS = 'Qc@FETCH_FILE_PURPOSE_OPTIONS',
  FETCH_FILE_EXTENSION_OPTIONS = 'Qc@FETCH_FILE_EXTENSION_OPTIONS',
  SET_FILE_PURPOSE_OPTIONS = 'Qc@SET_FILE_PURPOSE_OPTIONS',
  SET_FILE_EXTENSION_OPTIONS = 'Qc@SET_FILE_EXTENSION_OPTIONS',

  // qc records
  FETCH_QC_RECORDS = 'Qc@FETCH_QC_RECORDS',
  FETCH_LAST_QC_RECORD = 'Qc@FETCH_LAST_QC_RECORD',
  SET_QC_RECORDS = 'Qc@SET_QC_RECORDS',
  SET_LAST_QC_RECORD = 'Qc@SET_LAST_QC_RECORD',
  SET_FETCH_QC_RECORDS_LOADING = 'Qc@SET_FETCH_QC_RECORDS_LOADING',
  CREATE_QC_RECORD = 'Qc@CREATE_QC_RECORD',
  SET_CREATE_QC_RECORD_LOADING = 'Qc@SET_CREATE_QC_RECORD_LOADING',
  SET_IS_CREATE_QC_RECORD_SUCCESS = 'Qc@SET_IS_CREATE_QC_RECORD_SUCCESS',
  UPDATE_QC_RECORD = 'Qc@UPDATE_QC_RECORD',
  SET_UPDATE_QC_RECORD_LOADING = 'Qc@SET_UPDATE_QC_RECORD_LOADING',
  SET_IS_UPDATE_QC_RECORD_SUCCESS = 'Qc@SET_IS_UPDATE_QC_RECORD_SUCCESS',
  FETCH_FALLOUT_REASONS = 'Qc@FETCH_FALLOUT_REASONS',
  FETCH_STATUS_COLORS = 'Qc@FETCH_STATUS_COLORS',
  SET_FALLOUT_REASONS = 'Qc@SET_FALLOUT_REASONS',
  SET_STATUS_COLORS = 'Qc@SET_STATUS_COLORS',
  SET_FETCH_FALLOUT_REASONS_LOADING = 'Qc@SET_FETCH_FALLOUT_REASONS_LOADING',
  SET_FETCH_STATUS_COLORS_LOADING = 'Qc@SET_FETCH_STATUS_COLORS_LOADING',
}

export type QcState = {
  loading?: boolean;
  error?: Error;
  success?: boolean;
  otiQRevisions?: any;
  fileExtensionSelectOptions?: any;
  filePurposeSelectOptions?: any;
  lastQcRecord: QCFormContent | undefined;
  qcRecords?: QCFormListContent;
  isFetchQcRecordsLoading?: boolean;
  isCreateQcRecordLoading?: boolean;
  isCreateQcRecordSuccess?: boolean;
  isUpdateQcRecordLoading?: boolean;
  fallOutReasons?: FalloutReasonOptionsContent;
  statusColors?: StatusColorOptionsCotent;
  isFetchFallOutReasonsLoading?: boolean;
  isFetchStatusColorsLoading?: boolean;
};

export type QcPayload = {
  fileId?: string;
  sasId?: string;
  revisionId?: string;
  revisionNumber?: number;
  revisionDate?: string;
  loading?: boolean;
  error?: Error;
  success?: boolean;
  siteAssessmentStagingId?: string;
  otiqFileList?: any;
  filePurposeSelectOptions?: any;
  fileExtensionSelectOptions?: any;
  qc?: QCFormContent;
  qcRecords?: QCFormListContent;
  isFetchQcRecordsLoading?: boolean;
  isCreateQcRecordLoading?: boolean;
  isCreateQcRecordSuccess?: boolean;
  isUpdateQcRecordLoading?: boolean;
  currentSiteAssessmentId?: string;
  currentQCFormId?: string;
  fallOutReasons?: FalloutReasonOptionsContent;
  statusColors?: StatusColorOptionsCotent;
  isFetchFallOutReasonsLoading?: boolean;
  isFetchStatusColorsLoading?: boolean;
};

export type QcAction = {
  payload?: any;
  type: QcActionType;
};

export type QcReducer = (state: QcState, payload?: any) => QcState;
