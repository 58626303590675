import React, { useCallback, useEffect, useState } from 'react';

// hooks
import useSiteSearchQuery from 'src/hooks/useSiteSearchQuery';

// ca
import {
  Box,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  ICON,
  useTheme,
  IconButtonAnimate,
  InputStyle,
  FontAwesome,
  debounce,
} from 'ca-react-component-lib';

// constants
import { DEBOUNCE_SEARCH_QUERY_TIMEOUT } from './constants';

import SitesTable from './SitesTable';
import SitesLoading from './SitesLoading';

const QuickSitesSearchDialog = ({ isOpen, onClose }: { isOpen: boolean; onClose: Function }) => {
  const [value, setValue] = useState('');

  const theme = useTheme();

  const {
    isQuickSearchLoading,
    quickSearchQuery,
    quickSearchQueryResult,
    setQuickSearchQueryClear,
    setQuickSearchQuery,
    setQuickSearchLoading,
  } = useSiteSearchQuery();

  useEffect(
    () => () => {
      setQuickSearchQueryClear();
    },
    [setQuickSearchQueryClear]
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const { value } = event.target;

      setValue(value);
      setQuickSearchLoading(true);

      debounce(() => {
        if (value) {
          setQuickSearchQuery({
            ...quickSearchQuery,
            text: value,
          });
        } else {
          setQuickSearchQueryClear();
        }
      }, DEBOUNCE_SEARCH_QUERY_TIMEOUT);
    },
    [
      setValue,
      setQuickSearchQuery,
      quickSearchQuery,
      setQuickSearchQueryClear,
      setQuickSearchLoading,
    ]
  );

  const handleClose = useCallback(() => {
    setValue('');
    onClose();

    // wait animation dialog close to clear search query.
    setTimeout(setQuickSearchQueryClear, 150);
  }, [setQuickSearchQueryClear, onClose]);

  const hasNoResult =
    quickSearchQueryResult &&
    quickSearchQueryResult?.sites?.length === 0 &&
    quickSearchQuery &&
    quickSearchQuery.text;

  return (
    <Dialog fullWidth scroll="paper" maxWidth="md" open={isOpen} onClose={handleClose}>
      <DialogTitle sx={{ mb: 2 }}>
        <Stack direction="row" justifyContent="space-between">
          <div>Quick site search</div>
          <IconButtonAnimate
            color={isOpen ? 'primary' : 'default'}
            onClick={handleClose}
            sx={{ width: 40, height: 40, marginTop: '-8px' }}
          >
            <FontAwesome icon="xmark" color={theme.palette.grey[500_56]} />
          </IconButtonAnimate>
        </Stack>
      </DialogTitle>
      <DialogContent>
        {isOpen && (
          <InputStyle
            fullWidth
            autoFocus
            value={value}
            onChange={handleChange}
            placeholder="Site id"
            InputProps={{
              startAdornment: (
                <FontAwesome
                  icon="magnifying-glass"
                  color={theme.palette.grey[500]}
                  size={ICON.SMALL}
                  sx={{ mr: 1 }}
                />
              ),
            }}
          />
        )}
        <Box sx={{ pt: 3, pb: 2 }}>
          {!quickSearchQueryResult && !isQuickSearchLoading && (
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="body1" gutterBottom>
                Start typing site ID above
              </Typography>
            </Box>
          )}
          {hasNoResult && !isQuickSearchLoading && (
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="body1" sx={{ fontWeight: 'fontWeightBold' }} gutterBottom>
                No Results
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                Try search by another Site id
              </Typography>
            </Box>
          )}
          {isQuickSearchLoading ? <SitesLoading /> : <SitesTable onClose={handleClose} />}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default QuickSitesSearchDialog;
