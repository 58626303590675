import { createContext, useReducer } from 'react';

// types
import { TableHeads, TableDrawerState, Operation, ContextProps, ProviderProps } from './types';

// constants
import { defaultState } from './constants';

// actions
import { actions } from './actions';

// reducer
import { reducer } from './reducer';

// local storage
import { clearTableDrawer, getTableDrawer } from 'src/storage/TableDrawerConfig';

let initialState: TableDrawerState = {
  ...(getTableDrawer() ?? defaultState),
  open: defaultState.open,
  currentOperation: defaultState.currentOperation,
};

const TableDrawerContext = createContext<ContextProps | any>(initialState);

function TableDrawerProvider({ children }: ProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const changeTableColumns = (tableOperation: Operation, table: TableHeads) => {
    switch (tableOperation) {
      case Operation.setDashboardSites:
        dispatch({ type: actions.SET_DASHBOARD_SITES, payload: table });
        break;
      case Operation.setManagementTenants:
        dispatch({ type: actions.SET_MANAGEMENT_TENANTS, payload: table });
        break;
      case Operation.setManagementCompanies:
        dispatch({ type: actions.SET_MANAGEMENT_COMPANIES, payload: table });
        break;
      case Operation.setManagementUsers:
        dispatch({ type: actions.SET_MANAGEMENT_USERS, payload: table });
        break;
      case Operation.setManagementVendors:
        dispatch({ type: actions.SET_MANAGEMENT_VENDORS, payload: table });
        break;
      case Operation.setManagementTickets:
        dispatch({ type: actions.SET_MANAGEMENT_TICKETS, payload: table });
        break;
      case Operation.setDashboardAnnouncements:
        dispatch({ type: actions.SET_DASHBOARD_ANNOUNCEMENTS, payload: table });
        break;
      case Operation.setManagementNotifications:
        dispatch({ type: actions.SET_MANAGEMENT_NOTIFICATIONS, payload: table });
        break;
      default:
        dispatch({ type: actions.SET_DASHBOARD_SITES, payload: table });
    }
  };

  const reset = () => {
    clearTableDrawer();
    dispatch({ type: actions.RESET_SITES, payload: {} });
  };

  const toggleDrawer = (operation: Operation) =>
    dispatch({ type: actions.SET_OPEN, payload: operation });

  return (
    <TableDrawerContext.Provider
      value={{
        ...state,
        changeTableColumns,
        toggleDrawer,
        reset,
      }}
    >
      {children}
    </TableDrawerContext.Provider>
  );
}

export { TableDrawerContext, TableDrawerProvider };

/* 
  #Table Drawer Docs

  ##The table drawer has two main files. 
  - src/contexts/TableDrawerContext/index (Context).
  - src/components/TableDrawer/index (Component).

  ##OPEN TableDrawer:
  - Call the toggleDrawer method available in the context.
  - Pass an "Operation" from the enum declared in src/contexts/TableDrawerContext/types as an argument.
  - The operation will allow the drawer to display the correct column headings.

  ##Adding support for more tables:
  ###In the event that a new table is being made you can easily implement the TableDrawer component to work with it by doing the following:
  - Name the table.
  - Create an array of type TableHeads (src/contexts/TableDrawerContext/types) containing the desired table's headings.
  - Add this array into the defaultStates located in:
    - src/contexts/TableDrawerContext/constants .
    - src/components/TableDrawer/constants .
  - Add an "action" for this table to the object of actions (src/contexts/TableDrawerContext/actions).
  - Add a "Operation" for this table to the Operation enum (src/contexts/TableDrawerContext/types).
  - Add a switch case to the reducer for the action you made earlier (src/contexts/TableDrawerContext/reducer).
  - Add a corresponding switch case to the changeTableColumns method so it can dispatch the action to the reducer (src/contexts/TableDrawerContext/index).
  - Conditionally render the columns in the table you created based on the "enabled" property of each object in the array you just made.
*/
