import { put, call } from 'redux-saga/effects';

// reducer
import actions from '../actions';

// services
import * as siteServices from 'src/servicesV2/site';
import { fetchAssessmentSalesOrder } from './salesOrder';
import { getAssessmentPhoto } from 'src/servicesV2/site';

// types
import { SiteAction } from '../types';

export function* fetchSite({ payload }: SiteAction) {
  if (!payload || !payload.siteId) {
    return;
  }

  const { siteId, assessmentId } = payload;

  yield put(actions.setLoading(true));

  try {
    const response = yield call(siteServices.fetchSiteByName, siteId);

    yield put(actions.setSite(response));
    if(response) yield put(actions.setFetchSiteSuccess(true));

    const currentSiteAssessmentId =
      assessmentId ||
      (response && response.site.assessments && response.site.assessments[0].id) ||
      undefined;

    if (currentSiteAssessmentId) {
      yield put(actions.setCurrentSiteAssessmentId(currentSiteAssessmentId));
      
      yield call(fetchAssessmentSalesOrder, { assessmentId: currentSiteAssessmentId });
    }
  } catch (e) {
    yield put(actions.setError(e as Error));
  } finally {
    yield put(actions.setLoading(false));
    yield put(actions.setFetchSiteSuccess(false));
  }
}

/**
 * Using API V2.
 */
export function* fetchCurrentSiteAssessmentId({ payload }: SiteAction) {
  if (!payload || !payload.currentSiteAssessmentId) return;
  try {
    yield put(actions.setLoading(true));
    yield put(actions.setPhotosLoading(true));

    // fetch main photo for Asset Phofile
    const photoResponse: any = yield call(
      getAssessmentPhoto,
      payload.currentSiteAssessmentId
    );
    yield put(actions.setAssetProfilePhoto(photoResponse));
  } catch (e) {
    yield put(actions.setError(e as Error));
  } finally {
    yield put(actions.setLoading(false));
    yield put(actions.setPhotosLoading(false));
  }
}

export function* fetchSiteAssessmentStaging({ payload }: SiteAction) {
  if (!payload?.assessmentId) return;
  const { assessmentId } = payload;

  try 
  {

    yield put(actions.setLoading(true));

    const response = yield call(
      siteServices.fetchSiteAssessmentStaging,
      assessmentId
    );
    if (!response || !response?.assessment || !response?.assessment?.client_site_identifier) return;
    // SiteAssessmentStaging is needed as we have to get the clientSiteIdentifier from api
    const clientSiteIdentifier = response.assessment.client_site_identifier;
    yield put(actions.setClientSiteIdentifier(clientSiteIdentifier));
  } 
  catch (e) 
  {
    const error: Error = {
      name: 'assessment Id not found',
      message: '',
    };

    yield put(actions.setError(error));
  } 
  finally 
  {
    yield put(actions.setLoading(false));
  }
}
