import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// actions
import usersActions from 'src/store/Users/actions';

// selectors
import usersSelectors from 'src/store/Users/selectors';

// types
import { UserBodyRequestSchema, UserOptionsContent } from 'src/@types/user';

const useUsers = () => {
  const users = useSelector(usersSelectors.getUsers);
  const limit = useSelector(usersSelectors.getLimit);
  const cursor = useSelector(usersSelectors.getCursor);
  const orderBy = useSelector(usersSelectors.getOrderBy);
  const orderByDirection  = useSelector(usersSelectors.getOrderByDirection);
  const search = useSelector(usersSelectors.getSearch);
  const searchField = useSelector(usersSelectors.getSearchField);
  const isLoading = useSelector(usersSelectors.isLoading);
  const companyId = useSelector(usersSelectors.getCompanyId);
  const excludeInvited = useSelector(usersSelectors.getExcludeInvited);

  // edit users.
  const isUserContentLoading = useSelector(usersSelectors.getUserContentLoading);
  const isUserUpdateLoading = useSelector(usersSelectors.getUserUpdateLoading);
  const userContent = useSelector(usersSelectors.getUserContent);
  const isUserUpdateSuccess = useSelector(usersSelectors.getUserUpdateSuccess);

  // add users.
  const isUserCreateLoading = useSelector(usersSelectors.getUserCreateLoading);
  const isUserCreateSuccess = useSelector(usersSelectors.getUserCreateSuccess);

  // user simple.
  const userOptions = useSelector(usersSelectors.getUserOptions);
  const isUserOptionsLoading = useSelector(usersSelectors.getUserOptionsLoading);
  const userOptionsSelectionId = useSelector(usersSelectors.getUserOptionsSelectionId);
  const minRoleLevel = useSelector(usersSelectors.getMinRoleLevel);
  const maxRoleLevel = useSelector(usersSelectors.getMaxRoleLevel);

    
  // role types.
  const roleTypes = useSelector(usersSelectors.getRoleTypes);
  const isRoleTypesLoading = useSelector(usersSelectors.getRoleTypesLoading);

  // bulk insert.
  const bulkInsertResult = useSelector(usersSelectors.getBulkInsertResult);
  const isBulkInsertLoading = useSelector(usersSelectors.getBulkInsertLoading);

  // role options
  const roleOptions = useSelector(usersSelectors.getRoleOptions);
  const isRoleOptionsLoading = useSelector(usersSelectors.getRoleOptionsLoading);


  const dispatch = useDispatch();

  const fetchAllUsers = useCallback(
    () => {
      dispatch(usersActions.fetchAllUsers());
    },
    [dispatch]
  );

  const fetchUserContent = useCallback(
    (user_id: string) => {
      dispatch(usersActions.fetchUserContent(user_id));
    },
    [dispatch]
  );

  const setLimit = useCallback(
    (limit: number) => {
      dispatch(usersActions.setLimit(limit));
    },
    [dispatch]
  );

  const setCursor = useCallback(
    (cursor: string) => {
      dispatch(usersActions.setCursor(cursor));
    },
    [dispatch]
  );

  const setOrderBy = useCallback(
    (order_by: 'company_name' | 'full_name' | 'email' | 'phone_number' | 'invited' | 'date_created') => {
      dispatch(usersActions.setOrderBy(order_by));
    },
    [dispatch]
  );

  const setOrderByDirection = useCallback(
    (order_by_direction: 'asc' | 'desc') => {
      dispatch(usersActions.setOrderByDirection(order_by_direction));
    },
    [dispatch]
  );

  const setSearchField = useCallback(
    (search_field: 'company_name' | 'full_name' | 'email') => {
      dispatch(usersActions.setSearchField(search_field));
    },
    [dispatch]
  );

  const setSearch = useCallback(
    (search: string) => {
      dispatch(usersActions.setSearch(search));
    },
    [dispatch]
  );

  const setLoading = useCallback(
    (is_loading: boolean) => {
      dispatch(usersActions.setLoading(is_loading));
    },
    [dispatch]
  );

  const setCompanyId = useCallback(
    (company_id?: string) => {
      dispatch(usersActions.setCompanyId(company_id));
    },
    [dispatch]
  );

  const setExcludeInvited = useCallback(
    (exclude_invited?: boolean) => {
      dispatch(usersActions.setExcludeInvited(exclude_invited));
    },
    [dispatch]
  );


  // edit user.

  const patchUser = useCallback(
    (user_id:string, user: UserBodyRequestSchema) => {
      dispatch(usersActions.patchUser(user_id, user));
    },
    [dispatch]
  );


  // add user.
  const postUser = useCallback(
    (user: UserBodyRequestSchema) => {
      dispatch(usersActions.postUser(user));
    },
    [dispatch]
  );

  // user options
  const fetchUserOptions = useCallback(
    (search?: string, min_role_level?: number, max_role_level?: number) => {
      dispatch(usersActions.fetchUserOptions(search, min_role_level, max_role_level));
    },
    [dispatch]
  );

  const setUserOptions = useCallback(
    (user_options: UserOptionsContent) => {
      dispatch(usersActions.setUserOptions(user_options));
    },
    [dispatch]
  );

  const setUserOptionsLoading = useCallback(
    (is_user_options_loading: boolean) => {
      dispatch(usersActions.setUserOptionsLoading(is_user_options_loading));
    },
    [dispatch]
  );

  const setUserOptionsSelectionId = useCallback(
    (user_options_selection_id: string) => {
      dispatch(usersActions.setUserOptionsSelectionId(user_options_selection_id));
    },
    [dispatch]
  );

  const setMinRoleLevel = useCallback(
    (min_role_level: number) => {
      dispatch(usersActions.setMinRoleLevel(min_role_level));
    },
    [dispatch]
  );

  const setMaxRoleLevel = useCallback(
    (max_role_level: number) => {
      dispatch(usersActions.setMaxRoleLevel(max_role_level));
    },
    [dispatch]
  );

  // role types.
  const fetchRoleTypes = useCallback(() => {
    dispatch(usersActions.fetchRoleTypes());
  }, [dispatch]);

  // bulk insert.
  const bulkInsert = useCallback(
    (file: any, company_id?: string) => dispatch(usersActions.bulkInsert(file, company_id)),
    [dispatch]
  );

  const inviteUsersById = useCallback(
    (user_ids: string[]) =>
      dispatch(usersActions.inviteUserById(user_ids)),
    [dispatch]
  );

  // role options
  const fetchRoleOptions = useCallback(() => {
    dispatch(usersActions.fetchRoleOptions());
  }, [dispatch]);

  return {
    excludeInvited,
    users,
    limit,
    cursor,
    orderBy,
    orderByDirection,
    searchField,
    search,
    isLoading,
    companyId,
    setCompanyId,
    setLoading,
    setSearch,
    setExcludeInvited,
    inviteUsersById,
    setLimit,
    setCursor,
    setOrderBy,
    setOrderByDirection,
    setSearchField,
    fetchAllUsers,

    // edit user.
    isUserContentLoading,
    isUserUpdateLoading,
    userContent,
    isUserUpdateSuccess,
    patchUser,
    fetchUserContent,

    // add user.
    isUserCreateLoading,
    isUserCreateSuccess,
    postUser,

    // users simple.
    fetchUserOptions,
    setUserOptions,
    setUserOptionsLoading,
    setUserOptionsSelectionId,
    userOptions,
    isUserOptionsLoading,
    userOptionsSelectionId,
    minRoleLevel,
    maxRoleLevel,
    setMinRoleLevel,
    setMaxRoleLevel,

    // role types.
    roleTypes,
    fetchRoleTypes,
    isRoleTypesLoading,

    // bulk insert.
    bulkInsertResult,
    isBulkInsertLoading,
    bulkInsert,

    // role options
    fetchRoleOptions,
    roleOptions,
    isRoleOptionsLoading,
  };
};

export default useUsers;
