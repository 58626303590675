import { ApplicationState } from '../types';

export const _getData = (state: ApplicationState) => state.Toast;

const getMessage = (state: ApplicationState) => _getData(state).message;

const getDisplayed = (state: ApplicationState) => !!_getData(state).message;

const getSeverity = (state: ApplicationState) => _getData(state).severity;

const getOptions = (state: ApplicationState) => _getData(state).options;

const selectors = {
  getMessage,
  getSeverity,
  getDisplayed,
  getOptions,
};

export default selectors;
