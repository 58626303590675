import { put, call } from "redux-saga/effects";
import actions from "../actions";
import * as salesOrderServices from 'src/servicesV2/site';


export function* fetchAssessmentSalesOrder(payload) {
  if(!payload || !payload.assessmentId) return;

  try {
    yield put(actions.setIsAssessmentSalesOrderLoading(true));

    const response: boolean = yield call(
      salesOrderServices.fetchAssessmentSalesOrder, 
      payload.assessmentId
    );
    
    yield put(actions.setIsAssessmentSalesOrder(response));

  } catch (e) {
    yield put(actions.setIsAssessmentSalesOrder(false));
    yield put(actions.setError(e as Error));
  } finally {
    yield put(actions.setIsAssessmentSalesOrderLoading(false));
  }
};