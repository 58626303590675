import { PhotosLoadStrategy } from 'ca-react-component-lib';
import {
  ProcessingPipelineAction,
  ProcessingPipelineActionType,
  ProcessingPipelineReducer,
  ProcessingPipelinePayload,
  ProcessingPipelineState,
} from './types';
import { getTodoTenant } from 'src/storage/TodoTenant';

// Initial state for the processing pipeline
export const initialState: ProcessingPipelineState = {
  waiting_for_images: [],
  queue_for_processing: [],
  wait_for_processing: [],
  perform_qc: [],
  completed: [],
  waiting_for_images_loading: false,
  queue_for_processing_loading: false,
  wait_for_processing_loading: false,
  perform_qc_loading: false,
  completed_loading: false,
  request_refly: [],
  request_refly_loading: false,
  request_measurements: [],
  request_measurements_loading: false,
  re_process: [],
  re_process_loading: false,

  is_loading: false,
  error: undefined,

  editTiePointsLoading: false,
  deleteTiePointsLoading: false,
  selectedTiePointLocations: [],
  nextSelectedTiePointLocationPointer: 0,
  isTiePointPhotosShow: true,
  tiePointPhotoLoadingStrategy: PhotosLoadStrategy.RENDER_ON_SCROLL,
  tiePointPhotoTabIndex: 0,
  isImageLoading: false,
  
  isEditSiteAssessmentStagingSuccess: false,
  // ProcessingPipeline: refactor to "tenant" LabelValue type. This will make possible the TenantSelection use directly the value.
  tenantId: (() => {
    const tenant = getTodoTenant();

    if (tenant && tenant.value) {
      return tenant.value;
    } else {
      return undefined;
    }
  })(),

  // when isFilterBucketsAfterMovingAsset is set to true,
  // setFilteredSearchOptions functions are triggered in Sidebar component to update the number of assets in buckets
  isFilterBucketsAfterMovingAsset: false,

  actionViewOpen: false,
};

// Reducer to handle setting an error state
const setError: ProcessingPipelineReducer = (
  state: ProcessingPipelineState,
  payload?: ProcessingPipelinePayload
) => ({
  ...state,
  error: payload ? payload.error : undefined,
});

// Reducer to handle setting the items waiting for Images
const setWaitingForImages: ProcessingPipelineReducer = (
  state: ProcessingPipelineState,
  payload?: ProcessingPipelinePayload
) => ({
  ...state,
  waiting_for_images: (payload && payload.waiting_for_images) ?? state.waiting_for_images,
});

// Reducer to handle setting the loading state for waiting for Images
const setWaitingForImagesLoading: ProcessingPipelineReducer = (
  state: ProcessingPipelineState,
  payload?: ProcessingPipelinePayload
) => ({
  ...state,
  waiting_for_images_loading: !!payload?.waiting_for_images_loading,
});

// Reducer to handle setting the items in queue for processing
const setQueueForProcessing: ProcessingPipelineReducer = (
  state: ProcessingPipelineState,
  payload?: ProcessingPipelinePayload
) => ({
  ...state,
  queue_for_processing: (payload && payload.queue_for_processing) ?? state.queue_for_processing,
});

// Reducer to handle setting the loading state for queue for processing
const setQueueForProcessingLoading: ProcessingPipelineReducer = (
  state: ProcessingPipelineState,
  payload?: ProcessingPipelinePayload
) => ({
  ...state,
  queue_for_processing_loading: !!payload?.queue_for_processing_loading,
});

// Reducer to handle setting the items waiting for processing
const setWaitForProcessing: ProcessingPipelineReducer = (
  state: ProcessingPipelineState,
  payload?: ProcessingPipelinePayload
) => ({
  ...state,
  wait_for_processing: (payload && payload.wait_for_processing) ?? state.wait_for_processing,
});

// Reducer to handle setting the loading state for waiting for processing
const setWaitForProcessingLoading: ProcessingPipelineReducer = (
  state: ProcessingPipelineState,
  payload?: ProcessingPipelinePayload
) => ({
  ...state,
  wait_for_processing_loading: !!payload?.wait_for_processing_loading,
});

// Reducer to handle setting the items for quality control (QC)
const setPerformQC: ProcessingPipelineReducer = (
  state: ProcessingPipelineState,
  payload?: ProcessingPipelinePayload
) => ({
  ...state,
  perform_qc: (payload && payload.perform_qc) ?? state.perform_qc,
});

// Reducer to handle setting the loading state for quality control (QC)
const setPerformQCLoading: ProcessingPipelineReducer = (
  state: ProcessingPipelineState,
  payload?: ProcessingPipelinePayload
) => ({
  ...state,
  perform_qc_loading: !!payload?.perform_qc_loading,
});

// Reducer to handle setting the completed items
const setCompleted: ProcessingPipelineReducer = (
  state: ProcessingPipelineState,
  payload?: ProcessingPipelinePayload
) => ({
  ...state,
  completed: (payload && payload.completed) ?? state.completed,
});

// Reducer to handle setting the loading state for completed items
const setCompletedLoading: ProcessingPipelineReducer = (
  state: ProcessingPipelineState,
  payload?: ProcessingPipelinePayload
) => ({
  ...state,
  completed_loading: !!payload?.completed_loading,
});

// Reducer to handle setting the items for refly
const setRequestRefly: ProcessingPipelineReducer = (
  state: ProcessingPipelineState,
  payload?: ProcessingPipelinePayload
) => ({
  ...state,
  request_refly: (payload && payload.request_refly) ?? state.request_refly,
});

// Reducer to handle setting the loading state for refly
const setRequestReflyLoading: ProcessingPipelineReducer = (
  state: ProcessingPipelineState,
  payload?: ProcessingPipelinePayload
) => ({
  ...state,
  request_refly_loading: !!payload?.request_refly_loading,
});

// reducer to handler the items for measurements
const setRequestMeasurements: ProcessingPipelineReducer = (
  state: ProcessingPipelineState,
  payload?: ProcessingPipelinePayload
) => ({
  ...state,
  request_measurements: (payload && payload.request_measurements) ?? state.request_measurements,
});

// Reducer to handle setting the loading state for measurements
const setRequestMeasurementsLoading: ProcessingPipelineReducer = (
  state: ProcessingPipelineState,
  payload?: ProcessingPipelinePayload
) => ({
  ...state,
  request_measurements_loading: !!payload?.request_measurements_loading,
});

// Reducer to handle setting the items for reprocessing
const setReProcess: ProcessingPipelineReducer = (
  state: ProcessingPipelineState,
  payload?: ProcessingPipelinePayload
) => ({
  ...state,
  re_process: (payload && payload.re_process) ?? state.re_process,
});

// Reducer to handle setting the loading state for reprocessing
const setReProcessLoading: ProcessingPipelineReducer = (
  state: ProcessingPipelineState,
  payload?: ProcessingPipelinePayload
) => ({
  ...state,
  re_process_loading: !!payload?.re_process_loading,
});


const setEditTiePointsLoading: ProcessingPipelineReducer = (state: ProcessingPipelineState, payload?: ProcessingPipelinePayload) => ({
  ...state,
  editTiePointsLoading: !!payload?.is_loading,
});
const setDeleteTiePointsLoading: ProcessingPipelineReducer = (state: ProcessingPipelineState, payload?: ProcessingPipelinePayload) => ({
  ...state,
  deleteTiePointsLoading: !!payload?.is_loading,
});

const setSelectedTiePointLocations: ProcessingPipelineReducer = (state: ProcessingPipelineState, payload?: ProcessingPipelinePayload) => ({
  ...state,
  selectedTiePointLocations:
    (payload && payload.selectedTiePointLocations) ?? state.selectedTiePointLocations,
});
const setNextSelectedTiePointLocationPointer: ProcessingPipelineReducer = (
  state: ProcessingPipelineState,
  payload?: ProcessingPipelinePayload
) => ({
  ...state,
  nextSelectedTiePointLocationPointer:
    payload && payload.nextSelectedTiePointLocationPointer !== undefined
      ? payload.nextSelectedTiePointLocationPointer
      : state.nextSelectedTiePointLocationPointer,
});
const setIsTiePointPhotosShow: ProcessingPipelineReducer = (state: ProcessingPipelineState, payload?: ProcessingPipelinePayload) => ({
  ...state,
  isTiePointPhotosShow:
    payload && payload.isTiePointPhotosShow !== undefined
      ? payload.isTiePointPhotosShow
      : state.isTiePointPhotosShow,
});


const setTiePointPhotoLoadingStrategy: ProcessingPipelineReducer = (state: ProcessingPipelineState, payload?: ProcessingPipelinePayload) => ({
  ...state,
  tiePointPhotoLoadingStrategy:
    (payload && payload.tiePointPhotoLoadingStrategy) ?? state.tiePointPhotoLoadingStrategy,
});
const setTiePointPhotoTabIndex: ProcessingPipelineReducer = (state: ProcessingPipelineState, payload?: ProcessingPipelinePayload) => ({
  ...state,
  tiePointPhotoTabIndex:
    payload && payload.tiePointPhotoTabIndex !== undefined
      ? payload.tiePointPhotoTabIndex
      : state.tiePointPhotoTabIndex,
});

const setImageLoading: ProcessingPipelineReducer = (state: ProcessingPipelineState, payload?: ProcessingPipelinePayload) => ({
  ...state,
  isImageLoading: !!payload?.isImageLoading,
});

const setAssessmentStaging: ProcessingPipelineReducer = (state: ProcessingPipelineState, payload?: ProcessingPipelinePayload) => {
  try {
    const { fromAction, toAction } = payload?.data;
    const { id } = payload?.data?.assessment;
    const assessment = state[fromAction!].find((ProcessingPipeline) => ProcessingPipeline.id === id);
    state[fromAction!].forEach((element, index) => {
      if (element.id === id) {
        state[fromAction!].splice(index, 1);
      }
    });
    const newAssessment = {
      ...assessment,
      ...payload?.data.assessment,
      dataReceived: assessment?.dataReceived || assessment?.data_received ,
    };
    state[toAction].unshift(newAssessment);
    return {
      ...state,
    };
  } catch (e) {
    console.log('Error in setAssessmentStaging', e);
    return {
      ...state,
    };
  }
};


const setTenantId: ProcessingPipelineReducer = (state: ProcessingPipelineState, payload?: ProcessingPipelinePayload) => ({
  ...state,
  tenantId: payload && payload.tenantId !== undefined ? payload.tenantId : initialState.tenantId,
});

const setIsFilterBucketsAfterMovingAsset: ProcessingPipelineReducer = (
  state: ProcessingPipelineState,
  payload?: ProcessingPipelinePayload
) => ({
  ...state,
  isFilterBucketsAfterMovingAsset: !!payload?.isFilterBucketsAfterMovingAsset,
});

const setEditSiteAssessmentStagingSuccess: ProcessingPipelineReducer = (
  state: ProcessingPipelineState,
  payload?: ProcessingPipelinePayload
) => ({
  ...state,
  isEditSiteAssessmentStagingSuccess: !!payload?.isEditSiteAssessmentStagingSuccess,
});



const setSpeedDialAction: ProcessingPipelineReducer = (state: ProcessingPipelineState, payload?: ProcessingPipelinePayload) => ({
  ...state,
  speedDialAction: (payload && payload.speedDialAction) ?? initialState.speedDialAction,
});

const setActionViewOpen: ProcessingPipelineReducer = (state: ProcessingPipelineState, payload?: ProcessingPipelinePayload) => ({
  ...state,
  actionViewOpen: !!payload?.actionViewOpen,
});




// Reducer to handle clearing the processing pipeline state
const clearState: ProcessingPipelineReducer = (state: ProcessingPipelineState) => ({
  ...initialState,
});

const reducerMap = new Map<ProcessingPipelineActionType, ProcessingPipelineReducer>([
  [ProcessingPipelineActionType.SET_ERROR, setError],
  [ProcessingPipelineActionType.CLEAR, clearState],
  [ProcessingPipelineActionType.SET_WAIT_FOR_PROCESSING, setWaitForProcessing],
  [ProcessingPipelineActionType.SET_WAIT_FOR_PROCESSING_LOADING, setWaitForProcessingLoading],
  [ProcessingPipelineActionType.SET_QUEUE_FOR_PROCESSING, setQueueForProcessing],
  [ProcessingPipelineActionType.SET_QUEUE_FOR_PROCESSING_LOADING, setQueueForProcessingLoading],
  [ProcessingPipelineActionType.SET_PERFORM_QC, setPerformQC],
  [ProcessingPipelineActionType.SET_PERFORM_QC_LOADING, setPerformQCLoading],
  [ProcessingPipelineActionType.SET_COMPLETED, setCompleted],
  [ProcessingPipelineActionType.SET_COMPLETED_LOADING, setCompletedLoading],
  [ProcessingPipelineActionType.SET_WAITING_FOR_IMAGES, setWaitingForImages],
  [ProcessingPipelineActionType.SET_WAITING_FOR_IMAGES_LOADING, setWaitingForImagesLoading],
  [ProcessingPipelineActionType.SET_REQUEST_REFLY, setRequestRefly],
  [ProcessingPipelineActionType.SET_REQUEST_REFLY_LOADING, setRequestReflyLoading],
  [ProcessingPipelineActionType.SET_REQUEST_MEASUREMENTS, setRequestMeasurements],
  [ProcessingPipelineActionType.SET_REQUEST_MEASUREMENTS_LOADING, setRequestMeasurementsLoading],
  [ProcessingPipelineActionType.SET_RE_PROCESS, setReProcess],
  [ProcessingPipelineActionType.SET_RE_PROCESS_LOADING, setReProcessLoading],

  [ProcessingPipelineActionType.SET_EDIT_TIE_POINTS_LOADING, setEditTiePointsLoading],
  [ProcessingPipelineActionType.SET_DELETE_TIE_POINTS_LOADING, setDeleteTiePointsLoading],
  [ProcessingPipelineActionType.SET_SELECTED_TIE_POINT_LOCATIONS, setSelectedTiePointLocations],
  [ProcessingPipelineActionType.SET_NEXT_SELECTED_TIE_POINT_LOCATION_POINTER, setNextSelectedTiePointLocationPointer],
  [ProcessingPipelineActionType.SET_IS_TIE_POINT_PHOTOS_SHOW, setIsTiePointPhotosShow],
  [ProcessingPipelineActionType.SET_TIE_POINT_PHOTO_LOADING_STRATEGY, setTiePointPhotoLoadingStrategy],
  [ProcessingPipelineActionType.SET_TIE_POINT_PHOTO_TAB_INDEX, setTiePointPhotoTabIndex],
  [ProcessingPipelineActionType.SET_IMAGE_LOADING, setImageLoading],

  [ProcessingPipelineActionType.SET_ASSESSMENT_STAGING, setAssessmentStaging],
  [ProcessingPipelineActionType.SET_IS_EDIT_SITE_ASSESSMENT_STAGING_SUCCESS, setEditSiteAssessmentStagingSuccess],
  
  [ProcessingPipelineActionType.SET_TENANT_ID, setTenantId],
  [ProcessingPipelineActionType.SET_IS_FILTER_BUCKETS_AFTER_MOVING_ASSET, setIsFilterBucketsAfterMovingAsset],
  [ProcessingPipelineActionType.SET_SPEED_DIAL_ACTION, setSpeedDialAction],
  [ProcessingPipelineActionType.SET_ACTION_VIEW_OPEN, setActionViewOpen],

  
]);

const reducer = (
  state: ProcessingPipelineState = initialState,
  action: ProcessingPipelineAction
): ProcessingPipelineState => {
  const reducer = reducerMap.get(action.type);
  if (reducer) return reducer(state, action.payload);
  return state;
};

export default reducer;
