import { useMemo } from 'react';

// ca
import {
  Yup,
  useForm,
  yupResolver,
  FormProvider,
  RHFTextField,
  Button,
  Stack,
  Typography,
  Box,
  Divider,
  Alert,
} from 'ca-react-component-lib';

import { FormValuesProps } from './types';
import useAuth from 'src/hooks/useAuth';
import { AuthenticationError } from 'src/store/Authentication/types';
import { Onboarding } from 'src/@types/auth';

const GetAccessForm = () => {
  const { error, isLoading, postOnboardUser, setOnboarding } = useAuth();

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  });

  const defaultValues = useMemo(
    () => ({
      email: '',
    }),
    []
  );

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const { handleSubmit } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    postOnboardUser(data.email);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h4" gutterBottom>
            Get Access
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            If you have not yet received a password, enter your email below to onboard.
          </Typography>
        </Box>
      </Stack>
      <Stack spacing={3}>
        {error && error.name === AuthenticationError.VALIDATE && (
          <Alert severity="error">{error.message}</Alert>
        )}
        <RHFTextField name="email" label="Email address" size="medium" />
        <Button fullWidth size="large" variant="contained" type="submit" isLoading={isLoading}>
          Proceed
        </Button>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
          sx={{ my: 2 }}
        >
          <Stack flex={1}>
            <Divider />
          </Stack>
          <Typography sx={{ color: 'text.secondary' }}>OR</Typography>
          <Stack flex={1}>
            <Divider />
          </Stack>
        </Stack>
        <Button
          sx={{ textTransform: 'none' }}
          fullWidth
          size="large"
          variant="outlined"
          onClick={() => {
            setOnboarding(Onboarding.LOGIN);
          }}
        >
          <Typography>Return to Login</Typography>
        </Button>
      </Stack>
    </FormProvider>
  );
};

export default GetAccessForm;
