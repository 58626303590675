import { ConnectionResponse, JobResponse } from "src/@types/processing";

export enum ProcessingActionType {
  SET_PROCESSING_LOADING = 'Processing@SET_PROCESSING_LOADING',
  SET_PROCESSING_OPEN = 'Processing@SET_PROCESSING_OPEN',
  SET_PROCESSING_CLOSE = 'Processing@SET_PROCESSING_CLOSE',
  SET_PROCESSING_JOBS = 'Processing@SET_PROCESSING_JOBS',
  SET_PROCESSING_QUEUED = 'Processing@SET_PROCESSING_QUEUED',
  SET_SESSION = 'Processing@SET_SESSION',
  SET_SEARCH_QUERY = 'Processing@SET_SEARCH_QUERY',
  SET_TARGET_QUEUE_ID = 'Processing@SET_TARGET_QUEUE_ID',
  FETCH_MODEL_PROCESSING_QUEUES = 'Processing@FETCH_MODEL_PROCESSING_QUEUES',
  SET_MODEL_PROCESSING_QUEUES = 'Processing@SET_MODEL_PROCESSING_QUEUES',
  CLEAR = 'Processing@CLEAR',
}

export type ProcessingState = {
  jobs: any[];
  queued: ConnectionResponse | JobResponse[];
  isOpen: boolean;
  isLoading: boolean;
  searchQuery?: any;
  session?: any;
  targetQueueId: string;
  modelProcessingQueues: any;
};

export type ProcessingPayload = {
  jobs?: any[];
  queued?: ConnectionResponse | JobResponse[];
  isOpen?: boolean;
  isLoading?: boolean;
  searchQuery?: any;
  session?: any;
  targetQueueId?: string;
  modelProcessingQueues?: any;
};

export type ProcessingAction = {
  payload?: ProcessingPayload;
  type: ProcessingActionType;
};

export enum ProcessingType {
  RUNNING = 'RUNNING',
  WAITING = 'WAITING',
  FINISH = 'FINISH',
  FINISHED = 'FINISHED',
  FAILED = 'FAILED',
}

export type ProcessingReducer = (
  state: ProcessingState,
  payload?: ProcessingPayload
) => ProcessingState;
