import { ApplicationState } from '../types';

const _getData = (state: ApplicationState) => state.Companies;

const getError = (state: ApplicationState) => _getData(state).error;

const isLoading = (state: ApplicationState) => _getData(state).is_loading;

const isCompanyContentLoading = (state: ApplicationState) => _getData(state).company_content_loading;

const isCompanyCreateLoading = (state: ApplicationState) => _getData(state).company_create_loading;

const isCompanyUpdateLoading = (state: ApplicationState) => _getData(state).company_update_loading;

const getCompanies = (state: ApplicationState) => _getData(state).company_pagination;

const getCompanyContent = (state: ApplicationState) => _getData(state).company_content;

const getLimit = (state: ApplicationState) => _getData(state).limit;

const getCursor = (state: ApplicationState) => _getData(state).cursor;

const getOrderBy = (state: ApplicationState) => _getData(state).order_by;

const getOrderByDirection = (state: ApplicationState) => _getData(state).order_by_direction;

const getSearch = (state: ApplicationState) => _getData(state).search;

const getSearchField = (state: ApplicationState) => _getData(state).search_field;

const getTenantId = (state: ApplicationState) => _getData(state).tenant_id;

const getIsVendor = (state: ApplicationState) => _getData(state).is_vendor;

const getIsTowerOwner = (state: ApplicationState) => _getData(state).is_tower_owner;

const getCompanyCreateSuccess = (state: ApplicationState) => _getData(state).is_company_create_success;

const getCompanyUpdateSuccess = (state: ApplicationState) => _getData(state).is_company_update_success;

const getCompanyRequestObj = (state: ApplicationState) => _getData(state).company_request_obj;

// companies simple.
const getCompanyOptions = (state: ApplicationState) => _getData(state).company_options;

const getCompanyOptionsLoading = (state: ApplicationState) =>
  _getData(state).is_company_options_loading;

const getCompanySelectionId = (state: ApplicationState) => _getData(state).company_selection_id;

// ownership types.
const getOwnershipTypes = (state: ApplicationState) => _getData(state).ownership_types;

const getOwnershipTypesLoading = (state: ApplicationState) =>
  _getData(state).is_ownership_types_loading;

const selectors = {
  getError,
  isLoading,
  isCompanyContentLoading,
  isCompanyCreateLoading,
  isCompanyUpdateLoading,
  getCompanies,
  getLimit,
  getCursor,
  getOrderBy,
  getOrderByDirection,
  getSearchField,
  getSearch,
  getCompanyCreateSuccess,
  getCompanyUpdateSuccess,
  getTenantId,
  getIsVendor,
  getIsTowerOwner,
  getCompanyContent,
  getCompanyRequestObj,
  
  // companies simple.
  getCompanyOptions,
  getCompanyOptionsLoading,
  getCompanySelectionId,

  // ownership types.
  getOwnershipTypes,
  getOwnershipTypesLoading,
};

export default selectors;
