import { ApplicationState } from "../types";

export const _getData = (state: ApplicationState) => state.Ticket;


const getTicket = (state: ApplicationState) => _getData(state).ticket_pagination;

const getLimit = (state: ApplicationState) => _getData(state).limit;

const getCursor = (state: ApplicationState) => _getData(state).cursor;

const getOrderBy = (state: ApplicationState) => _getData(state).order_by;

const getOrderByDirection = (state: ApplicationState) => _getData(state).order_by_direction;

const getSearch = (state: ApplicationState) => _getData(state).search;

const getSearchField = (state: ApplicationState) => _getData(state).search_field;

const getStatusId = (state: ApplicationState) => _getData(state).status_id;

const getLoading = (state: ApplicationState) => _getData(state).is_loading;

const getTicketUpdateSuccess = (state: ApplicationState) => _getData(state).is_ticket_update_success;

const popoverOpened = (state:ApplicationState) => _getData(state).popover_opened;

const getTicketStatus = (state: ApplicationState) => _getData(state).ticket_status;

const loading = (state: ApplicationState) => _getData(state).is_loading;

const selectors = {
  getTicket,
  popoverOpened,
  loading,
  getLimit,
  getCursor,
  getOrderBy,
  getOrderByDirection,
  getSearch,
  getSearchField,
  getStatusId,
  getLoading,
  getTicketUpdateSuccess,
  getTicketStatus,
};

export default selectors;