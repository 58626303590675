const apiUrl = process.env.REACT_APP_STAFF_V2_API_ENDPOINT;

const endpoints = {
  customer_success_companies: `${apiUrl}/api/customer-success/company/`,
  customer_success_switch: `${apiUrl}/api/customer-success/switch/`,
  customer_success_access: `${apiUrl}/api/customer-success/access/`,
  customer_success_access_user_options: `${apiUrl}/api/customer-success/user/options/`,
  customer_success_access_company_options: `${apiUrl}/api/customer-success/company/options/`,

};

export default endpoints;