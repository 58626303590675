import { TenantCreateBodyRequestSchema, TenantUpdateBodyRequestSchema } from './types';
import request from 'src/services/request';
import endpoints from './endpoints';

const api = {
  fetchAllTenants: async (
    limit: number, 
    cursor?: string, 
    order_by_direction?: string,
    order_by?: string,
    search?: string,
    search_field?: string,
  ) => {
    let queryString = `?limit=${limit}`;

    if (cursor) queryString += `&cursor=${cursor}`;
    if (order_by_direction) queryString += `&order_by_direction=${order_by_direction}`;
    if (order_by) queryString += `&order_by=${order_by}`;
    if (search) queryString += `&search=${search}`;
    if (search_field) queryString += `&search_field=${search_field}`;

    return request(
      `${endpoints.tenant}${queryString}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });
  },
  fetchTenantContent: async (tenant_id: string) =>
    request(`${endpoints.tenant}${tenant_id}/`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }),
  patchTenant: async (tenant_id: string, payload: TenantUpdateBodyRequestSchema) =>
    request(`${endpoints.tenant}${tenant_id}/`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    }),
  postTenant: async (payload: TenantCreateBodyRequestSchema) =>
    request(`${endpoints.tenant}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    }),
  fetchTenantOptions: async (search?: string) => {
    let queryString = '';
    if (search) queryString += `?search=${search}`;

    return request(
      `${endpoints.tenant_options}${queryString}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });
  },
};

export default api;
