import { ApplicationState } from '../types';

const _getData = (state: ApplicationState) => state.Profile;

const getError = (state: ApplicationState) => _getData(state).error;

const isLoading = (state: ApplicationState) => _getData(state).is_loading;

const getUserContent = (state: ApplicationState) => _getData(state).user_content;

const getFirstName = (state: ApplicationState) => getUserContent(state).user?.first_name;

const getProfileId = (state: ApplicationState) => getUserContent(state).user?.id;

const getRole = (state: ApplicationState) => getUserContent(state).user?.role_name;

const getLastName = (state: ApplicationState) => getUserContent(state).user?.last_name;

const getDisplayName = (state: ApplicationState) =>
  getUserContent(state).user?.full_name ?? `${getFirstName(state)} ${getLastName(state)}`;

const getUpdateLoading = (state: ApplicationState) => _getData(state).update_loading;

const getUpdateError = (state: ApplicationState) => _getData(state).update_error;

const selectors = {
  getUserContent,
  getRole,
  getProfileId,
  getError,
  isLoading,
  getDisplayName,
  getUpdateLoading,
  getUpdateError,
};

export default selectors;
