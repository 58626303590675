import { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

// ca
import {
  Button,
  FontAwesome,
  Stack,
  IconButton,
  InputAdornment,
  Divider,
  Typography,
  Box,
  Alert,
  FormProvider,
  RHFTextField,
  Link,
  useForm,
  yupResolver,
  Yup,
  ICON,
  LoadingButton
} from 'ca-react-component-lib';

// routes
import { PATH_AUTH } from 'src/routes/paths';
import { FormValuesProps } from './types';

// hooks
import useAuth from 'src/hooks/useAuth';
import { Onboarding } from 'src/@types/auth';

export default function LoginForm() {
  const { login, isLoading, error, setOnboarding } = useAuth();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = useMemo(
    () => ({
      remember: true,
      email: '',
      password: '',
    }),
    []
  );

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    setError,
    reset,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    login(data.email, data.password);
  };

  useEffect(() => {
    if (error) {
      reset();
      setError('afterSubmit', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const errorMessage = useMemo(
    () => (errors?.afterSubmit?.message ? errors?.afterSubmit.message : error?.message),
    [errors, error]
  );

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h4" gutterBottom>
            Sign in
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
        </Box>
      </Stack>
      <Stack spacing={3}>
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        <RHFTextField
          name="email"
          label="Email address"
          size="medium"
          InputProps={{
            autoComplete: 'username',
          }}
        />

        <RHFTextField
          name="password"
          label="Password"
          size="medium"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            autoComplete: 'current-password', //username
            endAdornment: (
              <InputAdornment position="end" sx={{ mr: 1 }}>
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <FontAwesome
                    icon={showPassword ? 'eye' : 'eye-slash'}
                    type="solid"
                    size={ICON.LARGE}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Link
          variant="subtitle2"
          sx={{ cursor: 'pointer' }}
          onClick={() => navigate(PATH_AUTH.resetPassword)}
        >
          Forgot password?
        </Link>
      </Stack>
      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isLoading}>
        Login
      </LoadingButton>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
        sx={{ my: 2 }}
      >
        <Stack flex={1}>
          <Divider />
        </Stack>
        <Typography sx={{ color: 'text.secondary' }}>OR</Typography>
        <Stack flex={1}>
          <Divider />
        </Stack>
      </Stack>
      <Button
        fullWidth
        size="large"
        variant="outlined"
        onClick={() => setOnboarding(Onboarding.VALIDATE)}
      >
        Get Access
      </Button>
    </FormProvider>
  );
}