import { takeLatest } from 'redux-saga/effects';

// types
import { SiteActionType } from '../types';

import { createSite } from './add';

// asset class select options
import { fetchAssetClassSelectOptions } from './assetClass';

import { fetchStructureSelectOptions } from './structure';

import { fetchSite, fetchCurrentSiteAssessmentId, fetchSiteAssessmentStaging } from './sites';

import { fetchAssessmentSalesOrder } from './salesOrder';

import {
  deletePhotoTiePoints,
  editPhotoTiePoints,
  editSite,
  submitQueueForProcessing,
  deleteSiteAssessmentStaging,
  startAzureImport,
  startAzureExport,
  forceCompleteUpload,
  deleteSiteUser
} from './edit';

const sagas = [
  takeLatest(SiteActionType.FETCH_SITE, fetchSite),
  takeLatest(SiteActionType.SET_CURRENT_ASSESSMENT_ID, fetchCurrentSiteAssessmentId),
  takeLatest(SiteActionType.FETCH_ASSET_CLASS_SELECT_OPTIONS, fetchAssetClassSelectOptions),
  takeLatest(SiteActionType.FETCH_STRUCTURE_SELECT_OPTIONS, fetchStructureSelectOptions),
  takeLatest(SiteActionType.FETCH_SITE_ASSESSMENT_STAGING, fetchSiteAssessmentStaging),
  takeLatest(SiteActionType.CREATE_SITE, createSite),
  takeLatest(SiteActionType.EDIT_SITE, editSite),
  takeLatest(SiteActionType.EDIT_PHOTO_TIE_POINTS, editPhotoTiePoints),
  takeLatest(SiteActionType.START_AZURE_IMPORT, startAzureImport),
  takeLatest(SiteActionType.START_AZURE_EXPORT, startAzureExport),
  takeLatest(SiteActionType.FORCE_COMPLETE_UPLOAD, forceCompleteUpload),
  takeLatest(SiteActionType.DELETE_PHOTO_TIE_POINTS, deletePhotoTiePoints),
  takeLatest(SiteActionType.SUBMIT_QUEUE_FOR_PROCESSING, submitQueueForProcessing),
  takeLatest(SiteActionType.DELETE_SITE_ASSESSMENT_STAGING, deleteSiteAssessmentStaging),
  takeLatest(SiteActionType.FETCH_ASSESSMENT_SALES_ORDER, fetchAssessmentSalesOrder),
  takeLatest(SiteActionType.DELETE_SITE_USER, deleteSiteUser)
];

export default sagas;
