export enum ImportJobsActionType {
  FETCH_IMPORT_JOBS = 'ImportJobs@FETCH_IMPORT_JOBS',
  SET_IMPORT_JOBS = 'ImportJobs@SET_IMPORT_JOBS',
  SET_IMPORT_JOBS_LOADING = 'ImportJobs@SET_IMPORT_JOBS_LOADING',
  SET_IMPORT_JOBS_ERROR = 'ImportJobs@SET_IMPORT_JOBS_ERROR',
  SET_COLUMN = 'ImportJobs@SET_COLUMN',
  SET_SORTBY = 'ImportJobs@SET_SORTBY',
}

export type ImportJob = {
  azure_url: string;
  last_message: string;
  last_status: string;
  last_timestamp: string;
  job_created_at: string;
  project_name: string;
  s3_uri: string;
  sas_id: string;
}

export type ImportJobsState = {
  importJobs?: ImportJob[];
  importJobsLoading: boolean;
  importJobsError?: Error | string;
  column: string;
  sortBy: "asc" | "desc";
};

export type ImportJobsPayload = {
  importJobs?: ImportJob[];
  importJobsLoading?: boolean;
  importJobsError?: Error | string;
  column?: string;
  sortBy?: "asc" | "desc";
};

export type ImportJobsAction = {
  payload?: ImportJobsPayload;
  type: ImportJobsActionType;
};

export type ImportJobsReducer = (state: ImportJobsState, payload?: ImportJobsPayload) => ImportJobsState;
