import { put, call, select } from 'redux-saga/effects';

// services
import * as siteServices from 'src/servicesV2/site';
import * as processingPipelineServices from 'src/servicesV2/processingPipeline';
import {
  setAssessmentPhotoTiePoint,
  deleteAssessmentPhotoTiePoint,
  getAssessmentPhotos,
  triggerAzureImport,
  triggerAzureExport,
  completeUpload,
} from 'src/servicesV2/site';

// actions
import toastActions from 'src/store/Toast/actions';
import processingPipelineActions from 'src/store/ProcessingPipeline/actions';
import siteActions from 'src/store/Site/actions';
import siteSelectors from 'src/store/Site/selectors';

// types
import { SuccessError } from 'src/@types/root';
import { SiteAction } from '../types';
import { SiteImage } from 'src/@types/site';
import { TOAST_SEVERITY } from 'src/@types/toast';

// v2
import { convertToV2SiteAssessmentsPayload, convertToV2SitePayload, convertToV2SiteUsersPayload } from './add';

export function* editSite({ payload }: SiteAction) {
  if (!payload) return;

  try {
    yield put(siteActions.setLoading(true));
    const editSiteResponse = yield call(
      siteServices.editSite,
      convertToV2SitePayload(payload),
      payload.slmId!
    );

    // Handle site assessments with a for...of loop
    for (const assessment of payload.site.siteAssessments) {
      if (assessment.id) {
        yield call(siteServices.editSiteAssessment, convertToV2SiteAssessmentsPayload(assessment), assessment.id);
      } else {
        yield call(siteServices.createSiteAssessment, convertToV2SiteAssessmentsPayload(assessment), payload.slmId!);
      }
    }

    // Handle site people similarly
    for (const person of payload.site.sitePeople) {
      if (person.id.includes('-')) {
        yield call(siteServices.editSiteUser, convertToV2SiteUsersPayload(person), person.id);
      } else {
        yield call(siteServices.createSiteUser, convertToV2SiteUsersPayload(person), payload.slmId!);
      }
    }

    if (editSiteResponse?.site?.id) {
      yield put(toastActions.setToast('Updated Successfully'));
      // Re-fetch site data to ensure state is updated
      const response = yield call(siteServices.fetchSiteByName, payload.site.companySiteId);
      yield put(siteActions.setSite(response));

    } else {
      yield put(toastActions.setToast('Update Failed', TOAST_SEVERITY.ERROR, 10000));
    }

  } catch (e) {
    yield put(siteActions.setError(e as Error));
    yield put(toastActions.setToast('Update Failed', TOAST_SEVERITY.ERROR));
  } finally {
    yield put(siteActions.setLoading(false));
    yield put(toastActions.clearState());
  }
}


/**
 * Using API v2.
 */
export function* editPhotoTiePoints({ payload }: SiteAction) {
  try {
    if (!payload) return;
    yield put(processingPipelineActions.setEditTiePointsLoading(true));

    const currentSiteAssessmentId: string = yield select(siteSelectors.getCurrentSiteAssessmentId);
    const setTiePointResponse = yield call(setAssessmentPhotoTiePoint, currentSiteAssessmentId, payload.photoTiePoints);

    const currentSiteAssessmentPhotos = yield select(siteSelectors.getCurrentSiteAssessmentPhotos);
    const updatePhoto = currentSiteAssessmentPhotos.find((x) => x.id === payload.photoTiePoints?.ImageId);
    if (updatePhoto) updatePhoto.points = {
      ax: payload.photoTiePoints?.Ax,
      ay: payload.photoTiePoints?.Ay,
      bx: payload.photoTiePoints?.Bx,
      by: payload.photoTiePoints?.By,
      id: setTiePointResponse.id,
    };
  } catch (e) {
    yield put(siteActions.setError(e as Error));
  } finally {
    yield put(processingPipelineActions.setEditTiePointsLoading(false));
    yield put(processingPipelineActions.setImageLoading(false));
  }
}

/**
 * Using API v2.
 */
export function* startAzureImport({ payload }: any) {
  try {
    if (!payload) return;

    const currentSiteAssessmentId: string = yield select(siteSelectors.getCurrentSiteAssessmentId);
    const postUserResponse = yield call(triggerAzureImport, currentSiteAssessmentId, payload.url);

    if (postUserResponse) {
      yield put(toastActions.setToast(postUserResponse.message ? postUserResponse.message : 'Import started!'));
    } else {
      yield put(toastActions.setToast('Import failed', TOAST_SEVERITY.ERROR, 10000 ));
    }
  } catch (e) {
    yield put(toastActions.setToast('Import failed', TOAST_SEVERITY.ERROR, 10000 ));
    yield put(siteActions.setError(e as Error));
  }
}

/**
 * Using API v2.
 */
export function* startAzureExport({ payload }: any) {
  try {
    if (!payload) return;

    const currentSiteAssessmentId: string = yield select(siteSelectors.getCurrentSiteAssessmentId);
    const postUserResponse = yield call(triggerAzureExport, currentSiteAssessmentId, payload.url);

    if (postUserResponse) {
      yield put(toastActions.setToast(postUserResponse.message ? postUserResponse.message : 'Export started!'));
    } else {
      yield put(toastActions.setToast('Export failed', TOAST_SEVERITY.ERROR, 10000 ));
    }
  } catch (e) {
    yield put(toastActions.setToast('Export failed', TOAST_SEVERITY.ERROR, 10000 ));
    yield put(siteActions.setError(e as Error));
  }
}
export function* forceCompleteUpload() {
  try {
    const currentSiteAssessmentId: string = yield select(siteSelectors.getCurrentSiteAssessmentId);
    const response = yield call(completeUpload, currentSiteAssessmentId);

    if (response.upload) {
      yield put(toastActions.setToast('Upload completed!'));
    } else {
      yield put(toastActions.setToast('Couldn\'t complete the upload', TOAST_SEVERITY.ERROR, 10000 ));
    }
  } catch (e) {
    yield put(toastActions.setToast('Couldn\'t complete the upload', TOAST_SEVERITY.ERROR, 10000 ));
    yield put(siteActions.setError(e as Error));
  }
}

/**
 * Using API v2.
 */
export function* deletePhotoTiePoints({ payload }: SiteAction) {
  try {
    if (!payload || !payload.currentPhotoId || !payload.currentSiteAssessmentId) {
      return;
    }

    const siteImages: SiteImage[] = yield select(siteSelectors.getCurrentSiteAssessmentPhotos);
    const { currentSiteAssessmentId, currentPhotoId } = payload;
    const currentSiteImage = siteImages.find((siteImage: SiteImage) => siteImage.id === currentPhotoId);

    if (!currentSiteImage || !currentSiteImage.points?.id) {
      console.error("Tried to remove tie point from image with invalid tie point");
      return;
    }

    yield put(processingPipelineActions.setDeleteTiePointsLoading(true));

    yield call(
      deleteAssessmentPhotoTiePoint,
      currentSiteAssessmentId,
      currentSiteImage.points.id,
    );

    const photosResponse: SiteImage[] = yield call(
      getAssessmentPhotos,
      currentSiteAssessmentId
    );

    yield put(siteActions.setCurrentSiteAssessmentPhotos(photosResponse));
  } catch (e) {
    yield put(siteActions.setError(e as Error));
  } finally {
    yield put(processingPipelineActions.setDeleteTiePointsLoading(false));
    yield put(processingPipelineActions.setImageLoading(false));
  }
}

export function* submitQueueForProcessing({ payload }: SiteAction) {
  try {
    if (!payload) return;

    const { processingData, assessmentId, siteId } = payload;

    yield put(siteActions.setQueueForProcessingLoading(true));

    const response: string = yield call(siteServices.submitQueueForProcessingData, processingData);

    if (!response) return;
    if (response === 'Message successfully added') {
      yield put(
        toastActions.setToast(
          `Site #${siteId} was successfully queued for processing!`,
          TOAST_SEVERITY.SUCCESS,
          { autoHideDuration: 10000 }
        )
      );
      yield put(siteActions.setQueueForProcessingSuccess(true));

      const data = {
        assessment:
        {
          'action': 'Wait for processing',
        },
      };
      const response = yield call(
        processingPipelineServices.editSiteAssessmentStaging,
        assessmentId!,
        data
      );
      if (!response) return;
      yield put(siteActions.setAssessmentStaging(response?.assessment));
    }
  } catch (e) {
    yield put(
      toastActions.setToast('Processing failed!', TOAST_SEVERITY.ERROR, { autoHideDuration: 10000 })
    );
    yield put(siteActions.setError(e as Error));
  } finally {
    yield put(siteActions.setQueueForProcessingLoading(false));
    yield put(toastActions.clearState());
    yield put(processingPipelineActions.setFilterBucketsAfterMovingAsset(false));
    yield put(siteActions.setQueueForProcessingSuccess(false));
  }
}

export function* deleteSiteAssessmentStaging({ payload }: SiteAction) {
  try {
    if (!payload || !payload.assessmentId) return;
    const { assessmentId } = payload;

    const response = yield call(
      siteServices.deleteSiteAssessmentStaging,
      assessmentId
    );
    if (response) {
      yield put(toastActions.setToast(`Assessment successfully deleted`));
    }
  } catch (e) {
    yield put(siteActions.setError(e as Error));
  } finally {
  }
}

export function* deleteSiteUser({ payload }: SiteAction) {
  try {
    if (!payload || !payload.site_user_id) return;
    const { site_user_id } = payload;

    const response: SuccessError = yield call(
      siteServices.deleteSiteUser,
      site_user_id
    );
    if (response) {
      yield put(toastActions.setToast(`Person successfully deleted`));
    }
  } catch (e) {
    yield put(siteActions.setError(e as Error));
  } finally {
  }
}