import { useMemo, useState } from 'react';

// ca
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  Typography,
  useTheme,
  MenuPopover, 
  Point
} from 'ca-react-component-lib';

// components
import TableRowSmall from './TableRowSmall';

// types
import { QC_RECORD_STATUS } from 'src/@types/site';
import { Props } from './types';

const QcRecordStatusPopover = ({ siteAssessment }: Props) => {
  const theme = useTheme();
  const [open, setOpen] = useState<HTMLElement | null>(null);

  const {
    last_qc_record_status,
    assessment_tag,
    data_type,
    date,
  } = siteAssessment;

  const color = useMemo(() => {
    const status = last_qc_record_status as QC_RECORD_STATUS;

    if (status === QC_RECORD_STATUS.PASSED) {
      return theme.palette.success.dark;
    }
    if (status === QC_RECORD_STATUS.FAILED) {
      return theme.palette.error.dark;
    }
    return theme.palette.grey[400];
  }, [last_qc_record_status, theme]);
  
  return (
    <>
      <Box
        sx={{
          border: (theme) => `2px solid ${!!open ? theme.palette.grey[600] : 'transparent'}`,
          borderRadius: '50%',
        }}
        onClick={(event) => setOpen(event.currentTarget)}
      >
        <Point
          sx={{
            backgroundColor: color,
            cursor: 'pointer',
            margin: '2px',
            height: '10px',
            width: '10px',
          }}
        />
      </Box>
      <MenuPopover
        open={!!open}
        anchorEl={open}
        onClose={() => setOpen(null)}
        sx={{
          px: 0.2,
          pt: 2,
          pb: 2,
          mt: 1.5,
          ml: 2.25,
          width: 'auto',
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Typography
          variant="h5"
          sx={{
            px: 3,
            mb: 1,
          }}
        >
          Assessment Summary
        </Typography>
        <TableContainer>
          <Table sx={{ minWidth: 300 }} size="small">
            <TableBody>
              <TableRowSmall label="Assessment tag" value={assessment_tag} />
              <TableRowSmall label="Data type" value={data_type} />
              <TableRowSmall
                label="Date"
                value={date ? new Date(date).toLocaleDateString('en-US') : '-'}
              />
              <TableRowSmall label="QC status" value={last_qc_record_status} />
            </TableBody>
          </Table>
        </TableContainer>
      </MenuPopover>
    </>
  );
};

export default QcRecordStatusPopover;
