// api
import api from './api';

export const fetchOtiqRevisions = async (sasId: string): Promise<any> => {
  try {
    const response = await api.fetchOtiqRevisions(sasId);
    return response;
  } catch (error) {
    throw error;
  }
};

export const insertOtiqRevision = async (payload: any): Promise<any> => {
  try {
    const { sasId } = payload;
    const response = await api.insertOtiqRevision(sasId);
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchFilePurposeOptions = async (): Promise<any> => {
  try {
    const response = await api.fetchFilePurpose();
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchFileFormatOptions = async (): Promise<any> => {
  try {
    const response = await api.fetchFileFormat();
    return response;
  } catch (error) {
    throw error;
  }
};

export const insertOtiqFiles = async (payload: any): Promise<any> => {
  try {
    const { revisionId } = payload;
    delete payload.revisionId; // ts-ignore-line
    const response = await api.insertOtiqFiles(payload, revisionId);
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteOtiqFile = async (fileId: string): Promise<any> => {
  try {
    const response = await api.deleteOtiqFile(fileId);
    return response;
  } catch (error) {
    throw error;
  }
};
