const apiUrl = process.env.REACT_APP_STAFF_V2_API_ENDPOINT;

const endpoints = {
  getOtiqRevisions: `${apiUrl}/api/assessment/`,
  getFilePurposeOptions: `${apiUrl}/api/otiq-file-purpose/`,
  getFileFormatOptions: `${apiUrl}/api/otiq-file-format/`,
  insertOtiqRevision: `${apiUrl}/api/otiq-revision/`,
  insertOtiqFiles: `${apiUrl}/api/otiq-revision-file/`,
  deleteOtiqFile: `${apiUrl}/api/otiq-file/`,
};

export default endpoints;
