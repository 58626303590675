import {
  CustomerSuccessAccessAction,
  CustomerSuccessAccessActionType,
  CustomerSuccessAccessReducer,
  CustomerSuccessAccessState,
  CustomerSuccessAccessPayload,
} from './types';

export const initialState: CustomerSuccessAccessState = {
  error: undefined,
  customer_success_access: undefined,
  limit: 15,
  cursor: undefined,
  order_by: 'user_full_name',
  order_by_direction: 'asc',
  search: '',
  search_field: 'user_full_name',
  company_id: undefined,
  user_id: undefined,

  is_post_customer_success_access_loading: false,
  is_post_customer_success_access_success: false,
  is_delete_customer_success_access_loading: false,
  is_delete_customer_success_access_success: false,

  customer_success_switch_error: undefined,
  is_customer_success_switch_loading: false,
  customer_success_company: undefined,
  customer_success_companies: undefined,
  is_customer_success_companies_loading: false,
  customer_success_companies_search: undefined,

  customer_success_access_user_options: undefined,
  is_customer_success_access_user_options_loading: false,
  customer_success_access_company_options: undefined,
  is_customer_success_access_company_options_loading: false,

  loading: false,
};

const setError: CustomerSuccessAccessReducer = (
  state: CustomerSuccessAccessState,
  payload?: CustomerSuccessAccessPayload
) => ({
  ...state,
  error: payload ? payload.error : undefined,
});

const setLoading: CustomerSuccessAccessReducer = (
  state: CustomerSuccessAccessState,
  payload?: CustomerSuccessAccessPayload
) => ({
  ...state,
  loading: !!payload?.loading,
});

const setLimit: CustomerSuccessAccessReducer = (
  state: CustomerSuccessAccessState,
  payload?: CustomerSuccessAccessPayload
) => ({
  ...state,
  limit: (payload && payload.limit) ?? state.limit,
});

const setCursor: CustomerSuccessAccessReducer = (
  state: CustomerSuccessAccessState,
  payload?: CustomerSuccessAccessPayload
) => ({
  ...state,
  cursor: (payload && payload.cursor) ?? state.cursor,
});

const setOrderBy: CustomerSuccessAccessReducer = (
  state: CustomerSuccessAccessState,
  payload?: CustomerSuccessAccessPayload
) => ({
  ...state,
  order_by: (payload && payload.order_by) ?? state.order_by,
});

const setOrderByDirection: CustomerSuccessAccessReducer = (
  state: CustomerSuccessAccessState,
  payload?: CustomerSuccessAccessPayload
) => ({
  ...state,
  order_by_direction: (payload && payload.order_by_direction) ?? state.order_by_direction,
});

const setSearchField: CustomerSuccessAccessReducer = (
  state: CustomerSuccessAccessState,
  payload?: CustomerSuccessAccessPayload
) => ({
  ...state,
  search_field: (payload && payload.search_field) ?? state.search_field,
});

const setSearch: CustomerSuccessAccessReducer = (
  state: CustomerSuccessAccessState,
  payload?: CustomerSuccessAccessPayload
) => ({
  ...state,
  search: (payload && payload.search) ?? state.search,
});

const setAllCustomerSuccessAccess: CustomerSuccessAccessReducer = (
  state: CustomerSuccessAccessState,
  payload?: CustomerSuccessAccessPayload
) => ({
  ...state,
  customer_success_access:
    (payload && payload.customer_success_access) ?? state.customer_success_access,
});

const setCompanyId: CustomerSuccessAccessReducer = (
  state: CustomerSuccessAccessState,
  payload?: CustomerSuccessAccessPayload
) => ({
  ...state,
  company_id: (payload && payload.company_id) ?? state.company_id,
});

const setUserId: CustomerSuccessAccessReducer = (
  state: CustomerSuccessAccessState,
  payload?: CustomerSuccessAccessPayload
) => ({
  ...state,
  user_id: (payload && payload.user_id) ?? state.user_id,
});

const setCustomerSuccessCompanies: CustomerSuccessAccessReducer = (
  state: CustomerSuccessAccessState,
  payload?: CustomerSuccessAccessPayload
) => ({
  ...state,
  customer_success_companies:
    (payload && payload.customer_success_companies) ?? state.customer_success_companies,
});

const setCustomerSuccessCompaniesSearch: CustomerSuccessAccessReducer = (
  state: CustomerSuccessAccessState,
  payload?: CustomerSuccessAccessPayload
) => ({
  ...state,
  customer_success_companies_search:
    (payload && payload.customer_success_companies_search) ?? state.customer_success_companies_search,
});

const setCustomerSuccessCompaniesLoading: CustomerSuccessAccessReducer = (
  state: CustomerSuccessAccessState,
  payload?: CustomerSuccessAccessPayload
) => ({
  ...state,
  is_customer_success_companies_loading: !!payload?.is_customer_success_companies_loading,
});


const setPostCustomerSuccessAccessLoading: CustomerSuccessAccessReducer = (
  state: CustomerSuccessAccessState,
  payload?: CustomerSuccessAccessPayload
) => ({
  ...state,
  is_post_customer_success_access_loading: !!payload?.is_post_customer_success_access_loading,
});

const setPostCustomerSuccessAccessSuccess: CustomerSuccessAccessReducer = (
  state: CustomerSuccessAccessState,
  payload?: CustomerSuccessAccessPayload
) => ({
  ...state,
  is_post_customer_success_access_success: !!payload?.is_post_customer_success_access_success,
});

const setDeleteCustomerSuccessAccessLoading: CustomerSuccessAccessReducer = (
  state: CustomerSuccessAccessState,
  payload?: CustomerSuccessAccessPayload
) => ({
  ...state,
  is_delete_customer_success_access_loading: !!payload?.is_delete_customer_success_access_loading,
});

const setDeleteCustomerSuccessAccessSuccess: CustomerSuccessAccessReducer = (
  state: CustomerSuccessAccessState,
  payload?: CustomerSuccessAccessPayload
) => ({
  ...state,
  is_delete_customer_success_access_success: !!payload?.is_delete_customer_success_access_success,
});

const setCustomerSuccessSwitchError: CustomerSuccessAccessReducer = (
  state: CustomerSuccessAccessState,
  payload?: CustomerSuccessAccessPayload
) => ({
  ...state,
  customer_success_switch_error: payload ? payload.customer_success_switch_error : undefined,
});

const setCustomerSuccessSwitchLoading: CustomerSuccessAccessReducer = (
  state: CustomerSuccessAccessState,
  payload?: CustomerSuccessAccessPayload
) => ({
  ...state,
  is_customer_success_switch_loading: !!payload?.is_customer_success_switch_loading,
});

const setCustomerSuccessCompany: CustomerSuccessAccessReducer = (
  state: CustomerSuccessAccessState,
  payload?: CustomerSuccessAccessPayload
) => ({
  ...state,
  customer_success_company:
    (payload && payload.customer_success_company) ?? state.customer_success_company,
});

const clearAllCustomerSuccessAccess: CustomerSuccessAccessReducer = (
  state: CustomerSuccessAccessState
) => ({
  ...state,
  customer_success_access: undefined,
});

const clearManageAccessTableCompanyId: CustomerSuccessAccessReducer = (
  state: CustomerSuccessAccessState
) => ({
  ...state,
  company_id: undefined,
});

const clearManageAccessTableUserId: CustomerSuccessAccessReducer = (
  state: CustomerSuccessAccessState
) => ({
  ...state,
  user_id: undefined,
});

const setCustomerSuccessAccessCompanyOptions: CustomerSuccessAccessReducer = (
  state: CustomerSuccessAccessState,
  payload?: CustomerSuccessAccessPayload
) => ({
  ...state,
  customer_success_access_company_options:
    (payload && payload.customer_success_access_company_options) ??
    state.customer_success_access_company_options,
});

const setCustomerSuccessAccessCompanyOptionsLoading: CustomerSuccessAccessReducer = (
  state: CustomerSuccessAccessState,
  payload?: CustomerSuccessAccessPayload
) => ({
  ...state,
  is_customer_success_access_company_options_loading:
    !!payload?.is_customer_success_access_company_options_loading,
});

const setCustomerSuccessAccessCompanyOptionsSearch: CustomerSuccessAccessReducer = (
  state: CustomerSuccessAccessState,
  payload?: CustomerSuccessAccessPayload
) => ({
  ...state,
  customer_success_access_company_options_search:
    (payload && payload.customer_success_access_company_options_search) ??
    state.customer_success_access_company_options_search,
});

const setCustomerSuccessAccessUserOptions: CustomerSuccessAccessReducer = (
  state: CustomerSuccessAccessState,
  payload?: CustomerSuccessAccessPayload
) => ({
  ...state,
  customer_success_access_user_options:
    (payload && payload.customer_success_access_user_options) ??
    state.customer_success_access_user_options,
});

const setCustomerSuccessAccessUserOptionsLoading: CustomerSuccessAccessReducer = (
  state: CustomerSuccessAccessState,
  payload?: CustomerSuccessAccessPayload
) => ({
  ...state,
  is_customer_success_access_user_options_loading:
    !!payload?.is_customer_success_access_user_options_loading,
});

const setCustomerSuccessAccessUserOptionsSearch: CustomerSuccessAccessReducer = (
  state: CustomerSuccessAccessState,
  payload?: CustomerSuccessAccessPayload
) => ({
  ...state,
  customer_success_access_user_options_search:
    (payload && payload.customer_success_access_user_options_search) ??
    state.customer_success_access_user_options_search,
});

const reducerMap = new Map<CustomerSuccessAccessActionType, CustomerSuccessAccessReducer>([
  [CustomerSuccessAccessActionType.SET_ERROR, setError],
  [CustomerSuccessAccessActionType.SET_LOADING, setLoading],
  [CustomerSuccessAccessActionType.SET_LIMIT, setLimit],
  [CustomerSuccessAccessActionType.SET_CURSOR, setCursor],
  [CustomerSuccessAccessActionType.SET_ORDERBY, setOrderBy],
  [CustomerSuccessAccessActionType.SET_ORDERBY_DIRECTION, setOrderByDirection],
  [CustomerSuccessAccessActionType.SET_SEARCH_FIELD, setSearchField],
  [CustomerSuccessAccessActionType.SET_SEARCH, setSearch],
  [CustomerSuccessAccessActionType.SET_ALL_CUSTOMER_SUCCESS_ACCESS, setAllCustomerSuccessAccess],
  [CustomerSuccessAccessActionType.SET_COMPANY_ID, setCompanyId],
  [CustomerSuccessAccessActionType.SET_USER_ID, setUserId],
  [CustomerSuccessAccessActionType.SET_CUSTOMER_SUCCESS_COMPANIES, setCustomerSuccessCompanies],
  [
    CustomerSuccessAccessActionType.SET_CUSTOMER_SUCCESS_COMPANIES_SEARCH,
    setCustomerSuccessCompaniesSearch,
  ],
  [
    CustomerSuccessAccessActionType.SET_CUSTOMER_SUCCESS_COMPANIES_LOADING,
    setCustomerSuccessCompaniesLoading,
  ],
  [
    CustomerSuccessAccessActionType.SET_POST_CUSTOMER_SUCCESS_ACCESS_LOADING,
    setPostCustomerSuccessAccessLoading,
  ],
  [
    CustomerSuccessAccessActionType.SET_POST_CUSTOMER_SUCCESS_ACCESS_SUCCESS,
    setPostCustomerSuccessAccessSuccess,
  ],
  [
    CustomerSuccessAccessActionType.SET_DELETE_CUSTOMER_SUCCESS_ACCESS_LOADING,
    setDeleteCustomerSuccessAccessLoading,
  ],
  [
    CustomerSuccessAccessActionType.SET_DELETE_CUSTOMER_SUCCESS_ACCESS_SUCCESS,
    setDeleteCustomerSuccessAccessSuccess,
  ],

  [
    CustomerSuccessAccessActionType.SET_CUSTOMER_SUCCESS_SWITCH_ERROR,
    setCustomerSuccessSwitchError,
  ],
  [
    CustomerSuccessAccessActionType.SET_CUSTOMER_SUCCESS_SWITCH_LOADING,
    setCustomerSuccessSwitchLoading,
  ],
  [CustomerSuccessAccessActionType.SET_CUSTOMER_SUCCESS_COMPANY, setCustomerSuccessCompany],

  [
    CustomerSuccessAccessActionType.CLEAR_ALL_CUSTOMER_SUCCESS_ACCESS,
    clearAllCustomerSuccessAccess,
  ],
  [
    CustomerSuccessAccessActionType.CLEAR_MANAGE_ACCESS_TABLE_COMPANY_ID,
    clearManageAccessTableCompanyId,
  ],
  [CustomerSuccessAccessActionType.CLEAR_MANAGE_ACCESS_TABLE_USER_ID, clearManageAccessTableUserId],

  [
    CustomerSuccessAccessActionType.SET_CUSTOMER_SUCCESS_ACCESS_COMPANY_OPTIONS,
    setCustomerSuccessAccessCompanyOptions,
  ],
  [
    CustomerSuccessAccessActionType.SET_CUSTOMER_SUCCESS_ACCESS_COMPANY_OPTIONS_LOADING,
    setCustomerSuccessAccessCompanyOptionsLoading,
  ],
  [
    CustomerSuccessAccessActionType.SET_CUSTOMER_SUCCESS_ACCESS_USER_OPTIONS,
    setCustomerSuccessAccessUserOptions,
  ],
  [
    CustomerSuccessAccessActionType.SET_CUSTOMER_SUCCESS_ACCESS_USER_OPTIONS_LOADING,
    setCustomerSuccessAccessUserOptionsLoading,
  ],
  [
    CustomerSuccessAccessActionType.SET_CUSTOMER_SUCCESS_ACCESS_COMPANY_OPTIONS_SEARCH,
    setCustomerSuccessAccessCompanyOptionsSearch,
  ],
  [
    CustomerSuccessAccessActionType.SET_CUSTOMER_SUCCESS_ACCESS_USER_OPTIONS_SEARCH,
    setCustomerSuccessAccessUserOptionsSearch,
  ],
]);

export const reducer = (
  state: CustomerSuccessAccessState = initialState,
  action: CustomerSuccessAccessAction
): CustomerSuccessAccessState => {
  const reducer = reducerMap.get(action.type);
  if (reducer) return reducer(state, action.payload);
  return state;
};

export default reducer;
