
export type Announcement = {
  id?: string;
  title?: string;
  excerpt?: string;
  content?: string;
  date_created?: Date;
  content_string?: string;
};

export type AnnouncementContent = {
  announcement?: Announcement;
};

export type AnnouncementPagination = {
  next_page_cursor?: string;
  previous_page_cursor?: string;
  total_count?: number;
  page_size?: number;
  current_page?: number;
  last_page?: number;
  announcements?: Announcement[];
};


export type AnnouncementBody = { 
  title?: string;
  excerpt?: string;
  content?: string;
};

export type AnnouncementEditorObj = {
  announcement?: AnnouncementBody;
};

export enum EditorType {
  CREATE = 'CREATE',
  EDIT = 'EDIT'
}

export enum ANNOUNCEMENT_COLUMNS {
  TITLE = 'title',
  DATE_CREATED = 'date_created',
  ACTIONS = 'Actions',
}