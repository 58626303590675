import { ApplicationState } from '../types';

const _getData = (state: ApplicationState) => state.Announcement;

const getAnnouncement = (state: ApplicationState) => _getData(state).announcement_pagination;

const getAnnouncementContent = (state: ApplicationState) => _getData(state).announcement_content;

const getLimit = (state: ApplicationState) => _getData(state).limit;

const getCursor = (state: ApplicationState) => _getData(state).cursor;

const getOrderBy = (state: ApplicationState) => _getData(state).order_by;

const getOrderByDirection = (state: ApplicationState) => _getData(state).order_by_direction;

const getSearch = (state: ApplicationState) => _getData(state).search;

const getSearchField = (state: ApplicationState) => _getData(state).search_field;

const getLoading = (state: ApplicationState) => _getData(state).is_loading;

const getAnnouncementEditorObj = (state: ApplicationState) => _getData(state).announcement_editor_obj;

const getIsEditorUploading = (state: ApplicationState) => _getData(state).is_editor_uploading;

const getFinishEditorUploading = (state: ApplicationState) => _getData(state).finish_editor_uploading;

const selectors = {
  getAnnouncement,
  getAnnouncementContent,
  getLimit,
  getCursor,
  getOrderBy,
  getOrderByDirection,
  getSearch,
  getSearchField,
  getLoading,
  getAnnouncementEditorObj,
  getIsEditorUploading,
  getFinishEditorUploading
};

export default selectors;
