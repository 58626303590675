import { CustomerSuccessAccessActionType, CustomerSuccessAccessAction } from './types';

const actions = {
  fetchAllCustomerSuccessAccess: (): CustomerSuccessAccessAction => ({
    type: CustomerSuccessAccessActionType.FETCH_ALL_CUSTOMER_SUCCESS_ACCESS,
  }),
  setAllCustomerSuccessAccess: (customer_success_access: any): CustomerSuccessAccessAction => ({
    payload: { customer_success_access },
    type: CustomerSuccessAccessActionType.SET_ALL_CUSTOMER_SUCCESS_ACCESS,
  }),
  setLoading: (loading: boolean): CustomerSuccessAccessAction => ({
    payload: { loading },
    type: CustomerSuccessAccessActionType.SET_LOADING,
  }),
  setError: (error: Error): CustomerSuccessAccessAction => ({
    payload: { error },
    type: CustomerSuccessAccessActionType.SET_ERROR,
  }),
  setLimit: (limit: number): CustomerSuccessAccessAction => ({
    payload: { limit },
    type: CustomerSuccessAccessActionType.SET_LIMIT,
  }),
  setCursor: (cursor: string): CustomerSuccessAccessAction => ({
    payload: { cursor },
    type: CustomerSuccessAccessActionType.SET_CURSOR,
  }),
  setOrderBy: (order_by: 'user_full_name' | 'company_name'): CustomerSuccessAccessAction => ({
    payload: { order_by },
    type: CustomerSuccessAccessActionType.SET_ORDERBY,
  }),
  setOrderByDirection: (order_by_direction: 'asc' | 'desc'): CustomerSuccessAccessAction => ({
    payload: { order_by_direction },
    type: CustomerSuccessAccessActionType.SET_ORDERBY_DIRECTION,
  }),
  setSearch: (search: string): CustomerSuccessAccessAction => ({
    payload: { search },
    type: CustomerSuccessAccessActionType.SET_SEARCH,
  }),
  setSearchField: (
    search_field: 'user_full_name' | 'user_email' | 'company_name'
  ): CustomerSuccessAccessAction => ({
    payload: { search_field },
    type: CustomerSuccessAccessActionType.SET_SEARCH_FIELD,
  }),
  setCompanyId: (company_id: string): CustomerSuccessAccessAction => ({
    payload: { company_id },
    type: CustomerSuccessAccessActionType.SET_COMPANY_ID,
  }),
  setUserId: (user_id: string): CustomerSuccessAccessAction => ({
    payload: { user_id },
    type: CustomerSuccessAccessActionType.SET_USER_ID,
  }),

  postCustomerSuccessAccess: (customer_success_access: any): CustomerSuccessAccessAction => ({
    payload: { customer_success_access },
    type: CustomerSuccessAccessActionType.POST_CUSTOMER_SUCCESS_ACCESS,
  }),
  setPostCustomerSuccessAccessLoading: (
    is_post_customer_success_access_loading: boolean
  ): CustomerSuccessAccessAction => ({
    payload: { is_post_customer_success_access_loading },
    type: CustomerSuccessAccessActionType.SET_POST_CUSTOMER_SUCCESS_ACCESS_LOADING,
  }),
  setPostCustomerSuccessAccessSuccess: (
    is_post_customer_success_access_success: boolean
  ): CustomerSuccessAccessAction => ({
    payload: { is_post_customer_success_access_success },
    type: CustomerSuccessAccessActionType.SET_POST_CUSTOMER_SUCCESS_ACCESS_SUCCESS,
  }),

  deleteCustomerSuccessAccess: (customer_success_access: any): CustomerSuccessAccessAction => ({
    payload: { customer_success_access },
    type: CustomerSuccessAccessActionType.DELETE_CUSTOMER_SUCCESS_ACCESS,
  }),
  setDeleteCustomerSuccessAccessLoading: (
    is_delete_customer_success_access_loading: boolean
  ): CustomerSuccessAccessAction => ({
    payload: { is_delete_customer_success_access_loading },
    type: CustomerSuccessAccessActionType.SET_DELETE_CUSTOMER_SUCCESS_ACCESS_LOADING,
  }),
  setDeleteCustomerSuccessAccessSuccess: (
    is_delete_customer_success_access_success: boolean
  ): CustomerSuccessAccessAction => ({
    payload: { is_delete_customer_success_access_success },
    type: CustomerSuccessAccessActionType.SET_DELETE_CUSTOMER_SUCCESS_ACCESS_SUCCESS,
  }),

  fetchCustomerSuccessCompanies: (search?: string): CustomerSuccessAccessAction => ({
    payload: { search },
    type: CustomerSuccessAccessActionType.FETCH_CUSTOMER_SUCCESS_COMPANIES,
  }),

  setCustomerSuccessCompaniesLoading: (
    is_customer_success_companies_loading: boolean
  ): CustomerSuccessAccessAction => ({
    payload: { is_customer_success_companies_loading },
    type: CustomerSuccessAccessActionType.SET_CUSTOMER_SUCCESS_COMPANIES_LOADING,
  }),

  setCustomerSuccessCompaniesSearch: (
    customer_success_companies_search: string
  ): CustomerSuccessAccessAction => ({
    payload: { customer_success_companies_search },
    type: CustomerSuccessAccessActionType.SET_CUSTOMER_SUCCESS_COMPANIES_SEARCH,
  }),

  setCustomerSuccessCompanies: (customer_success_companies): CustomerSuccessAccessAction => ({
    payload: { customer_success_companies },
    type: CustomerSuccessAccessActionType.SET_CUSTOMER_SUCCESS_COMPANIES,
  }),

  setCustomerSuccessCompany: (company_id: string): CustomerSuccessAccessAction => ({
    payload: { company_id },
    type: CustomerSuccessAccessActionType.SET_CUSTOMER_SUCCESS_COMPANY,
  }),

  setCustomerSuccessSwitchLoading: (
    is_customer_success_switch_loading: boolean
  ): CustomerSuccessAccessAction => ({
    payload: { is_customer_success_switch_loading },
    type: CustomerSuccessAccessActionType.SET_CUSTOMER_SUCCESS_SWITCH_LOADING,
  }),

  setCustomerSuccessSwitchError: (
    customer_success_switch_error: Error
  ): CustomerSuccessAccessAction => ({
    payload: { customer_success_switch_error },
    type: CustomerSuccessAccessActionType.SET_CUSTOMER_SUCCESS_SWITCH_ERROR,
  }),

  clearManageAccessTableCompanyId: (): CustomerSuccessAccessAction => ({
    type: CustomerSuccessAccessActionType.CLEAR_MANAGE_ACCESS_TABLE_COMPANY_ID,
  }),
  clearAllCustomerSuccessAccess: (): CustomerSuccessAccessAction => ({
    type: CustomerSuccessAccessActionType.CLEAR_ALL_CUSTOMER_SUCCESS_ACCESS,
  }),
  clearManageAccessTableUserId: (): CustomerSuccessAccessAction => ({
    type: CustomerSuccessAccessActionType.CLEAR_MANAGE_ACCESS_TABLE_USER_ID,
  }),

  fetchCustomerSuccessAccessUserOptions: (
    search?: string,
    company_id?: string
  ): CustomerSuccessAccessAction => ({
    payload: { search, company_id },
    type: CustomerSuccessAccessActionType.FETCH_CUSTOMER_SUCCESS_ACCESS_USER_OPTIONS,
  }),
  setCustomerSuccessAccessUserOptions: (
    customer_success_access_user_options: any
  ): CustomerSuccessAccessAction => ({
    payload: { customer_success_access_user_options },
    type: CustomerSuccessAccessActionType.SET_CUSTOMER_SUCCESS_ACCESS_USER_OPTIONS,
  }),
  setCustomerSuccessAccessUserOptionsLoading: (
    is_customer_success_access_user_options_loading: boolean
  ): CustomerSuccessAccessAction => ({
    payload: { is_customer_success_access_user_options_loading },
    type: CustomerSuccessAccessActionType.SET_CUSTOMER_SUCCESS_ACCESS_USER_OPTIONS_LOADING,
  }),
  setCustomerSuccessAccessUserOptionsSearch: (
    customer_success_access_user_options_search: string
  ): CustomerSuccessAccessAction => ({
    payload: { customer_success_access_user_options_search },
    type: CustomerSuccessAccessActionType.SET_CUSTOMER_SUCCESS_ACCESS_USER_OPTIONS_SEARCH,
  }),

  fetchCustomerSuccessAccessCompanyOptions: (
    search?: string,
    user_id?: string
  ): CustomerSuccessAccessAction => ({
    payload: { search, user_id },
    type: CustomerSuccessAccessActionType.FETCH_CUSTOMER_SUCCESS_ACCESS_COMPANY_OPTIONS,
  }),

  setCustomerSuccessAccessCompanyOptions: (
    customer_success_access_company_options: any
  ): CustomerSuccessAccessAction => ({
    payload: { customer_success_access_company_options },
    type: CustomerSuccessAccessActionType.SET_CUSTOMER_SUCCESS_ACCESS_COMPANY_OPTIONS,
  }),

  setCustomerSuccessAccessCompanyOptionsLoading: (
    is_customer_success_access_company_options_loading: boolean
  ): CustomerSuccessAccessAction => ({
    payload: { is_customer_success_access_company_options_loading },
    type: CustomerSuccessAccessActionType.SET_CUSTOMER_SUCCESS_ACCESS_COMPANY_OPTIONS_LOADING,
  }),
  setCustomerSuccessAccessCompanyOptionsSearch: (
    customer_success_access_company_options_search: string
  ): CustomerSuccessAccessAction => ({
    payload: { customer_success_access_company_options_search },
    type: CustomerSuccessAccessActionType.SET_CUSTOMER_SUCCESS_ACCESS_COMPANY_OPTIONS_SEARCH,
  }),
};

export default actions;
