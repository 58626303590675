import { SelectedTiePointLocation } from 'src/@types/processingPipeline';
import { ProcessingPipelineAction, ProcessingPipelineActionType } from './types';
import { PhotosLoadStrategy } from 'ca-react-component-lib';

// Collection of actions for the processing pipeline
const actions = {
  fetchAllInfo: (tenantId?: string): ProcessingPipelineAction => ({
    payload: { tenantId },
    type: ProcessingPipelineActionType.FETCH_ALL_INFO,
  }),

  setTenantId: (tenantId: string): any => ({
    payload: { tenantId },
    type: ProcessingPipelineActionType.SET_TENANT_ID
  }),

  // Action to fetch items waiting for Images
  fetchWaitingForImages: (): ProcessingPipelineAction => ({
    type: ProcessingPipelineActionType.FETCH_WAITING_FOR_IMAGES,
  }),

  // Action to set the items waiting for Images
  setWaitingForImages: (waiting_for_images: any): ProcessingPipelineAction => ({
    payload: { waiting_for_images },
    type: ProcessingPipelineActionType.SET_WAITING_FOR_IMAGES,
  }),

  // Action to set the loading state for waiting for Images
  setWaitingForImagesLoading: (waiting_for_images_loading: boolean): ProcessingPipelineAction => ({
    payload: { waiting_for_images_loading },
    type: ProcessingPipelineActionType.SET_WAITING_FOR_IMAGES_LOADING,
  }),

  // Action to fetch items in queue for processing
  fetchQueueForProcessing: (): ProcessingPipelineAction => ({
    type: ProcessingPipelineActionType.FETCH_QUEUE_FOR_PROCESSING,
  }),

  // Action to set the items in queue for processing
  setQueueForProcessing: (queue_for_processing: any): ProcessingPipelineAction => ({
    payload: { queue_for_processing },
    type: ProcessingPipelineActionType.SET_QUEUE_FOR_PROCESSING,
  }),

  // Action to set the loading state for queue for processing
  setQueueForProcessingLoading: (
    queue_for_processing_loading: boolean
  ): ProcessingPipelineAction => ({
    payload: { queue_for_processing_loading },
    type: ProcessingPipelineActionType.SET_QUEUE_FOR_PROCESSING_LOADING,
  }),

  // Action to fetch items waiting for processing
  fetchWaitForProcessing: (): ProcessingPipelineAction => ({
    type: ProcessingPipelineActionType.FETCH_WAIT_FOR_PROCESSING,
  }),

  // Action to set the items waiting for processing
  setWaitForProcessing: (wait_for_processing: any): ProcessingPipelineAction => ({
    payload: { wait_for_processing },
    type: ProcessingPipelineActionType.SET_WAIT_FOR_PROCESSING,
  }),

  // Action to set the loading state for waiting for processing
  setWaitForProcessingLoading: (
    wait_for_processing_loading: boolean
  ): ProcessingPipelineAction => ({
    payload: { wait_for_processing_loading },
    type: ProcessingPipelineActionType.SET_WAIT_FOR_PROCESSING_LOADING,
  }),

  // Action to fetch items for quality control (QC)
  fetchPerformQC: (): ProcessingPipelineAction => ({
    type: ProcessingPipelineActionType.FETCH_PERFORM_QC,
  }),

  // Action to set the items for quality control (QC)
  setPerformQC: (perform_qc: any): ProcessingPipelineAction => ({
    payload: { perform_qc },
    type: ProcessingPipelineActionType.SET_PERFORM_QC,
  }),

  // Action to set the loading state for quality control (QC)
  setPerformQCLoading: (perform_qc_loading: boolean): ProcessingPipelineAction => ({
    payload: { perform_qc_loading },
    type: ProcessingPipelineActionType.SET_PERFORM_QC_LOADING,
  }),

  // Action to fetch completed items
  fetchCompleted: (): ProcessingPipelineAction => ({
    type: ProcessingPipelineActionType.FETCH_COMPLETED,
  }),

  // Action to set the completed items
  setCompleted: (completed: any): ProcessingPipelineAction => ({
    payload: { completed },
    type: ProcessingPipelineActionType.SET_COMPLETED,
  }),

  // Action to set the loading state for completed items
  setCompletedLoading: (completed_loading: boolean): ProcessingPipelineAction => ({
    payload: { completed_loading },
    type: ProcessingPipelineActionType.SET_COMPLETED_LOADING,
  }),

  // Action to fetch items requesting refly
  fetchRequestRefly: (): ProcessingPipelineAction => ({
    type: ProcessingPipelineActionType.FETCH_REQUEST_REFLY,
  }),

  // Action to set the items requesting refly
  setRequestRefly: (request_refly: any): ProcessingPipelineAction => ({
    payload: { request_refly },
    type: ProcessingPipelineActionType.SET_REQUEST_REFLY,
  }),

  // Action to set the loading state for items requesting refly
  setRequestReflyLoading: (request_refly_loading: boolean): ProcessingPipelineAction => ({
    payload: { request_refly_loading },
    type: ProcessingPipelineActionType.SET_REQUEST_REFLY_LOADING,
  }),

  // Action to fetch items requesting measurements
  fetchRequestMeasurements: (): ProcessingPipelineAction => ({
    type: ProcessingPipelineActionType.FETCH_REQUEST_MEASUREMENTS,
  }),

  // Action to set the items requesting measurements
  setRequestMeasurements: (request_measurements: any): ProcessingPipelineAction => ({
    payload: { request_measurements },
    type: ProcessingPipelineActionType.SET_REQUEST_MEASUREMENTS,
  }),

  // Action to set the loading state for items requesting measurements
  setRequestMeasurementsLoading: (
    request_measurements_loading: boolean
  ): ProcessingPipelineAction => ({
    payload: { request_measurements_loading },
    type: ProcessingPipelineActionType.SET_REQUEST_MEASUREMENTS_LOADING,
  }),

  // Action to fetch items for re-processing
  fetchReProcess: (): ProcessingPipelineAction => ({
    type: ProcessingPipelineActionType.FETCH_RE_PROCESS,
  }),

  // Action to set the items for re-processing
  setReProcess: (re_process: any): ProcessingPipelineAction => ({
    payload: { re_process },
    type: ProcessingPipelineActionType.SET_RE_PROCESS,
  }),

  // Action to set the loading state for re-processing
  setReProcessLoading: (re_process_loading: boolean): ProcessingPipelineAction => ({
    payload: { re_process_loading },
    type: ProcessingPipelineActionType.SET_RE_PROCESS_LOADING,
  }),

  setEditTiePointsLoading: (is_loading: boolean): ProcessingPipelineAction => ({
    payload: { is_loading },
    type: ProcessingPipelineActionType.SET_EDIT_TIE_POINTS_LOADING,
  }),

  //tie points

  setDeleteTiePointsLoading: (is_loading: boolean): ProcessingPipelineAction => ({
    payload: { is_loading },
    type: ProcessingPipelineActionType.SET_DELETE_TIE_POINTS_LOADING,
  }),

  setSelectedTiePointLocations: (selectedTiePointLocations?: SelectedTiePointLocation[]) => ({
    payload: { selectedTiePointLocations },
    type: ProcessingPipelineActionType.SET_SELECTED_TIE_POINT_LOCATIONS
  }),

  setNextSelectedTiePointLocationPointer: (nextSelectedTiePointLocationPointer: number) => ({
    payload: { nextSelectedTiePointLocationPointer },
    type: ProcessingPipelineActionType.SET_NEXT_SELECTED_TIE_POINT_LOCATION_POINTER
  }),

  setIsTiePointPhotosShow: (isTiePointPhotosShow: boolean) => ({
    payload: { isTiePointPhotosShow },
    type: ProcessingPipelineActionType.SET_IS_TIE_POINT_PHOTOS_SHOW
  }),

  scrollToNextSelectedTiePointPhoto: () => ({
    type: ProcessingPipelineActionType.SCROLL_TO_NEXT_SELECTED_TIE_POINT_PHOTO
  }),

  setTiePointPhotoLoadingStrategy: (tiePointPhotoLoadingStrategy: PhotosLoadStrategy) => ({
    payload: { tiePointPhotoLoadingStrategy },
    type: ProcessingPipelineActionType.SET_TIE_POINT_PHOTO_LOADING_STRATEGY
  }),

  setTiePointPhotoTabIndex: (tiePointPhotoTabIndex: number) => ({
    payload: { tiePointPhotoTabIndex },
    type: ProcessingPipelineActionType.SET_TIE_POINT_PHOTO_TAB_INDEX
  }),

  setImageLoading: (isImageLoading: boolean): ProcessingPipelineAction => ({
    payload: { isImageLoading },
    type: ProcessingPipelineActionType.SET_IMAGE_LOADING,
  }),
  
  //assessment

  setAssessmentStaging: (data: any) => ({
    payload: { data },
    type: ProcessingPipelineActionType.SET_ASSESSMENT_STAGING,
  }),


  editSiteAssessmentStaging: (
    assessmentId: string,
    site: any,
    fromAction: string,
    toAction: string,
    data: any,
    speedDialAction?: string
  ): any => ({
    payload: { assessmentId, site, fromAction, toAction, data, speedDialAction },
    type: ProcessingPipelineActionType.EDIT_SITE_ASSESSMENT_STAGING,
  }),

  updateSiteAfterEditSiteAssessmentStaging: (
    siteAssessment: any,
    site: any,
  ): any => ({
    payload: { siteAssessment, site },
    type: ProcessingPipelineActionType.UPDATE_SITE_AFTER_EDIT_SITE_ASSESSMENT_STAGING,
  }),

  setEditSiteAssessmentStagingSuccess: (isEditSiteAssessmentStagingSuccess: boolean): ProcessingPipelineAction => ({
    payload: { isEditSiteAssessmentStagingSuccess },
    type: ProcessingPipelineActionType.SET_IS_EDIT_SITE_ASSESSMENT_STAGING_SUCCESS,
  }),
  

  setFilterBucketsAfterMovingAsset: (isFilterBucketsAfterMovingAsset: boolean): any => ({
    payload: { isFilterBucketsAfterMovingAsset },
    type: ProcessingPipelineActionType.SET_IS_FILTER_BUCKETS_AFTER_MOVING_ASSET
  }),

  updateBucketsAfterSubmitForProcessingSuccess: (): ProcessingPipelineAction => ({
    type: ProcessingPipelineActionType.UPDATE_BUCKETS_AFTER_SUBMIT_FOR_PROCESSING_SUCCESS,
  }),



  setSpeedDialAction: (speedDialAction?: string) => ({
    payload: { speedDialAction },
    type: ProcessingPipelineActionType.SET_SPEED_DIAL_ACTION,
  }),

  setActionViewOpen: (actionViewOpen: boolean) => ({
    payload: { actionViewOpen },
    type: ProcessingPipelineActionType.SET_ACTION_VIEW_OPEN,
  }),


  // Action to set an error state
  setError: (error: Error | undefined): ProcessingPipelineAction => ({
    payload: { error },
    type: ProcessingPipelineActionType.SET_ERROR,
  }),

  // Action to clear the processing pipeline state
  clearState: (): ProcessingPipelineAction => ({
    type: ProcessingPipelineActionType.CLEAR,
  }),
};

export default actions;
