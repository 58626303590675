import {
  ImportJobsState,
  ImportJobsAction,
  ImportJobsReducer,
  ImportJobsPayload,
  ImportJobsActionType,
} from './types';

export const initialState: ImportJobsState = {
  importJobs: [],
  importJobsLoading: false,
  importJobsError: undefined,
  column: "job_created_at",
  sortBy: "desc"
};

const setImportJobs: ImportJobsReducer = (state: ImportJobsState, payload?: ImportJobsPayload) => ({
  ...state,
  importJobs: (payload && payload.importJobs) ?? state.importJobs,
});

const setImportJobsLoading: ImportJobsReducer = (state: ImportJobsState, payload?: ImportJobsPayload) => ({
  ...state,
  importJobsLoading:
    payload && payload.importJobsLoading !== undefined
      ? payload.importJobsLoading
      : state.importJobsLoading,
});

const setImportJobsError: ImportJobsReducer = (state: ImportJobsState, payload?: ImportJobsPayload) => ({
  ...state,
  importJobsError: payload ? payload.importJobsError : undefined,
});

const setColumn: ImportJobsReducer = (state: ImportJobsState, payload?: ImportJobsPayload) => ({
  ...state,
  column: (payload && payload.column) ?? state.column,
});

const setSortBy: ImportJobsReducer = (state: ImportJobsState, payload?: ImportJobsPayload) => ({
  ...state,
  sortBy: (payload && payload.sortBy) ?? state.sortBy,
});

const reducerMap = new Map<ImportJobsActionType, ImportJobsReducer>([
  [ImportJobsActionType.SET_IMPORT_JOBS, setImportJobs],
  [ImportJobsActionType.SET_IMPORT_JOBS_LOADING, setImportJobsLoading],
  [ImportJobsActionType.SET_IMPORT_JOBS_ERROR, setImportJobsError],
  [ImportJobsActionType.SET_COLUMN, setColumn],
  [ImportJobsActionType.SET_SORTBY, setSortBy],
]);

const reducer = (state: ImportJobsState = initialState, action: ImportJobsAction): ImportJobsState => {
  const reducer = reducerMap.get(action.type);
  if (reducer) return reducer(state, action.payload);
  return state;
};

export default reducer;
