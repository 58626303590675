import { put, call, select, takeLatest } from 'redux-saga/effects';

// services
import * as notificationServices from 'src/servicesV2/notification';

// selectors
import selectors from './selectors';

// actions
import actions from './actions';
import ToastAction from 'src/store/Toast/actions';

// types
import { NotificationContent, NotificationPagination, NotificationTypesContent } from 'src/@types/notification';
import { NotificationAction, NotificationActionType } from './types';


export function* fetchAllNotifications() {
  const limit: number = yield select(selectors.getLimit);
  const cursor: string = yield select(selectors.getCursor);
  const search: string = yield select(selectors.getSearch);
  const searchField: string = yield select(selectors.getSearchField);
  const order_by: string = yield select(selectors.getOrderBy);
  const order_by_direction: string = yield select(selectors.getOrderByDirection);
  try {
    yield put(actions.setIsNotificationPageLoading(true));
    const response: NotificationPagination = yield call(
      notificationServices.fetchAllNotifications,
      limit,
      cursor,
      search,
      searchField,
      order_by_direction,
      order_by,
    );
    yield put(actions.setNotifications(response));
  } catch (e) {
    yield put(actions.setError(e as Error));
  } finally {
    yield put(actions.setIsNotificationPageLoading(false));
  }
}

export function* fetchNotificationTypes() {
  try {
    const response: NotificationTypesContent= yield call(notificationServices.fetchNotificationTypes);
    yield put(actions.setNotificationTypes(response));
  } catch (e) {
    yield put(actions.setError(e as Error));
  }
}

export function* postNotification({ payload }: NotificationAction) {
  if (!payload?.notification_editor_obj) return;

  try {
    const { notification_editor_obj } = payload;
    yield put(actions.setIsSubmitingLoading(true));
    const response: NotificationContent = yield call(notificationServices.postNotification, notification_editor_obj);
    if (response?.notification?.id) {
      yield put(actions.setNotificationCreateSuccess(true));
      yield put(ToastAction.setToast('Create Success!'));
    }

    yield put(actions.setIsCreateNotification(false));
    yield put(actions.setNotificationEditorObj({}));

  } catch (e) {
    yield put(actions.setError(e as Error));
  } finally {
    yield put(actions.setIsSubmitingLoading(false));
    yield put(ToastAction.clearState());
    yield put(actions.setNotificationCreateSuccess(false));
  }
}

export function* patchNotification({ payload }: NotificationAction) {

  if (!payload || !payload.notification_id || !payload.notification_editor_obj) {
    return;
  }

  try {
    yield put(actions.setIsSubmitingLoading(true));
    const { notification_editor_obj, notification_id } = payload;
    const response: NotificationContent = yield call(notificationServices.patchNotification, notification_id, notification_editor_obj);
    if (response?.notification?.id) {
      yield put(actions.setNotificationUpdateSuccess(true));
      yield put(ToastAction.setToast('Edit Success!'));
    }

    yield put(actions.setIsUpdateNotification(false));
    yield put(actions.setNotificationEditorObj({}));

  } catch (e) {
    yield put(actions.setError(e as Error));
  } finally {
    yield put(actions.setIsSubmitingLoading(false));
    yield put(ToastAction.clearState());
    yield put(actions.setNotificationUpdateSuccess(false));
  }
}

const sagas = [
  takeLatest(NotificationActionType.FETCH_ALL_NOTIFICATIONS, fetchAllNotifications),
  takeLatest(NotificationActionType.FETCH_NOTIFICATION_TYPES, fetchNotificationTypes),
  takeLatest(NotificationActionType.CREATE_NOTIFICATION, postNotification),
  takeLatest(NotificationActionType.UPDATE_NOTIFICATION, patchNotification),
];

export default sagas;
