export const TABLE_HEAD_QUEUED = [
  { id: 0, label: '' },
  { id: 1, label: 'Position' },
  { id: 2, label: 'Site Id' },
  { id: 3, label: 'Type' },
  { id: 4, label: 'At Preset' },
  { id: 5, label: 'Photo Downsampling' },
  { id: 6, label: 'Tie Points' },
  { id: 7, label: 'S3 - Input' },
  { id: 8, label: 'S3 - Output' },
];

export const DEFAULT_QUEUE_ID = 'default';