import { useMemo } from 'react';
import { Outlet, useLocation, useNavigate, matchPath } from 'react-router-dom';

// ca
import { styled, Box, HEADER, ThemeProvider, Radar } from 'ca-react-component-lib';

// config
import appHeaderMenu from './config';

import { useProfile } from 'src/hooks';

// routes
import { MAIN_PATHS } from 'src/routes/paths';

// header
import SearchPopover from './Header/SearchPopover';
import ProfilePopover from './Header/ProfilePopover';

const { AppHeader, RadarThemeProvider } = Radar;

type MainStyleProps = {
  collapseClick: boolean;
};

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})<MainStyleProps>(({ theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT,
  height: "100%",
}));

export default function DashboardLayout() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { role } = useProfile();
  
  const appSidebarConfiguration = useMemo(() => {
    const getActive = (path: string, pathname: string) =>
      path ? !!matchPath({ path: path, end: false }, pathname) : false;

    return appHeaderMenu
      .filter((group) => {
        if (!group.roles) return true;
        return group.roles.some((item) => item === role);
      })
      .map((group) => ({
        ...group,
        menu: (group.menu as any[])
          .filter((menu) => {
            if (!menu.roles) return true;
            return menu.roles.some((item) => item === role);
          })
          .map((item) => ({
            ...item,
            active: getActive(item.path, pathname),
          })),
      }));
  }, [pathname, role]);
  
  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        height: '100%',
        minHeight: { lg: 1 },
        backgroundColor: (theme) => theme.palette.background.default,
      }}
    >
      <RadarThemeProvider>
        <AppHeader
          title={"Admin"}
          onLogoClick={() => navigate(MAIN_PATHS.root)}
          onClick={(path) => navigate(path)}
          appHeaderMenu={appSidebarConfiguration}
          rightComponents={
            <ThemeProvider>
              <Box sx={{ mr: 3 }}>
                <SearchPopover />
              </Box>
              <ProfilePopover />
            </ThemeProvider>
          }
        />
      </RadarThemeProvider>
      <MainStyle>
        <Outlet />
      </MainStyle>
    </Box>
  );
}
