import { TenantAction, TenantActionType } from './types';
import { TenantWithCompanyContent, TenantOptionsContent, TenantPagination, TenantCreateRequestObj, TenantUpdateRequestObj } from 'src/@types/tenant';

const actions = {
  fetchAllTenants: (): TenantAction => ({
    type: TenantActionType.FETCH_ALL_TENANTS,
  }),
  setTenants: (tenant_pagination?: TenantPagination): TenantAction => ({
    payload: { tenant_pagination },
    type: TenantActionType.SET_TENANTS,
  }),
  fetchTenantContent: (tenant_id: string): TenantAction => ({
    payload: { tenant_id },
    type: TenantActionType.FETCH_TENANT_CONTENT,
  }),
  setTenantContent: (tenant_content: TenantWithCompanyContent): TenantAction => ({
    payload: { tenant_content },
    type: TenantActionType.SET_TENANT_CONTENT,
  }),
  setLimit: (limit: number): TenantAction => ({
    payload: { limit },
    type: TenantActionType.SET_LIMIT,
  }),
  setCursor: (cursor: string): TenantAction => ({
    payload: { cursor },
    type: TenantActionType.SET_CURSOR,
  }),
  setOrderBy: (order_by: 'name' | 'subdomain'): TenantAction => ({
    payload: { order_by },
    type: TenantActionType.SET_ORDERBY,
  }),
  setOrderByDirection: (order_by_direction: 'asc' | 'desc'): TenantAction => ({
    payload: { order_by_direction },
    type: TenantActionType.SET_ORDERBY_DIRECTION,
  }),
  setSearch: (search: string): TenantAction=> ({
    payload: { search },
    type: TenantActionType.SET_SEARCH,
  }),
  setSearchField: (search_field: 'name' | 'subdomain'): TenantAction=> ({
    payload: { search_field },
    type: TenantActionType.SET_SEARCH_FIELD,
  }),
  setLoading: (is_loading: boolean): TenantAction=> ({
    payload: { is_loading },
    type: TenantActionType.SET_LOADING,
  }),
  setTenantContentLoading: (tenant_content_loading: boolean): TenantAction=> ({
    payload: { tenant_content_loading },
    type: TenantActionType.SET_TENANT_CONTENT_LOADING,
  }),
  setTenantCreateLoading: (tenant_create_loading: boolean): TenantAction=> ({
    payload: { tenant_create_loading },
    type: TenantActionType.SET_TENANT_CREATE_LOADING,
  }),
  setTenantUpdateLoading: (tenant_update_loading: boolean): TenantAction=> ({
    payload: { tenant_update_loading },
    type: TenantActionType.SET_TENANT_UPDATE_LOADING,
  }),
  setTenantRequestObj: (tenant_request_obj: TenantCreateRequestObj | TenantUpdateRequestObj): TenantAction=> ({
    payload: { tenant_request_obj },
    type: TenantActionType.SET_TENANT_REQUEST_OBJ,
  }),
  postTenant: (tenant_request_obj: TenantCreateRequestObj | TenantUpdateRequestObj): TenantAction=> ({
    payload: { tenant_request_obj },
    type: TenantActionType.CREATE_TENANT,
  }),
  patchTenant: (tenant_id: string, tenant_request_obj: TenantCreateRequestObj | TenantUpdateRequestObj): TenantAction=> ({
    payload: { tenant_id, tenant_request_obj },
    type: TenantActionType.UPDATE_TENANT,
  }),
  setError: (error: Error): TenantAction=> ({
    payload: { error },
    type: TenantActionType.SET_ERROR,
  }),
  setTenantCreateSuccess: (is_tenant_create_success: boolean): TenantAction=> ({
    payload: { is_tenant_create_success },
    type: TenantActionType.SET_TENANT_CREATE_SUCCESS,
  }),
  setTenantUpdateSuccess: (is_tenant_update_success: boolean): TenantAction=> ({
    payload: { is_tenant_update_success },
    type: TenantActionType.SET_TENANT_UPDATE_SUCCESS,
  }),
  clearState: (): TenantAction => ({
    type: TenantActionType.CLEAR,
  }),

  // tenant options.
  fetchTenantOptions: (search?: string): TenantAction=> ({
    payload: { search },
    type: TenantActionType.FETCH_TENANT_OPTIONS,
  }),
  setTenantOptionsLoading: (is_tenant_options_loading: boolean): TenantAction=> ({
    payload: { is_tenant_options_loading },
    type: TenantActionType.SET_TENANT_OPTIONS_LOADING,
  }),
  setTenantOptions: (tenant_options: TenantOptionsContent): TenantAction=> ({
    payload: { tenant_options },
    type: TenantActionType.SET_TENANT_OPTIONS,
  }),
  setTenantSelectionId: (tenant_selection_id: string): TenantAction=> ({
    payload: { tenant_selection_id },
    type: TenantActionType.SET_TENANT_SELECTION_ID,
  }),
  setSelectedTenant: (tenant_selection: any): TenantAction=> ({
    payload: { tenant_selection },
    type: TenantActionType.SET_SELECTED_TENANT,
  }),
};

export default actions;
