import request from 'src/services/request';
import endpoints from './endpoints';

import { AnnouncementBodyRequestSchema } from './type';

const api = {
  fetchAllAnnouncements: async (
    limit: number, 
    cursor?: string, 
    search?: string, 
    search_field?: string, 
    order_by_direction?: string, 
    order_by?: string
  ) => {
    let queryString = `?limit=${limit}`;
    
    if (cursor) queryString += `&cursor=${cursor}`;
    if (search) queryString += `&search=${search}`;
    if (search_field) queryString += `&search_field=${search_field}`;
    if (order_by_direction) queryString += `&order_by_direction=${order_by_direction}`;
    if (order_by) queryString += `&order_by=${order_by}`;

    return request(`${endpoints.announcement}/${queryString}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
  },
  fetchAnnouncementContent: async (announcement_id: string) =>
    request(`${endpoints.announcement}/${announcement_id}/`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }),
  postAnnouncement: async (payload: AnnouncementBodyRequestSchema) =>
    request(`${endpoints.announcement}/`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    }),
  patchAnnouncement: async (announcement_id: string, payload: AnnouncementBodyRequestSchema) =>
    request(`${endpoints.announcement}/${announcement_id}/`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    }),
};

export default api;
