import { call, put } from "redux-saga/effects";

// services
import * as processingPipelineServices from 'src/servicesV2/processingPipeline';

// actions
import actions from "../actions";
import siteActions from "../../Site/actions";

// sagas
import { fetchSite } from "src/store/Site/sagas/sites";
import { fetchBucketsFromAndToAssetWasMoved } from "./pipeline";


export function* editSiteAssessmentStaging({ payload }: any) {
  if (!payload?.assessmentId) return;
  const { assessmentId, site, fromAction, toAction, data, speedDialAction } = payload;
  try {
    const response = yield call(
      processingPipelineServices.editSiteAssessmentStaging,
      assessmentId,
      data
    );
    let assessment = response?.assessment;
    
    if (!assessment) return;

    yield put(actions.setEditSiteAssessmentStagingSuccess(true));

    if(fromAction && toAction) {
      yield put(actions.setAssessmentStaging({ assessment, fromAction, toAction }));
      yield put (actions.setSpeedDialAction(speedDialAction));

      yield call(fetchBucketsFromAndToAssetWasMoved, fromAction, toAction );
      yield put(actions.setFilterBucketsAfterMovingAsset(true));
    } else {
      const payload = { payload: { siteAssessment: assessment, site: site } };
      yield call(updateSiteAfterEditSiteAssessmentStaging, payload);
      return;
    }

    const payload: any = {
      payload: { siteId: site.site.name as string, assessmentId: assessmentId as string },
    };
    yield fetchSite(payload);
  } catch (e) {
    const error: Error = {
      name: 'assessment Id not found',
      message: '',
    };
    yield put(actions.setError(error));
  } finally {
    yield put(actions.setFilterBucketsAfterMovingAsset(false));
  }
}

export function* updateSiteAfterEditSiteAssessmentStaging({ payload }: any) {
  if (!payload.siteAssessment || !payload.site) return;
  const { siteAssessment, site } = payload;
  try {
    const updatedSiteAssessments = site.site.assessments.map(
      (assessment) => assessment.id === siteAssessment.id ? siteAssessment : assessment);

    const updatedSite = { ...site, assessments: [...updatedSiteAssessments] };

    yield put(siteActions.setSite(updatedSite));

  } catch (e) {
    yield put(actions.setError(e as Error));
  } finally {
    yield put(actions.setEditSiteAssessmentStagingSuccess(false));
  }
}
