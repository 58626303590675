import { put, takeLatest, call } from 'redux-saga/effects';

// actions
import actions from './actions';

// services
import { getImageExporterStatus } from 'src/servicesV2/site';

// types
import { ExportJobsActionType } from './types';

export function* fetchExportJobs() {
  try {
    yield put(actions.setExportJobsLoading(true));
    const response = yield call(getImageExporterStatus);
    yield put(actions.setExportJobs(response));
  } catch (e: any) {
    yield put(actions.setExportJobsError(e));
  } finally {
    yield put(actions.setExportJobsLoading(false));
  }
}

const sagas = [
  takeLatest(ExportJobsActionType.FETCH_EXPORT_JOBS, fetchExportJobs),
];

export default sagas;
