import { AssetClassContent, DailyForecast, SiteImage, SiteImageSummary, StructureTypeContent } from 'src/@types/site';
import { ProcessingData } from 'src/components/QueueMap/types';
import { PhotoTiePoints } from 'src/servicesV2/site/types';

export enum SiteActionType {
  FETCH_SITE = 'Site@FETCH_SITE',
  SET_FETCH_SITE_SUCCESS = 'Site@SET_FETCH_SITE_SUCCESS',
  FETCH_ASSESSMENT_IMAGES = 'Site@FETCH_ASSESSMENT_IMAGES',
  FETCH_5_DAYS_WEATHERS = 'Site@FETCH_5_DAYS_WEATHERS',
  SET_SITE = 'Site@SET_SITE',
  SET_ANOTHER_SITE_FOR_QUEUE_BUCKET = 'Site@SET_ANOTHER_SITE_FOR_QUEUE_BUCKET',
  SET_CURRENT_ASSESSMENT_ID = 'Site@SET_CURRENT_ASSESSMENT_ID',
  SET_CURRENT_SITE_ASSESSMENT_PHOTOS = 'Site@SET_CURRENT_SITE_ASSESSMENT_PHOTOS',
  SET_ASSET_PROFILE_PHOTO = 'Site@SET_ASSET_PROFILE_PHOTO',
  SET_LOADING = 'Site@SET_LOADING',
  SET_PHOTOS_LOADING = 'Site@SET_PHOTOS_LOADING',
  SET_ERROR = 'Site@SET_ERROR',

  //asset class select options
  SET_ASSET_CLASS_SELECT_OPTIONS = 'Site@SET_ASSET_CLASS_SELECT_OPTIONS',
  FETCH_ASSET_CLASS_SELECT_OPTIONS = 'Site@FETCH_ASSET_CLASS_SELECT_OPTIONS',
  SET_IS_ASSET_CLASS_SELECT_OPTIONS_LOADING = 'Site@SET_IS_ASSET_CLASS_SELECT_OPTIONS_LOADING',

  //structure select options
  SET_STRUCTURE_SELECT_OPTIONS = 'Site@SET_STRUCTURE_SELECT_OPTIONS',
  FETCH_STRUCTURE_SELECT_OPTIONS = 'Site@FETCH_STRUCTURE_SELECT_OPTIONS',
  SET_IS_STRUCTURE_SELECT_OPTIONS_LOADING = 'Site@SET_IS_STRUCTURE_SELECT_OPTIONS_LOADING',

  //add site
  CREATE_SITE = 'Site@CREATE_SITE',
  SET_IS_ADD_SITE_SUCCESS = 'Site@SET_IS_ADD_SITE_SUCCESS',

  //edit site
  EDIT_SITE = 'Site@EDIT_SITE',
  EDIT_SITE_ASSESSMENT_STAGING = 'Site@EDIT_SITE_ASSESSMENT_STAGING',
  EDIT_PHOTO_TIE_POINTS = 'Site@EDIT_PHOTO_TIE_POINTS',
  EDIT_CURRENT_SITE_ASSESSMENT_PHOTOS = 'Site@EDIT_CURRENT_SITE_ASSESSMENT_PHOTOS',
  DELETE_PHOTO_TIE_POINTS = 'Site@DELETE_PHOTO_TIE_POINTS',
  START_AZURE_IMPORT = 'Site@START_AZURE_IMPORT',
  START_AZURE_EXPORT = 'Site@START_AZURE_EXPORT',
  FORCE_COMPLETE_UPLOAD = 'Site@FORCE_COMPLETE_UPLOAD',
  SUBMIT_QUEUE_FOR_PROCESSING = 'Site@SUBMIT_QUEUE_FOR_PROCESSING',
  SET_SUBMIT_QUEUE_FOR_PROCESSING_LOADING = 'Site@SET_SUBMIT_QUEUE_FOR_PROCESSING_LOADING',
  SET_SUBMIT_QUEUE_FOR_PROCESSING_SUCCESS = 'Site@SET_SUBMIT_QUEUE_FOR_PROCESSING_SUCCESS',
  DELETE_SITE_USER = 'Site@DELETE_SITE_USER',

  SET_JOB_PROGRESS = 'Site@SET_JOB_PROGRESS',

  SET_PHOTO_TAB = 'Site@SET_PHOTO_TAB',
  SET_PHOTO_LIMIT = 'Site@SET_PHOTO_LIMIT',
  SET_PHOTO_OFFSET = 'Site@SET_PHOTO_OFFSET',

  DELETE_SITE_ASSESSMENT_STAGING = 'Site@DELETE_SITE_ASSESSMENT_STAGING',

  //assessment sales order
  FETCH_ASSESSMENT_SALES_ORDER = 'Site@FETCH_ASSESSMENT_SALES_ORDER',
  SET_IS_ASSESSMENT_SALES_ORDER = 'Site@SET_IS_ASSESSMENT_SALES_ORDER',
  SET_IS_ASSESSMENT_SALES_ORDER_LOADING = 'Site@SET_IS_ASSESSMENT_SALES_ORDER_LOADING',

  CLEAR = 'Site@CLEAR',
  SET_DAILY_FORECASTS = 'Site@SET_DAILY_FORECASTS',
  FETCH_SITE_ASSESSMENT_STAGING = 'Site@FETCH_SITE_ASSESSMENT_STAGING',
  SET_CLIENT_SITE_IDENTIFIER = 'Site@SET_CLIENT_SITE_IDENTIFIER',
  SET_ASSESSMENT_STAGING = 'Site@SET_ASSESSMENT_STAGING',
}

export type SiteState = {
  site?: any;
  loading: boolean;
  isPhotosLoading: boolean;
  error: undefined | Error;
  currentSiteAssessmentId?: string;
  currentSiteAssessmentPhotos: SiteImage[];
  assetProfilePhoto: SiteImageSummary | undefined;
  dailyForecasts: DailyForecast[];
  clientSiteIdentifier?: string;
  isSubmitQueueForProcessingLoading?: boolean;
  isSubmitQueueForProcessingSuccess?: boolean,
  isFetchSiteSuccess?: boolean;

  //asset class select option
  assetClassSelectOptions?: AssetClassContent;
  isAssetClassSelectOptionsLoading?: boolean;

  //structure select options
  structureSelectOptions?: StructureTypeContent;
  isStructureSelectOptionsLoading?: boolean;

  //add site
  addingSite?: any;
  isAddSiteSuccess?: boolean;

  //edit site
  editingSite?: any;

  //assessment sales order
  isAssessmentSalesOrder?: boolean;
  isAssessmentSalesOrderLoading?: boolean;

  //upload job progress
  jobProgress?: any;

  //photos
  photoTab: number;
  photoLimit: number;
  photoOffset: number;
};

export type SitePayload = {
  site?: any;
  slmId?: string;
  loading?: boolean;
  isPhotosLoading?: boolean;
  error?: Error;
  currentSiteAssessmentId?: string;
  currentSiteAssessmentPhotos?: SiteImage[];
  assetProfilePhoto?: SiteImageSummary;
  assessmentId?: string;
  siteId?: string;
  lat?: number;
  lng?: number;
  dailyForecasts?: DailyForecast[];
  clientSiteIdentifier?: string;
  currentPhotoId?: string;
  processingData?: ProcessingData;
  isSubmitQueueForProcessingLoading?: boolean;
  isSubmitQueueForProcessingSuccess?: boolean,
  isFetchSiteSuccess?: boolean;

  //asset class select option
  assetClassSelectOptions?: AssetClassContent;
  isAssetClassSelectOptionsLoading?: boolean;

  //structure select options
  structureSelectOptions?: StructureTypeContent;
  isStructureSelectOptionsLoading?: boolean;


  //add site
  addingSite?: any;
  isAddSiteSuccess?: boolean;

  //edit site
  editingSite?: any;
  photoTiePoints?: PhotoTiePoints;
  editingCurrentSiteAssessmentPhotos?: SiteImage;
  site_user_id?: string;

  //assessment sales order
  isAssessmentSalesOrder?: boolean;
  isAssessmentSalesOrderLoading?: boolean;

  jobProgress?: any;

  photoTab?: number;
  photoLimit?: number;
  photoOffset?: number;
};

export type SiteAction = {
  payload?: SitePayload;
  type: SiteActionType;
};

export type SiteReducer = (state: SiteState, payload?: SitePayload) => SiteState;
