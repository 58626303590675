import React from "react";

// @mui
import { Typography, TableRow } from "ca-react-component-lib";

// styles
import { TableCellSmall } from "./styles";

const TableRowSmall = ({ label, value }: { label: string, value?: string }) => (
  <TableRow sx={{ border: 0 }}>
    <TableCellSmall>
      <Typography
        sx={{
          color: 'text.secondary',
        }}
      >
        {label}
      </Typography>
    </TableCellSmall>
    <TableCellSmall>
      <Typography
        sx={{ 
          fontWeight: 'fontWeightBold', 
          color: 'text.secondary',
          pl: 2,
        }}
      >
        {value ?? '-'}
      </Typography>
    </TableCellSmall>
  </TableRow>
);

export default TableRowSmall;
