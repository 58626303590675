import { put, takeLatest, call } from 'redux-saga/effects';

// actions
import actions from './actions';

// services
import { getImageImporterStatus } from 'src/servicesV2/site';

// types
import { ImportJobsActionType } from './types';

export function* fetchImportJobs() {
  try {
    yield put(actions.setImportJobsLoading(true));
    const response = yield call(getImageImporterStatus);
    yield put(actions.setImportJobs(response));
  } catch (e: any) {
    yield put(actions.setImportJobsError(e));
  } finally {
    yield put(actions.setImportJobsLoading(false));
  }
}

const sagas = [
  takeLatest(ImportJobsActionType.FETCH_IMPORT_JOBS, fetchImportJobs),
];

export default sagas;
