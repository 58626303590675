import { call, put, takeLatest, select } from 'redux-saga/effects';

// services
import * as announceService from 'src/servicesV2/announcement';

import { TOAST_SEVERITY } from 'src/@types/toast';

// types
import { AnnouncementPagination, AnnouncementContent } from 'src/@types/announcement';
import { AnnouncementAction, AnnouncementActionType } from './types';

// actions
import actions from './actions';

// selectors
import selector from './selector';

import ToastAction from 'src/store/Toast/actions';

export function* fetchAllAnnouncements() {
  const limit: number = yield select(selector.getLimit);
  const cursor: string = yield select(selector.getCursor);
  const orderBy: string = yield select(selector.getOrderBy);
  const orderByDirection: string = yield select(selector.getOrderByDirection);
  const searchField: string = yield select(selector.getSearchField);
  const search: string = yield select(selector.getSearch);
  try {
    yield put(actions.setLoading(true));
    const response: AnnouncementPagination = yield call(
      announceService.fetchAllAnnouncements,
      limit,
      cursor,
      search,
      searchField,
      orderByDirection,
      orderBy
    );
    yield put(actions.setAnnouncement(response));
  } catch (e) {
    yield put(actions.setError(e as Error));
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* fetchAnnouncementContent({ payload }: AnnouncementAction) {
  try {
    if (!payload || !payload.announcement_id) {
      return;
    }

    const { announcement_id } = payload;

    yield put(actions.setLoading(true));
    const response: AnnouncementContent = yield call(
      announceService.fetchAnnouncementContent,
      announcement_id
    );
    yield put(actions.setAnnouncementContent(response));
    yield put(
      actions.setAnnouncementEditorObj({
        announcement: {
          title: response.announcement?.title,
          excerpt: response.announcement?.excerpt,
          content: response.announcement?.content,
        }
      })
    );
  } catch (e) {
    yield put(actions.setError(e as Error));
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* postAnnouncement({ payload }: AnnouncementAction) {
  try {
    if (!payload?.editor_content) return;
    const { editor_content } = payload;
    yield put(actions.setIsEditorUploading(true));
    const announcementEditorObj = yield select(selector.getAnnouncementEditorObj);
    
    const updatedAnnouncementEditorObj = {
      ...announcementEditorObj,
      announcement: {
        ...announcementEditorObj.announcement,
        content: editor_content,
      },
    };

    const res: AnnouncementContent = yield call(announceService.postAnnouncement, updatedAnnouncementEditorObj);

    if (res.announcement?.id) {
      yield put(ToastAction.setToast('Create Success!'));
      yield put(actions.setAnnouncementEditorObj({}));
      yield put(actions.setFinishEditorUploading(true));
    } else {
      yield put(ToastAction.setToast('Create Failed!', TOAST_SEVERITY.ERROR));
    }
  } catch (e) {
    yield put(actions.setError(e as Error));
  } finally {
    yield put(actions.setIsEditorUploading(false));
  }
}

export function* patchAnnouncement({ payload }: AnnouncementAction) {
  try {
    if (!payload || !payload.announcement_id) {
      return;
    }

    if (!payload?.editor_content) return;
    const { editor_content } = payload;
    const { announcement_id } = payload;

    yield put(actions.setIsEditorUploading(true));
    const announcementEditorObj = yield select(selector.getAnnouncementEditorObj);
    
    const updatedAnnouncementEditorObj = {
      ...announcementEditorObj,
      announcement: {
        ...announcementEditorObj.announcement,
        content: editor_content,
      },
    };

    const res: AnnouncementContent = yield call(announceService.patchAnnouncement, announcement_id, updatedAnnouncementEditorObj);
    if (res.announcement?.id) {
      yield put(ToastAction.setToast('Edit Success!'));
      yield put(actions.setAnnouncementEditorObj({}));
      yield put(actions.setFinishEditorUploading(true));
    } else {
      yield put(ToastAction.setToast('Edit Failed!', TOAST_SEVERITY.ERROR));
    }
  } catch (e) {
    yield put(actions.setError(e as Error));
  } finally {
    yield put(actions.setIsEditorUploading(false));
  }
}

const sagas: any[] = [
  takeLatest(AnnouncementActionType.FETCH_ALL_ANNOUNCEMENTS, fetchAllAnnouncements),
  takeLatest(AnnouncementActionType.FETCH_ANNOUNCEMENT_CONTENT, fetchAnnouncementContent),
  takeLatest(AnnouncementActionType.POST_ANNOUNCEMENT, postAnnouncement),
  takeLatest(AnnouncementActionType.PATCH_ANNOUNCEMENT, patchAnnouncement),
];

export default sagas;
