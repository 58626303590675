import { ExportJob, ExportJobsAction, ExportJobsActionType } from './types';

const actions = {
  fetchExportJobs: (): ExportJobsAction => ({
    type: ExportJobsActionType.FETCH_EXPORT_JOBS,
  }),
  setExportJobs: (exportJobs?: ExportJob[]): ExportJobsAction => ({
    payload: { exportJobs },
    type: ExportJobsActionType.SET_EXPORT_JOBS,
  }),
  setExportJobsLoading: (exportJobsLoading: boolean): ExportJobsAction => ({
    payload: { exportJobsLoading },
    type: ExportJobsActionType.SET_EXPORT_JOBS_LOADING,
  }),
  setExportJobsError: (exportJobsError: Error): ExportJobsAction => ({
    payload: { exportJobsError },
    type: ExportJobsActionType.SET_EXPORT_JOBS_ERROR,
  }),
  setColumn: (column: string): ExportJobsAction => ({
    payload: { column },
    type: ExportJobsActionType.SET_COLUMN,
  }),
  setSortBy: (sortBy: "asc" | "desc"): ExportJobsAction => ({
    payload: { sortBy },
    type: ExportJobsActionType.SET_SORTBY,
  }),
};

export default actions;
