import request from 'src/services/request';
import endpoints from './endpoints';
import { LabelValue } from 'src/@types/root';

const api = {
  fetchAllSites: async (
    limit: number,
    cursor?: string,
    search?: string,
    search_field?: string,
    order_by_direction?: string,
    order_by?: string,
    keyword_search?: string,
    search_terms?: string,
    asset_classes?: LabelValue[],
    structure_types?: LabelValue[],
    tenant_id?: string,
    tower_owners?: LabelValue[],
    assessment_tags?: LabelValue[],
    flood_zone_designations?: LabelValue[],
    drone_vendors?: LabelValue[],
    structure_height_from?: number,
    structure_height_to?: number,
    is_lease_space?: boolean,
    qc_status?: string,
    user_id?: string,
    only_sites_with_assessments?: boolean
  ) => {
    let queryString = `?limit=${limit}`;

    if (cursor) queryString += `&cursor=${cursor}`;
    if (search) queryString += `&search=${search}`;
    if (search_field) queryString += `&search_field=${search_field}`;
    if (order_by_direction) queryString += `&order_by_direction=${order_by_direction}`;
    if (order_by) queryString += `&order_by=${order_by}`;
    if (keyword_search) queryString += `&keyword_search=${keyword_search}`;
    if (search_terms) queryString += `&search_terms=${search_terms}`;

    // General function to add parameters for LabelValue types or simple string arrays
    const addParams = (paramName, values) => {
      if (!values || values.length === 0) return '';
      return values.map(value => `&${paramName}=` + encodeURIComponent(value.value || value)).join('');
    };

    // Adding each array of parameters using the function
    queryString += addParams('asset_classes', asset_classes);
    queryString += addParams('structure_types', structure_types);
    queryString += addParams('tower_owners', tower_owners);
    queryString += addParams('assessment_tags', assessment_tags);
    queryString += addParams('flood_zone_designations', flood_zone_designations);
    queryString += addParams('drone_vendors', drone_vendors);


    if (structure_height_from !== undefined) queryString += `&structure_height_from=${structure_height_from}`;
    if (structure_height_to !== undefined) queryString += `&structure_height_to=${structure_height_to}`;
    if (is_lease_space !== undefined) queryString += `&is_lease_space=${is_lease_space}`;
    if (qc_status) queryString += `&qc_status=${qc_status}`;
    if (user_id) queryString += `&user_id=${user_id}`;
    if (tenant_id) queryString += `&tenants=${tenant_id}`;
    if (only_sites_with_assessments !== undefined) queryString += `&only_sites_with_assessments=${only_sites_with_assessments}`;

    return request(`${endpoints.site}${queryString}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
  },

  fetchAssetClassOptions: async () =>
    request(endpoints.asset_class, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }),
    
  fetchStructureTypeOptions: async () =>
    request(endpoints.structure_type, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }),

  fetchSiteByName: async (siteName: string) =>
    request(`${endpoints.site}get-by-name/${siteName}/`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }),

  fetchAssetsNearby: async (siteId: string, radius: number, onlyLeaseSpace?: boolean, removeZeroDistance?: boolean ) =>
    request(`${endpoints.site}${siteId}/?radius=${radius}&only_lease_space=${onlyLeaseSpace}&remove_zero_distance=${removeZeroDistance}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }),

  createSite: async (payload: any) => request(`${endpoints.site}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  }),

  editSite: async (payload: any, siteId: string) => request(`${endpoints.site}${siteId}/`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  }),

  fetchSiteAssessmentStaging: async (assessmentId: string) =>
    request(`${endpoints.assessment}${assessmentId}/`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }),

  fetchAssessmentSalesOrder: async (sasId: string) =>
    request(`${endpoints.assessment}${sasId}/sales-order/`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }),

  createSiteAssessment: async (payload: any, siteId: string) => request(`${endpoints.assessment}${siteId}/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  }),

  editSiteAssessment: async (payload: any, siteAssessmentId: string) => request(`${endpoints.assessment}${siteAssessmentId}/`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  }),

  deleteSiteAssessmentStaging: async (assessmentId: string) =>
    request(`${endpoints.assessment}${assessmentId}/`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
    }),

  createSiteUser: async (payload: any, siteId: string) => request(`${endpoints.site_user_role}${siteId}/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  }),

  editSiteUser: async (payload: any, siteUserRoleId: string) => request(`${endpoints.site_user_role}${siteUserRoleId}/`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  }),

  deleteSiteUser: async (siteUserId: string) => request(`${endpoints.site_user_role}${siteUserId}/`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  }),

  submitQueueForProcessingData: async (payload: any) =>
    request(`${endpoints.submit_queue_for_processing}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    }),

  setAssessmentPhotoTiePoint: async (assessmentId: string, payload: any) =>
    request(`${endpoints.assessment}${assessmentId}/tie-point/`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    }),
  
  getAssessmentImages: async (assessmentId: string, tab?: string, limit?: number, offset?: number) =>
    request(`${endpoints.assessment}${assessmentId}/image/${limit ? "?limit="+limit+"&offset="+offset+"&file_path="+tab : ""}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }),

  getAssessmentImageSummary: async (assessmentId: string) =>
    request(`${endpoints.assessment}${assessmentId}/image-summary/`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }),

  deleteAssessmentPhotoTiePoint: async (assessmentId: string, tiePoint: string) =>
    request(`${endpoints.assessment}${assessmentId}/tie-point/${tiePoint}/`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
    }),

  getJobProgress: async (assessmentId: string) =>
    request(`${endpoints.assessment}${assessmentId}/job-progress`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }),

  forceCompleteUpload: async (assessmentId: string) =>
    request(`${endpoints.assessment}${assessmentId}/force-upload-as-completed`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
    }),

  triggerAzureImport: async (assessmentId: string, payload: any) =>
    request(`${endpoints.azureImport}${assessmentId}/`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    }),

  triggerAzureExport: async (assessmentId: string, payload: any) =>
    request(`${endpoints.azureExport}${assessmentId}/`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    }),

  getImageImporterStatus: async () =>
    request(`${endpoints.imageImporterStatus}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }),

  getImageExporterStatus: async () =>
    request(`${endpoints.imageExporterStatus}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }),

  getModelProcessingQueues: async () =>
    request(`${endpoints.modelProcessingQueues}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }),
};

export default api;
