import { ApplicationState } from '../types';

// Helper function to access the ProcessingPipeline slice of the state
const _getData = (state: ApplicationState) => state.ProcessingPipeline;

// Selector to get the error from the ProcessingPipeline state
const getError = (state: ApplicationState) => _getData(state).error;

const getTenantId = (state: ApplicationState) => _getData(state).tenantId;

// Selector to get items waiting for images from the ProcessingPipeline state
const getWaitingForImages = (state: ApplicationState) => _getData(state).waiting_for_images;

// Selector to determine if waiting for images is currently loading
const isWaitingForImagesLoading = (state: ApplicationState) =>
  _getData(state).waiting_for_images_loading;

// Selector to get the queue for processing from the ProcessingPipeline state
const getQueueForProcessing = (state: ApplicationState) => _getData(state).queue_for_processing;

// Selector to determine if the queue for processing is currently loading
const isQueueForProcessingLoading = (state: ApplicationState) =>
  _getData(state).queue_for_processing_loading;

// Selector to get items waiting for processing from the ProcessingPipeline state
const getWaitForProcessing = (state: ApplicationState) => _getData(state).wait_for_processing;

// Selector to determine if waiting for processing is currently loading
const isWaitForProcessingLoading = (state: ApplicationState) =>
  _getData(state).wait_for_processing_loading;

// Selector to get items for quality control (QC) from the ProcessingPipeline state
const getPerformQC = (state: ApplicationState) => _getData(state).perform_qc;

// Selector to determine if quality control (QC) is currently loading
const isPerformQCLoading = (state: ApplicationState) => _getData(state).perform_qc_loading;

// Selector to get completed items from the ProcessingPipeline state
const getCompleted = (state: ApplicationState) => _getData(state).completed;

// Selector to determine if completed items are currently loading
const isCompletedLoading = (state: ApplicationState) => _getData(state).completed_loading;

// Selector to get refly items from the ProcessingPipeline state
const getRefly = (state: ApplicationState) => _getData(state).request_refly;

// Selector to determine if refly items are currently loading
const isReflyLoading = (state: ApplicationState) => _getData(state).request_refly_loading;

// Selector to get request measurements from the ProcessingPipeline state
const getRequestMeasurements = (state: ApplicationState) => _getData(state).request_measurements;

// Selector to determine if request measurements are currently loading
const isRequestMeasurementsLoading = (state: ApplicationState) =>
  _getData(state).request_measurements_loading;

// Selector to get re-process items from the ProcessingPipeline state
const getReProcess = (state: ApplicationState) => _getData(state).re_process;

// Selector to determine if re-process items are currently loading
const isReProcessLoading = (state: ApplicationState) => _getData(state).re_process_loading;


// Selector to determine if any processing is currently loading
const isLoading = (state: ApplicationState) => _getData(state).is_loading;


const isEditTiePointsLoading = (state: ApplicationState) => _getData(state).editTiePointsLoading;

const isDeleteTiePointsLoading = (state: ApplicationState) => _getData(state).deleteTiePointsLoading;


const getSelectedTiePointLocations = (state: ApplicationState) => _getData(state).selectedTiePointLocations;

const getNextSelectedTiePointLocationPointer = (state: ApplicationState) => _getData(state).nextSelectedTiePointLocationPointer;

const getIsTiePointPhotosShow = (state: ApplicationState) => _getData(state).isTiePointPhotosShow;

const getTiePointPhotoLoadingStrategy = (state: ApplicationState) => _getData(state).tiePointPhotoLoadingStrategy;

const getTiePointPhotoTabIndex = (state: ApplicationState) => _getData(state).tiePointPhotoTabIndex;

const getImageLoading = (state: ApplicationState) => _getData(state).isImageLoading;

const getIsFilterBucketsAfterMovingAsset = (state: ApplicationState) => _getData(state).isFilterBucketsAfterMovingAsset;

const getIsEditSiteAssessmentStagingSuccess = (state: ApplicationState) => _getData(state).isEditSiteAssessmentStagingSuccess;


const getSpeedDialAction = (state: ApplicationState) => _getData(state).speedDialAction;

const getActionViewOpen = (state: ApplicationState) => _getData(state).actionViewOpen;


// Export all selectors as a single object
const selectors = {
  getError,
  getWaitingForImages,
  isWaitingForImagesLoading,
  getQueueForProcessing,
  isQueueForProcessingLoading,
  getWaitForProcessing,
  isWaitForProcessingLoading,
  getPerformQC,
  isPerformQCLoading,
  getCompleted,
  isCompletedLoading,
  getRefly,
  isReflyLoading,
  getRequestMeasurements,
  isRequestMeasurementsLoading,
  getReProcess,
  isReProcessLoading,
  isLoading,

  getTenantId,

  isEditTiePointsLoading,
  isDeleteTiePointsLoading,
  getSelectedTiePointLocations,
  getNextSelectedTiePointLocationPointer,
  getIsTiePointPhotosShow,
  getTiePointPhotoLoadingStrategy,
  getTiePointPhotoTabIndex,
  getImageLoading,

  getIsFilterBucketsAfterMovingAsset,
  getIsEditSiteAssessmentStagingSuccess,

  getSpeedDialAction,
  getActionViewOpen

};

export default selectors;
