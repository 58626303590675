import { put, takeLatest, call, select } from 'redux-saga/effects';

// actions
import actions from './actions';
import ToastAction from '../Toast/actions';

// types
import { QcAction, QcActionType } from './types';
import { FalloutReasonOptionsContent, QCFormContent, QCFormListContent, StatusColorOptionsCotent } from 'src/@types/qcForm';
import { TOAST_SEVERITY } from 'src/@types/toast';

// services
import * as OTIQServices from 'src/servicesV2/otiq';
import * as QCServices from 'src/servicesV2/qcForm';

// selectors
import siteSelectors from 'src/store/Site/selectors';

//QC Form on V2
export function* fetchQcRecords({ payload }: QcAction) {
  try {
    if (!payload || !payload?.currentSiteAssessmentId) return;
    yield put(actions.setFetchQcRecordsLoading(true));

    const response: QCFormListContent = yield call(
      QCServices.fetchAssessmentQCForms,
      payload.currentSiteAssessmentId
    );
    yield put(actions.setQcRecords(response));
  } catch (e) {
    yield put(actions.setError(e as Error));
  } finally {
    yield put(actions.setFetchQcRecordsLoading(false));
  }
}

export function* fetchLastQcRecord({ payload }: QcAction) {
  try {
    if (!payload || !payload?.currentSiteAssessmentId) return;
    yield put(actions.setFetchQcRecordsLoading(true));

    const response: QCFormContent = yield call(
      QCServices.fetchAssessmentLastQC,
      payload.currentSiteAssessmentId
    );
    yield put(actions.setLastQcRecord(response));
  } catch (e) {
    yield put(actions.setError(e as Error));
  } finally {
    yield put(actions.setFetchQcRecordsLoading(false));
  }
}

export function* createQCRecord({ payload }: QcAction) {
  try {
    if (!payload || !payload?.qc) return;

    yield put(actions.setCreateQcRecordLoading(true));
    const response: QCFormContent = yield call(QCServices.postQCForm, payload?.qc);

    if(response) 
    {
      yield put(ToastAction.setToast('QC record was successfully created.'));
      const currentSiteAssessmentId = yield select(siteSelectors.getCurrentSiteAssessmentId);

      const lastQCRecordResponse: QCFormContent = yield call(QCServices.fetchAssessmentLastQC, currentSiteAssessmentId);
      yield put(actions.setLastQcRecord(lastQCRecordResponse));
      
      const qcRecordsResponse: QCFormListContent = yield call(QCServices.fetchAssessmentQCForms, currentSiteAssessmentId);
      yield put(actions.setQcRecords(qcRecordsResponse));
    } 
    else 
    {
      yield put(ToastAction.setToast('Failed to create QC record', TOAST_SEVERITY.ERROR));
    }
  } 
  catch(e) 
  {
    yield put(actions.setError(e as Error));
    yield put(ToastAction.setToast('Failed to create QC record', TOAST_SEVERITY.ERROR));
  } 
  finally 
  {
    yield put(actions.setCreateQcRecordLoading(false));
    yield put(ToastAction.clearState());
  }
}

export function* updateQCRecord({ payload }: QcAction) {
  try 
  {
    if(!payload || !payload?.currentQCFormId || !payload?.qc) return;
    yield put(actions.setUpdateQcRecordLoading(true));
    const response: QCFormContent = yield call(QCServices.patchQCForm, payload?.currentQCFormId, payload?.qc);
    if(response?.qc_form?.id)
    {
      yield put(ToastAction.setToast('QC record was successfully updated.'));
      const currentSiteAssessmentId = yield select(siteSelectors.getCurrentSiteAssessmentId);

      const lastQcRecordResponse: QCFormContent = yield call(QCServices.fetchAssessmentLastQC, currentSiteAssessmentId);
      yield put(actions.setLastQcRecord(lastQcRecordResponse));

      const qcRecordsResponse: QCFormListContent = yield call(QCServices.fetchAssessmentQCForms, currentSiteAssessmentId);
      yield put(actions.setQcRecords(qcRecordsResponse));
    }
    else
    {
      yield put(ToastAction.setToast('Failed to update QC record', TOAST_SEVERITY.ERROR));
    }
  } 
  catch(e) 
  {
    yield put(actions.setError(e as Error));
    yield put(ToastAction.setToast('Failed to update QC record', TOAST_SEVERITY.ERROR));
  } 
  finally 
  {
    yield put(actions.setUpdateQcRecordLoading(false));
    yield put(ToastAction.clearState());
  }
}

export function* fetchFallOutReasons() {
  try {
    yield put(actions.setFetchFallOutReasonsLoading(true));
    const response: FalloutReasonOptionsContent = yield call(QCServices.fetchQCFalloutReasonOptions);
    if (response) {
      yield put(actions.setFallOutReasons(response));
    }
  } catch (error) {
    yield put(actions.setError(error));
  } finally {
    yield put(actions.setFetchFallOutReasonsLoading(false));
  }
}

export function* fetchStatusColors() {
  try {
    yield put(actions.setFetchStatusColorsLoading(true));
    const response: StatusColorOptionsCotent = yield call(QCServices.fetchQCStatusColorOptions);
    if (response) {
      yield put(actions.setStatusColors(response));
    }
  } catch (error) {
    yield put(actions.setError(error));
  } finally {
    yield put(actions.setFetchStatusColorsLoading(false));
  }
}


//OTIQ

export function* fetchOtiqRevisions({ payload }: any) {
  yield put(actions.setLoading(true));

  if (!payload) return;
  const { sasId } = payload;

  try {
    const response = yield call(OTIQServices.fetchOtiqRevisions, sasId!);
    if (response) {
      yield put(actions.setOtiqRevisions(response));
    }
  } catch (error) {
    yield put(actions.setError(error));
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* insertOtiqFiles({ payload }: QcAction) {
  yield put(actions.setLoading(true));

  try {
    const response = yield call(OTIQServices.insertOtiqFiles, payload);

    if (response.otiq_revision.id) {
      yield put(ToastAction.setToast('Revision file added successully!'));
      yield put(actions.setSuccess(true));
      const payload = { sasId: response.otiq_revision.site_assessment_staging_id };
      yield call(fetchOtiqRevisions, { payload });
    }
  } catch (error) {
    yield put(actions.setError(error));
    yield put(actions.setSuccess(false));
    yield put(
      ToastAction.setToast('Failed to add file. All fields required', TOAST_SEVERITY.ERROR)
    );
  } finally {
    yield put(actions.setLoading(false));
    yield put(actions.setSuccess(false));
  }
}

export function* insertOtiqRevision({ payload }: QcAction) {
  yield put(actions.setLoading(true));
  if (!payload) return;
  const { sasId } = payload;
  try {
    const response = yield call(OTIQServices.insertOtiqRevision, payload);

    if (response.otiq_revision.id) {
      const payload = { sasId };
      yield call(fetchOtiqRevisions, { payload });
    }
  } catch (error) {
    yield put(actions.setError(error));
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* deleteOtiqFile({ payload }: QcAction) {
  yield put(actions.setLoading(true));
  if (!payload) return;
  const { fileId, sasId } = payload;

  try {
    const response = yield call(OTIQServices.deleteOtiqFile, fileId!);
    if (response == null) {
      const payload = { sasId };
      yield call(fetchOtiqRevisions, { payload });
      yield put(ToastAction.setToast('Revision file deleted successully!'));
    }
  } catch (error) {
    yield put(ToastAction.setToast('Failed to delete revision file!', TOAST_SEVERITY.ERROR));
    yield put(actions.setError(error));
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* fetchFilePurposeOptions() {
  try {
    const response = yield call(OTIQServices.fetchFilePurposeOptions);
    yield put(actions.setFilePurposeOptions(response));
  } catch (e) {
    yield put(actions.setError(e as Error));
  }
}

export function* fetchFileExtensionOptions() {
  try {
    const response = yield call(OTIQServices.fetchFileFormatOptions);
    yield put(actions.setFileExtensionOptions(response));
  } catch (e) {
    yield put(actions.setError(e as Error));
  }
}

const sagas = [
  takeLatest(QcActionType.FETCH_QC_RECORDS, fetchQcRecords),
  takeLatest(QcActionType.FETCH_LAST_QC_RECORD, fetchLastQcRecord),
  takeLatest(QcActionType.CREATE_QC_RECORD, createQCRecord),
  takeLatest(QcActionType.UPDATE_QC_RECORD, updateQCRecord),
  takeLatest(QcActionType.FETCH_FALLOUT_REASONS, fetchFallOutReasons),
  takeLatest(QcActionType.FETCH_STATUS_COLORS, fetchStatusColors),
  takeLatest(QcActionType.INSERT_OTIQ_FILES, insertOtiqFiles),
  takeLatest(QcActionType.INSERT_OTIQ_REVISIONS, insertOtiqRevision),
  takeLatest(QcActionType.DELETE_OTIQ_FILE, deleteOtiqFile),
  takeLatest(QcActionType.GET_OTIQ_REVISIONS, fetchOtiqRevisions),
  takeLatest(QcActionType.FETCH_FILE_PURPOSE_OPTIONS, fetchFilePurposeOptions),
  takeLatest(QcActionType.FETCH_FILE_EXTENSION_OPTIONS, fetchFileExtensionOptions),
];

export default sagas;
