export type TodoData = {
  action: string | undefined;
  assessmentTag: string | undefined;
  companySiteId: string | undefined;
  dataReceived: string | undefined;
  duration: any;
  id: string | undefined;
  images: number | undefined;
  lastLogTimeStamp: Date | undefined;
  processingStatus: any;
  siteId: string | undefined;
  thumbnails: number | undefined;
  thumbnailsFailed: number | undefined;
};

export enum ACTIONS {
  WAITING_FOR_IMAGES = 'Waiting for images',
  QUEUE_FOR_PROCESSING = 'Queue for processing',
  WAIT_FOR_PROCESSING = 'Wait for processing',
  PERFORM_QC = 'Perform QC',
  COMPLETED = 'Completed',
  REQUEST_REFLY = 'Request re-fly',
  REQUEST_MEASUREMENTS = 'Request measurements',
  RE_QUEUE_FOR_PROCESSING = 'Re-Queue for processing',
}

export enum TodoStateKeys {
  WAITING_FOR_IMAGES = 'waitingForImages',
  QUEUE_FOR_PROCESSING = 'queue',
  WAIT_FOR_PROCESSING = 'processing',
  PERFORM_QC = 'performQc',
  COMPLETED = 'completed',
  REQUEST_REFLY = 'reFly',
  REQUEST_MEASUREMENTS = 'measurements',
  RE_QUEUE_FOR_PROCESSING = 'reProcessing',
}

export type SelectedTiePointLocation = {
  tab: number;
  photoId: string;
}