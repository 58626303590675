import { all } from 'redux-saga/effects';

import authenticationSagas from 'src/store/Authentication/sagas';
import profileSagas from 'src/store/Profile/sagas';
import siteSagas from 'src/store/Site/sagas';
import notificationSaga from 'src/store/Notification/sagas';
import processingPipelineSagas from 'src/store/ProcessingPipeline/sagas';
import tenantSagas from 'src/store/Tenant/sagas';
import siteSearchQuery from 'src/store/SiteSearchQuery/sagas';
import usersSagas from 'src/store/Users/sagas';
import companiesSagas from 'src/store/Companies/sagas';
import manageAccessSaga from 'src/store/ManageAccess/sagas';
import announcementSagas from 'src/store/Announcement/sagas';
import ticketSagas from 'src/store/Ticket/sagas';
import QcSaga from 'src/store/Qc/sagas';
import ImportJobs from 'src/store/ImportJobs/sagas';
import ExportJobs from 'src/store/ExportJobs/sagas';
import processingSagas from 'src/store/Processing/sagas';
import customerSuccessAccessSagas from 'src/store/CustomerSuccessAccess/sagas';

function* rootSagas() {
  yield all([...authenticationSagas]);
  yield all([...profileSagas]);
  yield all([...siteSagas]);
  yield all([...notificationSaga]);
  yield all([...processingPipelineSagas]);
  yield all([...tenantSagas]);
  yield all([...siteSearchQuery]);
  yield all([...usersSagas]);
  yield all([...companiesSagas]);
  yield all([...manageAccessSaga]);
  yield all([...announcementSagas]);
  yield all([...ticketSagas]);
  yield all([...QcSaga]);
  yield all([...ImportJobs]);
  yield all([...ExportJobs]);
  yield all([...processingSagas]);
  yield all([...customerSuccessAccessSagas]);

}

export default rootSagas;
