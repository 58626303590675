import { TenantWithCompanyContent, TenantPagination,  TenantOptionsContent, TenantCreateRequestObj, TenantUpdateRequestObj } from 'src/@types/tenant';

export enum TenantActionType {
  FETCH_ALL_TENANTS = 'Tenant@FETCH_ALL_TENANTS',
  SET_TENANTS = 'Tenant@SET_TENANTS',
  FETCH_TENANT_CONTENT = 'Tenant@FETCH_TENANT_CONTENT',
  SET_TENANT_CONTENT = 'Tenant@SET_TENANT_CONTENT',
  SET_LIMIT = 'Tenant@SET_LIMIT',
  SET_CURSOR = 'Tenant@SET_CURSOR',
  SET_ORDERBY = 'Tenant@SET_ORDERBY',
  SET_ORDERBY_DIRECTION = 'Tenant@SET_ORDERBY_DIRECTION',
  SET_SEARCH = 'Tenant@SET_SEARCH',
  SET_SEARCH_FIELD = 'Tenant@SET_SEARCH_FIELD',
  SET_LOADING = 'Tenant@SET_LOADING',
  SET_ERROR = 'Tenant@SET_ERROR',
  CREATE_TENANT = 'Tenant@CREATE_TENANT',
  UPDATE_TENANT = 'Tenant@UPDATE_TENANT',
  SET_TENANT_CONTENT_LOADING = 'Tenant@SET_TENANT_CONTENT_LOADING',
  SET_TENANT_CREATE_LOADING = 'Tenant@SET_TENANT_CREATE_LOADING',
  SET_TENANT_UPDATE_LOADING = 'Tenant@SET_TENANT_UPDATE_LOADING',
  SET_TENANT_CREATE_SUCCESS = 'Tenant@SET_TENANT_CREATE_SUCCESS',
  SET_TENANT_UPDATE_SUCCESS = 'Tenant@SET_TENANT_UPDATE_SUCCESS',
  SET_TENANT_REQUEST_OBJ = 'Tenant@SET_TENANT_REQUEST_OBJ',

  // tenant options.
  FETCH_TENANT_OPTIONS = 'Tenant@FETCH_TENANT_OPTIONS',
  SET_TENANT_OPTIONS = 'Tenant@SET_TENANT_OPTIONS',
  SET_TENANT_OPTIONS_LOADING = 'Tenant@SET_TENANT_OPTIONS_LOADING',
  SET_TENANT_SELECTION_ID = 'Tenant@SET_TENANT_SELECTION_ID',
  SET_SELECTED_TENANT = 'Tenant@SET_SELECTED_TENANT',

  CLEAR = 'Tenant@CLEAR',
}


export type TenantState = {
  error?: Error;
  tenant_pagination?: TenantPagination;
  tenant_content?: TenantWithCompanyContent;
  limit?: number;
  cursor?: string;
  order_by_direction?: 'asc' | 'desc';
  order_by?: 'name' | 'subdomain';
  search?: string;
  search_field?: 'name' | 'subdomain';
  is_loading?: boolean;
  tenant_content_loading?: boolean;
  tenant_create_loading?: boolean;
  tenant_update_loading?: boolean;
  is_tenant_create_success?: boolean;
  is_tenant_update_success?: boolean;
  tenant_request_obj?: TenantCreateRequestObj | TenantUpdateRequestObj;

  // tenant options.
  tenant_options?: TenantOptionsContent;
  is_tenant_options_loading?: boolean;
  tenant_selection_id?: string;
  tenant_selection?: any;
};

export type TenantPayload = {
  error?: Error;
  tenant_pagination?: TenantPagination;
  tenant_content?: TenantWithCompanyContent;
  limit?: number;
  cursor?: string;
  order_by_direction?: 'asc' | 'desc';
  order_by?: 'name' | 'subdomain';
  search?: string;
  search_field?: 'name' | 'subdomain';
  is_loading?: boolean;
  tenant_content_loading?: boolean;
  tenant_create_loading?: boolean;
  tenant_update_loading?: boolean;
  is_tenant_create_success?: boolean;
  is_tenant_update_success?: boolean;
  tenant_id?: string;
  tenant_request_obj?: TenantCreateRequestObj | TenantUpdateRequestObj;

  // tenant options.
  tenant_options?: TenantOptionsContent;
  is_tenant_options_loading?: boolean;
  tenant_selection_id?: string;
  tenant_selection?: any;
};

export type TenantAction = {
  payload?: TenantPayload;
  type: TenantActionType;
};

export type TenantReducer = (
  state: TenantState, 
  payload?: TenantPayload
) => TenantState;
