import { call, put, takeLatest, select } from 'redux-saga/effects';

// services
import * as companyService from 'src/servicesV2/company';

import { TOAST_SEVERITY } from 'src/@types/toast';

// actions
import actions from './actions';

// selectors
import selector from './selectors';

import ToastAction from 'src/store/Toast/actions';
import { CompanyPagination, CompanyContent, OwnershipTypeContent, CompanyOptionsContent } from 'src/@types/company';
import { CompanyAction, CompanyActionType } from './types';

export function* fetchAllCompanies() {
  const limit: number = yield select(selector.getLimit);
  const cursor: string = yield select(selector.getCursor);
  const search: string = yield select(selector.getSearch);
  const searchField: string = yield select(selector.getSearchField);
  const order_by: string = yield select(selector.getOrderBy);
  const order_by_direction: string = yield select(selector.getOrderByDirection);
  const tenant_id: string = yield select(selector.getTenantId);
  const is_tower_owner: boolean = yield select(selector.getIsTowerOwner);
  const is_vendor: boolean = yield select(selector.getIsVendor);
  try {
    yield put(actions.setLoading(true));
    const response: CompanyPagination = yield call(
      companyService.fetchAllCompanies,
      limit,
      cursor,
      search,
      searchField,
      order_by_direction,
      order_by,
      tenant_id,
      is_tower_owner,
      is_vendor
    );
    yield put(actions.setCompanies(response));
  } catch (e) {
    yield put(actions.setError(e as Error));
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* fetchCompanyContent({ payload }: CompanyAction) {
  try {
    if (!payload || !payload.company_id) {
      return;
    }

    const { company_id } = payload;

    yield put(actions.setCompanyContentLoading(true));
    const response: CompanyContent = yield call(
      companyService.fetchCompanyContent, 
      company_id
    );
    yield put(actions.setCompanyContent(response));
    yield put(actions.setCompanyRequestObj(response));
  } catch (e) {
    yield put(actions.setError(e as Error));
  } finally {
    yield put(actions.setCompanyContentLoading(false));
  }
}

export function* postCompany({ payload }: CompanyAction) {
  if (!payload?.company_request_obj) return;

  yield put(actions.setCompanyCreateLoading(true));

  try {
    const { company_request_obj } = payload;
    const response: CompanyContent = yield call(companyService.postCompany, company_request_obj);

    if (response) {
      yield put(actions.setCompanyCreateSuccess(true));
      yield put(ToastAction.setToast('Create success!' ?? ''));
    } else {
      if (response) yield put(actions.setError(new Error(response)));
      yield put(ToastAction.setToast('Create failed!', TOAST_SEVERITY.ERROR ?? ''));
      return;
    }

    yield call(fetchAllCompanies);
  } catch (e: any) {
    yield put(actions.setError(e));
  } finally {
    yield put(actions.setCompanyCreateLoading(false));
    yield put(ToastAction.clearState());
    yield put(actions.setCompanyCreateSuccess(false));
  }
}

export function* patchCompany({ payload }: CompanyAction) {
  if (!payload || !payload.company_id || !payload.company_request_obj) {
    return;
  }
  
  yield put(actions.setCompanyUpdateLoading(true));
  
  try {
    const { company_request_obj, company_id } = payload;

    const response: CompanyContent = yield call(companyService.patchCompany, company_id, company_request_obj);
    if (response.company?.id) {
      yield put(ToastAction.setToast('Edit success!' ?? ''));
      yield put(actions.setCompanyRequestObj({}));
      yield put(actions.setCompanyUpdateSuccess(true));
    } else {
      yield put(ToastAction.setToast('Edit failed!', TOAST_SEVERITY.ERROR ?? ''));
      return;
    }
  } catch (e: any) {
    yield put(actions.setError(e));
  } finally {
    yield put(actions.setCompanyUpdateLoading(false));
    yield put(ToastAction.clearState());
    yield put(actions.setCompanyUpdateSuccess(false));
  }
}


export function* fetchCompanyOptions() {
  const search: string = yield select(selector.getSearch);
  const is_vendor: boolean = yield select(selector.getIsVendor);
  const is_tower_owner: boolean = yield select(selector.getIsTowerOwner);

  yield put(actions.setCompanyOptionsLoading(true));
  try {
    const response: CompanyOptionsContent = yield call(
      companyService.fetchCompanyOptions,
      search,
      is_vendor,
      is_tower_owner
    );
    yield put(actions.setCompanyOptions(response));
  } catch (e) {
    console.error(e);
  } finally {
    yield put(actions.setCompanyOptionsLoading(false));
  }
}

export function* fetchOwnershipTypes() {
  yield put(actions.setOwnershipTypesLoading(true));
  try {
    const response: OwnershipTypeContent = yield call(
      companyService.fetchOwnershipTypeOptions
    );
    yield put(actions.setOwnershipTypes(response));
  } catch (e) {
    console.error(e);
  } finally {
    yield put(actions.setOwnershipTypesLoading(false));
  }
}


const sagas: any[] = [
  takeLatest(CompanyActionType.FETCH_ALL_COMPANIES, fetchAllCompanies),
  takeLatest(CompanyActionType.FETCH_COMPANY_CONTENT, fetchCompanyContent),
  takeLatest(CompanyActionType.CREATE_COMPANY, postCompany),
  takeLatest(CompanyActionType.UPDATE_COMPANY, patchCompany),
  takeLatest(CompanyActionType.FETCH_COMPANY_OPTIONS, fetchCompanyOptions),
  takeLatest(CompanyActionType.FETCH_OWNERSHIP_TYPES, fetchOwnershipTypes),
];


export default sagas;
