import { Company } from 'src/@types/company';
import React from 'react';
import { ROLE } from './user';



export type SiteSchema = {
  id: string;
  site_list_member_id: string;
  name: string;
  tenant_id: string;
  tenant_name: string;
  owner_id: string;
  owner_name: string;
  owner_site_id: string;
  structure_type_id: string;
  structure_type_name: string;
  asset_class_id: string;
  asset_class_name: string;
  ownership_type_id: string;
  ownership_type_name: string;
  status_id: string;
  short_name: string;
  address_line1: string;
  address_city: string;
  address_state: string;
  postal_code: string;
  county_name: string;
  latitude: number;
  longitude: number;
  access_code: string;
  access_instructions: string;
  structure_height_agl: number;
  flood_zone_designation: string;
  elevation: number;
  elevation_resolution: string;
  date_created: Date;
  region: string;
  client_asset_id: string;
}

export type AssessmentSchema = {
  id: string;
  site_id: string;
  site_list_member_id: string;
  client_site_identifier: string;
  assessment_tag: string;
  uav_operator_id: string;
  uav_operator_name: string;
  drone_vendor_company_id: string;
  drone_vendor_company_name: string;
  last_qc_record_status: string;
  model_geo_referenced: string;
  model_center_at_origin: string;
  date: Date;
  date_created: Date;
  data_received: Date;
  title: string;
  description: string;
  story_url: string;
  tileset_url: string;
  storage: string;
  storage_folder: string;
  storage_bucket: string;
  tenant_id: string;
  tenant_name: string;
  action: string;
}

export type SiteUserRoleSchema = {
  id: string;
  site_id: string;
  site_role_id: string;
  site_role_name: string;
  user_id: string;
  user_full_name: string;
}


export type SiteWithAssessmentAndUserRoleSchema = SiteSchema & {
  assessments?: AssessmentSchema[];
  site_user_roles?: SiteUserRoleSchema[];
}


export type SitePagination = {
  next_page_cursor?: string;
  previous_page_cursor?: string;
  total_count?: number;
  page_size?: number;
  current_page?: number;
  last_page?: number;
  sites?: SiteWithAssessmentAndUserRoleSchema[];
}


export interface StructureTypeSchema {
  id: string;
  name: string;
}

export interface StructureTypeContent {
  structure_types: StructureTypeSchema[];
}

export interface AssetClassSchema {
  id: string;
  name: string;
}

export interface AssetClassContent {
  asset_classes: AssetClassSchema[];
}


//V1

export type SiteRoot = {
  id: string | undefined;
  companySiteId: string | undefined;
  siteId: string | undefined;
  lastUpdateUserId: string | undefined;
  region: string | undefined;
  tenantId: string | undefined;
  site: Site | undefined;
  siteAssessments: SiteAssessment[] | any | undefined;
  sitePeople: SitePeople[] | undefined;
};

export type Site = {
  id: string | undefined;
  ownerId: string | undefined;
  ownerSiteId: string | undefined;
  structureTypeId: string | undefined;
  assetClassId: string | undefined;
  ownershipTypeId: string | undefined;
  statusId: string | undefined;
  shortName: string | undefined;
  addressLine1: string | undefined;
  addressCity: string | undefined;
  addressState: string | undefined;
  postalCode: string | undefined;
  countyName: string | undefined;
  latitude: number | undefined;
  longitude: number | undefined;
  accessCode: string | undefined;
  accessInstructions: string | undefined;
  user: string | undefined;
  structureHeightAgl: number | undefined;
  assetClassName: string | undefined;
  structureTypeName: string | undefined;
  floodZoneDesignation: string | undefined;
  elevation: number | undefined;
  elevationResolution: number | undefined;
  owner: Owner | undefined;
};

export type SiteAssessmentFile = {
  fileFormat: string | undefined;
  filePurpose: string | undefined;
  uri: string | undefined;
};

export type SiteAssessment = {
  clientSiteIdentifier?: string;
  date?: Date;
  statusId?: string;
  storyUrl?: string;
  tilesetUrl?: string;
  modelGeoReferenced?: boolean;
  modelCenterAtOrigin?: boolean;
  id?: string;
  siteListMemberId?: string;
  dataReceived?: string;
  dataType?: string;
  lastUserIdUpdated?: string;
  uavoperatorId?: string;
  processingStatus?: string;
  storage?: string;
  processingWorkerId?: string;
  processingWorkerHostname?: string;
  processingWorkerHostnameIp?: string;
  processingWorkerHostnameExtIp?: string;
  images?: number;
  thumbnails?: number;
  thumbnailsFailed?: number;
  processingCodeVersion?: string;
  lastLogId?: string;
  lastLogTimestamp?: string;
  action?: string;
  settings?: any;
  storageFolder?: string;
  storageBucket?: string;
  assessmentTag?: string;
  viewerUrl?: string;
  lastQcRecordStatus?: string;
  droneVendorCompanyId?: string;
  createdBy?: string;
  qcForms?: string;
  dateCreated?: Date;
  flightAnalysisReportUrl?: string;
  uavOperatorName?: string;
  files?: SiteAssessmentFile[];
  vendor?: Vendor;
  setting?: any;
  title?: string;
  description?: string;
  siteAssessmentImages?: SiteAssessmentImages[];
  tenantId?: string;
  exif?: any | string | null;
  dataTypeNavigation?: string;
  lastUserIdUpdatedNavigation?: string;
  siteListMember?: string;
  tenant?: string;
  uavoperator?: string;
  tilesetSetting?: string;
  cadPrepJobQueues?: any[];
  coldStorageQueues?: any[];
  fileDownloadPermissions?: any[];
  fileDownloadQueues?: any[];
  fileDownloadRequests?: any[];
  fileTransferRequests?: any[];
  fileUploadLogs?: any[];
  fileUploadRequests?: any[];
  otiqrevisions?: any[];
  processSettings?: any[];
  scaleCoefficients?: any[];
  droneVendorCompany?: string;
};

export type SiteAssessmentImages = {
  id: string;
  siteAssessmentId: string;
  folder: string;
  file: string;
  thumbnail: boolean;
  failed: boolean;
  index: string | undefined;
  points: TiePoints | undefined;
  latitude: number;
  longitude: number;
  absoluteAltitude: number;
  relativeAltitude: number;
  gimbalYawDegree: number;
  exif: Object | string | null;
  exifJson: string;
  createdDate: string;
  exifDateCreated: string;
  imageMetadataRequests: [];
};
export type TiePoints = {
  ax: string;
  ay: string;
  bx: string;
  by: string;
  id?: string; // used for API v2
  // @TODO remove the unused format
};

export type Vendor = {
  id: string | undefined;
  companyId: string | undefined;
  name: string | undefined;
  userUpdateId: string | undefined;
};

export type SitePeople = {
  id?: string;
  siteListMemberId?: string;
  siteListMemberRoleId?: string;
  userId?: string;
  userUpdateId?: string;
  siteListMemberRole?: SiteListMemberRole;
  userInfo?: UserInfo;
};

export type SiteListMemberRole = {
  id: string | undefined;
  companyId: string | undefined;
  name: string | undefined;
  userUpdateId: string | undefined;
};

export type UserInfo = {
  id: string | undefined;
  companyId: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  phoneNumber: string | undefined;
  email: string | undefined;
  role: string | undefined;
  userId: string | undefined;
  phoneVerified: string | undefined;
  loginEnabled: string | undefined;
  invited: string | undefined;
  currentCompanyId: string | undefined;
  fullName: string | undefined;
  companyName: string | undefined;
  company: Company | undefined;
  dateCreated: Date | undefined;
};

export type Owner = {
  id: string | undefined;
  name: string | undefined;
  shortName: string | undefined;
  abbreviation: string | undefined;
  website: string | undefined;
  user: string | undefined;
  accountsPayableEmail: string | undefined;
  billToAddress: string | undefined;
  accountsReceivableEmail: string | undefined;
  billFromAddress: string | undefined;
  usersCount: string | undefined;
  isVendor?: boolean;
  isTowerOwner?: boolean;
  tenantName: string;
  tenantId: string;
};

export enum SiteImagesFolders {
  Accessroad = 'accessroad',
  Compound = 'compound',
  Drone = 'drone',
  Facade = 'facade',
  Ground = 'ground',
  Hotspot = 'hotspot',
  Panorama = 'panorama',
  Rooftop = 'rooftop',
  Tower = 'tower',
}

export type SiteImage = {
  absoluteAltitude: number;
  exif: Object | string | null;
  timestamp:  null;
  folder: SiteImagesFolders;
  originalFolderName: string;
  gymbal: number;
  id: string;
  pictureFile: string;
  pictureLargeLink: string;
  pictureLink: string;
  points: TiePoints | null;
  relativeAltitude: number;
  thumbnail: string;
  color?: string;
};

export type SiteImageSummary = {
  file_path_summary: any,
  images_count: number,
  sample_image: SiteImage,
};

export type Direction = {
  degrees: string | undefined;
  localized: string | undefined;
  english: string | undefined;
};

export type Speed = {
  value: number | undefined;
  unit: string | undefined;
};

export type Wind = {
  direction: Direction | undefined;
  speed: Speed | undefined;
};

export type Day = {
  shortPhrase: string | undefined;
  icon: string | undefined;
  wind: Wind | undefined;
};

export type TemperatureUnit = {
  value: number | undefined;
  unit: string | undefined;
};

export type Temperature = {
  maximum: TemperatureUnit | undefined;
  minimum: TemperatureUnit | undefined;
};

export type DailyForecast = {
  date: string | undefined;
  weekdayName?: string;
  dateString?: string;
  day: Day | undefined;
  temperature: Temperature | undefined;
};



export enum TAB_KEYS {
  TAB_2D_KEY = 'TAB_2D_KEY',
  TAB_3D_KEY = 'TAB_3D_KEY',
  TAB_PHOTOS_KEY = 'TAB_PHOTOS_KEY',
  TAB_PANORAMA_KEY = 'TAB_PANORAMA_KEY',
  TAB_MAP_KEY = 'TAB_MAP_KEY',
  TAB_WEATHER_KEY = 'TAB_WEATHER_KEY',
  TAB_DETAILS_KEY = 'TAB_DETAILS_KEY',
  TAB_VR2D_KEY = 'TAB_VR2D_KEY',
  TAB_FLIGHT_REPORT_KEY = 'TAB_FLIGHT_REPORT_KEY',
  TAB_FILES_KEY = 'TAB_FILES_REPORT_KEY',
  TAB_APPURTENANCES_KEY = 'TAB_APPURTENANCES_KEY',
  TAB_IMAGE_LABELING_KEY = 'TAB_IMAGE_LABELING_KEY',
  TAB_UPLOAD_PROGRESS_KEY = 'TAB_UPLOAD_PROGRESS_KEY',
}

export enum TAB_SITE_KEYS {
  TAB_ASSET_PROFILE_KEY = 'TAB_ASSET_PROFILE_KEY',
  TAB_3D_KEY = 'TAB_3D_KEY',
  TAB_PHOTOS_KEY = 'TAB_PHOTOS_KEY',
  TAB_TIE_POINTS = 'TAB_TIE_POINTS',
  TAB_QUEUE = 'TAB_QUEUE',
  TAB_QC = 'TAB_QC',
  TAB_DELIVERABLES = 'Tab_DELIVERABLES',
}

export type SiteTab = {
  key: TAB_KEYS;
  label: string;
  icon: React.ReactNode;
  ariaControls: string;
  anchorLink: string;
  mobile?: boolean;
  role?: ROLE;
};

export enum QC_RECORD_STATUS {
  PASSED = 'passed',
  FAILED = 'failed',
  UNKNOWM = 'unknown',
}

// Used to define id for each site column in a table.
// The site table column need an id to use the sort api feature.
export enum SITE_COLUMNS {
  COMPANY_SITE_ID = 'name',
  TENANT_NAME = 'tenant_name',
  OWNER_SITE_ID = 'owner_site_id',
  STRUCTURE = 'Structure',
  ASSET = 'Asset',
  CAPTURES = 'Captures',
  LOCATION = 'Location',
  ADDRESS_STATE = 'address_state',
  QUICK_LINKS = 'QuickLinks',
  ELEVATION = 'Elevation',
  FLOOD_ZONE_DESIGNATION = 'FloodZoneDesignation',
  STRUCTURE_HEIGHT_AGL = 'StructureHeightAgl',
  EDIT = 'Edit',
}

export type AssetsNearby = {
  type?: string;
  geometry?: {
    type?: string;
    coordinates?: number[];
  };
  properties?: {
    siteID?: string;
    ownerSiteId?: number;
    distance?: number;
    icon?: {
      iconUrl?: string;
      iconSize?: number[];
      iconAnchor?: number[];
      popupAnchor?: number[];
      className?: string;
    };
  };
};

export type SiteAssessmentStaging = {
  clientSiteIdentifier?: string;
};

export type CreateEditSiteInitialState = {
  companySiteId?: string;
  site: {
    ownerSiteId?: string;
    structureTypeId?: string;
    assetClassId?: string;
    ownershipTypeId?: string;
    structureHeightAgl?: number;
    addressLine1?: string;
    addressCity?: string;
    addressState?: string;
    postalCode?: string;
    countyName?: string;
    latitude?: number;
    longitude?: number;
    accessCode?: string;
    accessInstructions?: string;
    owner?: any;
    ownerId?: string;
  };
  region?: string;
  sitePeople?: SitePeople[];
  siteAssessments?: any[];
};
