import { Onboarding } from "src/@types/auth";

export enum AuthenticationActionType {
  LOGIN = 'Authentication@LOGIN',
  REQUEST_RESET_PASSWORD = 'Authentication@REQUEST_RESET_PASSWORD',
  RESET_PASSWORD = 'Authentication@RESET_PASSWORD',
  SET_LOADING = 'Authentication@SET_LOADING',
  SET_EMAIL_SENT = 'Authentication@SET_EMAIL_SENT',
  SET_PASSWORD_RESET_ERROR = 'Authentication@SET_PASSWORD_RESET_ERROR',
  SET_PASSWORD_RESET_SUCCESS = 'Authentication@SET_PASSWORD_RESET_SUCCESS',
  SET_AUTHENTICATED = 'Authentication@SET_AUTHENTICATED',
  SET_ERROR = 'Authentication@SET_ERROR',
  SET_INITIALIZED = 'Authentication@SET_INITIALIZED',
  INITIALIZE = 'Authentication@INITIALIZE',
  ON_LOGIN = 'Authentication@ON_LOGIN',
  POST_ONBOARD_USER = 'Authentication@POST_ONBOARD_USER',
  SET_ONBOARDING = 'Authentication@SET_ONBOARDING',
  SET_ONBOARDING_ERROR = 'Authentication@SET_ONBOARDING_ERROR',
  LOGOUT = 'Authentication@LOGOUT',
  CLEAR = 'Authentication@CLEAR',
}

export type AuthenticationState = {
  loading: boolean;
  emailSent: boolean;
  passwordReset: boolean;
  error: undefined | Error;
  authenticated: boolean;
  initialized: boolean;
  onBoarding: Onboarding;
  onboardingError?: string;
  passwordResetError?: string;
};

export type AuthenticationPayload = {
  email?: string;
  password?: string;
  loading?: boolean;
  emailSent?: boolean;
  passwordReset?: boolean;
  error?: undefined | Error;
  authenticated?: boolean;
  initialized?: boolean;
  token?: string;
  refreshToken?: string;
  passwordResetToken?: string;
  onBoarding?: Onboarding;
  onboardingError?: string;
  passwordResetError?: string;
};

export type AuthenticationAction = {
  payload?: AuthenticationPayload;
  type: AuthenticationActionType;
};

export type AuthenticationReducer = (
  state: AuthenticationState,
  payload?: AuthenticationPayload
) => AuthenticationState;

export enum AuthenticationError {
  LOGIN = 'Login',
  REGISTER = 'Register',
  VALIDATE = 'Validate'
}