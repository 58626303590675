const apiUrl = process.env.REACT_APP_STAFF_V2_API_ENDPOINT;

const endpoints = {
  ticket: `${apiUrl}/api/ticket/`,
  ticket_issues: `${apiUrl}/api/ticket/issue/`,
  ticket_status: `${apiUrl}/api/ticket/status/`,
};

export default endpoints;

