import { ImportJob, ImportJobsAction, ImportJobsActionType } from './types';

const actions = {
  fetchImportJobs: (): ImportJobsAction => ({
    type: ImportJobsActionType.FETCH_IMPORT_JOBS,
  }),
  setImportJobs: (importJobs?: ImportJob[]): ImportJobsAction => ({
    payload: { importJobs },
    type: ImportJobsActionType.SET_IMPORT_JOBS,
  }),
  setImportJobsLoading: (importJobsLoading: boolean): ImportJobsAction => ({
    payload: { importJobsLoading },
    type: ImportJobsActionType.SET_IMPORT_JOBS_LOADING,
  }),
  setImportJobsError: (importJobsError: Error): ImportJobsAction => ({
    payload: { importJobsError },
    type: ImportJobsActionType.SET_IMPORT_JOBS_ERROR,
  }),
  setColumn: (column: string): ImportJobsAction => ({
    payload: { column },
    type: ImportJobsActionType.SET_COLUMN,
  }),
  setSortBy: (sortBy: "asc" | "desc"): ImportJobsAction => ({
    payload: { sortBy },
    type: ImportJobsActionType.SET_SORTBY,
  }),
};

export default actions;
