import {
  SiteSearchQueryAction,
  SiteSearchQueryActionType,
  SiteSearchQueryReducer,
  SiteSearchQueryPayload,
  SiteSearchQueryState,
} from './types';

export const initialState: SiteSearchQueryState = {
  isQuickSearchLoading: false,
  quickSearchQuery: {
    limit: 15,
    search: undefined,
    order_by_direction: undefined,
    order_by: undefined,
    keyword_search: undefined,
  },
  quickSearchQueryResult: undefined,
  isSearchLoading: false,
  searchQuery: {
    limit: 15,
    cursor: undefined,
    search: undefined,
    search_field: undefined,
    order_by_direction: undefined,
    order_by: undefined,
    keyword_search: '',
    search_terms: '',
    asset_classes: [],
    structure_types: [],
    tenant_id: '',
    tower_owners: [],
    assessment_tags: [],
    flood_zone_designations: [],
    drone_vendors: [],
    structure_height_from: 0,
    structure_height_to: 0,
    is_lease_space: undefined,
    qc_status: undefined,
    user_id: '',
    only_sites_with_assessments: false,
  },
  searchQueryResult: undefined,

  // select options.
  isSelectOptionsLoading: false,
  assetClassesSelectOptions: undefined,
  structureTypesSelectOptions: undefined,
  towerOwnersSelectOptions: undefined,
  vendorsSelectOptions: undefined,
};

const setQuickSearchLoading: SiteSearchQueryReducer = (
  state: SiteSearchQueryState,
  payload?: SiteSearchQueryPayload
) => ({
  ...state,
  isQuickSearchLoading:
    payload && payload.isQuickSearchLoading !== undefined
      ? payload.isQuickSearchLoading
      : state.isQuickSearchLoading,
});

const setQuickSearchQuery: SiteSearchQueryReducer = (
  state: SiteSearchQueryState,
  payload?: SiteSearchQueryPayload
) => ({
  ...state,
  quickSearchQuery:
    payload && payload.quickSearchQuery ? payload.quickSearchQuery : state.quickSearchQuery,
});

const setQuickSearchQueryResult: SiteSearchQueryReducer = (
  state: SiteSearchQueryState,
  payload?: SiteSearchQueryPayload
) => ({
  ...state,
  quickSearchQueryResult:
    payload && payload.quickSearchQueryResult
      ? payload.quickSearchQueryResult
      : state.quickSearchQueryResult,
});

const setQuickSearchQueryClear: SiteSearchQueryReducer = (state: SiteSearchQueryState) => ({
  ...state,
  isQuickSearchLoading: false,
  quickSearchQuery: initialState.quickSearchQuery,
  quickSearchQueryResult: undefined,
});

const setSearchLoading: SiteSearchQueryReducer = (
  state: SiteSearchQueryState,
  payload?: SiteSearchQueryPayload
) => ({
  ...state,
  isSearchLoading:
    payload && payload.isSearchLoading !== undefined
      ? payload.isSearchLoading
      : state.isSearchLoading,
});

const setSearchQuery: SiteSearchQueryReducer = (
  state: SiteSearchQueryState,
  payload?: SiteSearchQueryPayload
) => ({
  ...state,
  searchQuery: payload && payload.searchQuery ? payload.searchQuery : state.searchQuery,
});

const setSearchQueryResult: SiteSearchQueryReducer = (
  state: SiteSearchQueryState,
  payload?: SiteSearchQueryPayload
) => ({
  ...state,
  searchQueryResult:
    payload && payload.searchQueryResult ? payload.searchQueryResult : state.searchQueryResult,
});

const setSearchQueryClear: SiteSearchQueryReducer = (state: SiteSearchQueryState) => ({
  ...state,
  isSearchLoading: false,
  searchQuery: initialState.searchQuery,
  searchQueryResult: undefined,
});

// select options.

const setSelectOptionsLoading: SiteSearchQueryReducer = (
  state: SiteSearchQueryState,
  payload?: SiteSearchQueryPayload
) => ({
  ...state,
  isSelectOptionsLoading:
    payload && payload.isSelectOptionsLoading !== undefined
      ? payload.isSelectOptionsLoading
      : state.isSelectOptionsLoading,
});

const setSearchQuerySelectOptions: SiteSearchQueryReducer = (
  state: SiteSearchQueryState,
  payload?: SiteSearchQueryPayload
) => ({
  ...state,
  assetClassesSelectOptions:
    payload && payload.assetClassesSelectOptions
      ? payload.assetClassesSelectOptions
      : state.assetClassesSelectOptions,
  structureTypesSelectOptions:
    payload && payload.structureTypesSelectOptions
      ? payload.structureTypesSelectOptions
      : state.structureTypesSelectOptions,
  towerOwnersSelectOptions:
    payload && payload.towerOwnersSelectOptions
      ? payload.towerOwnersSelectOptions
      : state.towerOwnersSelectOptions,
  vendorsSelectOptions:
    payload && payload.vendorsSelectOptions
      ? payload.vendorsSelectOptions
      : state.vendorsSelectOptions,
});

const clearState: SiteSearchQueryReducer = () => ({
  ...initialState,
});

const reducerMap = new Map<SiteSearchQueryActionType, SiteSearchQueryReducer>([
  [SiteSearchQueryActionType.SET_QUICK_SEARCH_LOADING, setQuickSearchLoading],
  [SiteSearchQueryActionType.SET_QUICK_SEARCH_QUERY, setQuickSearchQuery],
  [SiteSearchQueryActionType.SET_QUICK_SEARCH_QUERY_RESULT, setQuickSearchQueryResult],
  [SiteSearchQueryActionType.SET_QUICK_SEARCH_QUERY_CLEAR, setQuickSearchQueryClear],
  [SiteSearchQueryActionType.SET_SEARCH_LOADING, setSearchLoading],
  [SiteSearchQueryActionType.SET_SEARCH_QUERY, setSearchQuery],
  [SiteSearchQueryActionType.SET_SEARCH_QUERY_RESULT, setSearchQueryResult],
  [SiteSearchQueryActionType.SET_SEARCH_QUERY_CLEAR, setSearchQueryClear],

  // select options.
  [SiteSearchQueryActionType.SET_SELECT_OPTIONS_LOADING, setSelectOptionsLoading],
  [SiteSearchQueryActionType.SET_SEARCH_QUERY_SELECT_OPTIONS, setSearchQuerySelectOptions],

  [SiteSearchQueryActionType.CLEAR, clearState],
]);

const reducer = (
  state: SiteSearchQueryState = initialState,
  action: SiteSearchQueryAction
): SiteSearchQueryState => {
  const reducer = reducerMap.get(action.type);
  if (reducer) return reducer(state, action.payload);
  return state;
};

export default reducer;
