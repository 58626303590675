// @mui
import { Box, CircularProgress, Typography } from "ca-react-component-lib";

const SitesLoading = () => (
  <Box sx={{ textAlign: 'center' }}>
    <CircularProgress />
    <Typography
      variant="body1"
      sx={{ mt: 2 }}
      gutterBottom
    >
      Searching for results...
    </Typography>
  </Box>
);

export default SitesLoading;