const apiUrl = process.env.REACT_APP_STAFF_V2_API_ENDPOINT;

const endpoints = {
  user: `${apiUrl}/api/user/`,
  user_profile: `${apiUrl}/api/user/profile/`,
  user_options: `${apiUrl}/api/user/options/`,
  user_roles: `${apiUrl}/api/user/roles/`,
  user_invite: `${apiUrl}/api/user/invite/`,
  user_settings: `${apiUrl}/api/user/settings/`,
  user_bulk_insert: `${apiUrl}/api/user/bulk-create-excel-or-csv/`,
  user_bulk_template: `${apiUrl}/api/user/bulk-create-template/`,
  roles: `${apiUrl}/api/user/role/options/`,
};

export default endpoints;
