import { call, put, select, takeLatest } from 'redux-saga/effects';

// types
import { TicketContent, TicketPagination, TicketStatusContent } from 'src/@types/ticket';
import { TicketAction, TicketActionType } from './types';

// service
import * as ticketService from 'src/servicesV2/ticket';

//toast
import ToastAction from 'src/store/Toast/actions';

// actions
import actions from './actions';

//selectors
import selectors from './selectors';
import { TOAST_SEVERITY } from 'src/@types/toast';


export function* fetchAllTickets() {
  const limit: number = yield select(selectors.getLimit);
  const cursor: string = yield select(selectors.getCursor);
  const orderBy: string = yield select(selectors.getOrderBy);
  const orderByDirection: string = yield select(selectors.getOrderByDirection);
  const searchField: string = yield select(selectors.getSearchField);
  const search: string = yield select(selectors.getSearch);
  const statusId: string = yield select(selectors.getStatusId);

  try {
    yield put(actions.setLoading(true));
    const response: TicketPagination = yield call(
      ticketService.fetchAllTickets,
      limit,
      cursor,
      search,
      searchField,
      orderByDirection,
      orderBy,
      statusId
    );
    yield put(actions.setTicket(response));
  } catch (e) {
    yield put(actions.setError(e as Error));
  } finally {
    yield put(actions.setLoading(false));
  }
}


export function* fetchTicketStatus() {
  try {
    yield put(actions.setLoading(true));
    const response: TicketStatusContent = yield call(ticketService.fetchTicketStatus);
    yield put(actions.setTicketStatus(response));
  } catch (e) {
    yield put(actions.setError(e as Error));
  } finally {
    yield put(actions.setLoading(false));
  }
}


export function* patchTicket({ payload }: TicketAction) {
  if (
    !payload || 
    !payload.ticket_id ||
    !payload.patch_ticket
  )
    return;
  try {
    yield put(actions.setLoading(true));
    const { ticket_id, patch_ticket } = payload;
    const response: TicketContent = yield call(
      ticketService.patchTicket,
      ticket_id,
      patch_ticket
    );
    if (response.ticket?.id) {
      yield put(ToastAction.setToast('Edit success!' ?? ''));
    } else {
      yield put(ToastAction.setToast('Edit failed!', TOAST_SEVERITY.ERROR ?? ''));
      return;
    }
  } catch (e) {
    yield put(actions.setError(e as Error));
  } finally {
    yield put(actions.setLoading(false));
  }
}



const sagas = [
  takeLatest(TicketActionType.FETCH_TICKET_STATUS, fetchTicketStatus),
  takeLatest(TicketActionType.FETCH_ALL_TICKETS, fetchAllTickets),
  takeLatest(TicketActionType.PATCH_TICKET, patchTicket),
];

export default sagas;
