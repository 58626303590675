import { AssetClassContent, SitePagination, StructureTypeContent } from 'src/@types/site';
import { SiteSearchQueryAction, SiteSearchQueryActionType } from './types';
import { CompanyOptionsContent } from 'src/@types/company';

const actions = {
  // Quick search sites dialog.
  setQuickSearchLoading: (isQuickSearchLoading: boolean): SiteSearchQueryAction => ({
    payload: { isQuickSearchLoading },
    type: SiteSearchQueryActionType.SET_QUICK_SEARCH_LOADING,
  }),

  setQuickSearchQuery: (quickSearchQuery: any): SiteSearchQueryAction => ({
    payload: { quickSearchQuery },
    type: SiteSearchQueryActionType.SET_QUICK_SEARCH_QUERY,
  }),

  setQuickSearchQueryResult: (
    quickSearchQueryResult: SitePagination
  ): SiteSearchQueryAction => ({
    payload: { quickSearchQueryResult },
    type: SiteSearchQueryActionType.SET_QUICK_SEARCH_QUERY_RESULT,
  }),

  setQuickSearchQueryClear: (): SiteSearchQueryAction => ({
    type: SiteSearchQueryActionType.SET_QUICK_SEARCH_QUERY_CLEAR,
  }),

  setSearchLoading: (isSearchLoading: boolean): SiteSearchQueryAction => ({
    payload: { isSearchLoading },
    type: SiteSearchQueryActionType.SET_SEARCH_LOADING,
  }),

  setSearchQuery: (searchQuery: any): SiteSearchQueryAction => ({
    payload: { searchQuery },
    type: SiteSearchQueryActionType.SET_SEARCH_QUERY,
  }),

  setSearchQueryResult: (searchQueryResult: SitePagination): SiteSearchQueryAction => ({
    payload: { searchQueryResult },
    type: SiteSearchQueryActionType.SET_SEARCH_QUERY_RESULT,
  }),

  setSearchQueryClear: (): SiteSearchQueryAction => ({
    type: SiteSearchQueryActionType.SET_SEARCH_QUERY_CLEAR,
  }),

  // select options.
  setSelectOptionsLoading: (isSelectOptionsLoading: boolean): SiteSearchQueryAction => ({
    payload: { isSelectOptionsLoading },
    type: SiteSearchQueryActionType.SET_SELECT_OPTIONS_LOADING,
  }),

  getSearchQuerySelectOptions: (): SiteSearchQueryAction => ({
    type: SiteSearchQueryActionType.GET_SEARCH_QUERY_SELECT_OPTIONS,
  }),

  setSearchQuerySelectOptions: (
    assetClassesSelectOptions: AssetClassContent,
    structureTypesSelectOptions: StructureTypeContent,
    towerOwnersSelectOptions: CompanyOptionsContent,
    vendorsSelectOptions: CompanyOptionsContent
  ): SiteSearchQueryAction => ({
    payload: {
      assetClassesSelectOptions,
      structureTypesSelectOptions,
      towerOwnersSelectOptions,
      vendorsSelectOptions,
    },
    type: SiteSearchQueryActionType.SET_SEARCH_QUERY_SELECT_OPTIONS,
  }),

  clearState: (): SiteSearchQueryAction => ({
    type: SiteSearchQueryActionType.CLEAR,
  }),
};

export default actions;
