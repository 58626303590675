import { put, call } from 'redux-saga/effects';

// actions
import actions from '../actions';

// services
import * as siteServices from 'src/servicesV2/site';

// types
import { AssetClassContent } from 'src/@types/site';

export function* fetchAssetClassSelectOptions() {
  try {
    yield put(actions.setisAssetClassSelectOptionsLoading(true));
    const response: AssetClassContent = yield call(siteServices.fetchAssetClassOptions);
    yield put(actions.setAssetClassSelectOptions(response));
  } catch (e) {
    yield put(actions.setError(e as Error));
  } finally {
    yield put(actions.setisAssetClassSelectOptionsLoading(false));
  }
}