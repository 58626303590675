// routes
import Router from './routes';

// theme
import { ThemeProvider, NotistackContext, ProgressBarStyle } from 'ca-react-component-lib';

// mapbox
import mapboxgl from 'mapbox-gl';

// components
import { GoogleAnalytics, ScrollToTop } from './components';
import TableDrawer from './components/TableDrawer';
import Toast from './components/Toast';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOXGL_API_KEY || '';

export default function App() {
  return (
    <ThemeProvider>
      <NotistackContext>
        <ProgressBarStyle />
        <Toast />
        <ScrollToTop />
        <Router />
        <GoogleAnalytics />
        <TableDrawer />
      </NotistackContext>
    </ThemeProvider>
  );
}
