import { ApplicationState } from '../types';

const _getData = (state: ApplicationState) => state.Authentication;

const isAuthenticated = (state: ApplicationState) => _getData(state).authenticated;

const isInitialized = (state: ApplicationState) => _getData(state).initialized;

const getError = (state: ApplicationState) => _getData(state).error;

const isLoading = (state: ApplicationState) => _getData(state).loading;

const isEmailSent = (state: ApplicationState) => _getData(state).emailSent;

const isPasswordReset = (state: ApplicationState) => _getData(state).passwordReset;

const getOnboarding = (state: ApplicationState) => _getData(state).onBoarding;

const getOnboardingError = (state: ApplicationState) => _getData(state).onboardingError;

const getPasswordResetError = (state: ApplicationState) => _getData(state).passwordResetError;

const selectors = {
  isAuthenticated,
  isInitialized,
  getError,
  isLoading,
  isEmailSent,
  isPasswordReset,
  getOnboarding,
  getOnboardingError,
  getPasswordResetError,
};

export default selectors;
