import { CompanyUserPagination } from 'src/@types/company';

export enum ManageAccessActionType {
  FETCH_ALL_COMPANY_USERS = 'ManageAccess@FETCH_ALL_COMPANY_USERS',
  SET_ALL_COMPANY_USER = 'ManageAccess@SET_ALL_COMPANY_USER',
  SET_LIMIT = 'ManageAccess@SET_LIMIT',
  SET_CURSOR = 'ManageAccess@SET_CURSOR',
  SET_ORDERBY = 'ManageAccess@SET_ORDERBY',
  SET_ORDERBY_DIRECTION = 'ManageAccess@SET_ORDERBY_DIRECTION',
  SET_SEARCH = 'ManageAccess@SET_SEARCH',
  SET_SEARCH_FIELD = 'ManageAccess@SET_SEARCH_FIELD',
  SET_COMPANY_ID = 'ManageAccess@SET_COMPANY_ID',
  FETCH_COMPANIES_SIMPLE = 'ManageAccess@FETCH_COMPANIES_SIMPLE',
  SET_MANAGE_ACCESS_TABLE_COMPANY_ID = 'ManageAccess@SET_MANAGE_ACCESS_TABLE_COMPANY_ID',
  SET_IS_MANAGE_ACCESS_TABLE_LOADING = 'ManageAccess@SET_IS_MANAGE_ACCESS_TABLE_LOADING',
  SET_MANAGE_ACCESS_SIDE_COLUMN_COMPANY_ID = 'ManageAccess@SET_MANAGE_ACCESS_SIDE_COLUMN_COMPANY_ID',
  SET_GIVE_ACCESS_USER_LIST = 'ManageAccess@SET_GIVE_ACCESS_USER_LIST',
  GIVE_COMPANY_ACCESS = 'ManageAccess@GIVE_COMPANY_ACCESS',
  CLEAR_ALL_COMPANY_USER = 'ManageAccess@CLEAR_ALL_COMPANY_USER',
  CLEAR_MANAGE_ACCESS_TABLE_COMPANY_ID = 'ManageAccess@CLEAR_MANAGE_ACCESS_TABLE_COMPANY_ID',
  SET_IS_EXTERNAL = 'ManageAccess@SET_IS_EXTERNAL',
  UPDATE_REVOKE_USER_LIST = 'ManageAccess@UPDATE_REVOKE_USER_LIST',
  SET_REVOKE_USER_LIST = 'ManageAccess@SET_REVOKE_USER_LIST',
  REVOKE_SINGLE_USER_ACCESS = 'ManageAccess@REVOKE_SINGLE_USER_ACCESS',
  REVOKE_BULK_USER_ACCESS = 'ManageAccess@REVOKE_BULK_USER_ACCESS',
  SET_ERROR = 'ManageAccess@SET_ERROR',
  CLEAR = 'ManageAccess@CLEAR',
}

export type ManageAccessState = {
  error?: Error;
  company_users_pagination?: CompanyUserPagination;
  limit?: number;
  cursor?: string;
  search?: string;
  search_field?: 'user_full_name' |'user_email' | 'company_name' |'user_company_name';
  order_by_direction?: 'asc' | 'desc';
  order_by?: 'user_full_name' | 'company_name' | 'user_company_name';
  company_id?: string;
  is_manage_access_table_loading?: boolean;
  manage_access_table_company_id?: string;
  manage_access_side_column_company_id?: string;
  give_access_user_list?: string[];
  is_external?: boolean;
  revoke_user_list?: string[];
};

export type ManageAccessPayload = {
  error?: Error;
  company_users_pagination?: CompanyUserPagination;
  limit?: number;
  cursor?: string;
  search?: string;
  search_field?: 'user_full_name' |'user_email' | 'company_name' |'user_company_name';
  order_by_direction?: 'asc' | 'desc';
  order_by?: 'user_full_name' | 'company_name' | 'user_company_name';
  company_id?: string;
  manage_access_table_company_id?: string;
  manage_access_side_column_company_id?: string;
  revoke_user_list?: string[];
  is_manage_access_table_loading?: boolean;
  give_access_user_list?: string[];
  is_external?: boolean;
  update_revoke_user?: string;
  revoke_single_user_id?: string;
};

export type ManageAccessAction = {
  payload?: ManageAccessPayload;
  type: ManageAccessActionType;
};

export type ManageAccessReducer = (
  state: ManageAccessState,
  payload?: ManageAccessPayload
) => ManageAccessState;
