import request from 'src/services/request';
import endpoints from './endpoints';

import { UserBodyRequestSchema } from './types';

const api = {
  fetchAllUsers: async (
    limit: number, 
    cursor?: string, 
    search?: string, 
    search_field?: string, 
    order_by_direction?: string, 
    order_by?: string,
    company_id?: string,
    exclude_invited?: boolean,
  ) => {
    let queryString = `?limit=${limit}`;
    
    if (cursor) queryString += `&cursor=${cursor}`;
    if (search) queryString += `&search=${search}`;
    if (search_field) queryString += `&search_field=${search_field}`;
    if (order_by_direction) queryString += `&order_by_direction=${order_by_direction}`;
    if (order_by) queryString += `&order_by=${order_by}`;
    if (company_id) queryString += `&company_id=${company_id}`;
    if (exclude_invited) queryString += `&exclude_invited=${exclude_invited}`;

    return request(`${endpoints.user}${queryString}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
  },
  fetchUserOptions: async (
    search?: string,
    min_role_level?: number,
    max_role_level?: number,
  ) => {
    const queryParams: string[] = [];
    if (search) queryParams.push(`search=${search}`);
    if (min_role_level) queryParams.push(`min_role_level=${min_role_level}`);
    if (max_role_level) queryParams.push(`max_role_level=${max_role_level}`);

    const queryString = queryParams.length ? `?${queryParams.join('&')}` : '';
  
    return request(`${endpoints.user_options}${queryString}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    });
  },
  fetchUserContent: async (user_id: string) =>
    request(`${endpoints.user}${user_id}/`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }),
  postUser: async (payload: UserBodyRequestSchema) =>
    request(`${endpoints.user}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    }),
  patchUser: async (user_id: string, payload: UserBodyRequestSchema) =>
    request(`${endpoints.user}${user_id}/`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    }),
  fetchUserProfile: async () =>
    request(endpoints.user_profile, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }),
  fetchRoles: async () =>
    request(endpoints.user_roles, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }),

  //Bulk User
  fetchExcelTemplate: async () =>
    request(
      `${endpoints.user_bulk_template}excel/`,
      {
        method: 'GET',
      },
      'arraybuffer'
    ),
  bulkInsert: async (payload: any) => {
    try {
      let queryString = '';
      if (payload.company_id) queryString += `?company_id=${payload.company_id}`;
  
      return await request(`${endpoints.user_bulk_insert}${queryString}`, {
        method: 'POST',
        body: payload.file,
      });
    } catch (error) {
      throw error;
    }
  },
  inviteUsers: async(payload: any) =>
    request(`${endpoints.user_invite}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'user_ids': payload }),
    }),
  // roles
  fetchRoleOptions: async () =>
    request(`${endpoints.roles}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    }),
};

export default api;