import request from "src/services/request";
import endpoints from "./endpoints";
import { TicketBodyRequestSchema } from "./type";

const api = {
  fetchAllTickets: async (
    limit: number, 
    cursor?: string, 
    search?: string, 
    search_field?: string, 
    order_by_direction?: string, 
    order_by?: string,
    status_id?: string
  ) => {
    let queryString = `?limit=${limit}`;
    
    if (cursor) queryString += `&cursor=${cursor}`;
    if (search) queryString += `&search=${search}`;
    if (search_field) queryString += `&search_field=${search_field}`;
    if (order_by_direction) queryString += `&order_by_direction=${order_by_direction}`;
    if (order_by) queryString += `&order_by=${order_by}`;
    if (status_id) queryString += `&status_id=${status_id}`;

    return request(`${endpoints.ticket}${queryString}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
  },
  patchTicket: async (ticket_id: string, payload: TicketBodyRequestSchema) =>
    request(`${endpoints.ticket}${ticket_id}/`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    }),

  fetchTicketStatus: async () => request(`${endpoints.ticket_status}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  })
};

export default api;