// hooks
import useProfile from 'src/hooks/useProfile';

// components
import { Avatar, useTheme } from 'ca-react-component-lib';

export default function MyAvatar() {
  const { displayName } = useProfile();
  const theme = useTheme();

  return (
    <Avatar
      sx={{
        bgcolor: theme.palette.grey[350],
        color: theme.palette.text.alternative,
        width: 32,
        height: 32
      }}
    >
      {displayName && displayName.charAt(0).toUpperCase()}
    </Avatar>
  );
}
