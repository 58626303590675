import { 
  TicketAction, 
  TicketActionType, 
  TicketPayload, 
  TicketReducer, 
  TicketState 
} from "./types";

export const initialState: TicketState = {
  error: undefined,
  ticket_pagination: undefined,
  is_loading: false,
  limit: 15,
  cursor: undefined,
  order_by: 'date_created',
  order_by_direction: 'desc',
  search_field: undefined,
  search: undefined,
  status_id: undefined,
  ticket_status: undefined,
  popover_opened: false,
};

const setTicket: TicketReducer = (
  state: TicketState,
  payload?: TicketPayload
) => ({
  ...state,
  ticket_pagination: (payload && payload.ticket_pagination) ?? state.ticket_pagination,
});


const setLimit: TicketReducer = (
  state: TicketState,
  payload?: TicketPayload
) => ({
  ...state,
  limit: (payload && payload.limit) ?? state.limit,
});

const setCursor: TicketReducer = (
  state: TicketState,
  payload?: TicketPayload
) => ({
  ...state,
  cursor: (payload && payload.cursor) ?? state.cursor,
});

const setOrderBy: TicketReducer = (
  state: TicketState,
  payload?: TicketPayload
) => ({
  ...state,
  order_by: (payload && payload.order_by) ?? state.order_by,
});

const setOrderByDirection: TicketReducer = (
  state: TicketState,
  payload?: TicketPayload
) => ({
  ...state,
  order_by_direction: (payload && payload.order_by_direction) ?? state.order_by_direction,
});

const setSearch: TicketReducer = (
  state: TicketState,
  payload?: TicketPayload
) => ({
  ...state,
  search: (payload && payload.search) ?? state.search,
});

const setSearchField: TicketReducer = (
  state: TicketState,
  payload?: TicketPayload
) => ({
  ...state,
  search_field: (payload && payload.search_field) ?? state.search_field,
});

const setStatusId: TicketReducer = (
  state: TicketState,
  payload?: TicketPayload
) => ({
  ...state,
  status_id: (payload && payload.status_id) ?? state.status_id,
});

const setLoading: TicketReducer = (
  state: TicketState,
  payload?: TicketPayload
) => ({
  ...state,
  is_loading: (payload && payload.is_loading) ?? state.is_loading,
});



const setPopoverOpened: TicketReducer = (state: TicketState, payload?: TicketPayload) => ({
  ...state,
  popover_opened: (payload && payload.popover_opened) ?? state.popover_opened
});


const setTicketStatus: TicketReducer = (state: TicketState, payload?:TicketPayload) => ({
  ...state,
  ticket_status: (payload && payload.ticket_status) ?? state.ticket_status
});



const setError: TicketReducer = (state: TicketState, payload?: TicketPayload) => ({
  ...state,
  error: payload ? payload.error : undefined,
});

const clearState: TicketReducer = () => ({
  ...initialState,
});

const reducerMap = new Map<TicketActionType, TicketReducer>([
  [TicketActionType.SET_POPOVER_OPENED, setPopoverOpened],
  [TicketActionType.SET_LOADING, setLoading],
  [TicketActionType.SET_ERROR, setError],
  [TicketActionType.CLEAR, clearState],
  [TicketActionType.SET_TICKET, setTicket],
  [TicketActionType.SET_LIMIT, setLimit],
  [TicketActionType.SET_CURSOR, setCursor],
  [TicketActionType.SET_ORDERBY, setOrderBy],
  [TicketActionType.SET_ORDERBY_DIRECTION, setOrderByDirection],
  [TicketActionType.SET_SEARCH, setSearch],
  [TicketActionType.SET_SEARCH_FIELD, setSearchField],
  [TicketActionType.SET_STATUS_ID, setStatusId],
  [TicketActionType.SET_TICKET_STATUS, setTicketStatus],
  
]);

const reducer = (state: TicketState = initialState, action: TicketAction): TicketState => {
  const reducer = reducerMap.get(action.type);
  if (reducer) return reducer(state, action.payload);
  return state;
};

export default reducer;