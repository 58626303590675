import { KEYS } from 'src/@types/root';
import { getData, setData, clearData } from 'ca-react-util-lib';

// Token.
export const getTableDrawer = () =>
  getData(KEYS.TABLE_DRAWER_CONFIG) === null
    ? null
    : JSON.parse(getData(KEYS.TABLE_DRAWER_CONFIG) || '{}');
export const setTableDrawer = (config: object) =>
  setData(KEYS.TABLE_DRAWER_CONFIG, JSON.stringify(config));
export const clearTableDrawer = () => clearData(KEYS.TABLE_DRAWER_CONFIG);
