import { Onboarding } from 'src/@types/auth';
import { AuthenticationAction, AuthenticationActionType } from './types';

const actions = {
  login: (email: string, password: string): AuthenticationAction => ({
    payload: { email, password },
    type: AuthenticationActionType.LOGIN,
  }),
  requestResetPassword: (email: string): AuthenticationAction => ({
    payload: { email },
    type: AuthenticationActionType.REQUEST_RESET_PASSWORD,
  }),
  resetPassword: (email: string, password: string, passwordResetToken: string): AuthenticationAction => ({
    payload: { email, password, passwordResetToken },
    type: AuthenticationActionType.RESET_PASSWORD,
  }),
  setEmailSent: (emailSent: boolean): AuthenticationAction => ({
    payload: { emailSent },
    type: AuthenticationActionType.SET_EMAIL_SENT,
  }),
  setPasswordReset: (passwordReset: boolean): AuthenticationAction => ({
    payload: { passwordReset },
    type: AuthenticationActionType.SET_PASSWORD_RESET_SUCCESS,
  }),
  setAuthenticated: (): AuthenticationAction => ({
    type: AuthenticationActionType.SET_AUTHENTICATED,
  }),
  setLoading: (loading: boolean): AuthenticationAction => ({
    payload: { loading },
    type: AuthenticationActionType.SET_LOADING,
  }),
  setError: (error: Error): AuthenticationAction => ({
    payload: { error },
    type: AuthenticationActionType.SET_ERROR,
  }),
  initialize: (): AuthenticationAction => ({
    type: AuthenticationActionType.INITIALIZE,
  }),
  setInitialized: (initialized: boolean): AuthenticationAction => ({
    payload: { initialized },
    type: AuthenticationActionType.SET_INITIALIZED,
  }),
  postOnboardUser: (email: string): AuthenticationAction => ({
    payload: { email },
    type: AuthenticationActionType.POST_ONBOARD_USER,
  }),
  setOnboarding: (onBoarding: Onboarding): AuthenticationAction => ({
    payload: { onBoarding },
    type: AuthenticationActionType.SET_ONBOARDING,
  }),
  setOnboardingError: (onboardingError: string): AuthenticationAction => ({
    payload: { onboardingError },
    type: AuthenticationActionType.SET_ONBOARDING_ERROR,
  }),
  setPasswordResetError: (passwordResetError: string): AuthenticationAction => ({
    payload: { passwordResetError },
    type: AuthenticationActionType.SET_PASSWORD_RESET_ERROR,
  }),
  logout: (): AuthenticationAction => ({
    type: AuthenticationActionType.LOGOUT,
  }),
  clearState: (): AuthenticationAction => ({
    type: AuthenticationActionType.CLEAR,
  }),
};

export default actions;
