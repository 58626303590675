// Define the base API URL from environment variables
const apiUrl = process.env.REACT_APP_STAFF_V2_API_ENDPOINT;

// Define the endpoints object containing the base URL for process pipeline API
const endpoints = {
  waitingForImages: `${apiUrl}/api/process-pipeline/?action=Waiting+for+images`,
  queueInfo: `${apiUrl}/api/process-pipeline/?action=Queue+for+processing`,
  processingInfo: `${apiUrl}/api/process-pipeline/?action=Wait+for+processing`,
  performQcInfo: `${apiUrl}/api/process-pipeline/?action=Perform+QC`,
  completedInfo: `${apiUrl}/api/process-pipeline/?action=Completed`,
  reflyInfo: `${apiUrl}/api/process-pipeline/?action=Request+re-fly`,
  measurementsInfo: `${apiUrl}/api/process-pipeline/?action=Request+measurements`,
  reprocessingInfo: `${apiUrl}/api/process-pipeline/?action=Re-Queue+for+processing`,
  getSiteAssessmentStaging: `${apiUrl}/api/assessment/`,
};

// Export the endpoints object for use in other modules
export default endpoints;
