export enum ExportJobsActionType {
  FETCH_EXPORT_JOBS = 'ExportJobs@FETCH_EXPORT_JOBS',
  SET_EXPORT_JOBS = 'ExportJobs@SET_EXPORT_JOBS',
  SET_EXPORT_JOBS_LOADING = 'ExportJobs@SET_EXPORT_JOBS_LOADING',
  SET_EXPORT_JOBS_ERROR = 'ExportJobs@SET_EXPORT_JOBS_ERROR',
  SET_COLUMN = 'ExportJobs@SET_COLUMN',
  SET_SORTBY = 'ExportJobs@SET_SORTBY',
}

export type ExportJob = {
  azure_url: string;
  last_message: string;
  last_status: string;
  last_timestamp: string;
  job_created_at: string;
  project_name: string;
  s3_uri: string;
  sas_id: string;
}

export type ExportJobsState = {
  exportJobs?: ExportJob[];
  exportJobsLoading: boolean;
  exportJobsError?: Error | string;
  column: string;
  sortBy: "asc" | "desc";
};

export type ExportJobsPayload = {
  exportJobs?: ExportJob[];
  exportJobsLoading?: boolean;
  exportJobsError?: Error | string;
  column?: string;
  sortBy?: "asc" | "desc";
};

export type ExportJobsAction = {
  payload?: ExportJobsPayload;
  type: ExportJobsActionType;
};

export type ExportJobsReducer = (state: ExportJobsState, payload?: ExportJobsPayload) => ExportJobsState;
