import { put, call } from 'redux-saga/effects';

// actions
import actions from '../actions';

// services
import * as siteServices from 'src/servicesV2/site';

// types
import { StructureTypeContent } from 'src/@types/site';

export function* fetchStructureSelectOptions() {
  try {
    yield put(actions.setIsStructureSelectOptionsLoading(true));
    const response: StructureTypeContent = yield call(siteServices.fetchStructureTypeOptions);
    yield put(actions.setStructureSelectOptions(response));
  } catch (e) {
    yield put(actions.setError(e as Error));
  } finally {
    yield put(actions.setIsStructureSelectOptionsLoading(false));
  }
}