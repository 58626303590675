import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// actions
import authenticationActions from 'src/store/Authentication/actions';

// selectors
import authenticationSelectors from 'src/store/Authentication/selectors';

// types
import { Onboarding } from 'src/@types/auth';

const useAuth = () => {
  const isAuthenticated = useSelector(authenticationSelectors.isAuthenticated);
  const isInitialized = useSelector(authenticationSelectors.isInitialized);
  const isLoading = useSelector(authenticationSelectors.isLoading);
  const isEmailSent = useSelector(authenticationSelectors.isEmailSent);
  const isPasswordReset = useSelector(authenticationSelectors.isPasswordReset);
  const onboarding = useSelector(authenticationSelectors.getOnboarding);
  const onboardingError = useSelector(authenticationSelectors.getOnboardingError);
  const error = useSelector(authenticationSelectors.getError);
  const passwordResetError = useSelector(authenticationSelectors.getPasswordResetError);

  const dispatch = useDispatch();

  useEffect(() => {
    // auto login when a auth token already stored.
    if (!isInitialized) {
      dispatch(authenticationActions.initialize());
    }
  }, [isInitialized, dispatch]);

  const login = useCallback(
    (email: string, password: string) => {
      dispatch(authenticationActions.login(email, password));
    },
    [dispatch]
  );

  const logout = useCallback(() => {
    dispatch(authenticationActions.logout());
  }, [dispatch]);

  const requestResetPassword = useCallback(
    (email: string) => {
      dispatch(authenticationActions.requestResetPassword(email));
    },
    [dispatch]
  );

  const resetPassword = useCallback(
    (email: string, password: string, passwordResetToken: string) => {
      dispatch(authenticationActions.resetPassword(email, password, passwordResetToken));
    },
    [dispatch]
  );

  const postOnboardUser = useCallback(
    (email: string) => {
      dispatch(authenticationActions.postOnboardUser(email));
    },
    [dispatch]
  );

  const setOnboarding = useCallback(
    (onboarding: Onboarding) => {
      dispatch(authenticationActions.setOnboarding(onboarding));
    },
    [dispatch]
  );

  const setOnboardingError = useCallback(
    (error: string) => {
      dispatch(authenticationActions.setOnboardingError(error));
    },
    [dispatch]
  );

  const setPasswordResetError = useCallback(
    (error: string) => {
      dispatch(authenticationActions.setPasswordResetError(error));
    },
    [dispatch]
  );

  return {
    isAuthenticated,
    isLoading,
    isEmailSent,
    isPasswordReset,
    isInitialized,
    error,
    onboarding,
    login,
    logout,
    requestResetPassword,
    postOnboardUser,
    resetPassword,
    setOnboarding,
    onboardingError,
    setOnboardingError,
    passwordResetError,
    setPasswordResetError,
  };
};

export default useAuth;
