import {
  AnnouncementAction,
  AnnouncementActionType,
  AnnouncementPayload,
  AnnouncementReducer,
  AnnouncementState,
} from './types';

export const initialState: AnnouncementState = {
  error: undefined,
  announcement_pagination: undefined,
  announcement_content: undefined,
  is_loading: false,
  limit: 15,
  cursor: undefined,
  order_by: 'date_created',
  order_by_direction: 'desc',
  search_field: undefined,
  search: undefined,
  announcement_editor_obj: {},
  is_editor_uploading: false,
  finish_editor_uploading: false
};

const setAnnouncement: AnnouncementReducer = (
  state: AnnouncementState,
  payload?: AnnouncementPayload
) => ({
  ...state,
  announcement_pagination: (payload && payload.announcement_pagination) ?? state.announcement_pagination,
});

const setAnnouncementContent: AnnouncementReducer = (
  state: AnnouncementState,
  payload?: AnnouncementPayload
) => ({
  ...state,
  announcement_content: (payload && payload.announcement_content) ?? state.announcement_content,
});

const setLimit: AnnouncementReducer = (
  state: AnnouncementState,
  payload?: AnnouncementPayload
) => ({
  ...state,
  limit: (payload && payload.limit) ?? state.limit,
});

const setCursor: AnnouncementReducer = (
  state: AnnouncementState,
  payload?: AnnouncementPayload
) => ({
  ...state,
  cursor: (payload && payload.cursor) ?? state.cursor,
});

const setOrderBy: AnnouncementReducer = (
  state: AnnouncementState,
  payload?: AnnouncementPayload
) => ({
  ...state,
  order_by: (payload && payload.order_by) ?? state.order_by,
});

const setOrderByDirection: AnnouncementReducer = (
  state: AnnouncementState,
  payload?: AnnouncementPayload
) => ({
  ...state,
  order_by_direction: (payload && payload.order_by_direction) ?? state.order_by_direction,
});

const setSearch: AnnouncementReducer = (
  state: AnnouncementState,
  payload?: AnnouncementPayload
) => ({
  ...state,
  search: (payload && payload.search) ?? state.search,
});

const setSearchField: AnnouncementReducer = (
  state: AnnouncementState,
  payload?: AnnouncementPayload
) => ({
  ...state,
  search_field: (payload && payload.search_field) ?? state.search_field,
});

const setLoading: AnnouncementReducer = (
  state: AnnouncementState,
  payload?: AnnouncementPayload
) => ({
  ...state,
  is_loading: (payload && payload.is_loading) ?? state.is_loading,
});

const setAnnouncementEditorObj: AnnouncementReducer = (
  state: AnnouncementState,
  payload?: AnnouncementPayload
) => ({
  ...state,
  announcement_editor_obj: (payload && payload.announcement_editor_obj) ?? state.announcement_editor_obj
});

const setIsEditorUploading: AnnouncementReducer = (
  state: AnnouncementState,
  payload?: AnnouncementPayload
) => ({
  ...state,
  is_editor_uploading: (payload && payload.is_editor_uploading !== undefined) ? payload.is_editor_uploading : state.is_editor_uploading
});

const setFinishEditorUploading: AnnouncementReducer = (
  state: AnnouncementState,
  payload?: AnnouncementPayload
) => ({
  ...state,
  finish_editor_uploading: (payload && payload.finish_editor_uploading !== undefined) ? payload.finish_editor_uploading : state.finish_editor_uploading
});

const setError: AnnouncementReducer = (
  state: AnnouncementState,
  payload?: AnnouncementPayload
) => ({
  ...state,
  error: payload ? payload.error : undefined,
});

const clearState: AnnouncementReducer = () => ({
  ...initialState,
});

const reducerMap = new Map<AnnouncementActionType, AnnouncementReducer>([
  [AnnouncementActionType.SET_ERROR, setError],
  [AnnouncementActionType.CLEAR, clearState],
  [AnnouncementActionType.SET_ANNOUNCEMENT, setAnnouncement],
  [AnnouncementActionType.SET_ANNOUNCEMENT_CONTENT, setAnnouncementContent],
  [AnnouncementActionType.SET_LIMIT, setLimit],
  [AnnouncementActionType.SET_CURSOR, setCursor],
  [AnnouncementActionType.SET_ORDERBY, setOrderBy],
  [AnnouncementActionType.SET_ORDERBY_DIRECTION, setOrderByDirection],
  [AnnouncementActionType.SET_SEARCH, setSearch],
  [AnnouncementActionType.SET_SEARCH_FIELD, setSearchField],
  [AnnouncementActionType.SET_LOADING, setLoading],
  [AnnouncementActionType.SET_ANNOUNCEMENT_EDITOR_OBJ, setAnnouncementEditorObj],
  [AnnouncementActionType.SET_IS_EDITOR_UPLOADING, setIsEditorUploading],
  [AnnouncementActionType.SET_FINISH_EDITOR_UPLOADING,setFinishEditorUploading]
]);

const reducer = (
  state: AnnouncementState = initialState,
  action: AnnouncementAction
): AnnouncementState => {
  const reducer = reducerMap.get(action.type);
  if (reducer) return reducer(state, action.payload);
  return state;
};

export default reducer;
