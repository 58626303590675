import { put, call } from 'redux-saga/effects';

// actions
import actions from '../actions';
import toastActions from 'src/store/Toast/actions';

// services
import * as siteServices from 'src/servicesV2/site';

// types
import { SiteAction } from '../types';
import { TOAST_SEVERITY } from 'src/@types/toast';

export const convertToV2SitePayload = (payload: any) => ({
  site: {
    latitude: payload.site.site.latitude,
    longitude: payload.site.site.longitude,
    access_code: payload.site.site.accessCode,
    county_name: payload.site.site.countyName,
    postal_code: payload.site.site.postalCode,
    address_city: payload.site.site.addressCity,
    address_line1: payload.site.site.addressLine1,
    address_state: payload.site.site.addressState,
    access_instructions: payload.site.site.accessInstructions,
    structure_height_agl: payload.site.site.structureHeightAgl,
    owner_id: payload.site.site.ownerId,
    owner_site_id: payload.site.site.ownerSiteId,
    asset_class_id: payload.site.site.assetClassId,
    ownership_type_id: payload.site.site.ownershipTypeId,
    structure_type_id: payload.site.site.structureTypeId,
    name: payload.site.companySiteId,
    region: payload.site.region,
  }
});

export const convertToV2SiteAssessmentsPayload = (assessment: any) => ({
  assessment: {
    storage: assessment.storage,
    date: assessment.date,
    title: assessment.title,
    data_type: assessment.data_type,
    story_url: assessment.story_url,
    tileset_url: assessment.tileset_url,
    description: assessment.description,
    data_received: assessment.data_received,
    assessment_tag: assessment.assessment_tag,
    storage_bucket: assessment.storage_bucket,
    storage_folder: assessment.storage_folder,
    model_geo_referenced: assessment.model_geo_referenced,
    model_center_at_origin: assessment.model_center_at_origin,
    uav_operator_id: assessment.uav_operator_id,
  }
});

export const convertToV2SiteUsersPayload = (user: any) => ({
  site_user_role: {
    user_id: user.user_id,
    site_role_id: user.site_role_id,
  }
});

export function* createSite({ payload }: SiteAction) {
  try {
    if (!payload) return;
    yield put(actions.setLoading(true));
    const createSiteResponse: any = yield call(siteServices.createSite, convertToV2SitePayload(payload));
    if (createSiteResponse?.site?.id) {
      yield put(actions.setLoading(false));
      yield put(actions.setIsAddSiteSuccess(true));
      yield put(toastActions.setToast('Site is Created!'));
    } else {
      yield put(toastActions.setToast('Fail to create site!', TOAST_SEVERITY.WARNING));
      yield put(actions.setIsAddSiteSuccess(false));
    }
  } catch (e) {
    yield put(actions.setError(e as Error));
  } finally {
    yield put(actions.setIsAddSiteSuccess(false));
    yield put(actions.setLoading(false));
    yield put(toastActions.clearState());
  }
}
