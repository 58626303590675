import { put, takeLatest, call } from 'redux-saga/effects';

// actions
import actions from './actions';
import toastActions from '../Toast/actions';

// services
import * as userServices from 'src/servicesV2/user';

// types
import { ProfileAction, ProfileActionType } from './types';
import { TOAST_SEVERITY } from 'src/@types/toast';
import { UserWithCompanyResponse } from 'src/@types/user';




// Used to the Profile Page, when user edit and save the new profile data.
export function* update({ payload }: ProfileAction) {
  try {
    if (!payload || !payload.user_id || !payload.user_content) {
      return;
    }

    yield put(actions.setUpdateError(undefined));
    yield put(actions.setUpdateLoading(true));
    const profileUserResponse: UserWithCompanyResponse = yield call(userServices.patchUser, payload.user_id, payload.user_content);

    if (profileUserResponse.user?.id) {
      yield put(toastActions.setToast('Edit Success!'));
      yield put(actions.setProfileUser(profileUserResponse));
    } else {
      yield put(toastActions.setToast('Edit Failed!', TOAST_SEVERITY.ERROR));
      return;
    }
  } catch (e) {
    console.error(e);
    yield put(toastActions.setToast('Edit Failed!', TOAST_SEVERITY.ERROR));
  } finally {
    yield put(actions.setUpdateLoading(false));
    yield put(toastActions.clearState());
  }
}

const sagas = [
  takeLatest(ProfileActionType.UPDATE_PROFILE, update),
];

export default sagas;
