import {
  ManageAccessAction,
  ManageAccessActionType,
  ManageAccessReducer,
  ManageAccessPayload,
  ManageAccessState,
} from './types';

export const initialState: ManageAccessState = {
  limit: 15,
  cursor: undefined,
  order_by: 'user_company_name',
  order_by_direction: 'desc',
  search_field: undefined,
  search: undefined,
  manage_access_table_company_id: undefined,
  company_users_pagination: undefined,
  error: undefined,
  is_manage_access_table_loading: false,
  give_access_user_list: [],
  is_external: false,
  revoke_user_list: [],
};

const updateRevokeUserList: ManageAccessReducer = (
  state: ManageAccessState,
  payload?: ManageAccessPayload
) => {
  if (!payload?.update_revoke_user) return { ...state };
  const { update_revoke_user } = payload;
  if (state.revoke_user_list?.some((item) => item === update_revoke_user)) {
    const updated_revoke_user_list = [...state.revoke_user_list];
    const index_of_updated_revoke_user = updated_revoke_user_list.findIndex(
      (item) => item === update_revoke_user
    );
    updated_revoke_user_list.splice(index_of_updated_revoke_user, 1);
    return { ...state, revoke_user_list: [...updated_revoke_user_list] };
  } else {
    const updated_revoke_user_list = [...(state.revoke_user_list || [])];
    updated_revoke_user_list.push(update_revoke_user);
    return { ...state, revoke_user_list: [...updated_revoke_user_list] };
  }
};

const setRevokeUserList: ManageAccessReducer = (
  state: ManageAccessState,
  payload?: ManageAccessPayload
) => ({
  ...state,
  revoke_user_list: (payload && payload.revoke_user_list) ?? state.revoke_user_list,
});

const setIsExternal: ManageAccessReducer = (
  state: ManageAccessState,
  payload?: ManageAccessPayload
) => ({
  ...state,
  is_external: payload && payload.is_external !== undefined ? payload.is_external : state.is_external,
});

const setGiveAccessUserList: ManageAccessReducer = (
  state: ManageAccessState,
  payload?: ManageAccessPayload
) => ({
  ...state,
  give_access_user_list: (payload && payload.give_access_user_list) ?? state.give_access_user_list,
});

const setManageAccessTableCompanyId: ManageAccessReducer = (
  state: ManageAccessState,
  payload?: ManageAccessPayload
) => ({
  ...state,
  manage_access_table_company_id: (payload && payload.manage_access_table_company_id) ?? state.manage_access_table_company_id,
});

const setManageAccessSideColumnCompanyId: ManageAccessReducer = (
  state: ManageAccessState,
  payload?: ManageAccessPayload
) => ({
  ...state,
  manage_access_side_column_company_id:
    (payload && payload.manage_access_side_column_company_id) ?? state.manage_access_side_column_company_id,
});

const setLimit: ManageAccessReducer = (
  state: ManageAccessState,
  payload?: ManageAccessPayload
) => ({
  ...state,
  limit: (payload && payload.limit) ?? state.limit,
});

const setCursor: ManageAccessReducer = (
  state: ManageAccessState,
  payload?: ManageAccessPayload
) => ({
  ...state,
  cursor: (payload && payload.cursor) ?? state.cursor,
});

const setOrderBy: ManageAccessReducer = (
  state: ManageAccessState,
  payload?: ManageAccessPayload
) => ({
  ...state,
  order_by: (payload && payload.order_by) ?? state.order_by,
});

const setOrderByDirection: ManageAccessReducer = (
  state: ManageAccessState,
  payload?: ManageAccessPayload
) => ({
  ...state,
  order_by_direction: (payload && payload.order_by_direction) ?? state.order_by_direction,
});

const setSearch: ManageAccessReducer = (
  state: ManageAccessState,
  payload?: ManageAccessPayload
) => ({
  ...state,
  search: (payload && payload.search) ?? state.search,
});

const setSearchField: ManageAccessReducer = (
  state: ManageAccessState,
  payload?: ManageAccessPayload
) => ({
  ...state,
  search_field: (payload && payload.search_field) ?? state.search_field,
});

const setCompanyId: ManageAccessReducer = (
  state: ManageAccessState,
  payload?: ManageAccessPayload
) => ({
  ...state,
  company_id: (payload && payload.company_id) ?? state.company_id,
});

const setAllCompanyUser: ManageAccessReducer = (state: ManageAccessState, payload?: ManageAccessPayload) => ({
  ...state,
  company_users_pagination: (payload && payload.company_users_pagination) ?? state.company_users_pagination
});

const clearAllCompanyUser: ManageAccessReducer = (state: ManageAccessState) => ({
  ...state,
  company_users_pagination: undefined,
});

const clearManageAccessTableCompanyId: ManageAccessReducer = (state: ManageAccessState) => ({
  ...state,
  manage_access_table_company_id: undefined,
});

const setIsManageAccessTableLoading: ManageAccessReducer = (
  state: ManageAccessState,
  payload?: ManageAccessPayload
) => ({
  ...state,
  is_manage_access_table_loading:
    payload && payload.is_manage_access_table_loading !== undefined
      ? payload.is_manage_access_table_loading
      : state.is_manage_access_table_loading,
});

const setError: ManageAccessReducer = (
  state: ManageAccessState,
  payload?: ManageAccessPayload
) => ({
  ...state,
  error: payload ? payload.error : undefined,
});

const clearState: ManageAccessReducer = () => ({
  ...initialState,
});

const reducerMap = new Map<ManageAccessActionType, ManageAccessReducer>([
  [ManageAccessActionType.SET_ERROR, setError],
  [ManageAccessActionType.CLEAR, clearState],
  [ManageAccessActionType.SET_ALL_COMPANY_USER, setAllCompanyUser],
  [ManageAccessActionType.SET_LIMIT, setLimit],
  [ManageAccessActionType.SET_CURSOR, setCursor],
  [ManageAccessActionType.SET_ORDERBY, setOrderBy],
  [ManageAccessActionType.SET_ORDERBY_DIRECTION, setOrderByDirection],
  [ManageAccessActionType.SET_SEARCH, setSearch],
  [ManageAccessActionType.SET_SEARCH_FIELD, setSearchField],
  [ManageAccessActionType.SET_COMPANY_ID, setCompanyId],
  [ManageAccessActionType.SET_MANAGE_ACCESS_TABLE_COMPANY_ID, setManageAccessSideColumnCompanyId],
  [ManageAccessActionType.SET_IS_MANAGE_ACCESS_TABLE_LOADING, setIsManageAccessTableLoading],
  [ManageAccessActionType.SET_MANAGE_ACCESS_SIDE_COLUMN_COMPANY_ID,setManageAccessSideColumnCompanyId],
  [ManageAccessActionType.SET_GIVE_ACCESS_USER_LIST, setGiveAccessUserList],
  [ManageAccessActionType.CLEAR_ALL_COMPANY_USER, clearAllCompanyUser],
  [ManageAccessActionType.CLEAR_MANAGE_ACCESS_TABLE_COMPANY_ID, clearManageAccessTableCompanyId],
  [ManageAccessActionType.SET_IS_EXTERNAL, setIsExternal],
  [ManageAccessActionType.SET_REVOKE_USER_LIST, setRevokeUserList],
  [ManageAccessActionType.UPDATE_REVOKE_USER_LIST, updateRevokeUserList],
  [ManageAccessActionType.SET_MANAGE_ACCESS_TABLE_COMPANY_ID, setManageAccessTableCompanyId],
]);

const reducer = (
  state: ManageAccessState = initialState,
  action: ManageAccessAction
): ManageAccessState => {
  const reducer = reducerMap.get(action.type);
  if (reducer) return reducer(state, action.payload);
  return state;
};

export default reducer;
