import api from './api';
import { TenantCreateRequestObj, TenantUpdateRequestObj, TenantPagination, TenantWithCompanyContent, TenantOptionsContent } from 'src/@types/tenant';
import { TenantCursorPaginatorResponseSchema, TenantOptionsResponseSchema, TenantWithCompanyResponseSchema } from './types';

export const fetchAllTenants = async (
  limit: number,
  cursor?: string,
  order_by_direction?: string,
  order_by?: string,
  search?: string,
  search_field?: string,
): Promise<TenantPagination> => {
  try {
    const response: TenantCursorPaginatorResponseSchema = await api.fetchAllTenants(
      limit,
      cursor,
      order_by_direction,
      order_by,
      search,
      search_field,
    );
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const fetchTenantContent = async (tenant_id: string): Promise<TenantWithCompanyContent> => {
  try {
    const response: TenantWithCompanyResponseSchema = await api.fetchTenantContent(tenant_id);
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const patchTenant = async (tenant_id: string, tenant: TenantUpdateRequestObj): Promise<TenantWithCompanyContent> => {
  try {
    const response: TenantWithCompanyResponseSchema = await api.patchTenant(tenant_id, tenant);
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const postTenant = async (tenant: TenantCreateRequestObj): Promise<TenantWithCompanyContent> => {
  try {
    const response: TenantWithCompanyResponseSchema = await api.postTenant(tenant);
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const fetchTenantOptions = async (search?: string): Promise<TenantOptionsContent> => {
  try {
    const response: TenantOptionsResponseSchema = await api.fetchTenantOptions(search);
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
