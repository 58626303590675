// routes
import { MAIN_PATHS } from 'src/routes/paths';

// components
import { ICON } from 'ca-react-component-lib';
import { ROLE } from 'src/@types/user';

const appHeaderMenu = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    label: 'dashboard',
    roles: [ROLE.INTERNAL_ADMIN, ROLE.CUSTOMER_SUCCESS],
    menu: [
      {
        label: 'Sites',
        path: MAIN_PATHS.sites,
        icon: {
          name: "location-dot",
          size: ICON.SMALL
        },
        active: false,
      },
      {
        label: 'Todo',
        path: MAIN_PATHS.todo,
        icon: {
          name: "archive",
          size: ICON.SMALL
        },
        active: false,
      },
      {
        label: 'Tenants',
        path: MAIN_PATHS.tenants,
        icon: {
          name: "user-group",
          size: ICON.SMALL
        },
        roles: [ROLE.INTERNAL_ADMIN],
        active: false,
      },
      {
        label: 'Announcements',
        path: MAIN_PATHS.announcements,
        icon: {
          name: "envelope",
          size: ICON.SMALL,
          roles: [ROLE.INTERNAL_ADMIN],

        },
        active: false,
      },
      {
        label: 'Processing',
        path: MAIN_PATHS.processing,
        icon: {
          name: "server",
          size: ICON.SMALL
        },
        roles: [ROLE.INTERNAL_ADMIN, ROLE.CUSTOMER_SUCCESS],
        active: false,
      },
    ],
  },
  {
    label: 'management',
    roles: [ROLE.INTERNAL_ADMIN, ROLE.CUSTOMER_SUCCESS],
    menu: [
      {
        label: 'companies',
        path: MAIN_PATHS.management.companies,
        icon: {
          name: "city",
          size: ICON.SMALL
        },
        roles: [ROLE.INTERNAL_ADMIN, ROLE.CUSTOMER_SUCCESS],
        active: false,
      },
      {
        label: 'Manage Access',
        path: MAIN_PATHS.management.manageAccess,
        icon: {
          name: "arrow-turn-up",
          size: ICON.SMALL
        },
        roles: [ROLE.INTERNAL_ADMIN],
        active: false,
      },
      {
        label: 'users',
        path: MAIN_PATHS.management.users,
        icon: {
          name: "user-group",
          size: ICON.SMALL
        },
        roles: [ROLE.INTERNAL_ADMIN, ROLE.CUSTOMER_SUCCESS],
        active: false,
      },

      {
        label: 'notifications',
        path: MAIN_PATHS.notifications,
        icon: {
          name: "bell",
          size: ICON.SMALL
        },
        roles: [ROLE.INTERNAL_ADMIN, ROLE.CUSTOMER_SUCCESS],
        active: false,
      },
      {
        label: 'tickets',
        path: MAIN_PATHS.management.tickets,
        icon: {
          name: "ticket",
          size: ICON.SMALL
        },
        roles: [ROLE.INTERNAL_ADMIN, ROLE.CUSTOMER_SUCCESS],
        active: false,
      },
    ],
  },
  // {
  //   label: 'tools',
  //   menu: [
  //     {
  //       label: 'Import Jobs Status',
  //       path: MAIN_PATHS.tools.importJobs,
  //       icon: {
  //         name: "image",
  //         size: ICON.SMALL
  //       },
  //       active: false,
  //     },
  //     {
  //       label: 'Export Jobs Status',
  //       path: MAIN_PATHS.tools.exportJobs,
  //       icon: {
  //         name: "image",
  //         size: ICON.SMALL
  //       },
  //       active: false,
  //     },
  //   ],
  // },
];

export default appHeaderMenu;
