import { ROLE, UserWithCompanyResponse } from 'src/@types/user';
import {
  ProfileAction,
  ProfileActionType,
  ProfileReducer,
  ProfilePayload,
  ProfileState,
} from './types';

const emptyUserData: UserWithCompanyResponse = {
  user: {
    id: '',
    company: undefined,
    first_name: '',
    last_name: '',
    phone_number: '',
    full_name: '',
    email: '',
    role: ROLE.UNKNOWN,
    phone_verified: false,
    invited: false,
    current_company: undefined,
  }
};

export const initialState: ProfileState = {
  error: undefined,
  user_content: emptyUserData,
  is_loading: false,
  update_loading: false,
  update_error: undefined,
};

const setError: ProfileReducer = (state: ProfileState, payload?: ProfilePayload) => ({
  ...state,
  error: payload ? payload.error : undefined,
});

const setLoading: ProfileReducer = (state: ProfileState, payload?: ProfilePayload) => ({
  ...state,
  is_loading: !!payload?.is_loading,
});

const setProfileUser: ProfileReducer = (state: ProfileState, payload?: ProfilePayload) => ({
  ...state,
  user_content: (payload && payload.user_content) ?? state.user_content,
});


const setUpdateLoading: ProfileReducer = (state: ProfileState, payload?: ProfilePayload) => ({
  ...state,
  update_loading: (payload && payload.update_loading) ?? state.update_loading,
});

const setUpdateError: ProfileReducer = (state: ProfileState, payload?: ProfilePayload) => ({
  ...state,
  update_error: (payload && payload.update_error) ?? state.update_error,
});

const clearState: ProfileReducer = () => ({
  ...initialState,
});

const reducerMap = new Map<ProfileActionType, ProfileReducer>([
  [ProfileActionType.SET_PROFILE_USER, setProfileUser],
  [ProfileActionType.SET_LOADING, setLoading],
  [ProfileActionType.SET_ERROR, setError],
  [ProfileActionType.SET_UPDATE_LOADING, setUpdateLoading],
  [ProfileActionType.SET_UPDATE_ERROR, setUpdateError],
  [ProfileActionType.CLEAR, clearState],
]);

const reducer = (state: ProfileState = initialState, action: ProfileAction): ProfileState => {
  const reducer = reducerMap.get(action.type);
  if (reducer) return reducer(state, action.payload);
  return state;
};

export default reducer;
