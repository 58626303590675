import { delay, put, select } from "redux-saga/effects";
import { HEADER, PhotosLoadStrategy } from "ca-react-component-lib";
import { SelectedTiePointLocation } from "src/@types/processingPipeline";
import actions from "../actions";
import selectors from "../selectors";

export function* scrollToNextSelectedTiePointPhoto() {
  const selectedTiePointLocations: SelectedTiePointLocation[] = yield select(selectors.getSelectedTiePointLocations);
  const nextSelectedTiePointLocationPointer: number = yield select(selectors.getNextSelectedTiePointLocationPointer);
  if (selectedTiePointLocations.length === 0) return;

  const current = yield select(selectors.getNextSelectedTiePointLocationPointer);
  yield put(actions.setIsTiePointPhotosShow(false));
  yield put(actions.setTiePointPhotoTabIndex(selectedTiePointLocations[current].tab));
  yield delay(300);
  yield put(actions.setIsTiePointPhotosShow(true));
  yield put(actions.setTiePointPhotoLoadingStrategy(PhotosLoadStrategy.NONE));
  yield delay(300);
  const el = document.getElementById(selectedTiePointLocations[current].photoId);
  if(!el) return;
  el.style.scrollMargin = `${HEADER.DASHBOARD_DESKTOP_HEIGHT}px`;
  el.scrollIntoView({ behavior: 'smooth' });
  if (nextSelectedTiePointLocationPointer < selectedTiePointLocations.length - 1) {
    yield put(actions.setNextSelectedTiePointLocationPointer(nextSelectedTiePointLocationPointer + 1));
  } else {
    yield put(actions.setNextSelectedTiePointLocationPointer(0));
  }
}
