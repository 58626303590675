import { CompanyUserPagination } from 'src/@types/company';
import { ManageAccessAction, ManageAccessActionType } from './types';

const actions = {
  revokeBulkUserAccess: (): ManageAccessAction => ({
    type: ManageAccessActionType.REVOKE_BULK_USER_ACCESS,
  }),
  updateRevokeUserList: (update_revoke_user: string): ManageAccessAction => ({
    payload: { update_revoke_user },
    type: ManageAccessActionType.UPDATE_REVOKE_USER_LIST,
  }),
  setRevokeUserList: (revoke_user_list: string[]): ManageAccessAction => ({
    payload: { revoke_user_list },
    type: ManageAccessActionType.SET_REVOKE_USER_LIST,
  }),
  setManageAccessTableCompanyId: (manage_access_table_company_id?: string): ManageAccessAction => ({
    payload: { manage_access_table_company_id },
    type: ManageAccessActionType.SET_MANAGE_ACCESS_TABLE_COMPANY_ID,
  }),
  setGiveAccessUserList: (give_access_user_list: string[]): ManageAccessAction => ({
    payload: { give_access_user_list },
    type: ManageAccessActionType.SET_GIVE_ACCESS_USER_LIST,
  }),
  giveCompanyAccess: (): ManageAccessAction => ({
    type: ManageAccessActionType.GIVE_COMPANY_ACCESS,
  }),
  setManageAccessSideColumnCompanyId: (manage_access_side_column_company_id?: string): ManageAccessAction => ({
    payload: { manage_access_side_column_company_id },
    type: ManageAccessActionType.SET_MANAGE_ACCESS_SIDE_COLUMN_COMPANY_ID,
  }),
  setLimit: (limit: number): ManageAccessAction => ({
    payload: { limit },
    type: ManageAccessActionType.SET_LIMIT,
  }),
  setCursor: (cursor: string): ManageAccessAction => ({
    payload: { cursor },
    type: ManageAccessActionType.SET_CURSOR,
  }),
  setOrderBy: (order_by: 'user_full_name' | 'company_name' | 'user_company_name'): ManageAccessAction => ({
    payload: { order_by },
    type: ManageAccessActionType.SET_ORDERBY,
  }),
  setOrderByDirection: (order_by_direction: 'asc' | 'desc'): ManageAccessAction => ({
    payload: { order_by_direction },
    type: ManageAccessActionType.SET_ORDERBY_DIRECTION,
  }),
  setSearch: (search: string): ManageAccessAction=> ({
    payload: { search },
    type: ManageAccessActionType.SET_SEARCH,
  }),
  setSearchField: (search_field: 'user_full_name' |'user_email' | 'company_name' |'user_company_name'): ManageAccessAction=> ({
    payload: { search_field },
    type: ManageAccessActionType.SET_SEARCH_FIELD,
  }),
  setCompanyId: (company_id: string): ManageAccessAction=> ({
    payload: { company_id },
    type: ManageAccessActionType.SET_COMPANY_ID,
  }),

  setAllCompanyUser: (company_users_pagination?: CompanyUserPagination): ManageAccessAction => ({
    payload: { company_users_pagination },
    type: ManageAccessActionType.SET_ALL_COMPANY_USER,
  }),
  fetchAllCompanyUsers: (): ManageAccessAction => ({
    type: ManageAccessActionType.FETCH_ALL_COMPANY_USERS,
  }),
  revokeSingleUserAccess: (revoke_single_user_id: string): ManageAccessAction => ({
    payload: { revoke_single_user_id },
    type: ManageAccessActionType.REVOKE_SINGLE_USER_ACCESS,
  }),
  setIsManageAccessTableLoading: (is_manage_access_table_loading: boolean): ManageAccessAction => ({
    payload: { is_manage_access_table_loading },
    type: ManageAccessActionType.SET_IS_MANAGE_ACCESS_TABLE_LOADING,
  }),
  clearManageAccessTableCompanyId: (): ManageAccessAction => ({
    type: ManageAccessActionType.CLEAR_MANAGE_ACCESS_TABLE_COMPANY_ID,
  }),
  clearAllCompanyUser: (): ManageAccessAction => ({
    type: ManageAccessActionType.CLEAR_ALL_COMPANY_USER,
  }),
  setIsExternal: (is_external: boolean): ManageAccessAction => ({
    payload: { is_external },
    type: ManageAccessActionType.SET_IS_EXTERNAL,
  }),
  setError: (error: Error | undefined): ManageAccessAction => ({
    payload: { error },
    type: ManageAccessActionType.SET_ERROR,
  }),
  clearState: (): ManageAccessAction => ({
    type: ManageAccessActionType.CLEAR,
  }),
};

export default actions;
