export const actions = {
  SET_DASHBOARD_SITES: "SET_DASHBOARD_SITES",
  SET_MANAGEMENT_TENANTS: "SET_MANAGEMENT_TENANTS",
  SET_MANAGEMENT_COMPANIES: "SET_MANAGEMENT_COMPANIES",
  SET_MANAGEMENT_USERS: "SET_MANAGEMENT_USERS",
  SET_MANAGEMENT_VENDORS: "SET_MANAGEMENT_VENDORS",
  SET_MANAGEMENT_TICKETS: "SET_MANAGEMENT_TICKETS",
  SET_OPEN: "SET_OPEN",
  SET_CURRENT_OPERATION: "SET_CURRENT_OPERATION",
  RESET_SITES: "RESET_SITES",
  SET_DASHBOARD_ANNOUNCEMENTS: "SET_DASHBOARD_ANNOUNCEMENTS",
  SET_MANAGEMENT_NOTIFICATIONS: "SET_MANAGEMENT_NOTIFICATIONS",
};