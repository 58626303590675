const apiUrl = process.env.REACT_APP_STAFF_V2_API_ENDPOINT;

const endpoints = {
  login: `${apiUrl}/api/auth/login/`,
  refresh: `${apiUrl}/api/auth/refresh/`,
  reset_password: `${apiUrl}/api/auth/reset-password/`,
  onboard: `${apiUrl}/api/auth/onboard/`,
};

export default endpoints;
