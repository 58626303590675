import { put, takeLatest, call } from 'redux-saga/effects';

// actions
import authenticationActions from './actions';
import profileActions from '../Profile/actions';
import CustomerSuccessAccessAction from '../CustomerSuccessAccess/actions';


// services
import * as authServices from 'src/servicesV2/auth';
import * as userServices from 'src/servicesV2/user';
import * as customerSuccessAccessServices from 'src/servicesV2/customerSuccessAccess';

// storage
import { setAuthToken, getAuthToken, clearAuthToken } from 'src/storage/AuthToken';

// types
import { SuccessError } from 'src/@types/root';
import { LoginRequestResponse } from 'src/servicesV2/auth/types';
import { AuthenticationAction, AuthenticationActionType, AuthenticationError } from './types';
import { Onboarding } from 'src/@types/auth';

/**
 * Make a request to get the profile data.
 *
 * Used by:
 * - login;
 * - initialize;
 * - resetPassword;
 */
function* updateProfile() {
  try {
    const user_content = yield call(userServices.fetchProfile);
    const companies = yield call(customerSuccessAccessServices.fetchCustomerSuccessCompanies);


    yield put(profileActions.setProfileUser(user_content));
    yield put(CustomerSuccessAccessAction.setCustomerSuccessCompanies(companies));
  } catch (e) {
    console.error(e.Message);
    throw e;
  }
}

export function* login({ payload }: AuthenticationAction) {
  if (!payload) {
    return;
  }

  const { email, password } = payload;

  if (!email || !password) {
    return;
  }

  yield put(authenticationActions.setLoading(true));
  try {
    // request login
    const loginRequestResponse: LoginRequestResponse = yield call(
      authServices.login,
      email,
      password
    );

    // if (loginRequestResponse.Role !== ROLE.INTERNAL_ADMIN) {
    //   const message = 'Access denied!';
    //   yield put(authenticationActions.setError({ message, name: AuthenticationError.LOGIN }));

    // set auth refresh token
    // yield call(setAuthRefreshToken, loginRequestResponse.RefreshToken);

    // set auth token
    yield call(setAuthToken, loginRequestResponse.auth_data.access_token);

    // update profile
    yield call(updateProfile);

    // set authenticated to true
    yield put(authenticationActions.setAuthenticated());
  } catch ({ message }) {
    yield put(authenticationActions.setError({ message, name: AuthenticationError.LOGIN }));
  } finally {
    yield put(authenticationActions.setLoading(false));
  }
}

/**
 * Started in the useAuth.
 */
export function* initialize() {
  try {
    const authToken: string = yield call(getAuthToken);

    if (authToken) {
      // set authenticated to true
      yield put(authenticationActions.setAuthenticated());

      // update profile
      yield call(updateProfile);
    }
  } catch (error) {
    // It is possible that on update profile auth token is invalid.
    // Then it is necessary to logout.
    yield call(logout);
    yield put(authenticationActions.setError(new Error('Session expired')));
  }

  yield put(authenticationActions.setInitialized(true));
}

export function* logout() {
  // clear auth token
  yield call(clearAuthToken);

  // clear state
  yield put(authenticationActions.clearState());

  // if reached here, then the application is already.
  // Because of the clear above, it is necessary to set initialized to true.
  yield put(authenticationActions.setInitialized(true));
}

export function* requestResetPassword({ payload }: AuthenticationAction) {
  if (!payload) {
    return;
  }

  const { email } = payload;

  if (!email) {
    return;
  }

  yield put(authenticationActions.setLoading(true));

  try {
    // request reset password
    const requestResetPasswordResponse: SuccessError = yield call(
      authServices.requestResetPassword,
      email
    );

    if (requestResetPasswordResponse.success) {
      yield put(authenticationActions.setEmailSent(true));
    }else{
      yield put (authenticationActions.setPasswordResetError(requestResetPasswordResponse.message || 'An error has occured while trying to reset your password. Please contact support for more information.'));
    }
  } catch ({ message }) {
    yield put(authenticationActions.setError({ message, name: AuthenticationError.LOGIN }));
  } finally {
    yield put(authenticationActions.setLoading(false));
  }
}

export function* resetPassword({ payload }: AuthenticationAction) {
  if (!payload) {
    return;
  }

  const { email, password, passwordResetToken } = payload;

  if (!email || !password || !passwordResetToken) {
    return;
  }

  yield put(authenticationActions.setLoading(true));

  try {
    // request reset password
    const resetPasswordResponse: SuccessError = yield call(
      authServices.resetPassword,
      password,
      passwordResetToken
    );

    if (resetPasswordResponse.success) {
      yield put(authenticationActions.setPasswordReset(true));

      yield call(login, {
        payload: { email, password },
        type: AuthenticationActionType.LOGIN,
      });
    }
  } catch ({ message }) {
    yield put(authenticationActions.setError({ message, name: AuthenticationError.REGISTER }));
  } finally {
    yield put(authenticationActions.setLoading(false));
  }
}

export function* postOnboardUser({ payload }: AuthenticationAction) {
  try {
    if (!payload?.email) {
      return;
    }
    const { email } = payload;
    yield put(authenticationActions.setLoading(true));
    const onboardResponse: SuccessError = yield call(authServices.postOnboardUser, email);

    if(onboardResponse.success) {
      yield put(authenticationActions.setOnboarding(Onboarding.SUCCESS));
    }
    else{
      yield put(authenticationActions.setOnboarding(Onboarding.ERROR));
      yield put(authenticationActions.setOnboardingError(onboardResponse.message || 'An error has occured while trying to onboard this user. Please contact support for more information.'));
    }
  } catch ({ message }) {
    yield put(authenticationActions.setError({ message, name: AuthenticationError.VALIDATE }));
  } finally {
    yield put(authenticationActions.setLoading(false));
  }
}

const sagas = [
  takeLatest(AuthenticationActionType.LOGIN, login),
  takeLatest(AuthenticationActionType.INITIALIZE, initialize),
  takeLatest(AuthenticationActionType.LOGOUT, logout),
  takeLatest(AuthenticationActionType.REQUEST_RESET_PASSWORD, requestResetPassword),
  takeLatest(AuthenticationActionType.RESET_PASSWORD, resetPassword),
  takeLatest(AuthenticationActionType.POST_ONBOARD_USER, postOnboardUser),
];

export default sagas;
