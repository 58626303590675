import { ApplicationState } from '../types';

const _getData = (state: ApplicationState) => state.Tenant;

const getError = (state: ApplicationState) => _getData(state).error;

const isLoading = (state: ApplicationState) => _getData(state).is_loading;

const isTenantContentLoading = (state: ApplicationState) => _getData(state).tenant_content_loading;

const isTenantCreateLoading = (state: ApplicationState) => _getData(state).tenant_create_loading;

const isTenantUpdateLoading = (state: ApplicationState) => _getData(state).tenant_update_loading;

const getTenants = (state: ApplicationState) => _getData(state).tenant_pagination;

const getTenantContent = (state: ApplicationState) => _getData(state).tenant_content;

const getLimit = (state: ApplicationState) => _getData(state).limit;

const getCursor = (state: ApplicationState) => _getData(state).cursor;

const getOrderBy = (state: ApplicationState) => _getData(state).order_by;

const getOrderByDirection = (state: ApplicationState) => _getData(state).order_by_direction;

const getSearch = (state: ApplicationState) => _getData(state).search;

const getSearchField = (state: ApplicationState) => _getData(state).search_field;

const getTenantCreateSuccess = (state: ApplicationState) => _getData(state).is_tenant_create_success;

const getTenantUpdateSuccess = (state: ApplicationState) => _getData(state).is_tenant_update_success;

const getTenantRequestObj = (state: ApplicationState) => _getData(state).tenant_request_obj;

const getTenantOptions = (state: ApplicationState) => _getData(state).tenant_options;

const getTenantOptionsLoading = (state: ApplicationState) => _getData(state).is_tenant_options_loading;

const getTenantSelectionId = (state: ApplicationState) => _getData(state).tenant_selection_id;

const getSelectedTenant = (state: ApplicationState) => _getData(state).tenant_selection;

const selectors = {
  getError,
  isLoading,
  isTenantContentLoading,
  isTenantCreateLoading,
  isTenantUpdateLoading,
  getTenants,
  getLimit,
  getCursor,
  getOrderBy,
  getOrderByDirection,
  getSearchField,
  getSearch,
  getTenantCreateSuccess,
  getTenantUpdateSuccess,
  getTenantContent,
  getTenantRequestObj,
  getTenantOptions,
  getTenantOptionsLoading,
  getTenantSelectionId,
  getSelectedTenant
};

export default selectors;
