import { SITE_COLUMNS } from 'src/@types/site';
import { TENANT_COLUMNS } from 'src/@types/tenant';
import { COMPANY_COLUMNS } from 'src/@types/company';
import { USER_COLUMNS } from 'src/@types/user';
import { ANNOUNCEMENT_COLUMNS } from 'src/@types/announcement';

import { Operation, TableDrawerState } from './types';

export const defaultState: TableDrawerState = {
  dashboardSites: [
    {
      id: SITE_COLUMNS.COMPANY_SITE_ID,
      label: 'SID',
      alignRight: false,
      canSort: true,
      enabled: true,
    },
    {
      id: SITE_COLUMNS.TENANT_NAME,
      label: 'Tenant',
      alignRight: false,
      canSort: true,
      enabled: true,
    },
    {
      id: SITE_COLUMNS.OWNER_SITE_ID,
      label: 'Owner / OSID',
      alignRight: false,
      canSort: true,
      enabled: true,
    },
    {
      id: SITE_COLUMNS.STRUCTURE,
      label: 'Structure Type',
      alignRight: false,
      canSort: false,
      enabled: true,
    },
    {
      id: SITE_COLUMNS.ASSET,
      label: 'Asset Class',
      alignRight: false,
      canSort: false,
      enabled: true,
    },
    {
      id: SITE_COLUMNS.CAPTURES,
      label: 'Captures',
      alignRight: false,
      canSort: false,
      enabled: true,
    },
    {
      id: SITE_COLUMNS.LOCATION,
      label: 'Location',
      alignRight: false,
      canSort: false,
      enabled: true,
    },
    {
      id: SITE_COLUMNS.ADDRESS_STATE,
      label: 'Address',
      alignRight: false,
      canSort: true,
      enabled: true,
    },
    {
      id: SITE_COLUMNS.ELEVATION,
      label: 'Elevation',
      alignRight: false,
      canSort: true,
      enabled: false,
    },
    {
      id: SITE_COLUMNS.FLOOD_ZONE_DESIGNATION,
      label: 'Flood Zone Designation',
      alignRight: false,
      canSort: false,
      enabled: false,
    },
    {
      id: SITE_COLUMNS.STRUCTURE_HEIGHT_AGL,
      label: 'Structure Height AGL',
      alignRight: false,
      canSort: true,
      enabled: false,
    },
    { id: SITE_COLUMNS.EDIT, label: 'Action', alignRight: false, canSort: false, enabled: true },
  ],
  managementTenants: [
    { id: TENANT_COLUMNS.NAME, label: 'Name', alignRight: false, canSort: true, enabled: true },
    {
      id: TENANT_COLUMNS.COMPANY_NAME,
      label: 'Company',
      alignRight: false,
      canSort: false,
      enabled: true,
    },
    {
      id: TENANT_COLUMNS.SUBDOMAIN,
      label: 'Subdomain',
      alignRight: false,
      canSort: true,
      enabled: true,
    },
    {
      id: TENANT_COLUMNS.ACTIONS,
      label: 'Actions',
      alignRight: false,
      canSort: false,
      enabled: true,
    },
  ],
  managementCompanies: [
    { id: COMPANY_COLUMNS.NAME, label: 'Names', alignRight: false, canSort: true, enabled: true },
    {
      id: COMPANY_COLUMNS.SHORT_NAME,
      label: 'Short Name',
      alignRight: false,
      canSort: true,
      enabled: true,
    },
    {
      id: COMPANY_COLUMNS.ABBREVIATION,
      label: 'Abbreviation',
      alignRight: false,
      canSort: true,
      enabled: true,
    },
    {
      id: COMPANY_COLUMNS.TENANT,
      label: 'Tenant',
      alignRight: false,
      canSort: true,
      enabled: true,
    },
    { id: COMPANY_COLUMNS.USERS, label: 'Users', alignRight: false, canSort: true, enabled: true },
    {
      id: COMPANY_COLUMNS.ACTIONS,
      label: 'Actions',
      alignRight: false,
      canSort: false,
      enabled: true,
    },
  ],
  managementUsers: [
    {
      id: USER_COLUMNS.FULL_NAME,
      label: 'Name',
      alignRight: false,
      canSort: true,
      enabled: true,
    },
    {
      id: USER_COLUMNS.COMPANY_NAME,
      label: 'Company',
      alignRight: false,
      canSort: true,
      enabled: true,
    },
    {
      id: USER_COLUMNS.EMAIL,
      label: 'Email',
      alignRight: false,
      canSort: true,
      enabled: true,
    },
    {
      id: USER_COLUMNS.TENANT_NAME,
      label: 'Tenant name',
      alignRight: false,
      canSort: true,
      enabled: true,
    },
    {
      id: USER_COLUMNS.PHONE_NUMBER,
      label: 'Phone number',
      alignRight: false,
      canSort: true,
      enabled: true,
    },
    {
      id: USER_COLUMNS.INVITED,
      label: 'Invited',
      alignRight: false,
      canSort: true,
      enabled: true,
    },
    {
      id: USER_COLUMNS.DATE_CREATED,
      label: 'User onboarded',
      alignRight: false,
      canSort: true,
      enabled: true,
    },
    {
      id: USER_COLUMNS.ACTIONS,
      label: 'Actions',
      alignRight: false,
      canSort: false,
      enabled: true,
    },
  ],
  managementVendors: [
    { id: 'Name', label: 'Name', alignRight: false, canSort: true, enabled: true },
    { id: 'ShortName', label: 'Short Name', alignRight: false, canSort: true, enabled: true },
    { id: 'Abbreviation', label: 'Abbreviation', alignRight: false, canSort: true, enabled: true },
  ],
  managementTickets: [
    { id: 'opened_by_name', label: 'Opened By', alignRight: false, canSort: true, enabled: true },
    { id: 'description', label: 'Description', alignRight: false, canSort: true, enabled: true },
    { id: 'status', label: 'Status', alignRight: false, canSort: false, enabled: true },
    { id: 'date_created', label: 'Created', alignRight: false, canSort: true, enabled: true },
    { id: 'action', label: 'Action', alignRight: false, canSort: false, enabled: true },
  ],
  dashboardAnnouncements: [
    { id: ANNOUNCEMENT_COLUMNS.TITLE, label: 'Title', alignRight: false, canSort: true, enabled: true },
    { id: ANNOUNCEMENT_COLUMNS.DATE_CREATED, label: 'Date Created', alignRight: false, canSort: true, enabled: true },
    { id: ANNOUNCEMENT_COLUMNS.ACTIONS, label: 'Actions', alignRight: false, canSort: false, enabled: true },
  ],
  managementNotifications: [
    { id: 'content', label: 'Content', alignRight: false, canSort: true, enabled: true },
    { id: 'notification_type_name', label: 'Notification Type', alignRight: false, canSort: false, enabled: true },
    { id: 'link', label: 'Link', alignRight: false, canSort: false, enabled: true },
    { id: 'date_created', label: 'Date', alignRight: false, canSort: true, enabled: true },
    { id: 'action', label: 'Action', alignRight: false, canSort: false, enabled: true },
  ],
  open: false,
  currentOperation: Operation.setDashboardSites,
};
