import { TenantAction, TenantActionType, TenantReducer, TenantPayload, TenantState } from './types';

export const initialState: TenantState = {
  limit: 15,
  cursor: undefined,
  order_by_direction: 'asc',
  order_by: 'name',
  search: undefined,
  search_field: undefined,
  tenant_pagination: undefined,
  tenant_content: undefined,
  error: undefined,
  is_loading: false,
  tenant_content_loading: false,
  tenant_create_loading: false,
  tenant_update_loading: false,
  tenant_request_obj: undefined,
  is_tenant_create_success: false,
  is_tenant_update_success: false,

  // Tenant options.
  tenant_options: undefined,
  is_tenant_options_loading: false,
};

const setTenants: TenantReducer = (
  state: TenantState,
  payload?: TenantPayload
) => ({
  ...state,
  tenant_pagination: (payload && payload.tenant_pagination) ?? state.tenant_pagination,
});

const setTenantSelectionId: TenantReducer = (state: TenantState, payload?: TenantPayload) => ({
  ...state,
  tenant_selection_id: payload ? payload.tenant_selection_id : undefined
});

const setSelectedTenant: TenantReducer = (state: TenantState, payload?: TenantPayload) => ({
  ...state,
  tenant_selection: payload ? payload.tenant_selection : undefined
});

const setError: TenantReducer = (state: TenantState, payload?: TenantPayload) => ({
  ...state,
  error: payload ? payload.error : undefined,
});

const setLoading: TenantReducer = (state: TenantState, payload?: TenantPayload) => ({
  ...state,
  is_loading: !!payload?.is_loading,
});

const setTenantContentLoading: TenantReducer = (state: TenantState, payload?: TenantPayload) => ({
  ...state,
  tenant_content_loading: (payload && payload.tenant_content_loading !== undefined) ? payload.tenant_content_loading : state.tenant_content_loading,
});

const setTenantCreateLoading: TenantReducer = (state: TenantState, payload?: TenantPayload) => ({
  ...state,
  tenant_create_loading: (payload && payload.tenant_create_loading !== undefined) ? payload.tenant_create_loading : state.tenant_create_loading,
});

const setTenantUpdateLoading: TenantReducer = (state: TenantState, payload?: TenantPayload) => ({
  ...state,
  tenant_update_loading: (payload && payload.tenant_update_loading !== undefined) ? payload.tenant_update_loading : state.tenant_update_loading,
});

const setLimit: TenantReducer = (
  state: TenantState,
  payload?: TenantPayload
) => ({
  ...state,
  limit: (payload && payload.limit) ?? state.limit,
});

const setCursor: TenantReducer = (
  state: TenantState,
  payload?: TenantPayload
) => ({
  ...state,
  cursor: (payload && payload.cursor) ?? state.cursor,
});

const setOrderBy: TenantReducer = (
  state: TenantState,
  payload?: TenantPayload
) => ({
  ...state,
  order_by: (payload && payload.order_by) ?? state.order_by,
});

const setOrderByDirection: TenantReducer = (
  state: TenantState,
  payload?: TenantPayload
) => ({
  ...state,
  order_by_direction: (payload && payload.order_by_direction) ?? state.order_by_direction,
});

const setSearch: TenantReducer = (
  state: TenantState,
  payload?: TenantPayload
) => ({
  ...state,
  search: (payload && payload.search) ?? state.search,
});

const setSearchField: TenantReducer = (
  state: TenantState,
  payload?: TenantPayload
) => ({
  ...state,
  search_field: (payload && payload.search_field) ?? state.search_field,
});

const setTenantContent: TenantReducer = (
  state: TenantState,
  payload?: TenantPayload
) => ({
  ...state,
  tenant_content: (payload && payload.tenant_content) ?? state.tenant_content,
});

const setTenantCreateSuccess: TenantReducer = (state: TenantState, payload?: TenantPayload) => ({
  ...state,
  is_tenant_create_success: (payload && payload.is_tenant_create_success) ?? state.is_tenant_create_success
});

const setTenantUpdateSuccess: TenantReducer = (state: TenantState, payload?: TenantPayload) => ({
  ...state,
  is_tenant_update_success: (payload && payload.is_tenant_update_success) ?? state.is_tenant_update_success
});

// Tenant options.
const setTenantOptions: TenantReducer = (
  state: TenantState,
  payload?: TenantPayload
) => ({
  ...state,
  tenant_options: (payload && payload.tenant_options) ?? state.tenant_options,
});

const setTenantOptionsLoading: TenantReducer = (
  state: TenantState,
  payload?: TenantPayload
) => ({
  ...state,
  is_tenant_options_loading:
    (payload && payload.is_tenant_options_loading) ?? state.is_tenant_options_loading,
});

const setTenantRequestObj: TenantReducer = (
  state: TenantState,
  payload?: TenantPayload
) => ({
  ...state,
  tenant_request_obj: (payload && payload.tenant_request_obj) ?? state.tenant_request_obj,
});

const clearState: TenantReducer = () => ({
  ...initialState,
});

const reducerMap = new Map<TenantActionType, TenantReducer>([
  [TenantActionType.SET_TENANTS, setTenants],
  [TenantActionType.SET_LIMIT, setLimit],
  [TenantActionType.SET_CURSOR, setCursor],
  [TenantActionType.SET_ORDERBY, setOrderBy],
  [TenantActionType.SET_ORDERBY_DIRECTION, setOrderByDirection],
  [TenantActionType.SET_SEARCH, setSearch],
  [TenantActionType.SET_SEARCH_FIELD, setSearchField],
  [TenantActionType.SET_LOADING, setLoading],
  [TenantActionType.SET_TENANT_CONTENT_LOADING, setTenantContentLoading],
  [TenantActionType.SET_TENANT_CREATE_LOADING, setTenantCreateLoading],
  [TenantActionType.SET_TENANT_UPDATE_LOADING, setTenantUpdateLoading],
  [TenantActionType.SET_TENANT_CREATE_SUCCESS, setTenantCreateSuccess],
  [TenantActionType.SET_TENANT_UPDATE_SUCCESS, setTenantUpdateSuccess],
  [TenantActionType.SET_TENANT_CONTENT, setTenantContent],
  [TenantActionType.SET_TENANT_REQUEST_OBJ, setTenantRequestObj],

  // Tenant options.
  [TenantActionType.SET_TENANT_OPTIONS, setTenantOptions],
  [TenantActionType.SET_TENANT_OPTIONS_LOADING, setTenantOptionsLoading],
  [TenantActionType.SET_TENANT_SELECTION_ID, setTenantSelectionId],
  [TenantActionType.SET_SELECTED_TENANT, setSelectedTenant],

  [TenantActionType.SET_ERROR, setError],
  [TenantActionType.CLEAR, clearState],
]);

const reducer = (
  state: TenantState = initialState, 
  action: TenantAction
): TenantState => {
  const reducer = reducerMap.get(action.type);
  if (reducer) return reducer(state, action.payload);
  return state;
};

export default reducer;
