import { UserWithCompanyResponse } from 'src/@types/user';
import { ProfileAction, ProfileActionType } from './types';

const actions = {
  setProfileUser: (user_content: UserWithCompanyResponse): ProfileAction => ({
    payload: { user_content },
    type: ProfileActionType.SET_PROFILE_USER,
  }),

  setLoading: (is_loading: boolean): ProfileAction => ({
    payload: { is_loading },
    type: ProfileActionType.SET_LOADING,
  }),

  setError: (error: Error | undefined): ProfileAction => ({
    payload: { error },
    type: ProfileActionType.SET_ERROR,
  }),

  update: (user_id: string, user_content: UserWithCompanyResponse): ProfileAction => ({
    payload: { user_id,  user_content },
    type: ProfileActionType.UPDATE_PROFILE,
  }),

  setUpdateLoading: (update_loading: boolean): ProfileAction => ({
    payload: { update_loading },
    type: ProfileActionType.SET_UPDATE_LOADING,
  }),

  setUpdateError: (update_error: Error | undefined): ProfileAction => ({
    payload: { update_error },
    type: ProfileActionType.SET_UPDATE_ERROR,
  }),

  clearState: (): ProfileAction => ({
    type: ProfileActionType.CLEAR,
  }),
};

export default actions;
