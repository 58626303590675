import { Stack, Typography, Box, Button } from 'ca-react-component-lib';
import { Onboarding } from 'src/@types/auth';
import { useAuth } from 'src/hooks';

const SuccessPanel = () => {

  const { setOnboarding } = useAuth();

  return (
  
    <Stack spacing={3}>
      <Stack direction="row" alignItems="center">
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h4" gutterBottom>
            Success
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            We have sent you and email with password set up instructions, please check your mail box and
            spam folder. If having issues please email support@consilienceanalytics.com
          </Typography>
        </Box>
      </Stack>
      <Button
        sx={{ textTransform: 'none' }}
        fullWidth
        size="large"
        variant="outlined"
        onClick={() => {
          setOnboarding(Onboarding.LOGIN);
        }}
      >
        <Typography>Return to Login</Typography>
      </Button>
    </Stack>
  );
};

export default SuccessPanel;