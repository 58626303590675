import { ApplicationState } from '../types';

const _getData = (state: ApplicationState) => state.SiteSearchQuery;

// Quick sites search dialog.
const getQuickSearchQuery = (state: ApplicationState) => _getData(state).quickSearchQuery;
const getQuickSearchQueryResult = (state: ApplicationState) =>
  _getData(state).quickSearchQueryResult;
const getQuickSearchLoading = (state: ApplicationState) => _getData(state).isQuickSearchLoading;

// Sites page.
const getSearchQuery = (state: ApplicationState) => _getData(state).searchQuery;
const getSearchQueryResult = (state: ApplicationState) => _getData(state).searchQueryResult;
const getSearchLoading = (state: ApplicationState) => _getData(state).isSearchLoading;

// Select options.
const getSelectOptionsLoading = (state: ApplicationState) => _getData(state).isSelectOptionsLoading;
const getAssetClassesSelectOptions = (state: ApplicationState) =>
  _getData(state).assetClassesSelectOptions;
const getStructureTypesSelectOptions = (state: ApplicationState) =>
  _getData(state).structureTypesSelectOptions;
const getTowerOwnersSelectOptions = (state: ApplicationState) =>
  _getData(state).towerOwnersSelectOptions;
const getVendorsSelectOptions = (state: ApplicationState) => _getData(state).vendorsSelectOptions;

const selectors = {
  // Quick search sites.
  getQuickSearchQuery,
  getQuickSearchQueryResult,
  getQuickSearchLoading,

  // Search sites page.
  getSearchQuery,
  getSearchQueryResult,
  getSearchLoading,

  // select options.
  getSelectOptionsLoading,
  getAssetClassesSelectOptions,
  getStructureTypesSelectOptions,
  getTowerOwnersSelectOptions,
  getVendorsSelectOptions,
};

export default selectors;
