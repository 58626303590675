import { AnnouncementPagination, AnnouncementContent, AnnouncementEditorObj } from 'src/@types/announcement';
import { AnnouncementAction, AnnouncementActionType } from './types';

const actions = {
  fetchAllAnnouncements: (): AnnouncementAction => ({
    type: AnnouncementActionType.FETCH_ALL_ANNOUNCEMENTS,
  }),
  setAnnouncement: (announcement_pagination: AnnouncementPagination): AnnouncementAction => ({
    payload: { announcement_pagination },
    type: AnnouncementActionType.SET_ANNOUNCEMENT,
  }),
  setAnnouncementContent: (announcement_content: AnnouncementContent): AnnouncementAction => ({
    payload: { announcement_content },
    type: AnnouncementActionType.SET_ANNOUNCEMENT_CONTENT,
  }),
  fetchAnnouncementContent: (announcement_id: string): AnnouncementAction => ({
    payload: { announcement_id },
    type: AnnouncementActionType.FETCH_ANNOUNCEMENT_CONTENT,
  }),
  setLimit: (limit: number): AnnouncementAction => ({
    payload: { limit },
    type: AnnouncementActionType.SET_LIMIT,
  }),
  setCursor: (cursor: string): AnnouncementAction => ({
    payload: { cursor },
    type: AnnouncementActionType.SET_CURSOR,
  }),
  setOrderBy: (order_by: 'date_created' | 'title'): AnnouncementAction => ({
    payload: { order_by },
    type: AnnouncementActionType.SET_ORDERBY,
  }),
  setOrderByDirection: (order_by_direction: 'asc' | 'desc'): AnnouncementAction => ({
    payload: { order_by_direction },
    type: AnnouncementActionType.SET_ORDERBY_DIRECTION,
  }),
  setSearch: (search: string): AnnouncementAction => ({
    payload: { search },
    type: AnnouncementActionType.SET_SEARCH,
  }),
  setSearchField: (search_field: 'title' | 'content'): AnnouncementAction => ({
    payload: { search_field },
    type: AnnouncementActionType.SET_SEARCH_FIELD,
  }),
  setLoading: (is_loading: boolean): AnnouncementAction => ({
    payload: { is_loading },
    type: AnnouncementActionType.SET_LOADING,
  }),
  setAnnouncementEditorObj: (announcement_editor_obj: AnnouncementEditorObj): AnnouncementAction => ({
    payload: { announcement_editor_obj },
    type: AnnouncementActionType.SET_ANNOUNCEMENT_EDITOR_OBJ,
  }),
  setIsEditorUploading: (is_editor_uploading: boolean): AnnouncementAction => ({
    payload: { is_editor_uploading },
    type: AnnouncementActionType.SET_IS_EDITOR_UPLOADING,
  }),
  setFinishEditorUploading: (finish_editor_uploading: boolean): AnnouncementAction => ({
    payload: { finish_editor_uploading },
    type: AnnouncementActionType.SET_FINISH_EDITOR_UPLOADING,
  }),
  postAnnouncement: (editor_content: string): AnnouncementAction => ({
    payload: { editor_content },
    type: AnnouncementActionType.POST_ANNOUNCEMENT,
  }),
  patchAnnouncement: (announcement_id: string, editor_content: string): AnnouncementAction => ({
    payload: { announcement_id, editor_content },
    type: AnnouncementActionType.PATCH_ANNOUNCEMENT,
  }),
  setError: (error: Error): AnnouncementAction => ({
    payload: { error },
    type: AnnouncementActionType.SET_ERROR,
  }),
  clearState: (): AnnouncementAction => ({
    type: AnnouncementActionType.CLEAR,
  }),
};

export default actions;
