import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_QUEUE_ID } from 'src/sections/@dashboard/Processing/Queued/constants';

// actions
import actions from 'src/store/Processing/actions';

// selectors
import processingSelectors from 'src/store/Processing/selectors';

const autobahn = require('autobahn');

const CROSSBAR_TOPIC_PREFIX = `${process.env.REACT_APP_CROSSBAR_TOPIC_PREFIX}`;
const CROSSBAR_CALL_QUEUED = `${CROSSBAR_TOPIC_PREFIX}.contextcapture-automation.job.queue.get`;
const CROSSBAR_SUBSCRIBE_JOBS = `${CROSSBAR_TOPIC_PREFIX}.contextcapture-automation.job.logs.updated`;
const CROSSBAR_SUBSCRIBE_QUEUED = `${CROSSBAR_TOPIC_PREFIX}.contextcapture-automation.job.queue.updated`;
const CROSSBAR_MOVE_TO_TOP = `${CROSSBAR_TOPIC_PREFIX}.contextcapture-automation.job.queue.message.move-to-top`;
const CROSSBAR_MOVE_TO_ANOTHER_QUEUE = `${CROSSBAR_TOPIC_PREFIX}.contextcapture-automation.job.queue.message.move-to-another-queue`;
const CROSSBAR_DELETE = `${CROSSBAR_TOPIC_PREFIX}.contextcapture-automation.job.queue.message.delete`;

const groupByProcessId = (process: any | undefined, processList: any[]): any[] => {
  if (!process) {
    return [];
  }

  let processListTemp: any[] = processList;

  const index = processListTemp.indexOf(
    processListTemp.find((p: any) => p.process_id === process.process_id)
  );
  if (index >= 0) {
    processListTemp[index] = process;
  } else {
    processListTemp.push(process);
  }
  return [...processListTemp].sort((job1, job2) =>
    new Date(job1.process_started_at) > new Date(job2.process_started_at) ? -1 : 0
  );
};

const connection = new autobahn.Connection({
  url: process.env.REACT_APP_CROSSBAR_SERVER_URL,
  realm: process.env.REACT_APP_CROSSBAR_REALM,
  authmethods: [process.env.REACT_APP_CROSSBAR_AUTH_METHODS],
  authid: process.env.REACT_APP_CROSSBAR_AUTH_ID,
  onchallenge: () => process.env.REACT_APP_CROSSBAR_CHALLENGE,
});

const useProcessing = () => {
  const jobs = useSelector(processingSelectors.getJobs);
  const jobsFiltered = useSelector(processingSelectors.getJobsFiltered);
  const jobsRunning = useSelector(processingSelectors.getJobsRunning);
  const jobsFinished = useSelector(processingSelectors.getJobsFinished);
  const jobsFailed = useSelector(processingSelectors.getJobsFailed);
  const queued = useSelector(processingSelectors.getQueued);
  const queuedFiltered = useSelector(processingSelectors.getQueuedFiltered);
  const altQueued = useSelector(processingSelectors.getAltQueued);

  const isOpen = useSelector(processingSelectors.getIsOpen);
  const isLoading = useSelector(processingSelectors.getIsLoading);
  const searchQuery = useSelector(processingSelectors.getSearchQuery);

  const sessionState = useSelector(processingSelectors.getSession);
  const targetQueueId = useSelector(processingSelectors.getTargetQueueId);
  const modelProcessingQueues = useSelector(processingSelectors.getModelProcessingQueues);

  const dispatch = useDispatch();

  const setLoading = useCallback(
    (isLoading) => {
      dispatch(actions.setLoading(isLoading));
    },
    [dispatch]
  );

  const setProcessingOpen = useCallback(() => {
    dispatch(actions.setProcessingOpen());
  }, [dispatch]);

  const setProcessingClose = useCallback(() => {
    dispatch(actions.setProcessingClose());
  }, [dispatch]);

  const setProcessingJobs = useCallback(
    (currentJobs) => {
      dispatch(actions.setProcessingJobs(currentJobs));
    },
    [dispatch]
  );

  const setProcessingQueued = useCallback(
    (currentQueued) => {
      dispatch(actions.setProcessingQueued(currentQueued));
    },
    [dispatch]
  );

  const fetchModelProcessingQueues = useCallback(
    () => {
      dispatch(actions.fetchModelProcessingQueues());
    }, [dispatch]
  );

  const open = useCallback(() => {
    if (isOpen) return;
    connection.onopen = (session: any, detail: string) => {
      setProcessingOpen();
      setLoading(false);

      session.call(CROSSBAR_CALL_QUEUED).then((res: any) => setProcessingQueued(res));

      session.subscribe(
        CROSSBAR_SUBSCRIBE_JOBS,
        function () {
          // res is a object of processing.
          const res: any = arguments[0][0];
          setProcessingJobs(groupByProcessId(res, jobs));
        },
        { match: 'exact' }
      );

      session.subscribe(
        CROSSBAR_SUBSCRIBE_QUEUED,
        function () {
          // res is a array of processing.
          const res = arguments[0][0];
          setProcessingQueued(res);
        },
        { match: 'exact' }
      );
      dispatch(actions.setSession(session));
    };

    connection.onclose = () => setProcessingClose();
    connection.open();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const close = useCallback(() => {
    if (isOpen) connection.close();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setSearchQuery = useCallback(
    (searchQuery) => {
      dispatch(actions.setSearchQuery(searchQuery));
    },
    [dispatch]
  );

  const moveQueuedMessageToTop = useCallback((msg_queue_id: string, currentQueueId: string) => {
    if (!sessionState) return;
    sessionState.call(CROSSBAR_MOVE_TO_TOP, [], {
      message_queue_id: msg_queue_id,
      alternate_queue: currentQueueId === DEFAULT_QUEUE_ID ? null : currentQueueId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionState]);

  const setTargetQueueId = useCallback(
    (targetQueueId: string) => {
      dispatch(actions.setTargetQueueId(targetQueueId));
    },
    [dispatch]
  );

  const moveToAnotherQueue = useCallback(
    (msg_queue_id: string, targetQueueId: string, currentQueueId: string) => {
      if (!sessionState) return;

      sessionState.call(CROSSBAR_MOVE_TO_ANOTHER_QUEUE, [], {
        message_queue_id: msg_queue_id,
        target_queue: targetQueueId === DEFAULT_QUEUE_ID ? null : targetQueueId,
        alternate_queue: currentQueueId === DEFAULT_QUEUE_ID ? null : currentQueueId,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [sessionState]
  );

  const removeFromQueue = useCallback((msg_queue_id: string, currentQueueId: string) => {
    if (!sessionState) return;
    sessionState.call(CROSSBAR_DELETE, [], {
      message_queue_id: msg_queue_id,
      alternate_queue: currentQueueId === DEFAULT_QUEUE_ID ? null : currentQueueId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionState]);

  return {
    jobs,
    jobsFiltered,
    jobsRunning,
    jobsFinished,
    jobsFailed,
    queued,
    queuedFiltered,
    altQueued,
    isOpen,
    isLoading,
    searchQuery,
    sessionState,
    targetQueueId,
    setProcessingOpen,
    setProcessingClose,
    open,
    close,
    setSearchQuery,
    moveQueuedMessageToTop,
    setTargetQueueId,
    moveToAnotherQueue,
    removeFromQueue,
    modelProcessingQueues,
    fetchModelProcessingQueues,
  };
};

export default useProcessing;
