import { getData, setData, clearData } from 'ca-react-util-lib';

// types
import { LabelValue, KEYS } from 'src/@types/root';

export const getTodoTenant = (): LabelValue | undefined =>
  getData(KEYS.TODO_TENANT) === null ? undefined : JSON.parse(getData(KEYS.TODO_TENANT) || '{}');

export const setTodoTenant = (tenant: LabelValue) =>
  setData(KEYS.TODO_TENANT, JSON.stringify(tenant));

export const clearTodoTenant = () => clearData(KEYS.TODO_TENANT);
