import { UserWithCompanyResponse } from 'src/@types/user';

export enum ProfileActionType {
  SET_PROFILE_USER = 'Profile@SET_PROFILE_USER',
  SET_LOADING = 'Profile@SET_LOADING',
  SET_ERROR = 'Profile@SET_ERROR',
  UPDATE_PROFILE = 'Profile@UPDATE_PROFILE',
  SET_UPDATE_LOADING = 'Profile@SET_UPDATE_LOADING',
  SET_UPDATE_ERROR = 'Profile@SET_UPDATE_ERROR',
  CLEAR = 'Profile@CLEAR',
}

export type ProfileState = {
  user_content: UserWithCompanyResponse;
  is_loading: boolean;
  error?: Error;
  update_loading: boolean;
  update_error?: Error;
};

export type ProfilePayload = {
  user_content?: UserWithCompanyResponse;
  is_loading?: boolean;
  company_id?: string;
  error?: Error;
  update_loading?: boolean;
  update_error?: Error;
  user_id?: string;
};

export type ProfileAction = {
  payload?: ProfilePayload;
  type: ProfileActionType;
};

export type ProfileReducer = (state: ProfileState, payload?: ProfilePayload) => ProfileState;
