import { TicketPagination, TicketStatusContent, TicketRequestObj } from 'src/@types/ticket';

export type TicketState = {
    popover_opened: boolean;
    error?: Error;
    ticket_status?: TicketStatusContent;
    ticket_pagination?: TicketPagination;
    limit: number;
    cursor?: string;
    order_by?: 'date_created' | 'description' | 'url';
    order_by_direction?: 'asc' | 'desc';
    search?: string;
    search_field?: 'description' | 'url';
    status_id?: string;
    is_loading: boolean;
    is_ticket_update_success?: boolean;
}

export enum TicketActionType {
    FETCH_ALL_TICKETS = 'Ticket@FETCH_ALL_TICKETS',
    SET_TICKET = 'Ticket@SET_TICKET',
    SET_LIMIT = 'Ticket@SET_LIMIT',
    SET_CURSOR = 'Ticket@SET_CURSOR',
    SET_ORDERBY = 'Ticket@SET_ORDERBY',
    SET_ORDERBY_DIRECTION = 'Ticket@SET_ORDERBY_DIRECTION',
    SET_SEARCH = 'Ticket@SET_SEARCH',
    SET_SEARCH_FIELD = 'Ticket@SET_SEARCH_FIELD',
    SET_STATUS_ID = 'Ticket@SET_STATUS_ID',
    SET_POPOVER_OPENED = 'Ticket@SET_POPOVER_OPENED',
    SET_LOADING = 'Ticket@SET_LOADING',
    SET_TICKET_UPDATE_SUCCESS = 'Ticket@SET_TICKET_UPDATE_SUCCESS',
    SET_ERROR = 'Ticket@SET_ERROR',
    CLEAR = 'Ticket@CLEAR',
    PATCH_TICKET = 'Ticket@PATCH_TICKET',
    FETCH_TICKET_STATUS = 'Ticket@FETCH_TICKET_STATUS',
    SET_TICKET_STATUS = 'Ticket@SET_TICKET_STATUS',

}

export type TicketPayload = {
    patch_ticket?: TicketRequestObj;
    found_status_id?: string;
    status?: string;
    popover_opened?: boolean;
    error?: Error;
    ticket_status?: TicketStatusContent,
    ticket_pagination?: TicketPagination;
    limit?: number;
    cursor?: string;
    order_by?: 'date_created' | 'description' | 'url';
    order_by_direction?: 'asc' | 'desc';
    search?: string;
    search_field?: 'description' | 'url';
    status_id?: string;
    is_loading?: boolean;
    is_ticket_update_success?: boolean;
    ticket_id?: string;
};

export type TicketAction = {
    payload?: TicketPayload;
    type: TicketActionType;
};

export type TicketReducer = (state: TicketState, payload?: TicketPayload) => TicketState
