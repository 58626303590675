import { ApplicationState } from '../types';

const _getData = (state: ApplicationState) => state.Users;

const getError = (state: ApplicationState) => _getData(state).error;

const isLoading = (state: ApplicationState) => _getData(state).is_loading;

const getUsers = (state: ApplicationState) => _getData(state).user_pagination;

const getLimit = (state: ApplicationState) => _getData(state).limit;

const getCursor = (state: ApplicationState) => _getData(state).cursor;

const getOrderBy = (state: ApplicationState) => _getData(state).order_by;

const getOrderByDirection = (state: ApplicationState) => _getData(state).order_by_direction;

const getSearch = (state: ApplicationState) => _getData(state).search;

const getSearchField = (state: ApplicationState) => _getData(state).search_field;


const getCompanyId = (state: ApplicationState) => _getData(state).company_id;

const getExcludeInvited = (state: ApplicationState) => _getData(state).exclude_invited;

// edit user.

const getUserContentLoading = (state: ApplicationState) => _getData(state).is_user_content_loading;

const getUserUpdateLoading = (state: ApplicationState) => _getData(state).is_user_update_loading;

const getUserContent = (state: ApplicationState) => _getData(state).user_content;

const getUserUpdateSuccess = (state: ApplicationState) => _getData(state).is_user_update_success;

// add user.
const getUserCreateLoading = (state: ApplicationState) => _getData(state).is_user_create_loading;

const getUserCreateSuccess = (state: ApplicationState) => _getData(state).is_user_create_success;

// users simple.
const getUserOptions = (state: ApplicationState) => _getData(state).user_options;

const getUserOptionsLoading = (state: ApplicationState) =>
  _getData(state).is_user_options_loading;

const getUserOptionsSelectionId = (state: ApplicationState) => _getData(state).user_options_selection_id;

const getMinRoleLevel = (state: ApplicationState) => _getData(state).min_role_level;

const getMaxRoleLevel = (state: ApplicationState) => _getData(state).max_role_level;


// role types.
const getRoleTypes = (state: ApplicationState) => _getData(state).role_types;

const getRoleTypesLoading = (state: ApplicationState) => _getData(state).is_role_types_loading;

// bulk insert.
const getBulkInsertResult = (state: ApplicationState) => _getData(state).bulk_insert_result;

const getBulkInsertLoading = (state: ApplicationState) => _getData(state).is_bulk_insert_loading;

// role options.
const getRoleOptions = (state: ApplicationState) => _getData(state).role_options;

const getRoleOptionsLoading = (state: ApplicationState) => _getData(state).is_role_options_loading;




const selectors = {
  getError,
  isLoading,
  getUsers,
  getSearch,
  getSearchField,
  getLimit,
  getCursor,
  getOrderBy,
  getOrderByDirection,
  getCompanyId,
  getExcludeInvited,

  // edit user.
  getUserContentLoading,
  getUserUpdateLoading,
  getUserContent,
  getUserUpdateSuccess,

  // add user.
  getUserCreateLoading,
  getUserCreateSuccess,

  // users simple.
  getUserOptions,
  getUserOptionsLoading,
  getUserOptionsSelectionId,
  getMinRoleLevel,
  getMaxRoleLevel,

  // role types.
  getRoleTypes,
  getRoleTypesLoading,

  // bulk insert.
  getBulkInsertResult,
  getBulkInsertLoading,

  // role options.
  getRoleOptions,
  getRoleOptionsLoading,
};

export default selectors;
