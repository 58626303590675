import { call, put, select } from "redux-saga/effects";
import actions from "../actions";
import selectors from "../selectors";
import { fetchTodoQueue, fetchTodoProcessing } from "./pipeline";

export function* updateBucketsAfterSubmitForProcessingSuccess() {
  try {
    yield put(actions.setSpeedDialAction());

    const tenantId: string = yield select(selectors.getTenantId);

    const queue = yield call(fetchTodoQueue, { tenantId: tenantId });
    const processing = yield call(fetchTodoProcessing, { tenantId: tenantId });
    if(queue || processing) {
      yield put(actions.setFilterBucketsAfterMovingAsset(true));
    }
  } catch (e) {
    yield put(actions.setError(e as Error));
  } finally {
    yield put(actions.setFilterBucketsAfterMovingAsset(false));
  }
};
