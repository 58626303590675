import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TenantWithCompanyContent, TenantOptionsContent } from 'src/@types/tenant';
import tenantActions from 'src/store/Tenant/actions';
import tenantSelectors from 'src/store/Tenant/selectors';

const useTenant = () => {
  const tenants = useSelector(tenantSelectors.getTenants);
  const tenantContent = useSelector(tenantSelectors.getTenantContent);
  const tenantRequestObj = useSelector(tenantSelectors.getTenantRequestObj);
  const isLoading = useSelector(tenantSelectors.isLoading);
  const limit = useSelector(tenantSelectors.getLimit);
  const cursor = useSelector(tenantSelectors.getCursor);
  const orderBy = useSelector(tenantSelectors.getOrderBy);
  const orderByDirection  = useSelector(tenantSelectors.getOrderByDirection);
  const search = useSelector(tenantSelectors.getSearch);
  const searchField = useSelector(tenantSelectors.getSearchField);
  const tenantContentLoading = useSelector(tenantSelectors.isTenantContentLoading);
  const tenantCreateLoading = useSelector(tenantSelectors.isTenantCreateLoading);
  const tenantUpdateLoading = useSelector(tenantSelectors.isTenantUpdateLoading);
  const isTenantCreateSuccess = useSelector(tenantSelectors.getTenantCreateSuccess);
  const isTenantUpdateSuccess = useSelector(tenantSelectors.getTenantUpdateSuccess);
  const error = useSelector(tenantSelectors.getError);

  // Tenant options.
  const tenantOptions = useSelector(tenantSelectors.getTenantOptions);
  const isTenantOptionsLoading = useSelector(tenantSelectors.getTenantOptionsLoading);
  const tenantSelectionId = useSelector(tenantSelectors.getTenantSelectionId);
  const selectedTenant = useSelector(tenantSelectors.getSelectedTenant);

  const dispatch = useDispatch();

  const fetchAllTenants = useCallback(() => {
    dispatch(tenantActions.fetchAllTenants());
  },
  [dispatch]
  );

  const fetchTenantContent = useCallback(
    (tenant_id: string) => {
      dispatch(tenantActions.fetchTenantContent(tenant_id));
    },
    [dispatch]
  );  
 
  const fetchTenantOptions = useCallback(
    (search?: string) => {
      dispatch(tenantActions.fetchTenantOptions(search));
    },
    [dispatch]
  );

  const setTenantOptionsLoading = useCallback(
    (is_tenant_options_loading: boolean) => {
      dispatch(tenantActions.setTenantOptionsLoading(is_tenant_options_loading));
    },
    [dispatch]
  );

  const setTenantSelectionId = useCallback(
    (tenant_selection_id: any) => {
      dispatch(tenantActions.setTenantSelectionId(tenant_selection_id));
    },
    [dispatch]
  );

  const setSelectedTenant = useCallback(
    (tenant_selection: any) => {
      dispatch(tenantActions.setSelectedTenant(tenant_selection));
    },
    [dispatch]
  );

  const setTenantOptions = useCallback(
    (tenant_options: TenantOptionsContent) => {
      dispatch(tenantActions.setTenantOptions(tenant_options));
    },
    [dispatch]
  );

  const createTenant = useCallback(
    (tenant: TenantWithCompanyContent) => {
      dispatch(tenantActions.postTenant(tenant));
    },
    [dispatch]
  );

  const updateTenant = useCallback(
    (tenant: TenantWithCompanyContent, tenant_id: string) => {
      dispatch(tenantActions.patchTenant(tenant_id, tenant));
    },
    [dispatch]
  );

  const setLimit = useCallback(
    (limit: number) => {
      dispatch(tenantActions.setLimit(limit));
    },
    [dispatch]
  );

  const setCursor = useCallback(
    (cursor: string) => {
      dispatch(tenantActions.setCursor(cursor));
    },
    [dispatch]
  );

  const setOrderBy = useCallback(
    (order_by: 'name' | 'subdomain') => {
      dispatch(tenantActions.setOrderBy(order_by));
    },
    [dispatch]
  );

  const setOrderByDirection = useCallback(
    (order_by_direction: 'asc' | 'desc') => {
      dispatch(tenantActions.setOrderByDirection(order_by_direction));
    },
    [dispatch]
  );

  const setSearchField = useCallback(
    (search_field: 'name' | 'subdomain') => {
      dispatch(tenantActions.setSearchField(search_field));
    },
    [dispatch]
  );

  const setSearch = useCallback(
    (search: string) => {
      dispatch(tenantActions.setSearch(search));
    },
    [dispatch]
  );

  const clearState = useCallback(
    () => dispatch(tenantActions.clearState()),
    [dispatch]
  );

  return {
    tenants,
    limit,
    cursor,
    orderBy,
    orderByDirection,
    searchField,
    search,
    tenantContent,
    tenantRequestObj,
    isLoading,
    tenantContentLoading,
    tenantCreateLoading,
    tenantUpdateLoading,
    fetchAllTenants,
    setLimit,
    setCursor,
    setOrderBy,
    setOrderByDirection,
    setSearchField,
    setSearch,
    createTenant,
    updateTenant,
    fetchTenantContent,
    isTenantCreateSuccess,
    isTenantUpdateSuccess,
    error,

    // Tenant options.
    fetchTenantOptions,
    setTenantOptions,
    setTenantOptionsLoading,
    setTenantSelectionId,
    setSelectedTenant,
    tenantOptions,
    isTenantOptionsLoading,
    tenantSelectionId,
    selectedTenant,
    clearState,
  };
};

export default useTenant;
