import { TOAST_SEVERITY } from "src/@types/toast";
import { ToastAction, ToastActionType } from './types';

const actions = {
  setToast: (message: string, severity: TOAST_SEVERITY = TOAST_SEVERITY.SUCCESS, options?: any): ToastAction => ({
    payload: { message, severity, options },
    type: ToastActionType.SET_TOAST,
  }),

  clearState: () => ({
    type: ToastActionType.CLEAR,
  }),
};

export default actions;
