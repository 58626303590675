// api
import api from './api';
import { QCFormContent, QCFormListContent, QCFormRequestObj, StatusColorOptionsCotent, FalloutReasonOptionsContent } from 'src/@types/qcForm';

import {
  QCFormResponseSchema,
  QCFormListResponseSchema,
  StatusColorOptionsResponseSchema,
  FalloutReasonOptionsResponseSchema,
} from './types';


export const fetchAssessmentQCForms = async (
  assessment_id: string
): Promise<QCFormListContent> => {
  try 
  {
    const response: QCFormListResponseSchema = await api.fetchAssessmentQCForms(assessment_id);
    return response;
  } 
  catch (e) 
  {
    throw e;
  }
};

export const fetchAssessmentLastQC = async (assessment_id: any): Promise<QCFormContent> => {
  try 
  {
    const response: QCFormResponseSchema = await api.fetchAssessmentLastQCForm(assessment_id);
    return response;
  } 
  catch(e) 
  {
    throw e;
  }
};

export const postQCForm = async (
  qc_form: QCFormRequestObj
): Promise<QCFormContent> => {
  try 
  {
    const response: QCFormResponseSchema = await api.postQCForm(qc_form);
    return response;
  } 
  catch(e) 
  {
    throw e;
  }
};

export const patchQCForm = async (
  qc_form_id: string,
  qc_form: QCFormRequestObj
): Promise<QCFormContent> => {
  try 
  {
    const response: QCFormResponseSchema = await api.patchQCForm(qc_form_id, qc_form);
    return response;
  } 
  catch(e) 
  {
    throw e;
  }
};

export const fetchQCStatusColorOptions = async (): Promise<StatusColorOptionsCotent> => {
  try 
  {
    const response: StatusColorOptionsResponseSchema = await api.fetchQCStatusColorOptions();
    return response;
  } 
  catch(e) 
  {
    console.error(e);
    throw e;
  }
};

export const fetchQCFalloutReasonOptions = async (): Promise<FalloutReasonOptionsContent> => {
  try 
  {
    const response: FalloutReasonOptionsResponseSchema = await api.fetchQCFalloutReasonOptions();
    return response;
  } 
  catch(e) 
  {
    console.error(e);
    throw e;
  }
};
