
import { TicketPagination, TicketRequestObj } from "src/@types/ticket";
import { TicketAction, TicketActionType } from "./types";

const actions = {
  fetchAllTickets: (): TicketAction => ({
    type: TicketActionType.FETCH_ALL_TICKETS
  }),
  setTicket: (ticket_pagination: TicketPagination): TicketAction => ({
    payload: { ticket_pagination },
    type: TicketActionType.SET_TICKET,
  }),
  setLimit: (limit: number): TicketAction => ({
    payload: { limit },
    type: TicketActionType.SET_LIMIT,
  }),
  setCursor: (cursor: string): TicketAction => ({
    payload: { cursor },
    type: TicketActionType.SET_CURSOR,
  }),
  setOrderBy: (order_by: 'date_created' | 'description' | 'url'): TicketAction => ({
    payload: { order_by },
    type: TicketActionType.SET_ORDERBY,
  }),
  setOrderByDirection: (order_by_direction: 'asc' | 'desc'): TicketAction => ({
    payload: { order_by_direction },
    type: TicketActionType.SET_ORDERBY_DIRECTION,
  }),
  setSearch: (search: string): TicketAction => ({
    payload: { search },
    type: TicketActionType.SET_SEARCH,
  }),
  setSearchField: (search_field: 'description' | 'url'): TicketAction => ({
    payload: { search_field },
    type: TicketActionType.SET_SEARCH_FIELD,
  }),
  setStatusId: (status_id: string): TicketAction => ({
    payload: { status_id },
    type: TicketActionType.SET_STATUS_ID,
  }),
  setPopoverOpened: (popover_opened: boolean): TicketAction => ({
    payload: { popover_opened },
    type: TicketActionType.SET_POPOVER_OPENED
  }),
  setLoading: (is_loading: boolean): TicketAction => ({
    payload: { is_loading },
    type: TicketActionType.SET_LOADING
  }),
  patchAnnouncement: (ticket_id: string, patch_ticket: TicketRequestObj): TicketAction => ({
    payload: { ticket_id, patch_ticket },
    type: TicketActionType.PATCH_TICKET,
  }),
  fetchTicketStatus: (): TicketAction => ({
    type: TicketActionType.FETCH_TICKET_STATUS  
  }),
  setTicketStatus: (ticket_status: any): TicketAction => ({
    payload: { ticket_status },
    type: TicketActionType.SET_TICKET_STATUS
  }),
  setError: (error: Error): TicketAction => ({
    payload: { error },
    type: TicketActionType.SET_ERROR,
  }),
  clearState: (): TicketAction => ({
    type: TicketActionType.CLEAR,
  }),
};

export default actions;
