import { takeLatest } from 'redux-saga/effects';

// types
import { ProcessingPipelineActionType } from '../types';

// todo
import {
  fetchAllTodoInfo,
  fetchTodoWaitingForImages,
  fetchTodoQueue,
  fetchTodoProcessing,
  fetchTodoPerformQC,
  fetchTodoCompleted,
  fetchTodoMeasurements,
  fetchTodoReprocessing,
  fetchTodoRefly,


} from './pipeline';

// edit
import { editSiteAssessmentStaging, updateSiteAfterEditSiteAssessmentStaging } from './edit';

// tiePoints
import { scrollToNextSelectedTiePointPhoto } from './tiePoints';

// processing
import { updateBucketsAfterSubmitForProcessingSuccess } from './processing';

const sagas = [
  takeLatest(ProcessingPipelineActionType.FETCH_WAITING_FOR_IMAGES, fetchTodoWaitingForImages),
  takeLatest(ProcessingPipelineActionType.FETCH_QUEUE_FOR_PROCESSING, fetchTodoQueue),
  takeLatest(ProcessingPipelineActionType.FETCH_WAIT_FOR_PROCESSING, fetchTodoProcessing),
  takeLatest(ProcessingPipelineActionType.FETCH_PERFORM_QC, fetchTodoPerformQC),
  takeLatest(ProcessingPipelineActionType.FETCH_COMPLETED, fetchTodoCompleted),
  takeLatest(ProcessingPipelineActionType.FETCH_REQUEST_REFLY, fetchTodoRefly),
  takeLatest(ProcessingPipelineActionType.FETCH_REQUEST_MEASUREMENTS, fetchTodoMeasurements),
  takeLatest(ProcessingPipelineActionType.FETCH_RE_PROCESS, fetchTodoReprocessing),
  takeLatest(ProcessingPipelineActionType.FETCH_ALL_INFO, fetchAllTodoInfo),
  takeLatest(ProcessingPipelineActionType.EDIT_SITE_ASSESSMENT_STAGING, editSiteAssessmentStaging),
  takeLatest(ProcessingPipelineActionType.SCROLL_TO_NEXT_SELECTED_TIE_POINT_PHOTO, scrollToNextSelectedTiePointPhoto),
  takeLatest(ProcessingPipelineActionType.UPDATE_BUCKETS_AFTER_SUBMIT_FOR_PROCESSING_SUCCESS, 
    updateBucketsAfterSubmitForProcessingSuccess),
  takeLatest(ProcessingPipelineActionType.UPDATE_SITE_AFTER_EDIT_SITE_ASSESSMENT_STAGING, 
    updateSiteAfterEditSiteAssessmentStaging),
];

export default sagas;
