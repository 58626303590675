import { Onboarding } from 'src/@types/auth';
import {
  AuthenticationAction,
  AuthenticationActionType,
  AuthenticationReducer,
  AuthenticationPayload,
  AuthenticationState,
} from './types';

export const initialState: AuthenticationState = {
  authenticated: false,
  emailSent: false,
  passwordReset: false,
  initialized: false,
  error: undefined,
  loading: false,
  onBoarding: Onboarding.LOGIN,
  onboardingError: undefined,
};

const setError: AuthenticationReducer = (
  state: AuthenticationState,
  payload?: AuthenticationPayload
) => ({
  ...state,
  error: payload ? payload.error : undefined,
});

const setLoading: AuthenticationReducer = (
  state: AuthenticationState,
  payload?: AuthenticationPayload
) => ({
  ...state,
  loading: !!payload?.loading,
});

const setEmailSent: AuthenticationReducer = (
  state: AuthenticationState,
  payload?: AuthenticationPayload
) => ({
  ...state,
  emailSent: !!payload?.emailSent,
});

const setPasswordReset: AuthenticationReducer = (
  state: AuthenticationState,
  payload?: AuthenticationPayload
) => ({
  ...state,
  passwordReset: !!payload?.passwordReset,
});

const setAuthenticated: AuthenticationReducer = (state: AuthenticationState) => ({
  ...state,
  authenticated: true,
});

const setOnboarding: AuthenticationReducer = (state: AuthenticationState, payload?: AuthenticationPayload) => ({
  ...state,
  onBoarding: (payload && payload.onBoarding) ?? state.onBoarding
});

const setOnboardingError: AuthenticationReducer = (state: AuthenticationState, payload?: AuthenticationPayload) => ({
  ...state,
  onboardingError: (payload && payload.onboardingError) ?? state.onboardingError
});

const setPasswordResetError: AuthenticationReducer = (state: AuthenticationState, payload?: AuthenticationPayload) => ({
  ...state,
  passwordResetError: (payload && payload.passwordResetError) ?? state.passwordResetError
});

const setInitialized: AuthenticationReducer = (state: AuthenticationState) => ({
  ...state,
  initialized: true,
});

const clearState: AuthenticationReducer = () => ({
  ...initialState,
});

const reducerMap = new Map<AuthenticationActionType, AuthenticationReducer>([
  [AuthenticationActionType.SET_AUTHENTICATED, setAuthenticated],
  [AuthenticationActionType.SET_LOADING, setLoading],
  [AuthenticationActionType.SET_INITIALIZED, setInitialized],
  [AuthenticationActionType.SET_EMAIL_SENT, setEmailSent],
  [AuthenticationActionType.SET_PASSWORD_RESET_SUCCESS, setPasswordReset],
  [AuthenticationActionType.SET_ERROR, setError],
  [AuthenticationActionType.CLEAR, clearState],
  [AuthenticationActionType.SET_ONBOARDING, setOnboarding],
  [AuthenticationActionType.SET_ONBOARDING_ERROR, setOnboardingError],
  [AuthenticationActionType.SET_PASSWORD_RESET_ERROR, setPasswordResetError],

]);

const reducer = (
  state: AuthenticationState = initialState,
  action: AuthenticationAction
): AuthenticationState => {
  const reducer = reducerMap.get(action.type);
  if (reducer) return reducer(state, action.payload);
  return state;
};

export default reducer;
