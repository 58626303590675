import {
  ExportJobsState,
  ExportJobsAction,
  ExportJobsReducer,
  ExportJobsPayload,
  ExportJobsActionType,
} from './types';

export const initialState: ExportJobsState = {
  exportJobs: [],
  exportJobsLoading: false,
  exportJobsError: undefined,
  column: "job_created_at",
  sortBy: "desc"
};

const setExportJobs: ExportJobsReducer = (state: ExportJobsState, payload?: ExportJobsPayload) => ({
  ...state,
  exportJobs: (payload && payload.exportJobs) ?? state.exportJobs,
});

const setExportJobsLoading: ExportJobsReducer = (state: ExportJobsState, payload?: ExportJobsPayload) => ({
  ...state,
  exportJobsLoading:
    payload && payload.exportJobsLoading !== undefined
      ? payload.exportJobsLoading
      : state.exportJobsLoading,
});

const setExportJobsError: ExportJobsReducer = (state: ExportJobsState, payload?: ExportJobsPayload) => ({
  ...state,
  exportJobsError: payload ? payload.exportJobsError : undefined,
});

const setColumn: ExportJobsReducer = (state: ExportJobsState, payload?: ExportJobsPayload) => ({
  ...state,
  column: (payload && payload.column) ?? state.column,
});

const setSortBy: ExportJobsReducer = (state: ExportJobsState, payload?: ExportJobsPayload) => ({
  ...state,
  sortBy: (payload && payload.sortBy) ?? state.sortBy,
});

const reducerMap = new Map<ExportJobsActionType, ExportJobsReducer>([
  [ExportJobsActionType.SET_EXPORT_JOBS, setExportJobs],
  [ExportJobsActionType.SET_EXPORT_JOBS_LOADING, setExportJobsLoading],
  [ExportJobsActionType.SET_EXPORT_JOBS_ERROR, setExportJobsError],
  [ExportJobsActionType.SET_COLUMN, setColumn],
  [ExportJobsActionType.SET_SORTBY, setSortBy],
]);

const reducer = (state: ExportJobsState = initialState, action: ExportJobsAction): ExportJobsState => {
  const reducer = reducerMap.get(action.type);
  if (reducer) return reducer(state, action.payload);
  return state;
};

export default reducer;
