import { put, call, fork, select } from 'redux-saga/effects';

// actions
import actions from '../actions';

// services
import * as processingPipelineServices from 'src/servicesV2/processingPipeline';

// types
import { TodoStateKeys } from 'src/@types/processingPipeline';

// selectors
import selectors from '../selectors';


export function* fetchTodoWaitingForImages(payload) {
  yield put(actions.setWaitingForImagesLoading(true));

  try {
    if (payload) {
      const { tenantId } = payload;
      const response: any = yield call(processingPipelineServices.fetchTodoWaitingForImages, tenantId);
      yield put(actions.setWaitingForImages(response?.assessments));
    } else {
      const response: any = yield call(processingPipelineServices.fetchTodoWaitingForImages);
      yield put(actions.setWaitingForImages(response?.assessments));
    }
  } catch ({ message }) {
    yield put(actions.setError({ message, name: 'error' }));
  } finally {
    yield put(actions.setWaitingForImagesLoading(false));
  }
}

export function* fetchTodoQueue(payload) {
  yield put(actions.setQueueForProcessingLoading(true));

  try {
    if (payload) {
      const { tenantId } = payload;
      const response: any = yield call(processingPipelineServices.fetchTodoQueue, tenantId);
      yield put(actions.setQueueForProcessing(response?.assessments));
    } else {
      const response: any = yield call(processingPipelineServices.fetchTodoQueue);
      yield put(actions.setQueueForProcessing(response?.assessments));
    }
  } catch ({ message }) {
    yield put(actions.setError({ message, name: 'error' }));
  } finally {
    yield put(actions.setQueueForProcessingLoading(false));
  }
}

export function* fetchTodoProcessing(payload) {
  yield put(actions.setWaitForProcessingLoading(true));

  try {
    if (payload) {
      const { tenantId } = payload;
      const response: any = yield call(processingPipelineServices.fetchTodoProcessing, tenantId);
      yield put(actions.setWaitForProcessingLoading(response?.assessments));
    } else {
      const response: any = yield call(processingPipelineServices.fetchTodoProcessing);
      yield put(actions.setWaitForProcessingLoading(response?.assessments));
    }
  } catch ({ message }) {
    yield put(actions.setError({ message, name: 'error' }));
  } finally {
    yield put(actions.setWaitForProcessingLoading(false));
  }
}

export function* fetchTodoPerformQC(payload) {
  yield put(actions.setPerformQCLoading(true));
  try {
    if (payload) {
      const { tenantId } = payload;
      const response: any = yield call(processingPipelineServices.fetchTodoPerformQC, tenantId);
      yield put(actions.setPerformQC(response?.assessments));
    } else {
      const response: any = yield call(processingPipelineServices.fetchTodoPerformQC);
      yield put(actions.setPerformQC(response?.assessments));
    }
  } catch ({ message }) {
    yield put(actions.setError({ message, name: 'error' }));
  } finally {
    yield put(actions.setPerformQCLoading(false));
  }
}

export function* fetchTodoCompleted(payload) {
  yield put(actions.setCompletedLoading(true));

  try {
    if (payload) {
      const { tenantId } = payload;
      const response: any = yield call(processingPipelineServices.fetchTodoCompleted, tenantId);
      yield put(actions.setCompleted(response?.assessments));
    } else {
      const response: any = yield call(processingPipelineServices.fetchTodoCompleted);
      yield put(actions.setCompleted(response?.assessments));
    }
  }
  catch ({ message }) {
    yield put(actions.setError({ message, name: 'error' }));
  } finally {
    yield put(actions.setCompletedLoading(false));
  }
}

export function* fetchTodoRefly(payload) {
  yield put(actions.setRequestReflyLoading(true));

  try {
    if (payload) {
      const { tenantId } = payload;
      const response: any = yield call(processingPipelineServices.fetchTodoRefly, tenantId);
      yield put(actions.setRequestRefly(response?.assessments));
    } else {
      const response: any = yield call(processingPipelineServices.fetchTodoRefly);
      yield put(actions.setRequestRefly(response?.assessments));
    }
  } catch ({ message }) {
    yield put(actions.setError({ message, name: 'error' }));
  } finally {
    yield put(actions.setRequestReflyLoading(false));
  }
}

export function* fetchTodoMeasurements(payload) {
  yield put(actions.setRequestMeasurementsLoading(true));

  try {
    if (payload) {
      const { tenantId } = payload;
      const response: any = yield call(
        processingPipelineServices.fetchTodoMeasurements,
        tenantId
      );
      yield put(actions.setRequestMeasurements(response?.assessments));
    } else {
      const response: any = yield call(processingPipelineServices.fetchTodoMeasurements);
      yield put(actions.setRequestMeasurements(response?.assessments));
    }
  } catch ({ message }) {
    yield put(actions.setError({ message, name: 'error' }));
  } finally {
    yield put(actions.setRequestMeasurementsLoading(false));
  }
}

export function* fetchTodoReprocessing(payload) {
  yield put(actions.setReProcessLoading(true));
  try {
    if (payload) {
      const { tenantId } = payload;
      const response: any = yield call(
        processingPipelineServices.fetchTodoReprocessing,
        tenantId
      );
      yield put(actions.setReProcess(response?.assessments));
    } else {
      const response: any = yield call(processingPipelineServices.fetchTodoReprocessing);
      yield put(actions.setReProcess(response?.assessments));
    }
  } catch ({ message }) {
    yield put(actions.setError({ message, name: 'error' }));
  } finally {
    yield put(actions.setReProcessLoading(false));
  }
}


export function* fetchAllTodoInfo(payload) {
  try {
    yield fork(fetchTodoWaitingForImages, payload?.payload);
    yield fork(fetchTodoQueue, payload?.payload);
    yield fork(fetchTodoProcessing, payload?.payload);
    yield fork(fetchTodoPerformQC, payload.payload);
    yield fork(fetchTodoCompleted, payload?.payload);
    yield fork(fetchTodoRefly, payload?.payload);
    yield fork(fetchTodoMeasurements, payload?.payload);
    yield fork(fetchTodoReprocessing, payload?.payload);
  } catch ({ message }) {
    yield put(actions.setError({ message, name: 'error' }));
  }
}

export function* fetchBucketsFromAndToAssetWasMoved(fromAction: string, toAction: string) {
  if (!fromAction || !toAction) return;

  try {
    const tenantId: string = yield select(selectors.getTenantId);

    if (
      fromAction === TodoStateKeys.WAITING_FOR_IMAGES ||
      toAction === TodoStateKeys.WAITING_FOR_IMAGES
    ) {
      yield call(fetchTodoWaitingForImages, { tenantId: tenantId });
    }

    if (
      fromAction === TodoStateKeys.QUEUE_FOR_PROCESSING ||
      toAction === TodoStateKeys.QUEUE_FOR_PROCESSING
    ) {
      yield call(fetchTodoQueue, { tenantId: tenantId });
    }
    if (
      fromAction === TodoStateKeys.WAIT_FOR_PROCESSING ||
      toAction === TodoStateKeys.WAIT_FOR_PROCESSING
    ) {
      yield call(fetchTodoProcessing, { tenantId: tenantId });
    }
    if (fromAction === TodoStateKeys.PERFORM_QC || toAction === TodoStateKeys.PERFORM_QC) {
      yield call(fetchTodoPerformQC, { tenantId: tenantId });
    }
    if (
      fromAction === TodoStateKeys.COMPLETED ||
      toAction === TodoStateKeys.COMPLETED
    ) {
      yield call(fetchTodoCompleted, { tenantId: tenantId });
    }
  } catch ({ message }) {
    yield put(actions.setError({ message, name: 'error' }));
  }
}
