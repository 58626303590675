import { ConnectionResponse, JobResponse } from 'src/@types/processing';
import { ProcessingAction, ProcessingActionType } from './types';

const actions = {
  setProcessingOpen: (): ProcessingAction => ({
    type: ProcessingActionType.SET_PROCESSING_OPEN,
  }),

  setProcessingClose: (): ProcessingAction => ({
    type: ProcessingActionType.SET_PROCESSING_CLOSE,
  }),

  setProcessingJobs: (jobs: any[]): ProcessingAction => ({
    payload: { jobs },
    type: ProcessingActionType.SET_PROCESSING_JOBS,
  }),

  setProcessingQueued: (queued: ConnectionResponse | JobResponse[]): ProcessingAction => ({
    payload: { queued },
    type: ProcessingActionType.SET_PROCESSING_QUEUED,
  }),

  setLoading: (isLoading: boolean): ProcessingAction => ({
    payload: { isLoading },
    type: ProcessingActionType.SET_PROCESSING_LOADING,
  }),

  setSearchQuery: (searchQuery: any): ProcessingAction => ({
    payload: { searchQuery },
    type: ProcessingActionType.SET_SEARCH_QUERY,
  }),

  setSession: (session: any): ProcessingAction => ({
    payload: { session },
    type: ProcessingActionType.SET_SESSION,
  }),

  setTargetQueueId: (targetQueueId: string): ProcessingAction => ({
    payload: { targetQueueId },
    type: ProcessingActionType.SET_TARGET_QUEUE_ID,
  }),

  clearState: (): ProcessingAction => ({
    type: ProcessingActionType.CLEAR,
  }),

  fetchModelProcessingQueues: (): ProcessingAction => ({
    type: ProcessingActionType.FETCH_MODEL_PROCESSING_QUEUES,
  }),

  setModelProcessingQueues: (modelProcessingQueues: any): ProcessingAction => ({
    payload: { modelProcessingQueues },
    type: ProcessingActionType.SET_MODEL_PROCESSING_QUEUES,
  }),
};

export default actions;
