// storage
import { getAuthToken, setAuthToken } from 'src/storage/AuthToken';

// Used to call logout when token and refresh token are invalid.
import Store from 'src/store';
import authActions from 'src/store/Authentication/actions';

type ResponseType = 'json' | 'text' | 'arraybuffer';

const refresh = async () => {
  const authToken = getAuthToken();

  try {
    const response = await fetch(
      `${process.env.REACT_APP_STAFF_V2_API_ENDPOINT}/api/auth/refresh/`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'text/plain',
          authorization: `Bearer ${authToken}`,
        },
      }
    );
    const responseJson = await response.json();
    if (responseJson.auth_data && responseJson.auth_data.access_token) {
      setAuthToken(responseJson.auth_data.access_token);
    } else {
      const { dispatch } = Store;
      dispatch(authActions.logout());
      dispatch(authActions.setError(new Error('Session expired')));
    }
  } catch (e) {
    throw new Error(e);
  }
};

const request = async (
  url: string,
  options: RequestInit,
  responseType?: ResponseType
): Promise<any> => {
  const sessionToken = getAuthToken();

  const customOptions: RequestInit = {
    ...options,
    headers: {
      ...options.headers,
      ...(sessionToken && { authorization: `Bearer ${sessionToken}` }),
    },
  };

  try {
    const response = await fetch(url, customOptions);

    // token expired.
    if (response.status === 401) {
      await refresh();
      return await request(url, options, responseType);
    }

    if (response.status === 204) {
      return {};
    }
    // unknown fetch error.
    if (response.status !== 200) {
      const { error } = await response.json();
      throw error;
    }

    if (responseType === 'text') {
      return await response.text();
    }

    if (responseType === 'arraybuffer') {
      return await response.arrayBuffer();
    }

    const data = await response.json();
    return data;
  } catch (e) {
    console.error(e.message);
    throw e;
  }
};

export default request;
