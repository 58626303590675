import { ApplicationState } from '../types';

const _getData = (state: ApplicationState) => state.ManageAccess;

const getError = (state: ApplicationState) => _getData(state).error;

const getAllCompanyUsers = (state: ApplicationState) => _getData(state).company_users_pagination;

const getLimit = (state: ApplicationState) => _getData(state).limit;

const getCursor = (state: ApplicationState) => _getData(state).cursor;

const getOrderBy = (state: ApplicationState) => _getData(state).order_by;

const getOrderByDirection = (state: ApplicationState) => _getData(state).order_by_direction;

const getSearch = (state: ApplicationState) => _getData(state).search;

const getSearchField = (state: ApplicationState) => _getData(state).search_field;

const getCompanyId = (state: ApplicationState) => _getData(state).company_id;

const getManageAccessTableCompanyId = (state: ApplicationState) =>
  _getData(state).manage_access_table_company_id;

const getIsManageAccessTableLoading = (state: ApplicationState) =>
  _getData(state).is_manage_access_table_loading;

const getManageAccessSideColumnCompanyId = (state: ApplicationState) =>
  _getData(state).manage_access_side_column_company_id;

const getGiveAccessUserList = (state: ApplicationState) => _getData(state).give_access_user_list;

const getIsExternal = (state: ApplicationState) => _getData(state).is_external;

const getRevokeUserList = (state: ApplicationState) => _getData(state).revoke_user_list;

const selectors = {
  getError,
  getAllCompanyUsers,
  getLimit,
  getCursor,
  getOrderBy,
  getOrderByDirection,
  getSearch,
  getSearchField,
  getCompanyId,
  getManageAccessTableCompanyId,
  getIsManageAccessTableLoading,
  getManageAccessSideColumnCompanyId,
  getGiveAccessUserList,
  getIsExternal,
  getRevokeUserList,
};

export default selectors;
