import { QCFormListContent, QCFormContent, QCFormRequestObj, FalloutReasonOptionsContent, StatusColorOptionsCotent } from 'src/@types/qcForm';
import { QcAction, QcActionType } from './types';

const actions = {
  getOtiqRevisions: (sasId): QcAction => ({
    payload: { sasId },
    type: QcActionType.GET_OTIQ_REVISIONS,
  }),
  setOtiqRevisions: (payload): QcAction => ({
    payload: payload,
    type: QcActionType.SET_OTIQ_REVISIONS,
  }),
  insertOtiqFile: (revisionId: string, sasId: string, otiqFileList: any): QcAction => ({
    payload: { revisionId, sasId, otiqFileList },
    type: QcActionType.INSERT_OTIQ_FILE,
  }),
  insertOtiqFiles: (payload): QcAction => ({
    payload: payload,
    type: QcActionType.INSERT_OTIQ_FILES,
  }),
  insertOtiqRevisions: (payload): QcAction => ({
    payload: payload,
    type: QcActionType.INSERT_OTIQ_REVISIONS,
  }),
  deleteOtiqFile: (fileId: string, sasId: string): QcAction => ({
    payload: { fileId, sasId },
    type: QcActionType.DELETE_OTIQ_FILE,
  }),
  setLoading: (loading: boolean): QcAction => ({
    payload: { loading },
    type: QcActionType.SET_LOADING,
  }),
  setError: (error: Error | undefined): QcAction => ({
    payload: { error },
    type: QcActionType.SET_ERROR,
  }),
  setSuccess: (success: boolean): QcAction => ({
    payload: { success },
    type: QcActionType.SET_SUCCESS,
  }),
  fetchFilePurposeOptions: (): QcAction => ({
    type: QcActionType.FETCH_FILE_PURPOSE_OPTIONS,
  }),
  setFilePurposeOptions: (filePurposeSelectOptions: any): QcAction => ({
    payload: { filePurposeSelectOptions },
    type: QcActionType.SET_FILE_PURPOSE_OPTIONS,
  }),
  fetchFileExtensionOptions: (): QcAction => ({
    type: QcActionType.FETCH_FILE_EXTENSION_OPTIONS,
  }),
  setFileExtensionOptions: (fileExtensionSelectOptions: any): QcAction => ({
    payload: { fileExtensionSelectOptions },
    type: QcActionType.SET_FILE_EXTENSION_OPTIONS,
  }),

  // qc record
  fetchLastQcRecord: (currentSiteAssessmentId: string) => ({
    payload: { currentSiteAssessmentId },
    type: QcActionType.FETCH_LAST_QC_RECORD,
  }),
  fetchQcRecords: (currentSiteAssessmentId: string) => ({
    payload: { currentSiteAssessmentId },
    type: QcActionType.FETCH_QC_RECORDS,
  }),
  setFetchQcRecordsLoading: (isFetchQcRecordsLoading: boolean) => ({
    payload: { isFetchQcRecordsLoading },
    type: QcActionType.SET_FETCH_QC_RECORDS_LOADING,
  }),
  setQcRecords: (qcRecords: QCFormListContent) => ({
    payload: { qcRecords },
    type: QcActionType.SET_QC_RECORDS,
  }),
  setLastQcRecord: (qc: QCFormContent) => ({
    payload: { qc },
    type: QcActionType.SET_LAST_QC_RECORD,
  }),
  setIsCreateQcRecordSuccess: (isCreateQcRecordSuccess: boolean): QcAction => ({
    payload: { isCreateQcRecordSuccess },
    type: QcActionType.SET_IS_CREATE_QC_RECORD_SUCCESS,
  }),
  createQcRecord: (qc: QCFormRequestObj): QcAction => ({
    payload: { qc },
    type: QcActionType.CREATE_QC_RECORD,
  }),
  setCreateQcRecordLoading: (isCreateQcRecordLoading: boolean): QcAction => ({
    payload: { isCreateQcRecordLoading },
    type: QcActionType.SET_CREATE_QC_RECORD_LOADING,
  }),
  updateQcRecord: ( currentQCFormId: string, qc: QCFormRequestObj): QcAction => ({
    payload: { currentQCFormId, qc },
    type: QcActionType.UPDATE_QC_RECORD,
  }),
  setUpdateQcRecordLoading: (isUpdateQcRecordLoading: boolean): QcAction => ({
    payload: { isUpdateQcRecordLoading },
    type: QcActionType.SET_UPDATE_QC_RECORD_LOADING,
  }),
  fetchFallOutReasons: () => ({
    type: QcActionType.FETCH_FALLOUT_REASONS,
  }),
  setFetchFallOutReasonsLoading: (isFetchFallOutReasonsLoading: boolean) => ({
    payload: { isFetchFallOutReasonsLoading },
    type: QcActionType.SET_FETCH_FALLOUT_REASONS_LOADING,
  }),
  setFallOutReasons: (fallOutReasons: FalloutReasonOptionsContent) => ({
    payload: { fallOutReasons },
    type: QcActionType.SET_FALLOUT_REASONS,
  }),
  fetchStatusColors: () => ({
    type: QcActionType.FETCH_STATUS_COLORS,
  }),
  setFetchStatusColorsLoading: (isFetchStatusColorsLoading: boolean) => ({
    payload: { isFetchStatusColorsLoading },
    type: QcActionType.SET_FETCH_STATUS_COLORS_LOADING,
  }),
  setStatusColors: (statusColors: StatusColorOptionsCotent) => ({
    payload: { statusColors },
    type: QcActionType.SET_STATUS_COLORS,
  }),
};

export default actions;
