import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ROLE } from 'src/@types/user';

// actions
import actions from 'src/store/Profile/actions';

// selectors
import profileSelectors from 'src/store/Profile/selectors';

const useProfile = () => {
  const profile = useSelector(profileSelectors.getUserContent);
  const displayName = useSelector(profileSelectors.getDisplayName);
  const role = useSelector(profileSelectors.getRole) || ROLE.UNKNOWN;
  const profileId = useSelector(profileSelectors.getProfileId);
  const error = useSelector(profileSelectors.getError);
  const updateError = useSelector(profileSelectors.getUpdateError);
  const updateLoading = useSelector(profileSelectors.getUpdateLoading);


  const dispatch = useDispatch();

  const setError = useCallback((error: Error | undefined) => {
    dispatch(actions.setError(error));
  }, [dispatch]);

  const update = useCallback((user_id, user_content) => {
    dispatch(actions.update(user_id, user_content));
  }, [dispatch]);


  return {
    profile,
    profileId,
    role,
    displayName,
    error,
    updateError,
    updateLoading,
    setError,
    update,
  };
};

export default useProfile;
