import { useCallback, useEffect, useState } from "react";

import HotKeysLabel from './HotKeysLabel';
import QuickSitesSearchDialog from "./QuickSitesSearchDialog";
import { operationalSystemKey, SHORTCUT_KEY } from "./constants";

const SearchPopover = () => {
  const [isOpen, setOpen] = useState(false);

  const onKeyDown = useCallback((e: KeyboardEvent) => {
    const shortcutKey = operationalSystemKey;
    if (!e || !e.key) return;
    if (e.key.toUpperCase() === 'K') {
      if (shortcutKey === SHORTCUT_KEY.CTRL && !e.ctrlKey) return;
      if (shortcutKey === SHORTCUT_KEY.META && !e.metaKey) return;
      setOpen(true);
      e.preventDefault();
    }
  }, []);

  // add keyup and keydown listeners.
  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);

  return (
    <>
      <HotKeysLabel onClick={() => setOpen(true)} />
      <QuickSitesSearchDialog isOpen={isOpen} onClose={() => setOpen(false)} />
    </>
  );
};

export default SearchPopover;
