import { NotificationEditorObj, NotificationPagination, NotificationTypesContent } from 'src/@types/notification';


export enum NotificationActionType {
  FETCH_ALL_NOTIFICATIONS = 'Notification@FETCH_ALL_NOTIFICATIONS',
  SET_NOTIFICATIONS = 'Notification@SET_NOTIFICATIONS',
  SET_LIMIT = 'Notification@SET_LIMIT',
  SET_CURSOR = 'Notification@SET_CURSOR',
  SET_ORDERBY = 'Notification@SET_ORDERBY',
  SET_ORDERBY_DIRECTION = 'Notification@SET_ORDERBY_DIRECTION',
  SET_SEARCH = 'Notification@SET_SEARCH',
  SET_NOTIFICATION_EDITOR_OBJ = 'Notification@SET_NOTIFICATION_EDITOR_OBJ',
  SET_SEARCH_FIELD = 'Notification@SET_SEARCH_FIELD',
  UPDATE_NOTIFICATION = 'Notification@UPDATE_NOTIFICATION',
  CREATE_NOTIFICATION = 'Notification@CREATE_NOTIFICATION',
  SET_IS_UPDATE_NOTIFICATION = 'Notification@SET_IS_UPDATE_NOTIFICATION',
  SET_IS_CREATE_NOTIFICATION = 'Notification@SET_IS_CREATE_NOTIFICATION',
  SET_NOTIFICATION_CREATE_SUCCESS = 'Notification@SET_NOTIFICATION_CREATE_SUCCESS',
  SET_NOTIFICATION_UPDATE_SUCCESS = 'Notification@SET_NOTIFICATION_UPDATE_SUCCESS',
  SET_IS_NOTIFICATION_PAGE_LOADING = 'Notification@SET_IS_NOTIFICATION_PAGE_LOADING',
  SET_IS_SUBMITING_LOADING = 'Notification@SET_IS_SUBMITING_LOADING',
  SET_ERROR = 'Notification@SET_ERROR',
  CLEAR = 'Notification@CLEAR',
 
  FETCH_NOTIFICATION_TYPES = 'Notification@FETCH_NOTIFICATION_TYPES',
  SET_NOTIFICATION_TYPES = 'Notification@SET_NOTIFICATION_TYPES',
}

export type NotificationState = {
  error?: Error | undefined;
  notification_pagination?: NotificationPagination;
  limit?: number;
  cursor?: string;
  search?: string;
  search_field?: 'url' | 'content';
  order_by_direction?: 'asc' | 'desc';
  order_by?: 'date_created' | 'content';
  is_notification_page_loading?: boolean;
  notification_types?: NotificationTypesContent;
  notification_editor_obj?: NotificationEditorObj;
  is_update_notification?: boolean;
  is_create_notification?: boolean;
  is_submiting_loading?: boolean;
  is_notification_create_success?: boolean;
  is_notification_update_success?: boolean;
};

export type NotificationPayload = {
  error?: Error | undefined;
  notification_pagination?: NotificationPagination;
  limit?: number;
  cursor?: string;
  search?: string;
  search_field?: 'url' | 'content';
  order_by_direction?: 'asc' | 'desc';
  order_by?: 'date_created' | 'content';
  is_notification_page_loading?: boolean;
  notification_types?: NotificationTypesContent;
  notification_editor_obj?: NotificationEditorObj;
  is_update_notification?: boolean;
  is_create_notification?: boolean;
  is_submiting_loading?: boolean;
  notification_id?: string;
  is_notification_create_success?: boolean;
  is_notification_update_success?: boolean;
  
};

export type NotificationAction = {
  payload?: NotificationPayload;
  type: NotificationActionType;
};

export type NotificationReducer = (state: NotificationState, payload?: NotificationPayload) => NotificationState;
