import { NotificationContent, NotificationEditorObj, NotificationPagination, NotificationTypesContent } from 'src/@types/notification';
import api from './api';
import { NotificationCursorPaginatorResponseSchema, NotificationTypeResponseSchema } from './types';


export const fetchAllNotifications = async (
  limit: number,
  cursor?: string,
  search?: string,
  search_field?: string,
  order_by_direction?: string,
  order_by?: string,
): Promise<NotificationPagination> => {
  try {
    const response: NotificationCursorPaginatorResponseSchema = await api.fetchAllNotifications(
      limit,
      cursor,
      search,
      search_field,
      order_by_direction,
      order_by);
    return response;
  } catch (e) {
    throw e;
  }
};
  

export const postNotification = async (
  notification: NotificationEditorObj
): Promise<NotificationContent> => {
  try {
    const res: any = await api.postNotification(notification);
    return res;
  } catch (e) {
    throw e;
  }
};

export const patchNotification = async (
  notification_id: string,
  notification: NotificationEditorObj
): Promise<NotificationContent> => {
  try {
    const res: any = await api.patchNotification(notification_id, notification);
    return res;
  } catch (e) {
    throw e;
  }
};


export const fetchNotificationTypes = async (): Promise<NotificationTypesContent> => {
  try {
    const response: NotificationTypeResponseSchema = await api.fetchNotificationTypes();
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};