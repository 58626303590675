import { TicketContent, TicketPagination, TicketRequestObj } from 'src/@types/ticket';
import api from './api';
import { TicketCursorPaginatorResponseSchema, TicketResponseSchema, TicketStatusResponseSchema } from './type';


export const fetchAllTickets = async (
  limit: number,
  cursor?: string,
  search?: string,
  search_field?: string,
  order_by_direction?: string,
  order_by?: string,
  status_id?: string
): Promise<TicketPagination> => {
  try {
    const response: TicketCursorPaginatorResponseSchema = await api.fetchAllTickets(
      limit,
      cursor,
      search,
      search_field,
      order_by_direction,
      order_by,
      status_id
    );
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};


export const patchTicket = async (
  ticket_id: string,
  ticket: TicketRequestObj
): Promise<TicketContent> => {
  try {
    const response: TicketResponseSchema = await api.patchTicket(ticket_id, ticket);
    return response;
  } catch (e) {
    throw e;
  }
};


export const fetchTicketStatus = async (): Promise<TicketStatusResponseSchema> => {
  try {
    const response: TicketStatusResponseSchema = await api.fetchTicketStatus();
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};