import {
  ToastState,
  ToastReducer,
  ToastPayload,
  ToastActionType,
  ToastAction
} from "./types";
import { TOAST_SEVERITY } from "src/@types/toast";

export const initialState: ToastState = {
  message: undefined,
  options: undefined,
  severity: TOAST_SEVERITY.SUCCESS,
};

const setToast: ToastReducer = (state: ToastState, payload?: ToastPayload) => ({
  ...state,
  message: payload ? payload.message : state.message,
  severity: payload ? payload.severity : initialState.severity,
  options: payload ? payload.options : initialState.options,
});

const clearState: ToastReducer = () => ({
  ...initialState,
});

const reducerMap = new Map<ToastActionType, ToastReducer>([
  [ToastActionType.SET_TOAST, setToast],
  [ToastActionType.CLEAR, clearState],
]);

const reducer = (state: ToastState = initialState, action: ToastAction): ToastState => {
  const reducer = reducerMap.get(action.type);
  if (reducer) return reducer(state, action.payload);
  return state;
};

export default reducer;
