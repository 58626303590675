import { useState, useEffect } from 'react';

// hooks
import { useTenant } from 'src/hooks';

// ca
import {
  Autocomplete,
  CircularProgress,
  TextField,
  debounce,
  ICON,
  Typography,
} from 'ca-react-component-lib';

// util
import { getAutocompleteText } from 'src/utils/getAutocompleteText';

// types
import { TenantSelectionProps } from './types';

const TenantSelection = ({ 
  label,
  fullWidth,
  onChange,
  error,
  sx,
  defaultValue,
  value,
  required,
  helperText,
}: TenantSelectionProps) => {
  const [isLoading, setLoading] = useState(false);
  const { 
    tenantOptions, 
    isTenantOptionsLoading, 
    fetchTenantOptions,
    setTenantOptions, 
    setSearch, 
    search
  } = useTenant();

  const onTenantSearchChange = (e: any) => {
    setLoading(true);
    debounce(() => {
      if (e.target.value) {
        setSearch(e.target.value);
        fetchTenantOptions(e.target.value);
      } else {
        setSearch('');
      }
      setLoading(false);
    }, 500);
  };

  const onAutoCompleteChange = (value?: any) => {
    if (!value || !value.id || !value.name) {
      setTenantOptions({ 'tenants': [] });
      setSearch('');
      onChange(undefined);
    } else {
      setSearch(value.name);
      onChange(value);
    }
  };

  useEffect(() => {
    if (!defaultValue) {
      return;
    }

    setSearch(defaultValue.name);
    fetchTenantOptions(defaultValue.name);

    return () => {
      setSearch('');
      setTenantOptions({ 'tenants': [] });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <Autocomplete
      sx={{
        backgroundColor: 'white',
        ...sx,
      }}
      fullWidth
      options={tenantOptions?.tenants || []}
      loading={isTenantOptionsLoading}
      value={value || search || null}
      getOptionLabel={(option) => (option?.name ? option?.name : option ?? '')}
      isOptionEqualToValue={(option, value) => option?.name?.toLowerCase() === value?.toLowerCase()}
      noOptionsText={getAutocompleteText(search, isTenantOptionsLoading)}
      onChange={(_, newValue) => onAutoCompleteChange(newValue)}
      defaultValue={defaultValue || null}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          <Typography variant="body2">{option.name}</Typography>
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          white
          error={!!error}
          helperText={helperText || ''}
          label="Tenant Name"
          required={required}
          placeholder="Tenant Name"
          onChange={(e) => onTenantSearchChange(e)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isTenantOptionsLoading || isLoading ? (
                  <CircularProgress color="inherit" size={ICON.DEFAULT} sx={{ mr: 1 }} />
                ) : (
                  params.InputProps.endAdornment
                )}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default TenantSelection;