import { DEFAULT_QUEUE_ID } from 'src/sections/@dashboard/Processing/Queued/constants';
import {
  ProcessingAction,
  ProcessingActionType,
  ProcessingReducer,
  ProcessingPayload,
  ProcessingState,
} from './types';

export const initialState: ProcessingState = {
  jobs: [],
  queued: [],
  isOpen: false,
  isLoading: true,
  searchQuery: { text: '' },
  targetQueueId: DEFAULT_QUEUE_ID,
  modelProcessingQueues: undefined,
};

const setLoading: ProcessingReducer = (state: ProcessingState, payload?: ProcessingPayload) => ({
  ...state,
  isLoading: (payload && payload.isLoading !== undefined) ? payload.isLoading : state.isLoading,
});

const setProcessingOpen: ProcessingReducer = (state: ProcessingState) => ({
  ...state,
  isOpen: true,
});

const setProcessingClose: ProcessingReducer = (state: ProcessingState) => ({
  ...state,
  isOpen: false,
});

const setProcessingJobs: ProcessingReducer = (state: ProcessingState, payload?: ProcessingPayload) => ({
  ...state,
  jobs: payload && payload.jobs ? payload.jobs : state.jobs,
});

const setProcessingQueued: ProcessingReducer = (state: ProcessingState, payload?: ProcessingPayload) => ({
  ...state,
  queued: payload && payload.queued ? payload.queued : state.queued,
});

const setModelProcessingQueues: ProcessingReducer = (state: ProcessingState, payload?: ProcessingPayload) => ({
  ...state,
  modelProcessingQueues: payload && payload.modelProcessingQueues ? payload.modelProcessingQueues : state.modelProcessingQueues,
});

const setSearchQuery: ProcessingReducer = (state: ProcessingState, payload?: ProcessingPayload) => ({
  ...state,
  searchQuery: payload && payload.searchQuery ? payload.searchQuery : state.searchQuery,
});

const setTargetQueueId: ProcessingReducer = (state: ProcessingState, payload?: ProcessingPayload) => ({
  ...state,
  targetQueueId: (payload && payload.targetQueueId) ? payload.targetQueueId:  state.targetQueueId,
});

const setSession: ProcessingReducer = (state: ProcessingState, payload?: ProcessingPayload) => ({
  ...state,
  session: payload && payload.session ? payload.session : state.session,
});

const clearState: ProcessingReducer = () => ({
  ...initialState,
});

const reducerMap = new Map<ProcessingActionType, ProcessingReducer>([
  [ProcessingActionType.SET_MODEL_PROCESSING_QUEUES, setModelProcessingQueues],
  [ProcessingActionType.SET_PROCESSING_LOADING, setLoading],
  [ProcessingActionType.SET_PROCESSING_OPEN, setProcessingOpen],
  [ProcessingActionType.SET_PROCESSING_CLOSE, setProcessingClose],
  [ProcessingActionType.SET_PROCESSING_JOBS, setProcessingJobs],
  [ProcessingActionType.SET_PROCESSING_QUEUED, setProcessingQueued],
  [ProcessingActionType.SET_SEARCH_QUERY, setSearchQuery],
  [ProcessingActionType.SET_TARGET_QUEUE_ID,setTargetQueueId],
  [ProcessingActionType.SET_SESSION, setSession],
  [ProcessingActionType.CLEAR, clearState],
]);

const reducer = (state: ProcessingState = initialState, action: ProcessingAction): ProcessingState => {
  const reducer = reducerMap.get(action.type);
  if (reducer) return reducer(state, action.payload);
  return state;
};

export default reducer;
