// Import the api object from the api module
import { ProcessPipelineResponseSchema } from './types';
import api from './api';

/**
 * Fetches the content of a specific process pipeline by its ID, parsing it into a structured object.
 *
 * @param action - The ID of the process pipeline to fetch.
 * @returns Promise resolving to the parsed process pipeline content.
 */

export const fetchTodoWaitingForImages = async (tenant_id?: string) => {
  try {
    const response: ProcessPipelineResponseSchema = await api.fetchTodoWaitingForImages(tenant_id);
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const fetchTodoQueue = async (tenant_id?: string) => {
  try {
    const response: ProcessPipelineResponseSchema = await api.fetchTodoQueueInfo(tenant_id);
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const fetchTodoProcessing = async (tenant_id?: string) => {
  try {
    const response: ProcessPipelineResponseSchema = await api.fetchTodoProcessingInfo(tenant_id);
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};


export const fetchTodoPerformQC = async (tenant_id?: string) => {
  try {
    const response: ProcessPipelineResponseSchema = await api.fetchTodoPerformQC(tenant_id);
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const fetchTodoCompleted = async (tenant_id?: string) => {
  try {
    const response: ProcessPipelineResponseSchema = await api.fetchTodoCompletedInfo(tenant_id);
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};


export const fetchTodoRefly = async (tenant_id?: string) => {
  try {
    const response: ProcessPipelineResponseSchema = await api.fetchTodoReflyInfo(tenant_id);
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const fetchTodoMeasurements = async (tenant_id?: string) => {
  try {
    const response = await api.fetchTodoMeasurementsInfo(tenant_id);
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const fetchTodoReprocessing = async (tenant_id?: string) => {
  try {
    const response: ProcessPipelineResponseSchema = await api.fetchTodoReprocessingInfo(tenant_id);
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const editSiteAssessmentStaging = async (
  assessment_id: string,
  payload: any
): Promise<any> => {
  try {
    const response = await api.editSiteAssessmentStaging(assessment_id, payload);
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};