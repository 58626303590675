import {
  NotificationAction,
  NotificationActionType,
  NotificationReducer,
  NotificationPayload,
  NotificationState
} from './types';

export const initialState: NotificationState = {
  error: undefined,
  is_notification_page_loading: false,
  notification_types: undefined,
  notification_editor_obj: undefined,
  is_update_notification: false,
  is_create_notification: false,
  is_submiting_loading: false,
  limit: 15,
  cursor: undefined,
  order_by: 'date_created',
  order_by_direction: 'desc',
  search_field: undefined,
  search: undefined,
  notification_pagination: undefined,
  is_notification_create_success: false,
  is_notification_update_success: false,
};

const setNotifications: NotificationReducer = (
  state: NotificationState,
  payload?: NotificationPayload
) => ({
  ...state,
  notification_pagination: (payload && payload.notification_pagination) ?? state.notification_pagination,
});


const setLimit: NotificationReducer = (
  state: NotificationState,
  payload?: NotificationPayload
) => ({
  ...state,
  limit: (payload && payload.limit) ?? state.limit,
});

const setCursor: NotificationReducer = (
  state: NotificationState,
  payload?: NotificationPayload
) => ({
  ...state,
  cursor: (payload && payload.cursor) ?? state.cursor,
});

const setOrderBy: NotificationReducer = (
  state: NotificationState,
  payload?: NotificationPayload
) => ({
  ...state,
  order_by: (payload && payload.order_by) ?? state.order_by,
});

const setOrderByDirection: NotificationReducer = (
  state: NotificationState,
  payload?: NotificationPayload
) => ({
  ...state,
  order_by_direction: (payload && payload.order_by_direction) ?? state.order_by_direction,
});

const setSearch: NotificationReducer = (
  state: NotificationState,
  payload?: NotificationPayload
) => ({
  ...state,
  search: (payload && payload.search) ?? state.search,
});

const setSearchField: NotificationReducer = (
  state: NotificationState,
  payload?: NotificationPayload
) => ({
  ...state,
  search_field: (payload && payload.search_field) ?? state.search_field,
});



const setIsNotificationPageLoading: NotificationReducer = (state: NotificationState, payload?: NotificationPayload) => ({
  ...state,
  is_notification_page_loading: (payload && payload.is_notification_page_loading) ?? state.is_notification_page_loading
});

const setNotificationTypes: NotificationReducer = (state: NotificationState, payload?: NotificationPayload) => ({
  ...state,
  notification_types: (payload && payload.notification_types) ?? state.notification_types
});

const setNotificationEditorObj: NotificationReducer = (state: NotificationState, payload?: NotificationPayload) => ({
  ...state,
  notification_editor_obj: (payload && payload.notification_editor_obj) ?? state.notification_editor_obj
});

const setIsUpdateNotification: NotificationReducer = (state: NotificationState, payload?: NotificationPayload) => ({
  ...state,
  is_update_notification: (payload && payload.is_update_notification !== undefined) ? payload.is_update_notification : state.is_update_notification
});

const setIsCreateNotification: NotificationReducer = (state: NotificationState, payload?: NotificationPayload) => ({
  ...state,
  is_create_notification: (payload && payload.is_create_notification !== undefined) ? payload.is_create_notification : state.is_create_notification
});

const setIsSubmitingLoading: NotificationReducer = (state: NotificationState, payload?: NotificationPayload) => ({
  ...state,
  is_submiting_loading: (payload && payload.is_submiting_loading !== undefined) ? payload.is_submiting_loading : state.is_submiting_loading
});

const setError: NotificationReducer = (state: NotificationState, payload?: NotificationPayload) => ({
  ...state,
  error: payload ? payload.error : undefined,
});


const setNotificationCreateSuccess: NotificationReducer = (state: NotificationState, payload?: NotificationPayload) => ({
  ...state,
  is_notification_create_success: (payload && payload.is_notification_create_success) ?? state.is_notification_create_success
});

const setNotificationUpdateSuccess: NotificationReducer = (state: NotificationState, payload?: NotificationPayload) => ({
  ...state,
  is_notification_update_success: (payload && payload.is_notification_update_success) ?? state.is_notification_update_success
});

const clearState: NotificationReducer = () => ({
  ...initialState,
});

const reducerMap = new Map<NotificationActionType, NotificationReducer>([
  [NotificationActionType.SET_ERROR, setError],
  [NotificationActionType.CLEAR, clearState],
  [NotificationActionType.SET_NOTIFICATIONS, setNotifications],
  [NotificationActionType.SET_LIMIT, setLimit],
  [NotificationActionType.SET_CURSOR, setCursor],
  [NotificationActionType.SET_ORDERBY, setOrderBy],
  [NotificationActionType.SET_ORDERBY_DIRECTION, setOrderByDirection],
  [NotificationActionType.SET_SEARCH, setSearch],
  [NotificationActionType.SET_SEARCH_FIELD, setSearchField],
  [NotificationActionType.SET_NOTIFICATION_EDITOR_OBJ, setNotificationEditorObj],
  [NotificationActionType.SET_IS_UPDATE_NOTIFICATION, setIsUpdateNotification],
  [NotificationActionType.SET_IS_NOTIFICATION_PAGE_LOADING, setIsNotificationPageLoading],
  [NotificationActionType.SET_NOTIFICATION_TYPES, setNotificationTypes],
  [NotificationActionType.SET_IS_CREATE_NOTIFICATION, setIsCreateNotification],
  [NotificationActionType.SET_IS_SUBMITING_LOADING, setIsSubmitingLoading],
  [NotificationActionType.SET_NOTIFICATION_CREATE_SUCCESS, setNotificationCreateSuccess],
  [NotificationActionType.SET_NOTIFICATION_UPDATE_SUCCESS, setNotificationUpdateSuccess],
  
]);

const reducer = (state: NotificationState = initialState, action: NotificationAction): NotificationState => {
  const reducer = reducerMap.get(action.type);
  if (reducer) return reducer(state, action.payload);
  return state;
};

export default reducer;
