import { useEffect } from "react";
import { useSnackbar, SnackbarVariantType } from 'ca-react-component-lib';

// hooks
import useToast from "src/hooks/useToast";

const Toast = () => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    message,
    severity,
    setToast,
    options,
  } = useToast();
  
  useEffect(() => {
    if (message) {
      enqueueSnackbar(
        message,
        {
          variant: severity as SnackbarVariantType,
          ...(options ? options : {})
        }
      );
      setToast(undefined, undefined);
    }
  }, [
    message,
    severity,
    setToast,
    options,
    enqueueSnackbar,
  ]);

  return null;
};

export default Toast;