import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toastActions from 'src/store/Toast/actions';
import toastSelectors from 'src/store/Toast/selectors';

const useToast = () => {
  const message = useSelector(toastSelectors.getMessage);

  const isDisplayed = useSelector(toastSelectors.getDisplayed);

  const severity = useSelector(toastSelectors.getSeverity);
  
  const options = useSelector(toastSelectors.getOptions);

  const dispatch = useDispatch();

  const setToast = useCallback((message, severity, options?: any) => {
    dispatch(toastActions.setToast(message, severity, options));
  }, [dispatch]);

  return {
    message,
    severity,
    isDisplayed,
    setToast,
    options,
  };
};

export default useToast;
