// api
import { UserPagination, UserBodyRequestSchema, UserOptionsContent } from 'src/@types/user';
import api from './api';
import { UserBulkCreateResponseSchema, UserCursorPaginatorResponseSchema, UserOptionsResponseSchema, UserWithCompanyResponseSchema } from './types';


export const fetchAllUsers = async (
  limit: number,
  cursor?: string,
  search?: string,
  search_field?: string,
  order_by_direction?: string,
  order_by?: string,
  company_id?: string,
  excluded_invited?: boolean
): Promise<UserPagination> => {
  try {
    const response: UserCursorPaginatorResponseSchema = await api.fetchAllUsers(
      limit,
      cursor,
      search,
      search_field,
      order_by_direction,
      order_by,
      company_id,
      excluded_invited
    );
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const fetchUserContent = async (user_id: string): Promise<UserWithCompanyResponseSchema> => {
  try {
    const response: UserWithCompanyResponseSchema = await api.fetchUserContent(user_id);
    return response;
  } catch (e) {
    throw e;
  }
};

export const fetchProfile = async (): Promise<any> => {
  try {
    const response: UserWithCompanyResponseSchema = await api.fetchUserProfile();
    if (response?.user?.id) {
      return response;
    }
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const postUser = async (user: UserBodyRequestSchema): Promise<UserWithCompanyResponseSchema> => {
  try {
    const response: UserWithCompanyResponseSchema = await api.postUser(user);
    return response;
  } catch (e) {
    throw e;
  }
};

export const patchUser = async (user_id: string, user: UserBodyRequestSchema): Promise<UserWithCompanyResponseSchema> => {
  try {
    const response: UserWithCompanyResponseSchema = await api.patchUser(user_id, user);
    return response;
  } catch (e) {
    throw e;
  }
};

export const fetchUserOptions = async (search?: string, min_role_level?: number, max_role_level?: number): Promise<UserOptionsContent> => {
  try {
    const response: UserOptionsResponseSchema = await api.fetchUserOptions(search, min_role_level, max_role_level);
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const fetchRoles = async (): Promise<any> => {
  try {
    const response = await api.fetchRoles();
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

//Bulk User

export const fetchExcelTemplate = async (): Promise<any> => {
  try {
    const response: any = await api.fetchExcelTemplate();
    return response;
  } catch (e) {
    throw e;
  }
};

export const bulkInsert = async (payload: any): Promise<any> => {
  try {
    const form_data = new FormData();
    form_data.append('file', payload.file[0]);

    const response: UserBulkCreateResponseSchema = await api.bulkInsert({
      file: form_data,
      company_id: payload.company_id,
    });
    return response;
  } catch (e) {
    throw e;
  }
};

export const inviteUsersById = async (userIds: any): Promise<any> => {
  try {
    const response = await api.inviteUsers(userIds);
    return response;
  } catch (e) {
    throw e;
  }
};

// roles
export const fetchRoleOptions = async () => {
  try {
    const response = await api.fetchRoleOptions();
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};