// hooks
import useAuth from 'src/hooks/useAuth';

// components
import { Paper, CAAuthLayout } from 'ca-react-component-lib';

import { LoginForm, GetAccessForm, SuccessPanel, ErrorPanel } from 'src/sections/@auth/login';

// types
import { Onboarding } from 'src/@types/auth';

export default function Login() {
  const { onboarding, onboardingError } = useAuth();

  return (
    <CAAuthLayout title={'Login'}>
      <Paper sx={{ p: 4 }}>
        {onboarding === Onboarding.LOGIN && <LoginForm />}
        {onboarding === Onboarding.VALIDATE && <GetAccessForm />}
        {onboarding === Onboarding.SUCCESS && <SuccessPanel />}
        {onboarding === Onboarding.ERROR && <ErrorPanel message={onboardingError}/>}
      </Paper>
    </CAAuthLayout>
  );
}
