import { useCallback } from "react";

// hooks
import useProfile from "./useProfile";

// types
import { ROLE } from "src/@types/user";

const useRoleGuard = () => {
  const { role } = useProfile();

  const isAllowed = useCallback((roles?: ROLE[]) => {
    
    // If "roles" is not defined: not need permission.
    if (!roles) {
      return true;
    }
    
    
    // If "roles" has the "role" and "role" is defined, then has permission.
    if (role && roles.includes(role)) {
      return true;
    }

    // There is no permission.
    return false;
  }, [role]); 
  
  return {
    isAllowed
  };
};

export default useRoleGuard;
