import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

import { useProfile } from 'src/hooks';

const GoogleAnalytics = () => {
  const { profile } = useProfile();
  const location = useLocation();

  useEffect(() => {
    if (!profile || !profile.user?.id) return;

    (window as any).gtag('config', 'G-E5CT9CNKPK', {
      page_title: location.pathname,
      page_path: location.pathname,
      user_id: profile.user?.id,
    });
  }, [location, profile]);

  return null;
};

export default GoogleAnalytics;
