import request from 'src/services/request';
import endpoints from './endpoints';

const api = {
  fetchOtiqRevisions: async (sasId: string) =>
    request(`${endpoints.getOtiqRevisions}${sasId}/otiq-revision/`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }),

  fetchFilePurpose: async () =>
    request(`${endpoints.getFilePurposeOptions}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }),

  fetchFileFormat: async () =>
    request(`${endpoints.getFileFormatOptions}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }),

  insertOtiqFiles: async (payload: any, revisionId: string) =>
    request(`${endpoints.insertOtiqFiles}${revisionId}/`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    }),

  insertOtiqRevision: async (sasId: string) =>
    request(`${endpoints.insertOtiqRevision}${sasId}/`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    }),

  deleteOtiqFile: async (fileId: string) =>
    request(`${endpoints.deleteOtiqFile}${fileId}/`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
    }),
};

export default api;