import { ApplicationState } from '../types';

const _getData = (state: ApplicationState) => state.Qc;

const isLoading = (state: ApplicationState) => _getData(state).loading;

const isError = (state: ApplicationState) => _getData(state).error;

const isSuccess = (state: ApplicationState) => _getData(state).success;

const filePurposeSelectOptions = (state: ApplicationState) =>
  _getData(state).filePurposeSelectOptions;

const fileExtensionSelectOptions = (state: ApplicationState) =>
  _getData(state).fileExtensionSelectOptions;

const revisions = (state: ApplicationState) => _getData(state).otiQRevisions;

const qcRecords = (state: ApplicationState) => _getData(state).qcRecords;

const lastQcRecord = (state: ApplicationState) => _getData(state).lastQcRecord;

const fallOutReasons = (state: ApplicationState) => _getData(state).fallOutReasons;

const statusColors = (state: ApplicationState) => _getData(state).statusColors;

const isFetchQcRecordsLoading = (state: ApplicationState) =>
  _getData(state).isFetchQcRecordsLoading;

const isCreateQcRecordLoading = (state: ApplicationState) =>
  _getData(state).isCreateQcRecordLoading;

const isCreateQcRecordSuccess = (state: ApplicationState) =>
  _getData(state).isCreateQcRecordLoading;

const isUpdateQcRecordLoading = (state: ApplicationState) =>
  _getData(state).isUpdateQcRecordLoading;

const isFetchFallOutReasonsLoading = (state: ApplicationState) =>
  _getData(state).isFetchFallOutReasonsLoading;

const isFetchStatusColorsLoading = (state: ApplicationState) =>
  _getData(state).isFetchStatusColorsLoading;

const selectors = {
  isLoading,
  revisions,
  isError,
  isSuccess,
  filePurposeSelectOptions,
  fileExtensionSelectOptions,
  isCreateQcRecordLoading,
  isCreateQcRecordSuccess,
  isUpdateQcRecordLoading,
  isFetchQcRecordsLoading,
  qcRecords,
  lastQcRecord,
  fallOutReasons,
  statusColors,
  isFetchFallOutReasonsLoading,
  isFetchStatusColorsLoading,
};

export default selectors;
