import { useDispatch, useSelector } from 'react-redux';

import customerSuccessAccessSelectors from 'src/store/CustomerSuccessAccess/selectors';
import customerSuccessAccessActions from 'src/store/CustomerSuccessAccess/actions';
import { useCallback } from 'react';

const useCustomerSuccessAccess = () => {
  const limit = useSelector(customerSuccessAccessSelectors.getLimit);
  const cursor = useSelector(customerSuccessAccessSelectors.getCursor);
  const orderBy = useSelector(customerSuccessAccessSelectors.getOrderBy);
  const orderByDirection = useSelector(customerSuccessAccessSelectors.getOrderByDirection);
  const search = useSelector(customerSuccessAccessSelectors.getSearch);
  const searchField = useSelector(customerSuccessAccessSelectors.getSearchField);
  const loading = useSelector(customerSuccessAccessSelectors.isLoading);
  const customerSuccessAccess = useSelector(
    customerSuccessAccessSelectors.getCustomerSuccessAccess
  );
  const companyId = useSelector(customerSuccessAccessSelectors.getCompanyId);
  const userId = useSelector(customerSuccessAccessSelectors.getUserId);
  const postCustomerSuccessAccessLoading = useSelector(
    customerSuccessAccessSelectors.getPostCustomerSuccessAccessLoading
  );
  const postCustomerSuccessAccessSuccess = useSelector(
    customerSuccessAccessSelectors.getPostCustomerSuccessAccessSuccess
  );
  const error = useSelector(customerSuccessAccessSelectors.getError);
  const deleteCustomerSuccessAccessLoading = useSelector(
    customerSuccessAccessSelectors.getDeleteCustomerSuccessAccessLoading
  );
  const deleteCustomerSuccessAccessSuccess = useSelector(
    customerSuccessAccessSelectors.getDeleteCustomerSuccessAccessSuccess
  );

  const customerSuccessCompanies = useSelector(
    customerSuccessAccessSelectors.getCustomerSuccessCompanies
  );
  const customerSuccessCompaniesLoading = useSelector(
    customerSuccessAccessSelectors.getCustomerSuccessCompaniesLoading
  );
  const customerSuccessCompaniesSearch = useSelector(
    customerSuccessAccessSelectors.getCustomerSuccessAccessCompaniesSearch
  );
  const customerSuccessSwitchError = useSelector(
    customerSuccessAccessSelectors.getCustomerSuccessSwitchError
  );
  const customerSuccessSwitchLoading = useSelector(
    customerSuccessAccessSelectors.getCustomerSuccessSwitchLoading
  );

  const customerSuccessAccessCompanyOptions = useSelector(
    customerSuccessAccessSelectors.getCustomerSuccessAccessCompanyOptions
  );
  const customerSuccessAccessUserOptions = useSelector(
    customerSuccessAccessSelectors.getCustomerSuccessAccessUserOptions
  );
  const customerSuccessAccessCompanyOptionsSearch = useSelector(
    customerSuccessAccessSelectors.getCustomerSuccessAccessCompanyOptionsSearch
  );
  const customerSuccessAccessUserOptionsSearch = useSelector(
    customerSuccessAccessSelectors.getCustomerSuccessAccessUserOptionsSearch
  );
  const customerSuccessAccessUserOptionsLoading = useSelector(
    customerSuccessAccessSelectors.getCustomerSuccessAccessUserOptionsLoading
  );
  const customerSuccessAccessCompanyOptionsLoading = useSelector(
    customerSuccessAccessSelectors.getCustomerSuccessAccessCompanyOptionsLoading
  );

  const dispatch = useDispatch();

  const setLimit = useCallback(
    (limit: number) => {
      dispatch(customerSuccessAccessActions.setLimit(limit));
    },
    [dispatch]
  );

  const setCursor = useCallback(
    (cursor: string) => {
      dispatch(customerSuccessAccessActions.setCursor(cursor));
    },
    [dispatch]
  );

  const setOrderBy = useCallback(
    (orderBy: 'user_full_name' | 'company_name') => {
      dispatch(customerSuccessAccessActions.setOrderBy(orderBy));
    },
    [dispatch]
  );

  const setOrderByDirection = useCallback(
    (orderByDirection: 'asc' | 'desc') => {
      dispatch(customerSuccessAccessActions.setOrderByDirection(orderByDirection));
    },
    [dispatch]
  );

  const setSearch = useCallback(
    (search: string) => {
      dispatch(customerSuccessAccessActions.setSearch(search));
    },
    [dispatch]
  );

  const setSearchField = useCallback(
    (searchField: 'user_full_name' | 'user_email' | 'company_name') => {
      dispatch(customerSuccessAccessActions.setSearchField(searchField));
    },
    [dispatch]
  );

  const fetchAllCustomerSuccessAccess = useCallback(() => {
    dispatch(customerSuccessAccessActions.fetchAllCustomerSuccessAccess());
  }, [dispatch]);


  const postCustomerSuccessAccess = useCallback(
    (customerSuccessAccess: any) => {
      dispatch(customerSuccessAccessActions.postCustomerSuccessAccess(customerSuccessAccess));
    },
    [dispatch]
  );

  const deleteCompanySuccessAccess = useCallback(
    (customerSuccessAccess: any) => {
      dispatch(customerSuccessAccessActions.deleteCustomerSuccessAccess(customerSuccessAccess));
    },
    [dispatch]
  );

  const setCompanyId = useCallback(
    (companyId: string) => {
      dispatch(customerSuccessAccessActions.setCompanyId(companyId));
    },
    [dispatch]
  );

  const setUserId = useCallback(
    (userId: string) => {
      dispatch(customerSuccessAccessActions.setUserId(userId));
    },
    [dispatch]
  );

  const clearAllCustomerSuccessAccess = useCallback(() => {
    dispatch(customerSuccessAccessActions.clearAllCustomerSuccessAccess());
  }, [dispatch]);

  const clearManageAccessTableCompanyId = useCallback(() => {
    dispatch(customerSuccessAccessActions.clearManageAccessTableCompanyId());
  }, [dispatch]);

  const clearManageAccessTableUserId = useCallback(() => {
    dispatch(customerSuccessAccessActions.clearManageAccessTableUserId());
  }, [dispatch]);

  const fetchCustomerSuccessCompanies = useCallback(
    (search?: string) => {
      dispatch(customerSuccessAccessActions.fetchCustomerSuccessCompanies(search));
    },
    [dispatch]
  );

  const setCustomerSuccessCompaniesSearch = useCallback(
    (search: string) => {
      dispatch(customerSuccessAccessActions.setCustomerSuccessCompaniesSearch(search));
    },
    [dispatch]
  );

  const setCustomerSuccessCompany = useCallback(
    (companyId: string) => {
      console.log('hook setCustomerSuccessCompany', companyId);
      dispatch(customerSuccessAccessActions.setCustomerSuccessCompany(companyId));
    },
    [dispatch]
  );

  const fetchCustomerSuccessAccessCompanyOptions = useCallback(
    (search?: string, user_id?: string) => {
      dispatch(
        customerSuccessAccessActions.fetchCustomerSuccessAccessCompanyOptions(search, user_id)
      );
    },
    [dispatch]
  );

  const fetchCustomerSuccessAccessUserOptions = useCallback(
    (search?: string, company_id?: string) => {
      dispatch(
        customerSuccessAccessActions.fetchCustomerSuccessAccessUserOptions(search, company_id)
      );
    },
    [dispatch]
  );

  const setCustomerSuccessAccessCompanyOptionsLoading = useCallback(
    (is_customer_success_access_company_options_loading: boolean) => {
      dispatch(
        customerSuccessAccessActions.setCustomerSuccessAccessCompanyOptionsLoading(
          is_customer_success_access_company_options_loading
        )
      );
    },
    [dispatch]
  );

  const setCustomerSuccessAccessUserOptionsLoading = useCallback(
    (is_customer_success_access_user_options_loading: boolean) => {
      dispatch(
        customerSuccessAccessActions.setCustomerSuccessAccessUserOptionsLoading(
          is_customer_success_access_user_options_loading
        )
      );
    },
    [dispatch]
  );
  const setCustomerSuccessAccessCompanyOptionsSearch = useCallback(
    (customer_success_access_company_options_search: string) => {
      dispatch(
        customerSuccessAccessActions.setCustomerSuccessAccessCompanyOptionsSearch(
          customer_success_access_company_options_search
        )
      );
    },
    [dispatch]
  );
  const setCustomerSuccessAccessUserOptionsSearch = useCallback(
    (customer_success_access_user_options_search: string) => {
      dispatch(
        customerSuccessAccessActions.setCustomerSuccessAccessUserOptionsSearch(
          customer_success_access_user_options_search
        )
      );
    },
    [dispatch]
  );

  return {
    limit,
    cursor,
    orderBy,
    orderByDirection,
    search,
    searchField,
    loading,
    customerSuccessAccess,
    companyId,
    userId,
    postCustomerSuccessAccessLoading,
    postCustomerSuccessAccessSuccess,
    error,
    deleteCustomerSuccessAccessLoading,
    deleteCustomerSuccessAccessSuccess,
    customerSuccessCompanies,
    customerSuccessCompaniesLoading,
    customerSuccessCompaniesSearch,
    fetchCustomerSuccessCompanies,
    setCustomerSuccessCompaniesSearch,
    customerSuccessSwitchError,
    customerSuccessSwitchLoading,
    setLimit,
    setCursor,
    setOrderBy,
    setOrderByDirection,
    setSearch,
    setSearchField,
    fetchAllCustomerSuccessAccess,
    postCustomerSuccessAccess,
    deleteCompanySuccessAccess,
    setCompanyId,
    setUserId,
    setCustomerSuccessCompany,
    clearAllCustomerSuccessAccess,
    clearManageAccessTableCompanyId,
    clearManageAccessTableUserId,
    fetchCustomerSuccessAccessCompanyOptions,
    fetchCustomerSuccessAccessUserOptions,
    customerSuccessAccessCompanyOptions,
    customerSuccessAccessUserOptions,
    customerSuccessAccessCompanyOptionsSearch,
    customerSuccessAccessUserOptionsSearch,
    customerSuccessAccessUserOptionsLoading,
    customerSuccessAccessCompanyOptionsLoading,
    setCustomerSuccessAccessCompanyOptionsLoading,
    setCustomerSuccessAccessUserOptionsLoading,
    setCustomerSuccessAccessCompanyOptionsSearch,
    setCustomerSuccessAccessUserOptionsSearch,
  };
};

export default useCustomerSuccessAccess;
