import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TicketRequestObj, TicketStatusContent } from 'src/@types/ticket';
import ticketActions from 'src/store/Ticket/actions';
import ticketSelectors from 'src/store/Ticket/selectors';

const useTicket = () => {

  const popoverOpened = useSelector(ticketSelectors.popoverOpened);
  const loading = useSelector(ticketSelectors.loading);
  const ticketStatus = useSelector(ticketSelectors.getTicketStatus);
  const tickets = useSelector(ticketSelectors.getTicket);
  const limit = useSelector(ticketSelectors.getLimit);
  const cursor = useSelector(ticketSelectors.getCursor);
  const orderBy = useSelector(ticketSelectors.getOrderBy);
  const orderByDirection = useSelector(ticketSelectors.getOrderByDirection);
  const search = useSelector(ticketSelectors.getSearch);
  const searchField = useSelector(ticketSelectors.getSearchField);
  const statusId = useSelector(ticketSelectors.getStatusId);

  const dispatch = useDispatch();

  const fetchAllTickets = useCallback(() => {
    dispatch(ticketActions.fetchAllTickets());
  }, [dispatch]);

  const setLimit = useCallback(
    (limit: number) => {
      dispatch(ticketActions.setLimit(limit));
    },
    [dispatch]
  );

  const setCursor = useCallback(
    (cursor: string) => {
      dispatch(ticketActions.setCursor(cursor));
    },
    [dispatch]
  );

  const setOrderBy = useCallback(
    (orderBy: 'date_created' | 'url' | 'description') => {
      dispatch(ticketActions.setOrderBy(orderBy));
    },
    [dispatch]
  );

  const setOrderByDirection = useCallback(
    (orderByDirection: 'asc' | 'desc') => {
      dispatch(ticketActions.setOrderByDirection(orderByDirection));
    },
    [dispatch]
  );

  const setSearch = useCallback(
    (search: string) => {
      dispatch(ticketActions.setSearch(search));
    },
    [dispatch]
  );

  const setSearchField = useCallback(
    (searchField: 'url' | 'description') => {
      dispatch(ticketActions.setSearchField(searchField));
    },
    [dispatch]
  );

  const setStatusId = useCallback(
    (statusId: string) => {
      dispatch(ticketActions.setStatusId(statusId));
    },
    [dispatch]
  );

  const setPopoverOpened = useCallback((popoverOpened: boolean) => {
    dispatch(ticketActions.setPopoverOpened(popoverOpened));
  }, [dispatch]);

  const setLoading = useCallback((loading: boolean) => {
    dispatch(ticketActions.setLoading(loading));
  }, [dispatch]);

  const patchTicket = useCallback(
    (ticketId: string, editorContent: TicketRequestObj) => {
      dispatch(ticketActions.patchAnnouncement(ticketId, editorContent));
    },
    [dispatch]
  );

  const fetchTicketStatus = useCallback(() => {
    dispatch(ticketActions.fetchTicketStatus());
  }, [dispatch]);


  const setTicketStatus = useCallback((status: TicketStatusContent) => {
    dispatch(ticketActions.setTicketStatus(status));
  }, [dispatch]);


  return {
    setPopoverOpened,
    setLoading,
    popoverOpened,
    loading,
    ticketStatus,
    tickets,
    limit,
    cursor,
    orderBy,
    orderByDirection,
    search,
    searchField,
    statusId,
    fetchAllTickets,
    setLimit,
    setCursor,
    setOrderBy,
    setOrderByDirection,
    setSearch,
    setSearchField,
    setStatusId,
    patchTicket,
    fetchTicketStatus,
    setTicketStatus,
    
  };
};

export default useTicket;