import { combineReducers } from 'redux';

import ToastReducer from 'src/store/Toast/reducer';
import AuthenticationReducer from 'src/store/Authentication/reducer';
import ProfileReducer from 'src/store/Profile/reducer';
import SiteReducer from 'src/store/Site/reducer';
import NotificationReducer from 'src/store/Notification/reducer';
import ProcessingPipelineReducer from 'src/store/ProcessingPipeline/reducer';
import TenantReducer from 'src/store/Tenant/reducer';
import SiteSearchQueryReducer from 'src/store/SiteSearchQuery/reducer';
import UsersReducer from 'src/store/Users/reducer';
import CompaniesReducer from 'src/store/Companies/reducer';
import ProcessingReducer from 'src/store/Processing/reducer';
import ManageAccessReducer from 'src/store/ManageAccess/reducer';
import AnnouncementReducer from 'src/store/Announcement/reducer';
import TicketReducer from 'src/store/Ticket/reducer';
import QcReducer from 'src/store/Qc/reducer';
import ImportJobsReducer from 'src/store/ImportJobs/reducer';
import ExportJobsReducer from 'src/store/ExportJobs/reducer';
import CustomerSuccessAccessReducer from 'src/store/CustomerSuccessAccess/reducer';

const reducers = combineReducers({
  Toast: ToastReducer,
  Authentication: AuthenticationReducer,
  Profile: ProfileReducer,
  Site: SiteReducer,
  Notification: NotificationReducer,
  ProcessingPipeline: ProcessingPipelineReducer,
  Tenant: TenantReducer,
  SiteSearchQuery: SiteSearchQueryReducer,
  Users: UsersReducer,
  Companies: CompaniesReducer,
  Processing: ProcessingReducer,
  ManageAccess: ManageAccessReducer,
  Announcement: AnnouncementReducer,
  Ticket: TicketReducer,
  Qc: QcReducer,
  ImportJobs: ImportJobsReducer,
  ExportJobs: ExportJobsReducer,
  CustomerSuccessAccess: CustomerSuccessAccessReducer,
});

export default reducers;
export type ReducerState = ReturnType<typeof reducers>;
