import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// actions
import actions from 'src/store/SiteSearchQuery/actions';

// selectors
import SiteSearchQuerySelectors from 'src/store/SiteSearchQuery/selectors';

const useSiteSearchQuery = () => {
  // Quick Search Dialog.
  const quickSearchQuery = useSelector(SiteSearchQuerySelectors.getQuickSearchQuery);
  const quickSearchQueryResult = useSelector(SiteSearchQuerySelectors.getQuickSearchQueryResult);
  const isQuickSearchLoading = useSelector(SiteSearchQuerySelectors.getQuickSearchLoading);

  // Sites page.
  const searchQuery = useSelector(SiteSearchQuerySelectors.getSearchQuery);
  const searchQueryResult = useSelector(SiteSearchQuerySelectors.getSearchQueryResult);
  const isSearchLoading = useSelector(SiteSearchQuerySelectors.getSearchLoading);

  // Select options.
  const isSelectOptionsLoading = useSelector(SiteSearchQuerySelectors.getSelectOptionsLoading);
  const assetClassesSelectOptions = useSelector(
    SiteSearchQuerySelectors.getAssetClassesSelectOptions
  );
  const structureTypesSelectOptions = useSelector(
    SiteSearchQuerySelectors.getStructureTypesSelectOptions
  );
  const towerOwnersSelectOptions = useSelector(
    SiteSearchQuerySelectors.getTowerOwnersSelectOptions
  );
  const vendorsSelectOptions = useSelector(SiteSearchQuerySelectors.getVendorsSelectOptions);

  const dispatch = useDispatch();

  // Quick sites dialog.
  const setQuickSearchQuery = useCallback(
    (searchQuery) => {
      dispatch(actions.setQuickSearchQuery(searchQuery));
    },
    [dispatch]
  );

  const setQuickSearchLoading = useCallback(
    (isQuickSearchLoading) => {
      dispatch(actions.setQuickSearchLoading(isQuickSearchLoading));
    },
    [dispatch]
  );

  const setQuickSearchQueryClear = useCallback(() => {
    dispatch(actions.setQuickSearchQueryClear());
  }, [dispatch]);

  // Sites search page.
  const setSearchQuery = useCallback(
    (searchQuery) => {
      dispatch(actions.setSearchQuery(searchQuery));
    },
    [dispatch]
  );

  const setSearchLoading = useCallback(
    (isSearchLoading) => {
      dispatch(actions.setSearchLoading(isSearchLoading));
    },
    [dispatch]
  );

  const setSearchQueryClear = useCallback(() => {
    dispatch(actions.setSearchQueryClear());
  }, [dispatch]);

  const getSearchQuerySelectOptions = useCallback(() => {
    dispatch(actions.getSearchQuerySelectOptions());
  }, [dispatch]);

  return {
    // quick site search.
    isQuickSearchLoading,
    quickSearchQuery,
    quickSearchQueryResult,
    setQuickSearchQuery,
    setQuickSearchLoading,
    setQuickSearchQueryClear,

    // site page.
    isSearchLoading,
    searchQuery,
    searchQueryResult,
    setSearchQuery,
    setSearchLoading,
    setSearchQueryClear,

    // select options.
    getSearchQuerySelectOptions,
    isSelectOptionsLoading,
    assetClassesSelectOptions,
    structureTypesSelectOptions,
    towerOwnersSelectOptions,
    vendorsSelectOptions,
  };
};

export default useSiteSearchQuery;
