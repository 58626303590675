import { NotificationEditorObj, NotificationPagination, NotificationTypesContent } from 'src/@types/notification';
import { NotificationAction, NotificationActionType } from './types';

const actions = {
  setError: (error: Error): NotificationAction => ({
    payload: { error },
    type: NotificationActionType.SET_ERROR,
  }),
  clearState: (): NotificationAction => ({
    type: NotificationActionType.CLEAR,
  }),
  fetchAllNotifications: (): NotificationAction => ({
    type: NotificationActionType.FETCH_ALL_NOTIFICATIONS,
  }),
  setNotifications: (notification_pagination: NotificationPagination): NotificationAction => ({
    payload: { notification_pagination },
    type: NotificationActionType.SET_NOTIFICATIONS,
  }),
  setLimit: (limit: number): NotificationAction => ({
    payload: { limit },
    type: NotificationActionType.SET_LIMIT,
  }),
  setCursor: (cursor: string): NotificationAction => ({
    payload: { cursor },
    type: NotificationActionType.SET_CURSOR,
  }),
  setOrderBy: (order_by: 'date_created' | 'content'): NotificationAction => ({
    payload: { order_by },
    type: NotificationActionType.SET_ORDERBY,
  }),
  setOrderByDirection: (order_by_direction: 'asc' | 'desc'): NotificationAction => ({
    payload: { order_by_direction },
    type: NotificationActionType.SET_ORDERBY_DIRECTION,
  }),
  setSearch: (search: string): NotificationAction=> ({
    payload: { search },
    type: NotificationActionType.SET_SEARCH,
  }),
  setSearchField: (search_field: 'url' | 'content'): NotificationAction=> ({
    payload: { search_field },
    type: NotificationActionType.SET_SEARCH_FIELD,
  }),
  postNotification: (notification_editor_obj: NotificationEditorObj): NotificationAction=> ({
    payload: { notification_editor_obj },
    type: NotificationActionType.CREATE_NOTIFICATION,
  }),
  patchNotification: (notification_id: string, notification_editor_obj: NotificationEditorObj): NotificationAction=> ({
    payload: { notification_id, notification_editor_obj },
    type: NotificationActionType.UPDATE_NOTIFICATION,
  }),
  setIsNotificationPageLoading: (is_notification_page_loading: boolean): NotificationAction => ({
    payload: { is_notification_page_loading },
    type: NotificationActionType.SET_IS_NOTIFICATION_PAGE_LOADING
  }),
  fetchNotificationTypes: (): NotificationAction => ({
    type: NotificationActionType.FETCH_NOTIFICATION_TYPES
  }),
  setNotificationTypes: (notification_types: NotificationTypesContent): NotificationAction => ({
    payload: { notification_types },
    type: NotificationActionType.SET_NOTIFICATION_TYPES
  }),
  setNotificationEditorObj: (notification_editor_obj: NotificationEditorObj): NotificationAction => ({
    payload: { notification_editor_obj },
    type: NotificationActionType.SET_NOTIFICATION_EDITOR_OBJ
  }),
  setIsUpdateNotification: (is_update_notification: boolean): NotificationAction => ({
    payload: { is_update_notification },
    type: NotificationActionType.SET_IS_UPDATE_NOTIFICATION
  }),
  setIsCreateNotification: (is_create_notification: boolean): NotificationAction => ({
    payload: { is_create_notification },
    type: NotificationActionType.SET_IS_CREATE_NOTIFICATION
  }),
  setIsSubmitingLoading: (is_submiting_loading: boolean): NotificationAction => ({
    payload: { is_submiting_loading },
    type: NotificationActionType.SET_IS_SUBMITING_LOADING
  }),
  setNotificationCreateSuccess: (is_notification_create_success: boolean): NotificationAction=> ({
    payload: { is_notification_create_success },
    type: NotificationActionType.SET_NOTIFICATION_CREATE_SUCCESS,
  }),
  setNotificationUpdateSuccess: (is_notification_update_success: boolean): NotificationAction=> ({
    payload: { is_notification_update_success },
    type: NotificationActionType.SET_NOTIFICATION_UPDATE_SUCCESS,
  }),
};

export default actions;
