import { put, call, takeLatest } from 'redux-saga/effects';
import actions from './actions';
import { ProcessingActionType } from './types';
import { getModelProcessingQueues } from 'src/servicesV2/site';

export function* fetchModelProcessingQueues() {
  try {
    yield put(actions.setLoading(true));
    const response = yield call(getModelProcessingQueues);

    yield put(actions.setModelProcessingQueues(response));
  } catch (e) {
    console.log(e);
    // yield put(actions.setError(e as Error));
  } finally {
    yield put(actions.setLoading(false));
  }
}

const sagas = [
  takeLatest(ProcessingActionType.FETCH_MODEL_PROCESSING_QUEUES, fetchModelProcessingQueues),
];

export default sagas;