import { AnnouncementPagination, AnnouncementContent, AnnouncementEditorObj } from 'src/@types/announcement';

export enum AnnouncementActionType {
  FETCH_ALL_ANNOUNCEMENTS = 'Announcement@FETCH_ALL_ANNOUNCEMENTS',
  SET_ANNOUNCEMENT = 'Announcement@SET_ANNOUNCEMENT',
  FETCH_ANNOUNCEMENT_CONTENT = 'Announcement@FETCH_ANNOUNCEMENT_CONTENT',
  SET_ANNOUNCEMENT_CONTENT = 'Announcement@SET_ANNOUNCEMENT_CONTENT',
  SET_LIMIT = 'Announcement@SET_LIMIT',
  SET_CURSOR = 'Announcement@SET_CURSOR',
  SET_ORDERBY = 'Announcement@SET_ORDERBY',
  SET_ORDERBY_DIRECTION = 'Announcement@SET_ORDERBY_DIRECTION',
  SET_SEARCH = 'Announcement@SET_SEARCH',
  SET_SEARCH_FIELD = 'Announcement@SET_SEARCH_FIELD',
  SET_LOADING = 'Announcement@SET_LOADING',
  SET_ANNOUNCEMENT_EDITOR_OBJ = 'Announcement@SET_ANNOUNCEMENT_EDITOR_OBJ',
  POST_ANNOUNCEMENT = 'Announcement@POST_ANNOUNCEMENT',
  SET_IS_EDITOR_UPLOADING = 'Announcement@SET_IS_EDITOR_UPLOADING',
  SET_FINISH_EDITOR_UPLOADING = 'Announcement@SET_FINISH_EDITOR_UPLOADING',
  PATCH_ANNOUNCEMENT = 'Announcement@PATCH_ANNOUNCEMENT',
  SET_ERROR = 'Announcement@SET_ERROR',
  CLEAR = 'Announcement@CLEAR',
}

export type AnnouncementState = {
  error?: Error;
  announcement_pagination?: AnnouncementPagination;
  announcement_content?: AnnouncementContent;
  limit: number;
  cursor?: string;
  order_by?: 'date_created' | 'title';
  order_by_direction?: 'asc' | 'desc';
  search?: string;
  search_field?: 'title' | 'content';
  is_loading: boolean;
  announcement_editor_obj: AnnouncementEditorObj;
  is_editor_uploading: boolean;
  finish_editor_uploading: boolean;
};

export type AnnouncementPayload = {
  error?: Error;
  announcement_pagination?: AnnouncementPagination;
  announcement_id?: string;
  announcement_content?: AnnouncementContent;
  limit?: number;
  cursor?: string;
  order_by?: 'date_created' | 'title';
  order_by_direction?: 'asc' | 'desc';
  search?: string;
  search_field?: 'title' | 'content';
  is_loading?: boolean;
  announcement_editor_obj?: AnnouncementEditorObj;
  editor_content?: string;
  is_editor_uploading?: boolean;
  finish_editor_uploading?: boolean;
};

export type AnnouncementAction = {
  payload?: AnnouncementPayload;
  type: AnnouncementActionType;
};

export type AnnouncementReducer = (
  state: AnnouncementState,
  payload?: AnnouncementPayload
) => AnnouncementState;
